import { createSlice } from "@reduxjs/toolkit";

export const ManualInvSlice = createSlice({
  name: "Invoice",
  initialState: {
    Common: {
      ScrName: "ManualInvoice",
      BSId: 0, //Branch State Id
      Curr_Date: new Date().toISOString().split("T")[0],
      arrEntInvCol: [],
      arrEntPermission: [],
      arrEnttableCol: [],
      arrEntCountry: [],
      arrEntState: [],
      arrEntBillType: [],
      arrEntTaxType: [],
      InvColPer: {
        strCustName: true,
        strInvNo: true,
        dpInvDate: true,
        strMobileNo: true,
        strEmail: true,
        strAddress: true,
        strShipAdd: true,
        strPartyDC: false,
        strRefNo1: false,
        dpRefDate1: false,
        strRefNo2: false,
        dpRefDate2: false,
        strRefNo3: false,
        dpRefDate3: false,
        strRefNo4: false,
        dpRefDate4: false,
        deliveryMode: false,
        strRefName: false,
        strAgentName: false,
        strEcomGSTN: false,
        strValidDays: false,
        dpValidDate: false,
        strPayTerms: false,
        dpPayTermUpto: false,
        strDelTerms: false,
        dpDelTermUpto: false,
        strTransport: false,
        strVechicleNo: false,
        strDestination: false,
        strDespatch: false,
        dpDespatchDate: false,
        strLicenseNo: false,
        dpLicenseDate: false,
        strRemark1: false,
        strRemark2: false,
        strRemark3: false,
        strRemark4: false,
      },
    },
    HT: [
      {
        InvId: 0,
        PreFix: "",
        NumSeqNo: "",
        ManualNo: true,
        InvNo: "",
        InvDate: new Date().toISOString().split("T")[0],
        PartyVouchers: "",
        Customer: "",
        BT_Id: 1,
        TaxRelId: 1,
        ItemCount: 0,
        Qty: 0,
        FQty: 0,
        GoodsValue: 0,
        ItemDiscVal: 0,
        CashDiscVal: 0,
        ALDiscPer: 0,
        ALDiscAmt: 0,
        ALDiscVal: 0,
        BillDiscPer: 0,
        BillDiscAmt: 0,
        BillDiscVal: 0,
        ALAmt: 0,
        DiscAmt: 0,
        TCSTaxAmt: 0,
        TaxAmt: 0,
        TaxableAmt: 0,
        GrossAmt: 0,
        RoundOff: 0,
        NetAmt: 0,
        PaidAmt: 0,
        BalAmt: 0,
        BillStatus: 0,
        CreatedBy: 0,
        BranchId: 0,
        TaxCalcOnId: 0,
        OtherCal: "No",
        AutoRoundOff: 0,
        isOnchange: false,
      },
    ],
    HTA: [
      {
        InvId: 0,
        RefNum1: "",
        RefDate1: new Date().toISOString().split("T")[0],
        RefNum2: "",
        RefDate2: new Date().toISOString().split("T")[0],
        RefNum3: "",
        RefDate3: new Date().toISOString().split("T")[0],
        RefNum4: "",
        RefDate4: new Date().toISOString().split("T")[0],
        DeliveryMode: "",
        RefName: "",
        AgentName: "",
        EComGSTNo: "",
        ValidDays: "",
        ValidUpto: new Date().toISOString().split("T")[0],
        PayTermDays: "",
        PayTermUpto: new Date().toISOString().split("T")[0],
        DeliTermDays: "",
        DeliTermUpto: new Date().toISOString().split("T")[0],
        Transport: "",
        VehicleNumber: "",
        Despatch: "",
        DespatchDate: new Date().toISOString().split("T")[0],
        Destination: "",
        LicenseNo: "",
        LicenseDate: new Date().toISOString().split("T")[0],
        Remark1: "",
        Remark2: "",
        Remark3: "",
        Remark4: "",
      },
    ],
    DT: {
      arrEntDT: [],
      arrEntDTTax: [],
      arrEntTax: [],
      arrEntAL: [],
      arrEntALTax: [],
      NewProduct: [],
      isOnchange: false,
      isALOnchange: false,
    },
    Tax: {
      arrSGST: [],
      arrCGST: [],
      arrIGST: [],
      arrTcsTax: [],
      arrTaxType: [],
    },
    BillAdd: [
      {
        AddId: 0,
        CustName: "",
        AliasName: "",
        Add1: "",
        Add2: "",
        Add3: "",
        Add4: "",
        Pincode: "",
        State: "32",
        Country: "100",
        Mobile: "",
        Email: "",
        GST: "",
        Remarks: "",
        InvId: 0,
        BranchId: 0,
      },
    ],
    ShipAdd: [
      {
        ShipAddId: 0,
        CustName: "",
        AliasName: "",
        Add1: "",
        Add2: "",
        Add3: "",
        Add4: "",
        Pincode: "",
        State: "32",
        Country: "100",
        Mobile: "",
        Email: "",
        GST: "",
        Remarks: "",
        InvId: 0,
        BranchId: 0,
      },
    ],
  },
  reducers: {
    setdefaultdata: (state, action) => {
      state.HT[0].InvNo = action.payload.InvNo;
      state.HT[0].PreFix = action.payload.PreFix;
      state.HT[0].NumSeqNo = action.payload.NumSeqNo;
      state.HT[0].ManualNo = action.payload.ManualNo;
      state.HT[0].InvColPer = action.payload.InvColPer;
      state.HT[0].TaxCalcOnId = action.payload.TaxCalcOnId;
      state.HT[0].OtherCal = action.payload.OtherCal;
      state.HT[0].AutoRoundOff = action.payload.AutoRoundOff;
      state.Common.arrEntCountry = action.payload.EntCountry;
      state.Common.arrEntState = action.payload.EntState;
      state.Common.BSId = action.payload.BSId;
      state.Common.arrEntPermission = action.payload.Permission;
      state.Common.arrEntInvCol = action.payload.InvCol;
      state.Common.arrEnttableCol = action.payload.tblcol;
      state.Common.arrEntBillType = action.payload.BillType;
      state.Common.arrEntTaxType = action.payload.TaxType;
      state.DT.arrEntDT = action.payload.DT;
    },
    Add_customer: (state, action) => {
      state.HT[0].Customer = action.payload.CustName;
      state.BillAdd[0].AddId = 0;
      state.BillAdd[0].CustName = action.payload.CustName;
      state.BillAdd[0].AliasName = action.payload.AliasName;
      state.BillAdd[0].Add1 = action.payload.Add1;
      state.BillAdd[0].Add2 = action.payload.Add2;
      state.BillAdd[0].Add3 = action.payload.Add3;
      state.BillAdd[0].Add4 = action.payload.Add4;
      state.BillAdd[0].Pincode = action.payload.Pincode;
      state.BillAdd[0].State = action.payload.State;
      state.BillAdd[0].Country = action.payload.Country;
      state.BillAdd[0].Mobile = action.payload.Mobile;
      state.BillAdd[0].Email = action.payload.Email;
      state.BillAdd[0].GST = action.payload.GST;
      state.BillAdd[0].Remarks = action.payload.Remarks;
      state.ShipAdd[0].ShipAddId = 0;
      state.ShipAdd[0].CustName = action.payload.CustName;
      state.ShipAdd[0].AliasName = action.payload.AliasName;
      state.ShipAdd[0].Add1 = action.payload.Add1;
      state.ShipAdd[0].Add2 = action.payload.Add2;
      state.ShipAdd[0].Add3 = action.payload.Add3;
      state.ShipAdd[0].Add4 = action.payload.Add4;
      state.ShipAdd[0].Pincode = action.payload.Pincode;
      state.ShipAdd[0].State = action.payload.State;
      state.ShipAdd[0].Country = action.payload.Country;
      state.ShipAdd[0].Mobile = action.payload.Mobile;
      state.ShipAdd[0].Email = action.payload.Email;
      state.ShipAdd[0].GST = action.payload.GST;
      state.ShipAdd[0].Remarks = action.payload.Remarks;
    },
    setInvoiceHT: (state, action) => {
      state.HT[0][action.payload.Name] = action.payload.value;
    },
    setInvoiceHTA: (state, action) => {
      state.HTA[0][action.payload.Name] = action.payload.value;
    },
    setManualHT: (state, action) => {
      state.HT[0].InvId = action.payload.InvId;
      state.HT[0].PreFix = action.payload.PreFix;
      state.HT[0].NumSeqNo = action.payload.NumSeqNo;
      state.HT[0].ManualNo = action.payload.ManualNo;
      state.HT[0].InvNo = action.payload.InvNo;
      state.HT[0].InvDate = action.payload.InvDate;
      state.HT[0].PartyVouchers = action.payload.PartyVouchers;
      state.HT[0].Customer = action.payload.Customer;
      state.HT[0].iCustId = action.payload.iCustId;
      state.HT[0].BT_Id = action.payload.BT_Id;
      state.HT[0].TaxRelId = action.payload.TaxRelId;
      state.HT[0].ItemCount = action.payload.ItemCount;
      state.HT[0].Qty = action.payload.Qty;
      state.HT[0].FQty = action.payload.FQty;
      state.HT[0].GoodsValue = action.payload.GoodsValue;
      state.HT[0].ItemDiscVal = action.payload.ItemDiscVal;
      state.HT[0].CashDiscVal = action.payload.CashDiscVal;
      state.HT[0].ALDiscPer = action.payload.ALDiscPer;
      state.HT[0].ALDiscAmt = action.payload.ALDiscAmt;
      state.HT[0].ALDiscVal = action.payload.ALDiscVal;
      state.HT[0].BillDiscPer = action.payload.BillDiscPer;
      state.HT[0].BillDiscAmt = action.payload.BillDiscAmt;
      state.HT[0].BillDiscVal = action.payload.BillDiscVal;
      state.HT[0].ALAmt = action.payload.ALAmt;
      state.HT[0].DiscAmt = action.payload.DiscAmt;
      state.HT[0].TCSTaxAmt = action.payload.TCSTaxAmt;
      state.HT[0].TaxAmt = action.payload.TaxAmt;
      state.HT[0].TaxableAmt = action.payload.TaxableAmt;
      state.HT[0].GrossAmt = action.payload.GrossAmt;
      state.HT[0].RoundOff = action.payload.RoundOff;
      state.HT[0].NetAmt = action.payload.NetAmt;
      state.HT[0].PaidAmt = action.payload.PaidAmt;
      state.HT[0].BalAmt = action.payload.BalAmt;
      state.HT[0].BillStatus = action.payload.BillStatus;
      state.HT[0].CreatedBy = action.payload.CreatedBy;
      state.HT[0].BranchId = action.payload.BranchId;
      state.HT[0].TaxCalcOnId = action.payload.TaxCalcOnId;
      state.HT[0].OtherCal = action.payload.OtherCal;
      state.HT[0].AutoRoundOff = action.payload.AutoRoundOff;
      state.HT[0].isOnchange = action.payload.isOnchange;
      state.HTA[0].InvId = action.payload.InvId;
      state.HTA[0].RefNum1 = action.payload.RefNum1;
      state.HTA[0].RefDate1 = action.payload.RefDate1;
      state.HTA[0].RefNum2 = action.payload.RefNum2;
      state.HTA[0].RefDate2 = action.payload.RefDate2;
      state.HTA[0].RefNum3 = action.payload.RefNum3;
      state.HTA[0].RefDate3 = action.payload.RefDate3;
      state.HTA[0].RefNum4 = action.payload.RefNum4;
      state.HTA[0].RefDate4 = action.payload.RefDate4;
      state.HTA[0].DeliveryMode = action.payload.DeliveryMode;
      state.HTA[0].RefName = action.payload.RefName;
      state.HTA[0].AgentName = action.payload.AgentName;
      state.HTA[0].EComGSTNo = action.payload.EComGSTNo;
      state.HTA[0].ValidDays = action.payload.ValidDays;
      state.HTA[0].ValidUpto = action.payload.ValidUpto;
      state.HTA[0].PayTermDays = action.payload.PayTermDays;
      state.HTA[0].PayTermUpto = action.payload.PayTermUpto;
      state.HTA[0].DeliTermDays = action.payload.DeliTermDays;
      state.HTA[0].DeliTermUpto = action.payload.DeliTermUpto;
      state.HTA[0].Transport = action.payload.Transport;
      state.HTA[0].VehicleNumber = action.payload.VehicleNumber;
      state.HTA[0].Despatch = action.payload.Despatch;
      state.HTA[0].DespatchDate = action.payload.DespatchDate;
      state.HTA[0].Destination = action.payload.Destination;
      state.HTA[0].LicenseNo = action.payload.LicenseNo;
      state.HTA[0].LicenseDate = action.payload.LicenseDate;
      state.HTA[0].Remark1 = action.payload.Remark1;
      state.HTA[0].Remark2 = action.payload.Remark2;
      state.HTA[0].Remark3 = action.payload.Remark3;
      state.HTA[0].Remark4 = action.payload.Remark4;
      state.DT.arrEntDT = action.payload.arrEntDT;
      state.DT.arrEntDTTax = action.payload.arrEntDTTax;
      state.DT.arrEntAL = action.payload.arrEntAL;
      state.DT.arrEntALTax = action.payload.arrEntALTax;
      state.DT.arrEntTax = action.payload.arrEntTax;
      state.DT.isOnchange = false;
      state.DT.isALOnchange = false;
    },
    setInvoiceDT: (state, action) => {
      state.DT.arrEntDT = action.payload;
      state.HT[0].ItemCount = action.payload.length;
    },
    setisInvoiceDT_Onchange: (state, action) => {
      state.DT.isOnchange = action.payload;
    },
    setInvoicetaxdefault: (state, action) => {
      state.Tax = action.payload;
    },
    setTaxType: (state, action) => {
      state.Tax.TaxType = action.payload;
    },
    setTcsTax: (state, action) => {
      state.Tax.iTCSId = action.payload;
    },
    add_Othercharges: (state, action) => {
      state.DT.arrEntAL = action.payload;
    },
    setisAL_Onchange: (state, action) => {
      state.DT.isALOnchange = action.payload;
    },
    setisALDisc_Onchange: (state, action) => {
      state.HT[0].isOnchange = true;
      if (action.payload.Name === "ALDiscPer") {
        state.HT[0].ALDiscPer = action.payload.value;
        state.HT[0].ALDiscAmt = 0;
      } else if (action.payload.Name === "ALDiscAmt") {
        state.HT[0].ALDiscPer = 0;
        state.HT[0].ALDiscAmt = action.payload.value;
      }
    },
    setdiscount: (state, action) => {
      state.HT[0].isOnchange = true;
      if (action.payload.Name === "BillDiscPer") {
        state.HT[0].BillDiscPer = action.payload.value;
        state.HT[0].BillDiscAmt = 0;
      } else if (action.payload.Name === "BillDiscAmt") {
        state.HT[0].BillDiscAmt = action.payload.value;
        state.HT[0].BillDiscPer = 0;
      }
    },
    setBillAddress: (state, action) => {
      state.BillAdd = action.payload;
    },
    setShippAddress: (state, action) => {
      state.ShipAdd = action.payload;
    },
    setParty_Address: (state, action) => {
      state.BillAdd[0].AddId = action.payload.AddId;
      state.BillAdd[0].CustName = action.payload.CustName;
      state.BillAdd[0].AliasName = action.payload.AliasName;
      state.BillAdd[0].Add1 = action.payload.Add1;
      state.BillAdd[0].Add2 = action.payload.Add2;
      state.BillAdd[0].Add3 = action.payload.Add3;
      state.BillAdd[0].Add4 = action.payload.Add4;
      state.BillAdd[0].Pincode = action.payload.Pincode;
      state.BillAdd[0].State = action.payload.State;
      state.BillAdd[0].Country = action.payload.Country;
      state.BillAdd[0].Mobile = action.payload.Mobile;
      state.BillAdd[0].Email = action.payload.Email;
      state.BillAdd[0].GST = action.payload.GST;
      state.BillAdd[0].Remarks = action.payload.Remarks;
      state.BillAdd[0].InvId = action.payload.InvId;
      state.BillAdd[0].BranchId = action.payload.BranchId;

      state.ShipAdd[0].ShipAddId = action.payload.ShipAddId;
      state.ShipAdd[0].CustName = action.payload.ShipCustName;
      state.ShipAdd[0].AliasName = action.payload.ShipAliasName;
      state.ShipAdd[0].Add1 = action.payload.ShipAdd1;
      state.ShipAdd[0].Add2 = action.payload.ShipAdd2;
      state.ShipAdd[0].Add3 = action.payload.ShipAdd3;
      state.ShipAdd[0].Add4 = action.payload.ShipAdd4;
      state.ShipAdd[0].Pincode = action.payload.ShipPincode;
      state.ShipAdd[0].State = action.payload.ShipState;
      state.ShipAdd[0].Country = action.payload.ShipCountry;
      state.ShipAdd[0].Mobile = action.payload.ShipMobile;
      state.ShipAdd[0].Email = action.payload.ShipEmail;
      state.ShipAdd[0].GST = action.payload.ShipGST;
      state.ShipAdd[0].Remarks = action.payload.ShipRemarks;
      state.ShipAdd[0].InvId = action.payload.InvId;
      state.ShipAdd[0].BranchId = action.payload.BranchId;
    },
    setBillAdd_Onchange: (state, action) => {
      state.BillAdd[0][action.payload.Name] = action.payload.value;
    },
    setShippAdd_Onchange: (state, action) => {
      state.ShipAdd[0][action.payload.Name] = action.payload.value;
    },
    Update_Calculation: (state, action) => {
      state.DT.arrEntDT = action.payload.arrEntDT;
      state.DT.arrEntDTTax = action.payload.arrEntDTTax;
      state.DT.arrEntAL = action.payload.arrEntAL;
      state.DT.arrEntALTax = action.payload.arrEntALTax;
      state.DT.arrEntTax = action.payload.arrEntTax;

      state.HT[0].Qty = action.payload.Summary.Qty;
      state.HT[0].GoodsValue = action.payload.Summary.GoodsValue;
      state.HT[0].DiscAmt = action.payload.Summary.DiscAmt;
      state.HT[0].TaxAmt = action.payload.Summary.TaxAmt;
      state.HT[0].TCSTaxAmt = action.payload.Summary.TCSTaxAmt;
      state.HT[0].ALAmt = action.payload.Summary.ALAmt;
      state.HT[0].RoundOff = action.payload.Summary.RoundOff;
      state.HT[0].NetAmt = action.payload.Summary.NetAmt;
      state.HT[0].BillDiscVal = action.payload.BillDiscVal;
      state.DT.isOnchange = false;
      state.DT.isALOnchange = false;
      state.HT[0].isOnchange = false;
    },
    setState: (state, action) => {
      if (action.payload.Name === "Bill") {
        state.BillAdd[0].State = action.payload.State.toString();
        state.HT[0].TaxRelId = action.payload.TaxRelId;
      } else {
        state.ShipAdd[0].State = action.payload.State.toString();
      }
    },
    setCountry: (state, action) => {
      state.Common.arrEntState = action.payload.EntState;
      if (action.payload.Name === "Bill") {
        state.BillAdd[0].Country = action.payload.Country.toString();
      } else {
        state.ShipAdd[0].Country = action.payload.Country.toString();
      }
    },
    Clear_ManualInvoice: (state) => {
      state.Common = {
        ScrName: "ManualInvoice",
        BSId: 0, //Branch State Id
        Curr_Date: new Date().toISOString().split("T")[0],
        arrEntInvCol: [],
        arrEntPermission: [],
        arrEnttableCol: [],
        arrEntCustomer: [],
        arrEntCountry: [],
        arrEntState: [],
        InvColPer: {
          strCustName: true,
          strInvNo: true,
          dpInvDate: true,
          strMobileNo: true,
          strEmail: true,
          strAddress: true,
          strShipAdd: true,
          strPartyDC: false,
          strRefNo1: false,
          dpRefDate1: false,
          strRefNo2: false,
          dpRefDate2: false,
          strRefNo3: false,
          dpRefDate3: false,
          strRefNo4: false,
          dpRefDate4: false,
          strRefName: false,
          strAgentName: false,
          strEcomGSTN: false,
          strValidDays: false,
          dpValidDate: false,
          strPayTerms: false,
          dpPayTermUpto: false,
          strDelTerms: false,
          dpDelTermUpto: false,
          strTransport: false,
          strVechicleNo: false,
          strDestination: false,
          strDespatch: false,
          dpDespatchDate: false,
          strLicenseNo: false,
          dpLicenseDate: false,
          strRemark1: false,
          strRemark2: false,
          strRemark3: false,
          strRemark4: false,
        },
      };
      state.HT = [
        {
          InvId: 0,
          PreFix: "",
          NumSeqNo: "",
          ManualNo: true,
          InvNo: "",
          InvDate: new Date().toISOString().split("T")[0],
          PartyVouchers: "",
          Customer: "",
          iCustId: 0,
          BT_Id: 0,
          TaxRelId: 1,
          DM_Id: 0,
          ItemCount: 0,
          Qty: 0,
          FQty: 0,
          GoodsValue: 0,
          ItemDiscVal: 0,
          CashDiscVal: 0,
          ALDiscPer: 0,
          ALDiscAmt: 0,
          ALDiscVal: 0,
          BillDiscPer: 0,
          BillDiscAmt: 0,
          BillDiscVal: 0,
          ALAmt: 0,
          DiscAmt: 0,
          TCSTaxAmt: 0,
          TaxAmt: 0,
          TaxableAmt: 0,
          GrossAmt: 0,
          RoundOff: 0,
          NetAmt: 0,
          PaidAmt: 0,
          BalAmt: 0,
          BillStatus: 0,
          CreatedBy: 0,
          BranchId: 0,
          TaxCalcOnId: 0,
          OtherCal: "No",
          AutoRoundOff: 0,
        },
      ];
      state.HTA = [
        {
          InvId: 0,
          RefNum1: "",
          RefDate1: new Date().toISOString().split("T")[0],
          RefNum2: "",
          RefDate2: new Date().toISOString().split("T")[0],
          RefNum3: "",
          RefDate3: new Date().toISOString().split("T")[0],
          RefNum4: "",
          RefDate4: new Date().toISOString().split("T")[0],
          RefName: "",
          AgentName: "",
          EComGSTNo: "",
          ValidDays: "",
          ValidUpto: new Date().toISOString().split("T")[0],
          PayTermDays: "",
          PayTermUpto: new Date().toISOString().split("T")[0],
          DeliTermDays: "",
          DeliTermUpto: new Date().toISOString().split("T")[0],
          Transport: "",
          VehicleNumber: "",
          Despatch: "",
          DespatchDate: new Date().toISOString().split("T")[0],
          Destination: "",
          LicenseNo: "",
          LicenseDate: new Date().toISOString().split("T")[0],
          Remark1: "",
          Remark2: "",
          Remark3: "",
          Remark4: "",
        },
      ];
      state.DT = {
        arrEntDT: [],
        arrEntDTTax: [],
        arrEntTax: [],
        arrEntAL: [],
        arrEntALTax: [],
        NewProduct: [],
        isOnchange: false,
        isALOnchange: false,
      };
      state.Summary = {
        Qty: 0,
        GoodsValue: 0,
        Discount: 0,
        Tax: 0,
        TCSTax: 0,
        AddLess: 0,
        RoundOff: 0,
        NetAmt: 0,
      };
      state.Tax = {
        arrSGST: [],
        arrCGST: [],
        arrIGST: [],
        arrTcsTax: [],
        arrTaxType: [],
      };
      state.Discount = {
        dBillDiscPer: 0,
        dBillDiscAmt: 0,
        dBillDiscValue: 0,
        dALDiscPer: 0,
        dALDiscAmt: 0,
        dALDiscValue: 0,
        isOnchange: false,
      };
      state.BillAdd = [
        {
          AddId: 0,
          CustName: "",
          Add1: "",
          Add2: "",
          Add3: "",
          Add4: "",
          Pincode: "",
          State: "32",
          Country: "100",
          Mobile: "",
          Email: "",
          GST: "",
          BranchId: 0,
        },
      ];
      state.ShipAdd = [
        {
          ShipAddId: 0,
          AddId: 0,
          CustName: "",
          Add1: "",
          Add2: "",
          Add3: "",
          Add4: "",
          Pincode: "",
          State: "32",
          Country: "100",
          Mobile: "",
          Email: "",
          GST: "",
          BranchId: 0,
        },
      ];
    },
  },
});

export const {
  setdefaultdata,
  Add_customer,
  setInvoiceHT,
  setInvoiceDT,
  setManualHT,
  setisInvoiceDT_Onchange,
  setInvoiceSummary,
  setInvoicetaxdefault,
  setTaxType,
  setTcsTax,
  add_Othercharges,
  setdiscount,
  setInvoiceHTA,
  setisAL_Onchange,
  setBillAddress,
  setShippAddress,
  setParty_Address,
  setBillAdd_Onchange,
  setShippAdd_Onchange,
  Update_Calculation,
  setState,
  setCountry,
  Clear_ManualInvoice,
} = ManualInvSlice.actions;
export default ManualInvSlice.reducer;
