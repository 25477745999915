import React, { useEffect, useState } from "react";
import Button from "../Master/MasterButton";
import MasterList from "../Master/MasterListItem";
import SearchOtherHeadLedger from "./SearchOtherHeadLedger";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import { useDispatch, useSelector } from "react-redux";
function TaxMaster() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const strScreenName = "TAX MASTER";
  const iScrId = 6;
  const [IsLoding, setIsLoding] = useState(true);
  const [strMessage, setstrMessage] = useState("");
  const [strIcon, setstrIcon] = useState("");
  const [IsNotification, setIsNotification] = useState(false);
  const [IsDialogOpen, setIsDialogOpen] = useState(false);
  const [IsDialogFor, setIsDialogFor] = useState("");
  const [strQuestion, setstrQuestion] = useState("");
  const [iTaxId, setiTaxId] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const [dTaxPer, setdTaxPer] = useState(0);
  const [iTaxTypeId, setiTaxTypeId] = useState(1);
  const [iStatusId, setiStatusId] = useState(1);
  const [iPurLedId, setiPurLedId] = useState(0);
  const [iSalLedId, setiSalLedId] = useState(0);
  const [iJobLedId, setiJobLedId] = useState(0);
  const [iSalTaxLedId, setiSalTaxLedId] = useState(0);
  const [iPurRetLedId, setiPurRetLedId] = useState(0);
  const [iSalRetLedId, setiSalRetLedId] = useState(0);
  const [iPurTaxLedId, setiPurTaxLedId] = useState(0);
  const [iJobTaxLedId, setiJobTaxLedId] = useState(0);
  const [iSerInputTaxLedId, setiSerInputTaxLedId] = useState(0);
  const [iSerOutputTaxLedId, setiSerOutputTaxLedId] = useState(0);

  const [strTaxDesc, setstrTaxDesc] = useState("");
  const [strPLName, setstrPLName] = useState("");
  const [strSLName, setstrSLName] = useState("");
  const [strPTLName, setstrPTLName] = useState("");
  const [strSTLName, setstrSTLName] = useState("");
  const [strPRLName, setstrPRLName] = useState("");
  const [strSRLName, setstrSRLName] = useState("");
  const [strJLName, setstrJLName] = useState("");
  const [strJTLName, setstrJTLName] = useState("");
  const [strINPLName, setstrINPLName] = useState("");
  const [strOutPLName, setstrOutPLName] = useState("");
  const [strAlias, setstrAlias] = useState("");
  const [Count, setCount] = useState(0);
  const [binputCreditReq, setbinputCreditReq] = useState(false);
  const [Ledgerfrom, setLedgerfrom] = useState("");

  const [ListEntList, setListEntList] = useState([]);
  const [ListStatus, setListStatus] = useState([]);
  const [ListTaxTyp, setListTaxTyp] = useState([]);
  const [ListFilter, setListFilter] = useState([]);
  const [ListLedger, setListLedger] = useState([]);

  useEffect(() => {
    return Load();
  }, []);

  const Load = async () => {
    setstrTaxDesc("");
    setstrAlias("");
    setiTaxTypeId(1);
    setbinputCreditReq(false);
    setdTaxPer(0);
    setiTaxId(0);
    setiPurLedId(0);
    setiPurTaxLedId(0);
    setiSalLedId(0);
    setiSalTaxLedId(0);
    setstrPLName("");
    setstrPTLName("");
    setstrSLName("");
    setstrSTLName("");
    setstrPRLName("");
    setiPurRetLedId(0);
    setstrSRLName("");
    setiSalRetLedId(0);
    setstrJLName("");
    setiJobLedId(0);
    setstrJTLName("");
    setiJobTaxLedId(0);
    setstrINPLName("");
    setiSerInputTaxLedId(0);
    setstrOutPLName("");
    setiSerOutputTaxLedId(0);
    setiStatusId(1);
    setIsLoding(true);
    const _LocalParems = await DecryptData(localStorage.jbctrlparams);
    const res = await API_FETCH(
      "TaxCreationMaster/GetDefaultData/" + _LocalParems.DB,
      dispatch
    );
    if (res) {
      setListEntList(res.objEntList);
      setListFilter(res.objFilter);
      setListStatus(res.objLoadStatus);
      setListTaxTyp(res.objEntTaxType);
      setListLedger(res.objLedger);
      setCount(res.objEntList.length);
      setIsLoding(false);
    }
  };

  const DeleteIconClick = async (iTaxId) => {
    const res = await API_POST(
      "TaxCreationMaster/Delete_Tax/" +
        iTaxId +
        "/" +
        Number(_AuthParems.Branch) +
        "/" +
        _AuthParems.DB,
      dispatch
    );
    if (res) {
      MsgBox("Success", res.strMessage, "success");
      Load();
    }
  };

  const DeleteClick = async () => {
    const res = await API_POST(
      "TaxCreationMaster/Delete/" + iTaxId + "/" + _AuthParems.DB,
      dispatch
    );
    if (res) {
      MsgBox("Success", res.strMessage, "success");
      Load();
    }
  };

  const GetLedger = async (data) => {
    const res = await API_FETCH(
      `TaxCreationMaster/GetTaxCreation/${Number(data)}/${Number(
        _AuthParems.Branch
      )}/${_AuthParems.DB}`,
      dispatch
    );
    if (res) {
      setstrTaxDesc(res.strTaxDesc);
      setstrAlias(res.strAlias);
      setiTaxTypeId(res.iTaxTypeId);
      setbinputCreditReq(res.binputCreditReq);
      setdTaxPer(res.dTaxPer);
      setiTaxId(res.iTaxId);
      setiPurLedId(res.iPurLedId);
      setiPurTaxLedId(res.iPurTaxLedId);
      setiSalLedId(res.iSalLedId);
      setiSalTaxLedId(res.iSalTaxLedId);
      setstrPLName(res.strPLName);
      setstrPTLName(res.strPTLName);
      setstrSLName(res.strSLName);
      setstrSTLName(res.strSTLName);
      setstrPRLName(res.strPRLName);
      setiPurRetLedId(res.iPurRetLedId);
      setstrSRLName(res.strSRLName);
      setiSalRetLedId(res.iSalRetLedId);
      setstrJLName(res.strJLName);
      setiJobLedId(res.iJobLedId);
      setstrJTLName(res.strJTLName);
      setiJobTaxLedId(res.iJobTaxLedId);
      setstrINPLName(res.strINPLName);
      setiSerInputTaxLedId(res.iSerInputTaxLedId);
      setstrOutPLName(res.strOutPLName);
      setiSerOutputTaxLedId(res.iSerOutputTaxLedId);
      setiStatusId(res.iStatusId);
    }
  };

  const InsUpdateTax = async () => {
    const TaxInsUpd = {
      iScrId: iScrId,
      iTaxId: iTaxId,
      strTaxDesc: strTaxDesc,
      strAlias: strAlias,
      binputCreditReq: binputCreditReq,
      dTaxPer: Number(dTaxPer),
      iTaxTypeId: Number(iTaxTypeId),
      iStatusId: Number(iStatusId),
      iPurLedId: Number(iPurLedId),
      iSalLedId: Number(iSalLedId),
      iJobLedId: Number(iJobLedId),
      iPurRetLedId: Number(iPurRetLedId),
      iSalRetLedId: Number(iSalRetLedId),
      iSalTaxLedId: Number(iSalTaxLedId),
      iPurTaxLedId: Number(iPurTaxLedId),
      iJobTaxLedId: Number(iJobTaxLedId),
      iSerInputTaxLedId: Number(iSerInputTaxLedId),
      iSerOutputTaxLedId: Number(iSerOutputTaxLedId),
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST(
      "TaxCreationMaster/InsUpd/",
      TaxInsUpd,
      dispatch
    );
    if (res) {
      MsgBox("Success", res.strMessage, "success");
      Load();
    }
  };

  const ClearClick = () => {
    Load();
  };

  const GlobalSearch = async (data) => {
    if (data.strSearch === "") {
      data.strSearch = "null";
    }
    const res = await API_FETCH(
      "TaxCreationMaster/Search/" +
        data.strSearch +
        "/" +
        data.strFilterColumn +
        "/" +
        _AuthParems.DB,
      dispatch
    );
    if (res) {
      setListEntList(res);
    }
  };

  const MsgBox = (Header, Message, Icon) => {
    setIsNotification(!IsNotification);
    setstrMessage(Message);
    setstrIcon(Icon);
  };

  const LedgerOpen = async (LedgerFrom, Filter, Ledger) => {
    if (Filter === "") {
      Filter = "null";
    }
    setLedgerfrom(Ledger);
    const res = await API_FETCH(
      "TaxCreationMaster/LedgerOpen/" +
        LedgerFrom +
        "/" +
        Filter +
        "/" +
        _AuthParems.DB,
      dispatch
    );
    if (res) {
      setListLedger(res);
      setisOpen(!isOpen);
    }
  };

  const SelectLedger = (Ledger) => {
    switch (Ledgerfrom) {
      case "PL":
        setiPurLedId(Ledger.ledId);
        setstrPLName(Ledger.ledName);
        break;
      case "PTL":
        setiPurTaxLedId(Ledger.ledId);
        setstrPTLName(Ledger.ledName);
        break;
      case "PRL":
        setiPurRetLedId(Ledger.ledId);
        setstrPRLName(Ledger.ledName);
        break;
      case "SL":
        setiSalLedId(Ledger.ledId);
        setstrSLName(Ledger.ledName);
        break;
      case "STL":
        setiSalTaxLedId(Ledger.ledId);
        setstrSTLName(Ledger.ledName);
        break;
      case "SRL":
        setiSalRetLedId(Ledger.ledId);
        setstrSRLName(Ledger.ledName);
        break;
      case "SILN":
        setiSerInputTaxLedId(Ledger.ledId);
        setstrINPLName(Ledger.ledName);
        break;
      case "SOTL":
        setiSerOutputTaxLedId(Ledger.ledId);
        setstrOutPLName(Ledger.ledName);
        break;
      case "JL":
        setiJobLedId(Ledger.ledId);
        setstrJLName(Ledger.ledName);
        break;
      case "JTL":
        setiJobTaxLedId(Ledger.ledId);
        setstrJTLName(Ledger.ledName);
        break;
      default:
        break;
    }
    setisOpen(!isOpen);
  };

  return (
    <div className="Master-container">
      <div className="row">
        <MasterList
          ComBoxValue={ListFilter}
          EntList={ListEntList}
          GetDetails={GetLedger}
          Delete={DeleteIconClick}
          Count={Count}
          Search={GlobalSearch}
          ScreenID={iScrId}
          accessor="strTaxDesc"
          accessorid="iTaxId"
        />
        <div className="col-md-9 col-sm-12  mt-3 ">
          <div className="fg-card  mb-3">
            <div className="container-fluid pt-2">
              <div className="row">
                <div className="col-md-6 col-sm-12 mb-2">
                  <label>Tax Description</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strTaxDesc}
                    onChange={(e) => setstrTaxDesc(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-2">
                  <label>Alias</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strAlias}
                    onChange={(e) => setstrAlias(e.target.value)}
                  />
                </div>
                <div className="col-md-2 col-sm-12  mb-2">
                  <label>Tax Type</label>
                  <br />
                  <div className="btn-group">
                    <select
                      className="select "
                      value={iTaxTypeId}
                      onChange={(e) => setiTaxTypeId(e.target.value)}
                    >
                      {ListTaxTyp.map((TaxTyp, Index) => (
                        <option key={Index} value={TaxTyp.iTT_Id}>
                          {TaxTyp.strTaxType}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 mb-2">
                  <label>Status</label>
                  <br />
                  <div className="btn-group">
                    <select
                      className="select "
                      value={iStatusId}
                      onChange={(e) => setiStatusId(e.target.value)}
                    >
                      {ListStatus.map((Status, Index) => (
                        <option key={Index} value={Status.iStatusId}>
                          {Status.strStatusName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-2 text-nowrap">
                  <label>Input Credit</label>
                  <br />
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={binputCreditReq}
                      onChange={(e) => setbinputCreditReq(!binputCreditReq)}
                      id="flexCheckDefault"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 mb-2">
                  <label>Tax %</label>
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    value={dTaxPer}
                    onChange={(e) => setdTaxPer(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-2">
                  <label>Purchase Ledger</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strPLName}
                    readOnly
                    onDoubleClick={(e) => LedgerOpen("25", "", "PL")}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-2">
                  <label>JobWork Ledger</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strJLName}
                    readOnly
                    onDoubleClick={(e) => LedgerOpen("35", "", "JL")}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-2">
                  <label>Purchase Tax Ledger</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strPTLName}
                    readOnly
                    onDoubleClick={(e) => LedgerOpen("12", "", "PTL")}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-2">
                  <label>JobWork Tax Ledger</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strJTLName}
                    readOnly
                    onDoubleClick={(e) => LedgerOpen("12", "", "JTL")}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-2">
                  <label>Sales Ledger</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strSLName}
                    readOnly
                    onDoubleClick={(e) => LedgerOpen("28", "", "SL")}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-2">
                  <label>Service InputTax Ledger</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strINPLName}
                    readOnly
                    onDoubleClick={(e) => LedgerOpen("12", "", "SILN")}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-2">
                  <label>Sales Tax Ledger</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strSTLName}
                    readOnly
                    onDoubleClick={(e) => LedgerOpen("12", "", "STL")}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-2">
                  <label>Service OutputTax Ledger</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strOutPLName}
                    readOnly
                    onDoubleClick={(e) => LedgerOpen("12", "", "SOTL")}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-2">
                  <label>Purchase Return Ledger</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strPRLName}
                    readOnly
                    onDoubleClick={(e) => LedgerOpen("25", "", "PRL")}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-2">
                  <label>Sales Return Ledger</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strSRLName}
                    readOnly
                    onDoubleClick={(e) => LedgerOpen("28", "", "SRL")}
                  />
                </div>
              </div>
            </div>
          </div>
          <Button
            Save={(e) => {
              if (iPurLedId === 0) {
                MsgBox("", "Please Select Purchase Ledger", "info");
              } else if (strTaxDesc === "") {
                MsgBox("", "Please Enter Description", "info");
              } else if (dTaxPer === 0) {
                MsgBox("", "Please Enter Tax %", "info");
              } else if (iJobLedId === 0) {
                MsgBox("", "Please Select JobWork Ledger", "info");
              } else if (iPurTaxLedId === 0) {
                MsgBox("", "Please Select Purchase Tax Ledger", "info");
              } else if (iJobTaxLedId === 0) {
                MsgBox("", "Please Select Jobwork Tax Ledger", "info");
              } else if (iSalLedId === 0) {
                MsgBox("", "Please Select Sales Ledger", "info");
              } else if (iSerInputTaxLedId === 0) {
                MsgBox("", "Please Select Service Input Ledger", "info");
              } else if (iSalTaxLedId === 0) {
                MsgBox("", "Please Select Sales Tax Ledger", "info");
              } else if (iSerOutputTaxLedId === 0) {
                MsgBox("", "Please Select Service Output Ledger", "info");
              } else if (iPurRetLedId === 0) {
                MsgBox("", "Please Select Purchase Return Ledger", "info");
              } else if (iSalRetLedId === 0) {
                MsgBox("", "Please Select Sales Return Ledger", "info");
              } else {
                setIsDialogFor("Save");
                setstrQuestion("Do You Want to Save ?");
                setIsDialogOpen(!IsDialogOpen);
              }
            }}
            Clear={ClearClick}
            Delete={(e) => {
              if (iTaxId === 0) {
                MsgBox("", "Please Select Tax", "error");
              } else {
                setIsDialogFor("Delete");
                setstrQuestion("Do You Want to Delete ?");
                setIsDialogOpen(!IsDialogOpen);
              }
            }}
            ScrId={iScrId}
          />
        </div>
        {isOpen && (
          <SearchOtherHeadLedger
            Close={(e) => setisOpen(!isOpen)}
            SelectLedger={SelectLedger}
            LoadLedger={ListLedger}
          />
        )}
      </div>
    </div>
  );
}
export default TaxMaster;
