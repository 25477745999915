import React, { useEffect, useState } from "react";

import Button from "./MasterButton";
import MasterList from "./MasterListItem";
import $ from "jquery";
import SearchLedger from "../Accounts/SearchLedger";
import SideMenu from "../General/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import Messagedialogbox from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import MasterCodeSetting from "./MasterCodeSetting";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
function ServiceMaster() {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [iSerId, setiSerId] = useState(0);
  const [strSerCode, setstrSerCode] = useState("");
  const [strSerName, setstrSerName] = useState("");
  const [strSACCode, setstrSACCode] = useState("");
  const [strRemarks, setstrRemarks] = useState("");
  const [strPrintName, setstrPrintName] = useState("");
  const [strLedId, setstrLedId] = useState("");
  const [iScrId, setiScrId] = useState(59);
  const [strSerCodeTag, setstrSerCodeTag] = useState("Enter Service Code");
  const [strTransTagLedName, setstrTransTagLedName] = useState("TagLedgerName");
  const [strLedName, setstrLedName] = useState("");
  const [strSearch, setstrSearch] = useState("");
  const [strFilter, setstrFilter] = useState("");
  const [strFilterColum, setstrFilterColum] = useState("All");
  const [iSGSTTaxId, setiSGSTTaxId] = useState(24);
  const [iCGSTTaxId, setiCGSTTaxId] = useState(27);
  const [iIGSTTaxId, setiIGSTTaxId] = useState(18);
  const [iTcsTaxId, setiTcsTaxId] = useState(43);
  const [iTaxTypeId, setiTaxTypeId] = useState(1);
  const [isShowTcs, setisShowTcs] = useState(false);
  const [listCodeFormat, setlistCodeFormat] = useState([]);
  const [listEntSer, setlistEntSer] = useState([]);
  const [listSGST, setlistSGST] = useState([]);
  const [listCGST, setlistCGST] = useState([]);
  const [listIGST, setlistIGST] = useState([]);
  const [ListTcsTax, setListTcsTax] = useState([]);
  const [listTaxType, setlistTaxType] = useState([]);
  const [ManualCode, setManualCode] = useState(true);
  const [IsMasterCode, setIsMasterCode] = useState(false);
  const [ListValidateProperties, setListValidateProperties] = useState([]);
  const [isLedger, setisLedger] = useState(false);
  useEffect(() => {
    Load_Servicemaster();
  }, []);
  const Load_Servicemaster = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);
      const res = await API_FETCH(
        `ServiceMaster/Load_Servicemaster/${Number(_LocalParems.Branch)}/${
          _LocalParems.DB
        }`,
        dispatch
      );
      if (res) {
        setlistEntSer(res.objEntService);
        setlistCodeFormat(res.objCodeFormat);
        setstrSerCode(res.objCodeFormat[0].strCode);
        setlistTaxType(res.objEntTaxType);
        setlistSGST(res.objEntSGSTTax);
        setlistCGST(res.objEntCGSTTax);
        setlistIGST(res.objEntIGSTTax);
        setListTcsTax(res.objEntTcs);
        setManualCode(!res.objCodeFormat[0].isManualNo);
        TabOrderAssign(res.Transtab);
        setListValidateProperties(res.Transtab);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const ClearClick = () => {
    try {
      Pre_Loading(dispatch, false);
      setiSerId(0);
      setstrSerCode("");
      setstrSerName("");
      setstrSACCode("");
      setstrRemarks("");
      setstrPrintName("");
      setstrLedId("");
      setstrLedName("");
      setiScrId(59);
      setstrSerCodeTag("Enter Service Code");
      setstrTransTagLedName("TagLedgerName");
      setstrSearch("");
      setstrFilter("");
      setstrFilterColum("All");
      setiSGSTTaxId(24);
      setiCGSTTaxId(27);
      setiIGSTTaxId(18);
      setiTaxTypeId(1);
      setiTcsTaxId(43);
      setisShowTcs(false);
      Load_Servicemaster();
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const SaveClick = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const ObjInsUpdService = {
        strSerCode: strSerCode,
        iBranchId: Number(_AuthParems.Branch),
        strSerName: strSerName,
        strSACCode: strSACCode,
        strPrintName: strPrintName,
        strRemarks: strRemarks,
        strSerCodeTag: strSerCodeTag,
        iTitleTag: iScrId,
        iSerId: iSerId,
        iNumSeqNo: listCodeFormat[0].iNumSeqNo,
        strPrefix: listCodeFormat[0].strPrefix,
        strSearch: strSearch,
        strFilter: strFilter,
        strFilterColumn: strFilterColum,
        strLedId: strLedId.toString(),
        strTransTagLedName: strTransTagLedName,
        strLedName: strLedName,
        iTaxTypeId: Number(iTaxTypeId),
        iSGSTTaxId: Number(iSGSTTaxId),
        iCGSTTaxId: Number(iCGSTTaxId),
        iIGSTTaxId: Number(iIGSTTaxId),
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "ServiceMaster/InsUpd_ServiceMaster/",
        ObjInsUpdService,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        ClearClick();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const DeleteClick = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_POST(
        "ServiceMaster/Delete_Service/" +
          iSerId +
          "/" +
          Number(_AuthParems.Branch) +
          "/" +
          _AuthParems.DB,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        ClearClick();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const SelectLedger = (Ledger) => {
    setisLedger(!isLedger);
    setstrLedId(Ledger.ledId);
    setstrLedName(Ledger.ledName);
  };
  const DeleteIconClick = async (iSerId) => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_POST(
        "ServiceMaster/Delete/" + iSerId + "/" + iScrId + "/" + _AuthParems.DB,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        ClearClick();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const View_Service = async (iSerId) => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        `ServiceMaster/View_Service/${iSerId}/${_AuthParems.Branch}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        let objSer = res.EntService[0];
        setstrSerCode(objSer.strSerCode);
        setstrSerName(objSer.strSerName);
        setstrSACCode(objSer.strSACCode);
        setstrPrintName(objSer.strPrintName);
        setstrRemarks(objSer.strRemarks);
        setiSerId(objSer.iSerId);
        setstrSerCode(objSer.strSerCode);
        setstrLedId(objSer.strLedId);
        setstrLedName(objSer.strLedName);
        setiSGSTTaxId(objSer.iSGSTTaxId);
        setiCGSTTaxId(objSer.iCGSTTaxId);
        setiIGSTTaxId(objSer.iIGSTTaxId);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Search_Service = async (strtext) => {
    try {
      setstrSearch(strtext);
      const objSearch = {
        iScrId: iScrId,
        iBranchId: Number(_AuthParems.Branch),
        iPartyId: 0,
        strColName: "All",
        strSearch: strtext,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "ServiceMaster/Search_Service/",
        objSearch,
        dispatch
      );
      if (res) {
        setlistEntSer(res.EntService);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const TaxOnChange = (objtax) => {
    let dTaxPer = Number(objtax.dTaxPer);
    var TaxType = objtax.strTaxDesc.split(" ")[0];
    switch (TaxType) {
      case "SGST":
        setiSGSTTaxId(objtax.iTaxId);
        setiCGSTTaxId(
          listCGST.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setiIGSTTaxId(
          listIGST.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      case "CGST":
        setiSGSTTaxId(
          listSGST.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setiCGSTTaxId(objtax.iTaxId);
        setiIGSTTaxId(
          listIGST.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      default:
        setiIGSTTaxId(objtax.iTaxId);
        setiSGSTTaxId(
          listSGST.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        setiCGSTTaxId(
          listCGST.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        break;
    }
  };
  //#region TabSetting
  const [ListTabOrder, setListTabOrder] = useState([
    {
      SerCodeTab: "",
      SerNamTab: "",
      SACcodeTab: "",
      RemarkTab: "",
      PrintNameTab: "",
      LedgerTab: "",
      TaxTypeTab: "",
      CGSTTab: "",
      IGSTTab: "",
      SGSTTab: "",
      ServiceListTab: "",
      ServiceFIlterTab: "",
      ServiceSearchTab: "",
      BtnSavtab: "",
      BtnCleartab: "",
      BtnDeletetab: "",
      BtnClosetab: "",
      BtnExporttab: "",
    },
  ]);
  const TabOrderAssign = (EntProperties) => {
    let TabOrder = ListTabOrder;
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      switch (EntProperties[i].strCtrlName) {
        case "txtServiceCode":
          TabOrder[0].SerCodeTab = EntProperties[i].iTabIndex;
          break;
        case "txtSACCode":
          TabOrder[0].SACcodeTab = EntProperties[i].iTabIndex;
          break;
        case "txtServiceName":
          TabOrder[0].SerNamTab = EntProperties[i].iTabIndex;
          break;
        case "cbTaxType":
          TabOrder[0].TaxTypeTab = EntProperties[i].iTabIndex;
          break;
        case "cbCgst":
          TabOrder[0].CGSTTab = EntProperties[i].iTabIndex;
          break;
        case "cbIgst":
          TabOrder[0].IGSTTab = EntProperties[i].iTabIndex;
          break;
        case "cbSgst":
          TabOrder[0].SGSTTab = EntProperties[i].iTabIndex;
          break;
        case "txtPrintName":
          TabOrder[0].PrintNameTab = EntProperties[i].iTabIndex;
          break;
        case "txtRemarks":
          TabOrder[0].RemarkTab = EntProperties[i].iTabIndex;
          break;
        case "txtLedger":
          TabOrder[0].LedgerTab = EntProperties[i].iTabIndex;
          break;
        case "btnSave":
          TabOrder[0].BtnSavtab = EntProperties[i].iTabIndex;
          break;
        case "btnClear":
          TabOrder[0].BtnCleartab = EntProperties[i].iTabIndex;
          break;
        case "btnDelete":
          TabOrder[0].BtnDeletetab = EntProperties[i].iTabIndex;
          break;
        case "btnClose":
          TabOrder[0].BtnClosetab = EntProperties[i].iTabIndex;
          break;
        case "cbFilter":
          TabOrder[0].ServiceFIlterTab = EntProperties[i].iTabIndex;
          break;
        case "txtSearch":
          TabOrder[0].ServiceSearchTab = EntProperties[i].iTabIndex;
          break;
        case "lbServiceName":
          TabOrder[0].ServiceListTab = EntProperties[i].iTabIndex;
          break;
        case "btnExportImport":
          TabOrder[0].BtnExporttab = EntProperties[i].iTabIndex;
          break;
        default:
          break;
      }
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    ); //returns object
    sessionStorage.maxTab = maxTab.iTabIndex;
    setListTabOrder(TabOrder);
  };
  const TabIndex = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].ServiceListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].ServiceListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].ServiceListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].ServiceListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].ServiceListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].ServiceListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].ServiceListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  //#endregion
  const PropertiesValidation = () => {
    var ErrorMsg = true;
    let ValidateItem = ListValidateProperties.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtServiceName":
          ErrorMsg = strSerName === "" ? false : true;
          break;
        case "txtPrintName":
          ErrorMsg = strPrintName === "" ? false : true;
          break;
        case "txtRemarks":
          ErrorMsg = strRemarks === "" ? false : true;
          break;
        case "cbSgst":
          ErrorMsg = iSGSTTaxId === "" ? false : true;
          break;
        case "cbIgst":
          ErrorMsg = iIGSTTaxId === "" ? false : true;
          break;
        case "cbCgst":
          ErrorMsg = iCGSTTaxId === "" ? false : true;
          break;
        case "cbTaxType":
          ErrorMsg = iTaxTypeId === "" ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        Show_Message(dispatch, ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };

  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <MasterList
          ScrId={iScrId}
          strSearchtxt={strSearch}
          tabOrder={ListTabOrder[0].ServiceSearchTab}
          Search={(val) => Search_Service(val)}
          arrMasterData={listEntSer}
          isImg={false}
          displayName="strSerName"
          Delete={DeleteIconClick}
          Parem1="iSerId"
          View_Data={View_Service}
          placeholder="Service"
        />
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-6 col-sm-12 mb-2">
                <label>
                  Name <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder=" Service Name"
                  tabIndex={ListTabOrder[0].SerNamTab}
                  onKeyDown={(e) => TabIndex(e)}
                  value={strSerName}
                  spellCheck={false}
                  onChange={(e) => setstrSerName(e.target.value)}
                  autoFocus
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>
                  Code <span className="danger">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    tabIndex={ListTabOrder[0].SerCodeTab}
                    onKeyDown={(e) => TabIndex(e)}
                    value={strSerCode}
                    readOnly={ManualCode}
                    onChange={(e) => setstrSerCode(e.target.value)}
                  />
                  <span
                    className="inv-no"
                    onClick={(e) => setIsMasterCode(!IsMasterCode)}
                  >
                    <i className="bx bx-cog"></i>
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>SAC</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder=" SAC Code"
                  tabIndex={ListTabOrder[0].SACcodeTab}
                  onKeyDown={(e) => TabIndex(e)}
                  value={strSACCode}
                  onChange={(e) => setstrSACCode(e.target.value)}
                />
              </div>
              <div className="col-md-6 col-sm-12 mb-2">
                <label>Print Name </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  tabIndex={ListTabOrder[0].PrintNameTab}
                  onKeyDown={(e) => TabIndex(e)}
                  placeholder=" Print Name"
                  spellCheck={false}
                  value={strPrintName}
                  onChange={(e) => setstrPrintName(e.target.value)}
                />
              </div>
              <div className="col-md-6 col-sm-6 mb-2">
                <label>Ledger</label>
                <div className="custom-select">
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    placeholder="Select Ledger"
                    tabIndex={ListTabOrder[0].LedgerTab}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") setisLedger(!isLedger);
                      else TabIndex(e);
                    }}
                    onClick={() => setisLedger(!isLedger)}
                    defaultValue={strLedName}
                  />
                  <i className="fa-solid fa-caret-down"></i>
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <label>Tax Type</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow1"
                  showoption={_parem.isoptionShow1}
                  placeholder="Select Tax Type"
                  taborder={ListTabOrder[0].Taxtytab}
                  keydown={(e) => TabIndex(e)}
                  select_value={(val) => {
                    setiTaxTypeId(val);
                    if (Number(val) === 1) setisShowTcs(false);
                    else setisShowTcs(true);
                  }}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iTaxTypeId}
                  displayName="strTaxLvlType"
                  disvalue="iTaxLvlId"
                  arrydata={listTaxType}
                  EmptVal="Tax Type "
                />
              </div>
              {!isShowTcs && (
                <div className="col-md-3 col-sm-3">
                  <label>GST Tax %</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow4"
                    showoption={_parem.isoptionShow4}
                    placeholder="Select IGST Tax"
                    keydown={(e) => TabIndex(e)}
                    select_value={(val) => TaxOnChange(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={false}
                    defaultval={iIGSTTaxId}
                    displayName="strTaxDesc"
                    disvalue="iTaxId"
                    arrydata={listIGST}
                    EmptVal="GSTTax"
                    replace={["IGST", "GST"]}
                  />
                </div>
              )}
              {isShowTcs && (
                <div className="col-md-3 col-sm-3">
                  <label>TCS Tax %</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow6"
                    showoption={_parem.isoptionShow6}
                    placeholder="Select TCS Tax"
                    keydown={(e) => TabIndex(e)}
                    select_value={(val) => setiTcsTaxId(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iTcsTaxId}
                    displayName="strTaxDesc"
                    disvalue="iTaxId"
                    arrydata={ListTcsTax}
                    EmptVal="TCS "
                  />
                </div>
              )}
              <div className="col-md-6 col-sm-12 mb-2">
                <label>Remark</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  tabIndex={ListTabOrder[0].RemarkTab}
                  onKeyDown={(e) => TabIndex(e)}
                  placeholder=" Remark"
                  value={strRemarks}
                  onChange={(e) => setstrRemarks(e.target.value)}
                />
              </div>
            </div>
            {IsMasterCode && (
              <MasterCodeSetting
                Close={(e) => setIsMasterCode(!IsMasterCode)}
                iScrId={iScrId}
              />
            )}
            <Messagedialogbox />
            {isLedger && (
              <SearchLedger
                Close={() => setisLedger(!isLedger)}
                SelectLedger={SelectLedger}
              />
            )}
          </div>
          <Button
            Save={(e) => {
              if (PropertiesValidation()) {
                Ask_Question(
                  dispatch,
                  SaveClick,
                  "Do You Want to  Save Service?"
                );
              }
            }}
            Clear={ClearClick}
            Delete={(e) => {
              if (iSerId === 0) {
                Show_Message(dispatch, "Please Select Service", "info");
              } else {
                Ask_Question(
                  dispatch,
                  DeleteClick,
                  "Do You Want to  Delete Service?"
                );
              }
            }}
            ScrId={iScrId}
            Iid={iSerId}
            SaveIndex={ListTabOrder[0].BtnSavtab}
            DeleteIndex={ListTabOrder[0].BtnDeletetab}
            CloseIndex={ListTabOrder[0].BtnClosetab}
            ClearIndex={ListTabOrder[0].BtnCleartab}
            ExportIndex={ListTabOrder[0].BtnExporttab}
            onKeyDown={(e) => TabIndex(e)}
          />
        </div>
      </div>
    </>
  );
}
export default ServiceMaster;
