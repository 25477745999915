import React, { useEffect } from "react";
import SettlementInput from "./SettlementInput";
import SideMenu from "../General/SideMenu";
import Messagedialogbox, {
  Ask_Question,
  Show_Message,
} from "../General/Messagedialogbox";
import { useDispatch, useSelector } from "react-redux";
import {
  refresh_Settlement,
  refresh_Settlementtab,
  setSettlementDefault,
  setsettlementtab,
} from "../features/Accounts";
import $ from "jquery";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
function ReceiptSettlement() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const _HTParem = useSelector((state) => state.Accounts.SettleMentHT);
  const _DTParem = useSelector((state) => state.Accounts.SettleMentDT);

  useEffect(() => {
    Load_ReceiptSettlement();
  }, []);
  const Load_ReceiptSettlement = async () => {
    const _LocalParems = await DecryptData(localStorage.jbctrlparams);

    const res = await API_FETCH(
      "ReceiptSettlement/Load_Settlement/" + _LocalParems.DB,
      dispatch
    );
    if (res) {
      dispatch(
        setSettlementDefault({
          iScrId: 17,
          iVenTypeId: 4,
          iVenScrId: 2,
          strVenScrCol: "CustName",
          strFromDate: res.strFromDate,
          arrVouchType: res.EntVouchType,
          arrVenType: res.EntVenType,
        })
      );
    }
  };
  const Save_Settlement = async () => {
    const res = await API_POST(
      "ReceiptSettlement/InsUpd_Settlement/",
      {
        iRecId: _HTParem.iPayRecId,
        objRecDetails: _DTParem.arrDTdata,
        strVouchType: "",
        iBranchId: Number(_AuthParems.Branch),
        strDBName: _AuthParems.DB,
      },
      dispatch
    );
    if (res) {
      dispatch(refresh_Settlement());
      Load_ReceiptSettlement();
    }
  };
  const tab_click = (tabindex) => {
    dispatch(setsettlementtab(tabindex === 0 ? true : false));
    dispatch(refresh_Settlementtab());
    Load_ReceiptSettlement();
    let line = document.getElementById("tab-line");
    let lable = $(`.trans-buttons label`);
    let fromleft = lable[tabindex].offsetLeft;
    let width = lable[tabindex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    $(`.trans-buttons label:eq(${tabindex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");
  };
  return (
    <>
      <SideMenu ScrId={130} />
      <div className="Master-container">
        <div className="Master-card1">
          <div className="Master-wrapper">
            <div className="row">
              <div className="trans-tab mb-2 mt-2">
                <div className="trans-buttons">
                  <label onClick={(e) => tab_click(0)}>Un Settled</label>
                  <label onClick={(e) => tab_click(1)}>Settled</label>
                  <div id="tab-line"></div>
                </div>
              </div>
              <SettlementInput PartyUrl="ReceiptSettlement" />
            </div>
            <Messagedialogbox />
          </div>
          <div className="btn-section">
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) => {
                if (_HTParem.iVenId === 0) {
                  Show_Message(dispatch, "Vendor Name is Required", "info");
                } else if (_DTParem.arrDTdata.length === 0) {
                  Show_Message(
                    dispatch,
                    "You don't have Receipts to Save",
                    "info"
                  );
                } else if (_HTParem.iPayRecId === 0) {
                  Show_Message(
                    dispatch,
                    "You have to select one Receipt to Save",
                    "info"
                  );
                } else {
                  Ask_Question(
                    dispatch,
                    Save_Settlement,
                    "Do You Want to  Save Settlement?"
                  );
                }
              }}
              disabled={!_HTParem.isUnSettle}
            >
              Save &nbsp; <i className="fas fa-save"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) => (window.location.href = "/Accounts/Receipt")}
            >
              Close &nbsp; <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReceiptSettlement;
