import React, { useEffect, useState } from "react";

import LogoGallery from "./LogoGallery";
import PrintScreen from "./PrintScreen";
import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import CustomeIndexSelectOption from "../General/CustomeIndexSelectOption";
import SideMenu from "../General/SideMenu";
import $ from "jquery";
import Messagedialogbox from "../General/Messagedialogbox";
import TemplateCopy from "./TemplateCopy";
import LineCopy from "./LineCopy";
import NewTemplate from "./NewTemplate";
import {
  API_DELETE,
  API_FETCH,
  API_POST,
  DecryptData,
} from "../General/Utility";
import Print_DesignCaller from "../General/Print_DesignCaller";
import ReactTable from "../General/ReactTable";
function PrintMaster() {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [iScrId, setiScrId] = useState(32);
  const [iFormatId, setiFormatId] = useState(0);
  const [iAreaId, setiAreaId] = useState(0);
  const [iDesignId, setiDesignId] = useState(0);
  const [arrScreenName, setarrScreenName] = useState([]);
  const [arrPrintFormate, setarrPrintFormate] = useState([]);
  const [arrPrintArea, setarrPrintArea] = useState([]);
  const [arrAreaColumn, setarrAreaColumn] = useState([]);
  const [arrColumDesign, setarrColumDesign] = useState([]);

  const [idselected, setidselected] = useState(0);
  const [iselectedIndex, setiselectedIndex] = useState(0);
  const [strSearch, setstrSearch] = useState("");
  const [iLineAt, setiLineAt] = useState(0);
  const [iStartAt, setiStartAt] = useState(0);
  const [iWidth, setiWidth] = useState(0);
  const [iFontSize, setiFontSize] = useState(14);
  const [strFont, setstrFont] = useState("times");
  const [strWordWrab, setstrWordWrab] = useState("nowrap");
  const [strAlignment, setstrAlignment] = useState("left");
  const [strFontSyle, setstrFontSyle] = useState("regular");
  const [strfontWeight, setstrfontWeight] = useState("normal");
  const [iInOrder, setiInOrder] = useState(1);
  const [strColor, setstrColor] = useState("black");
  const [strLableName, setstrLableName] = useState("");
  const [strPrefix, setstrPrefix] = useState("");
  const [strSuffix, setstrSuffix] = useState("");
  const [strFieldName, setstrFieldName] = useState("");
  const [strDisplayName, setstrDisplayName] = useState("");
  const [strColumName, setstrColumName] = useState("");
  const [islblreadonly, setislblreadonly] = useState(false);
  const [IsPreViewOpen, setIsPreViewOpen] = useState(false);
  const [isImageUploadOpen, setisImageUploadOpen] = useState(false);
  const [isNewtemplate, setisNewtemplate] = useState(false);
  const [islinecopy, setislinecopy] = useState(false);
  const [istemplatecopy, setistemplatecopy] = useState(false);

  useEffect(() => {
    Load_PrintScreen();
  }, []);

  const Load_PrintScreen = async () => {
    try {
      const authparems = await DecryptData(localStorage.jbctrlparams);
      const res = await API_FETCH("PrintDesing/Load_PrintScreens/", dispatch);
      if (res) {
        setarrScreenName(res.EntScreen);
        View_PrintTemplate(17, authparems);
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.strMessage, "info");
    }
  };
  const View_PrintTemplate = async (ScrId, authparems) => {
    try {
      setiScrId(Number(ScrId));
      const res = await API_FETCH(
        `PrintDesing/View_PrintFormate/${Number(ScrId)}/${Number(
          authparems.Branch
        )}/${authparems.DB}`,
        dispatch
      );
      if (res) {
        setarrPrintFormate(res.EntTemplate);
        setarrPrintArea(res.EntColumtype);
      }
    } catch (err) {
      Show_Message(dispatch, err.strMessage, "info");
    }
  };
  const View_PrintFormate = (FormateId) => {
    setiFormatId(FormateId);
    setiAreaId(0);
    setarrAreaColumn([]);
    setarrColumDesign([]);
  };
  const View_PrintColumns = async (AreaId) => {
    try {
      if (Number(iFormatId) === 0) {
        Show_Message(dispatch, "Please Select Formate", "info");
      } else if (Number(AreaId) === 0) {
        Show_Message(dispatch, "Please Select Area", "info");
      } else {
        Pre_Loading(dispatch, true);
        setiAreaId(Number(AreaId));
        const objSearch = {
          iScrId: iScrId,
          iFormateId: Number(iFormatId),
          iAreaId: Number(AreaId),
          strSearch: strSearch,
          iBranchId: Number(_AuthParems.Branch),
          strDBName: _AuthParems.DB,
        };
        const res = await API_POST(
          "PrintDesing/View_FormateDesing/",
          objSearch,
          dispatch
        );
        if (res) {
          setarrAreaColumn(res.EntColumns);
          let EntDesing = res.EntDesign;
          EntDesing = EntDesing.sort((a, b) => a.lineAt - b.lineAt);
          setarrColumDesign(EntDesing);
        }
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.strMessage, "info");
    }
  };
  const Add_NewDesign = (RowInfo, Index) => {
    try {
      if (
        RowInfo.valueMember === "Label" ||
        RowInfo.valueMember === "AddLessHeaderLabel"
      )
        setislblreadonly(false);
      else setislblreadonly(true);
      setstrColumName(RowInfo.valueMember);
      setiselectedIndex(Index);
      setstrDisplayName(RowInfo.displayName);
      setstrFieldName(RowInfo.valueMember);
    } catch (err) {
      Show_Message(dispatch, err.strMessage, "info");
    }
  };
  const Modify_Design = (RowInfo) => {
    if (
      RowInfo.fieldName === "Label" ||
      RowInfo.fieldName === "AddLessHeaderLabel"
    )
      setislblreadonly(false);
    else setislblreadonly(true);

    setstrColumName(RowInfo.fieldName);
    setiDesignId(RowInfo.designId);
    setiLineAt(RowInfo.lineAt);
    setiStartAt(RowInfo.startAt);
    setiWidth(RowInfo.width);
    setiFontSize(RowInfo.fontSize);
    setstrFont(RowInfo.font);
    setstrWordWrab(RowInfo.wordWrap);
    setstrAlignment(RowInfo.alignment);
    setstrLableName(RowInfo.labelName);
    setstrFontSyle(RowInfo.fontStyle);
    setstrPrefix(RowInfo.prefix);
    setstrSuffix(RowInfo.suffix);
    setiInOrder(RowInfo.inOrderTo);
    setstrDisplayName(RowInfo.displayName);
    setstrFieldName(RowInfo.fieldName);
    setiDesignId(RowInfo.designId);
    setstrColor(RowInfo.color);
  };
  const ClearDesign = () => {
    setstrColumName("");
    setiDesignId(0);
    setiLineAt(0);
    setiStartAt(0);
    setiWidth(0);
    setiFontSize(14);
    setiInOrder(1);
    setstrFont("times");
    setstrWordWrab("nowrap");
    setstrAlignment("left");
    setstrFontSyle("regular");
    setstrColor("black");
    setstrLableName("");
    setstrPrefix("");
    setstrSuffix("");
    setstrDisplayName("");
    setstrFieldName("");
  };
  const Save_ColDesign = async () => {
    try {
      if (!islblreadonly && strLableName === "")
        Show_Message(dispatch, "Please Enter Lable Name", "info");
      else {
        Pre_Loading(dispatch, true);
        const UpdateDesign = {
          ilbiCselectedInd: Number(iselectedIndex),
          iDesignId: iDesignId,
          dLineAt: Number(iLineAt),
          dStartAt: Number(iStartAt),
          iWidth: Number(iWidth),
          iFontSize: Number(iFontSize),
          strLabel: strLableName,
          strPrefix: strPrefix,
          strSuffix: strSuffix,
          iInOrderTo: Number(iInOrder),
          IsLabelEnabled: strLableName.length > 0 ? true : false,
          strAlignMent: strAlignment,
          strFontStyle: strFontSyle,
          strFont: strFont,
          strFontWeight: strfontWeight,
          strFontColor: strColor,
          sttWrap: strWordWrab,
          iFormatNameId: Number(iFormatId),
          iPrintAreaId: Number(iAreaId),
          iScrId: Number(iScrId),
          strFieldName: strFieldName,
          strDisplayName: strDisplayName,
          strDBName: _AuthParems.DB,
        };
        const res = await API_POST(
          "PrintDesing/InsUpd_ColDesign/",
          UpdateDesign,
          dispatch
        );
        if (res) {
          await ClearDesign();
          await View_PrintColumns(iAreaId);
          await Show_Message(dispatch, res.strMessage, "success");
          await PrintInvoice();
        }
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "info");
    }
  };
  const Search_Columns = async (strtxt) => {
    setstrSearch(strtxt);
    try {
      setiAreaId(Number(iAreaId));
      const objSearch = {
        iScrId: iScrId,
        iFormateId: Number(iFormatId),
        iAreaId: Number(iAreaId),
        strSearch: strtxt,
        iBranchId: Number(_AuthParems.Branch),
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "PrintDesing/View_FormateDesing/",
        objSearch,
        dispatch
      );
      if (res) {
        setarrAreaColumn(res.EntColumns);
        let EntDesing = res.EntDesign;
        EntDesing = EntDesing.sort((a, b) => a.lineAt - b.lineAt);
        setarrColumDesign(EntDesing);
      }
    } catch (err) {
      Show_Message(dispatch, err.strMessage, "info");
    }
  };
  const Delete_ColDesign = async (designId) => {
    try {
      const res = await API_DELETE(
        `PrintDesing/Delete_ColDesign/${Number(designId)}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        View_PrintColumns(iAreaId);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  const DesignColumns = [
    {
      Header: "Column Name",
      accessor: "displayName",
      minWidth: 200,
    },
    {
      Header: "Line At",
      accessor: "lineAt",
      style: { justifyContent: "center" },
      maxWidth: 80,
    },
    {
      Header: "Start At",
      accessor: "startAt",
      style: { justifyContent: "center" },
      maxWidth: 80,
    },
    {
      Header: "Order To",
      accessor: "inOrderTo",
      style: { justifyContent: "center" },
      maxWidth: 80,
    },
    {
      Header: "Width",
      accessor: "width",
      style: { justifyContent: "center" },
      maxWidth: 80,
    },
    {
      Header: "Label",
      accessor: "labelName",
      minWidth: 200,
      style: { justifyContent: "left" },
    },
    {
      Header: "Size",
      accessor: "fontSize",
      style: { justifyContent: "center" },
      maxWidth: 80,
    },
    {
      Header: "Style",
      accessor: "fontStyle",
      style: { justifyContent: "center" },
      maxWidth: 80,
    },
    {
      Header: "Weight",
      accessor: "fontWeight",
      style: { justifyContent: "center" },
      maxWidth: 80,
    },
    {
      Header: "Wrap",
      accessor: "wordWrap",
      style: { justifyContent: "center" },
      maxWidth: 80,
    },
    {
      Header: "Alignment",
      accessor: "alignment",
      style: { justifyContent: "center" },
      maxWidth: 80,
    },
    { Header: "Prefix", accessor: "prefix", style: { justifyContent: "left" } },
    { Header: "Suffix", accessor: "suffix", style: { justifyContent: "left" } },
    {
      Header: "Color",
      accessor: "color",
      maxWidth: 80,
      style: { justifyContent: "left" },
    },
    {
      Header: "Font",
      accessor: "font",
      maxWidth: 80,
      style: { justifyContent: "left" },
    },
    {
      Header: "Delete",
      id: "sNo",
      maxWidth: 50,
      style: { justifyContent: "center" },
      accessor: (RowInfo) => (
        <i
          onClick={(e) => {
            Ask_Question(
              dispatch,
              Delete_ColDesign,
              "Do You Want to  Delete Design?",
              RowInfo.designId
            );
          }}
          className="far fa-trash-alt"
        ></i>
      ),
    },
  ];
  const arrColor = [
    "aliceblue",
    "antiquewhite",
    "aqua",
    "aquamarine	",
    "azure",
    "beige",
    "bisque",
    "black",
    "blanchedalmond",
    "blue",
    "blueviolet",
    "brown",
    "burlywood",
    "cadetblue",
    "chartreuse",
    "chocolate",
    "coral",
    "cornflowerblue",
    "cornsilk",
    "crimson",
    "cyan",
    "darkblue",
    "darkcyan",
    "darkgoldenrod",
    "darkgray",
    "darkgrey",
    "darkgreen",
    "darkkhaki",
    "darkmagenta",
    "darkolivegreen",
    "darkorange",
    "darkorchid",
    "darkred",
    "darksalmon",
    "darkseagreen",
    "darkslateblue",
    "darkslategray",
    "darkslategrey",
    "darkturquoise",
    "darkviolet",
    "deeppink",
    "deepskyblue",
    "dimgray",
    "dimgrey",
    "dodgerblue",
    "firebrick",
    "floralwhite",
    "forestgreen",
    "fuchsia",
    "gainsboro",
    "ghostwhite",
    "gold",
    "goldenrod",
    "gray",
    "grey",
    "green",
    "greenyellow",
    "honeydew",
    "hotpink",
    "indianred",
    "indigo",
    "ivory",
    "khaki",
    "lavender",
    "lavenderblush",
    "lawngreen",
    "lemonchiffon",
    "lightblue",
    "lightcoral",
    "lightcyan",
    "lightgoldenrodyellow",
    "lightgray",
    "lightgrey",
    "lightgreen",
    "lightpink",
    "lightsalmon",
    "lightseagreen",
    "lightskyblue",
    "lightslategray",
    "lightslategrey",
    "lightyellow",
    "lime",
    "limegreen",
    "linen",
    "magenta",
    "maroon",
    "mediumaquamarine",
    "mediumblue",
    "mediumorchid",
    "mediumpurple",
    "mediumseagreen",
    "mediumslateblue",
    "mediumspringgreen",
    "mediumturquoise",
    "mediumvioletred",
    "midnightblue",
    "mintcream",
    "mistyrose",
    "moccasin",
    "navajowhite",
    "navy",
    "oldlace",
    "olive",
    "olivedrab",
    "orange",
    "orangered",
    "orchid",
    "palegoldenrod",
    "palegreen",
    "paleturquoise",
    "palevioletred",
    "papayawhip",
    "peachpuff",
    "peru",
    "pink",
    "plum",
    "powderblue",
    "purple",
    "rebeccapurple",
    "red",
    "rosybrown",
    "royalblue",
    "saddlebrown",
    "salmon",
    "sandybrown",
    "seagreen",
    "seashell",
    "sienna",
    "silver",
    "skyblue",
    "slateblue",
    "slategray",
    "slategrey",
    "snow",
    "springgreen",
    "steelblue",
    "tan",
    "teal",
    "thistle",
    "tomato",
    "turquoise",
    "violet",
    "wheat",
    "white",
    "whitesmoke",
    "yellow",
    "yellowgreen",
  ];
  const arrFontStyle = [
    "normal",
    "italic",
    "oblique",
    "bold",
    "regular",
    "underline",
  ];
  const arrFontFamily = [
    "courier",
    "Courier",
    "helvetica",
    "Helvetica",
    "symbol",
    "Symbol",
    "times",
    "Times",
    "zapfdingbats",
    "ZapfDingbats",
  ];
  const arrFontWeight = [
    100,
    200,
    300,
    400,
    500,
    600,
    700,
    800,
    900,
    "bold",
    "normal",
  ];
  const arrTextAlignment = ["center", "left", "right", "justify"];
  const arrWordWrab = ["nowrap", "wrap"];

  const PrintInvoice = async () => {
    try {
      if (iScrId === 0) {
        Show_Message(dispatch, "Please Select Screen", "info");
      } else if (iFormatId === 0) {
        Show_Message(dispatch, "Please Select Formate", "info");
      } else {
        Pre_Loading(dispatch, true);
        var strScrType = "";
        if (iScrId === 41 || iScrId === 42 || iScrId === 43 || iScrId === 44)
          strScrType = "Accounts";
        else strScrType = "Transaction";
        let TempId = iFormatId > 0 ? iFormatId : 0;
        await Print_DesignCaller(
          _AuthParems,
          iScrId,
          strScrType,
          0,
          TempId,
          "Print",
          dispatch,
          false
        );
      }
    } catch (err) {
      Show_Message(dispatch, err.strMessage, "info");
    }
  };
  return (
    <>
      <SideMenu ScrId={32} />
      <div className="Master-container">
        <div className="Master-card">
          <div className="m-header">
            <div className="m-hsearch">
              <input
                type="text"
                autoCorrect="off"
                autoComplete="off"
                placeholder="Search Column"
                value={strSearch}
                onChange={(e) => Search_Columns(e.target.value)}
                autoFocus
              />
              <i
                className="fas fa-search"
                onClick={(e) => Search_Columns(strSearch)}
              ></i>
            </div>
          </div>
          <div className="data-list">
            {arrAreaColumn.map((dt, Index) => (
              <button
                key={Index}
                tabIndex={Index}
                onKeyDown={(e) => {
                  const arrlength = arrAreaColumn.length - 1;
                  if (e.key === "ArrowDown") {
                    if (Index === arrlength)
                      $(`.data-list [tabindex=${Index + 1}]`).focus();
                    else $(`.data-list [tabindex=${0}]`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    if (Index === 0)
                      $(`.data-list [tabindex=${arrlength}]`).focus();
                    else $(`.data-list [tabindex=${Index - 1}]`).focus();
                  }
                  if (e.key === "Enter") {
                    Add_NewDesign(dt, Index);
                  }
                }}
              >
                <label onClick={(e) => Add_NewDesign(dt, Index)}>
                  {dt.displayName}
                </label>
              </button>
            ))}
          </div>
        </div>
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-4 col-sm-12 mb-1">
                <label>
                  Screen<span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_parem.isoptionShow}
                  placeholder="Select Screen"
                  select_value={(val) => View_PrintTemplate(Number(val))}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iScrId}
                  displayName="strScrName"
                  disvalue="iScrId"
                  arrydata={arrScreenName}
                  EmptVal="Screen Name"
                />
              </div>
              <div className="col-md-4 col-sm-12 mb-1">
                <label>
                  Formate Name<span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow1"
                  showoption={_parem.isoptionShow1}
                  placeholder="Select Formate"
                  select_value={(val) => View_PrintFormate(Number(val))}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iFormatId}
                  displayName="strPrintTempName"
                  disvalue="iPrintTempId"
                  arrydata={arrPrintFormate}
                  EmptVal="Formate"
                  disabled={iScrId === 0 ? true : false}
                />
              </div>
              <div className="col-md-4 col-sm-12 mb-1">
                <label>
                  Print Area<span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow2"
                  showoption={_parem.isoptionShow2}
                  placeholder="Select Print Area"
                  select_value={(val) => View_PrintColumns(Number(val))}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iAreaId}
                  displayName="printAreaName"
                  disvalue="printAreaId"
                  arrydata={arrPrintArea}
                  EmptVal="Print Area"
                  disabled={iFormatId === 0 ? true : false}
                />
              </div>
              <div className="col-md-4 col-sm-12 mb-1">
                <label>Field Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={strColumName}
                  disabled
                  readOnly
                />
              </div>
              <div className="col-md-2 col-sm-4 mb-1">
                <label>
                  Line At<span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Height Line"
                  onClick={(e) => e.target.select()}
                  value={iLineAt}
                  onChange={(e) => setiLineAt(e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      e.target.value = (0).toFixed(2);
                    }
                  }}
                />
              </div>
              <div className="col-md-2 col-sm-4 mb-1">
                <label>
                  Start At<span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Line Start"
                  onClick={(e) => e.target.select()}
                  value={iStartAt}
                  onChange={(e) => setiStartAt(e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      e.target.value = (0).toFixed(2);
                    }
                  }}
                />
              </div>
              <div className="col-md-2 col-sm-4 mb-1">
                <label>
                  Width<span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Line Width"
                  value={iWidth}
                  onChange={(e) => setiWidth(e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      e.target.value = (0).toFixed(2);
                    }
                  }}
                />
              </div>
              <div className="col-md-2 col-sm-6 mb-1">
                <label>In Order</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  onClick={(e) => e.target.select()}
                  placeholder="Enter in Order To"
                  value={iInOrder}
                  onChange={(e) => setiInOrder(e.target.value)}
                />
              </div>
              <div className="col-md-6 col-sm-12 mb-1">
                <label>
                  Lable Name<span className="danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Lable Name"
                  value={strLableName}
                  onChange={(e) => setstrLableName(e.target.value)}
                  disabled={islblreadonly}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Prefix</label>
                <input
                  type="text"
                  aria-label="First name"
                  onClick={(e) => e.target.select()}
                  className="form-control"
                  placeholder="Enter Prefix Value"
                  value={strPrefix}
                  onChange={(e) => setstrPrefix(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Suffix</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Suffix Value"
                  onClick={(e) => e.target.select()}
                  value={strSuffix}
                  onChange={(e) => setstrSuffix(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>
                  Font Size<span className="danger">*</span>
                </label>
                <input
                  type="text"
                  onClick={(e) => e.target.select()}
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Font Size"
                  value={iFontSize}
                  onChange={(e) => setiFontSize(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Font Style</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow3"
                  showoption={_parem.isoptionShow3}
                  placeholder="Select Font Style"
                  select_value={(val) => setstrFontSyle(val)}
                  defaultval={strFontSyle}
                  arrydata={arrFontStyle}
                  EmptVal="Font Style"
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Font Weight</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow4"
                  showoption={_parem.isoptionShow4}
                  placeholder="Select Font Weight"
                  select_value={(val) => setstrfontWeight(val)}
                  defaultval={strfontWeight}
                  arrydata={arrFontWeight}
                  EmptVal="Font Weight"
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Word Wrap</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow5"
                  showoption={_parem.isoptionShow5}
                  placeholder="Select Word Wrap"
                  select_value={(val) => setstrWordWrab(val)}
                  defaultval={strWordWrab}
                  arrydata={arrWordWrab}
                  EmptVal="Word Wrap"
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Alignment</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow6"
                  showoption={_parem.isoptionShow6}
                  placeholder="Select Text Align"
                  select_value={(val) => setstrAlignment(val)}
                  defaultval={strAlignment}
                  arrydata={arrTextAlignment}
                  EmptVal="Text Align"
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Font</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow7"
                  showoption={_parem.isoptionShow7}
                  placeholder="Select Font"
                  select_value={(val) => setstrFont(val)}
                  defaultval={strFont}
                  arrydata={arrFontFamily}
                  EmptVal="Font"
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>
                  Color<span className="danger"></span>
                </label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow8"
                  showoption={_parem.isoptionShow8}
                  placeholder="Select Color"
                  select_value={(val) => setstrColor(val)}
                  defaultval={strColor}
                  arrydata={arrColor}
                  EmptVal="Color"
                />
              </div>
              <ReactTable
                columns={DesignColumns}
                data={arrColumDesign}
                minRows={5}
                row_click={(Design, Index) => {}}
                row_doubleclick={(Design, Index) => {
                  Modify_Design(Design);
                }}
                background={true}
                className="full-table"
              />
            </div>
          </div>
          {isImageUploadOpen && (
            <LogoGallery
              Close={(e) => setisImageUploadOpen(!isImageUploadOpen)}
            />
          )}
          {IsPreViewOpen && (
            <PrintScreen
              Close={(e) => setIsPreViewOpen(!IsPreViewOpen)}
              iScrId={iScrId}
              iFormatId={iFormatId}
            />
          )}
          {islinecopy && <LineCopy Close={(e) => setislinecopy(!islinecopy)} />}
          {isNewtemplate && (
            <NewTemplate Close={(e) => setisNewtemplate(!isNewtemplate)} />
          )}
          {istemplatecopy && (
            <TemplateCopy Close={(e) => setistemplatecopy(!istemplatecopy)} />
          )}
          <Messagedialogbox />
          <div className="btn-section">
            <button className="btn-fabgreen" onClick={PrintInvoice}>
              PreView &nbsp; <i className="fas fa-print"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => setisNewtemplate(!isNewtemplate)}
            >
              Print Template&nbsp;<i className="bx bx-plus"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => setistemplatecopy(!istemplatecopy)}
            >
              Template Copy &nbsp;<i className="bx bx-copy"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => (window.location.href = "/LineDesign")}
            >
              Line Design&nbsp; <i className="fas fa-edit"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => setislinecopy(!islinecopy)}
            >
              Line Copy&nbsp; <i className="bx bx-copy"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => setisImageUploadOpen(!isImageUploadOpen)}
            >
              Image &nbsp;
              <i className="fa fa-picture-o" aria-hidden="true"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (iLineAt === 0) {
                  Show_Message(dispatch, "Line At Required", "info");
                } else if (iStartAt === 0) {
                  Show_Message(dispatch, "Start At Required", "info");
                } else if (strColumName === "") {
                  Show_Message(dispatch, "Please Select Field", "info");
                } else {
                  Save_ColDesign();
                }
              }}
            >
              Save&nbsp; <i className="fa-solid fa-save"></i>
            </button>
            <button className="btn-fabgreen" onClick={ClearDesign}>
              Clear&nbsp; <i className="fa-solid fa-broom"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => (window.location.href = "/JustBill/DashBoard")}
            >
              Close &nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrintMaster;
