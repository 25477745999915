import { useSelector } from "react-redux";
import { API_POST, DecryptData } from "../General/Utility";
import Messagedialogbox, {
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import {
  Show_hidetranstabs,
  Show_tabs,
  Update_BtnPermission,
  setdafaultparems,
} from "../features/Transactionstates";
import $ from "jquery";
import SideMenu from "../General/SideMenu";
import TransactionHeader from "./TransactionHeader";
import TransactionInput from "./TransactionInput";
import TransactionSummary from "./TransactionSummary";
import PartySearch from "./PartySearch";
import TransactionView from "./TransactionView";
import SearchReturn from "./SearchReturn";
import ReceiptPopup from "../Accounts/ReceiptPopup";
import PaymentPopup from "../Accounts/PaymentPopup";
import VouchernoSetting from "./VouchernoSetting";
import PartyCreation from "./PartyCreation";
import ShippingAddressPopup from "./AddressPopup";
import ItemRemarks from "./ItemRemarks";
import RootCalculation from "./RootCalculation";
import PendingConvertion from "./PendingConvertion";
import PrintScreen from "../Print/PrintScreen";
import PrinterSetting from "../Print/PrinterSetting";
import EmailPopup from "../General/EmailPopup";
import WhatsApp from "../General/WhatsApp";
import TransactionButton from "./TransactionButton";
import BatchProductSearch from "./BatchProductSearch";
import AttributeProductSearch from "./AttributeProductSearch";
import TransProductSearch from "./TransProductSearch";

export const TransConfig = (iScrId) => {
  const config = {};
  switch (iScrId) {
    case 7:
      config.Controller = "PurchaseEnquiry";
      config.strScrType = "Purchase";
      config.strScrName = "Purchase Enquiry";
      config.iScrId = iScrId;
      break;
    case 8:
      config.Controller = "PurchaseQuotation";
      config.strScrType = "Purchase";
      config.strScrName = "Purchase Quotation";
      config.iScrId = iScrId;
      break;
    case 9:
      config.Controller = "PurchaseOrder";
      config.strScrType = "Purchase";
      config.strScrName = "Purchase Order";
      config.iScrId = iScrId;
      break;
    case 10:
      config.Controller = "PurchaseChallan";
      config.strScrType = "Purchase";
      config.strScrName = "Purchase DC";
      config.iScrId = iScrId;
      break;
    case 11:
      config.Controller = "PurchaseInvoice";
      config.strScrType = "Purchase";
      config.strScrName = "Purchase Invoice";
      config.iScrId = iScrId;
      break;
    case 12:
      config.Controller = "PurchaseReturn";
      config.strScrType = "Purchase";
      config.strScrName = "Purchase Return";
      config.iScrId = iScrId;
      break;
    case 78:
      config.Controller = "PurchaseEstimate";
      config.strScrType = "Purchase";
      config.strScrName = "Purchase Estimate";
      config.iScrId = iScrId;
      break;
    case 13:
      config.Controller = "SalesEnquiry";
      config.strScrType = "Sales";
      config.strScrName = "Sales Enquiry";
      config.iScrId = iScrId;
      break;
    case 14:
      config.Controller = "SalesQuotation";
      config.strScrType = "Sales";
      config.strScrName = "Sales Quotation";
      config.iScrId = iScrId;
      break;
    case 15:
      config.Controller = "SalesOrder";
      config.strScrType = "Sales";
      config.strScrName = "Sales Order";
      config.iScrId = iScrId;
      break;
    case 16:
      config.Controller = "SalesChallan";
      config.strScrType = "Sales";
      config.strScrName = "Sales DC";
      config.iScrId = iScrId;
      break;
    case 17:
      config.Controller = "SalesInvoice";
      config.strScrType = "Sales";
      config.strScrName = "Sales Invoice";
      config.iScrId = iScrId;
      break;
    case 18:
      config.Controller = "SalesReturn";
      config.strScrType = "Sales";
      config.strScrName = "Sales Return";
      config.iScrId = iScrId;
      break;
    case 67:
      config.Controller = "SalesEstimate";
      config.strScrType = "Sales";
      config.strScrName = "Sales Estimate";
      config.iScrId = iScrId;
      break;
    case 93:
      config.Controller = "POS";
      config.strScrType = "Sales";
      config.strScrName = "Point of Sales";
      config.iScrId = iScrId;
      break;
    case 71:
      config.Controller = "InwardChallan";
      config.strScrType = "JobWork";
      config.strScrName = "Inward Challan";
      config.iScrId = iScrId;
      break;
    case 72:
      config.Controller = "OutwardChallan";
      config.strScrType = "JobWork";
      config.strScrName = "Outward Challan";
      config.iScrId = iScrId;
      break;
    case 73:
      config.Controller = "OutwardInvoice";
      config.strScrType = "JobWork";
      config.strScrName = "Outward Invoice";
      config.iScrId = iScrId;
      break;
    case 74:
      config.Controller = "OutsourceChallan";
      config.strScrType = "JobWork";
      config.strScrName = "Outsource Challan";
      config.iScrId = iScrId;
      break;
    case 75:
      config.Controller = "InsourceChallan";
      config.strScrType = "JobWork";
      config.strScrName = "Insource Challan";
      config.iScrId = iScrId;
      break;
    case 76:
      config.Controller = "InsourceInvoice";
      config.strScrType = "JobWork";
      config.strScrName = "Insource Invoice";
      config.iScrId = iScrId;
      break;
    default:
      break;
  }
  return config;
};
export const KeyUpDown = async (e, Common, DT) => {
  var maxTab = Number(sessionStorage.maxTab);
  var ActiveIndex = document.activeElement.tabIndex;
  let tabletab = Common.arrtaborder.producttable;
  let tablerows = DT.arrEntDT.length;
  if (e.key === "ArrowLeft") {
    if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
      if (ActiveIndex - 1 === tabletab && tablerows > 0) {
        $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
      } else {
        ActiveIndex = ActiveIndex - 1;
        $("[tabindex='" + ActiveIndex + "']").focus();
        $("[tabindex='" + ActiveIndex + "']").select();
      }
    } else {
      if (maxTab - 1 === tabletab && tablerows > 0) {
        $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
      } else {
        $("[tabindex='" + maxTab + "']").focus();
        $("[tabindex='" + maxTab + "']").select();
      }
    }
  }
  if (e.key === "ArrowRight") {
    if (ActiveIndex >= 0 && ActiveIndex < maxTab) {
      if (ActiveIndex + 1 === tabletab && tablerows > 0) {
        $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
      } else {
        ActiveIndex = ActiveIndex + 1;
        $("[tabindex='" + ActiveIndex + "']").focus();
        $("[tabindex='" + ActiveIndex + "']").select();
      }
    } else {
      $("[tabindex='" + 0 + "']").focus();
      $("[tabindex='" + 0 + "']").select();
    }
  }
  if (e.key === "Enter") {
    if (ActiveIndex >= 0 && ActiveIndex < maxTab) {
      if (ActiveIndex + 1 === tabletab && tablerows > 0) {
        $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
      } else {
        ActiveIndex = ActiveIndex + 1;
        $("[tabindex='" + ActiveIndex + "']").focus();
        $("[tabindex='" + ActiveIndex + "']").select();
      }
    } else {
      $("[tabindex='" + 0 + "']").focus();
      $("[tabindex='" + 0 + "']").select();
    }
  }
  if (e.key === "ArrowDown") {
    if (ActiveIndex >= 0 && ActiveIndex < maxTab) {
      if (ActiveIndex + 1 === tabletab && tablerows > 0) {
        $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
      } else {
        ActiveIndex = ActiveIndex + 1;
        $("[tabindex='" + ActiveIndex + "']").focus();
        $("[tabindex='" + ActiveIndex + "']").select();
      }
    } else {
      $("[tabindex='" + 0 + "']").focus();
      $("[tabindex='" + 0 + "']").select();
    }
  }
  if (e.key === "ArrowUp") {
    if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
      if (ActiveIndex - 1 === tabletab && tablerows > 0) {
        $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
      } else {
        ActiveIndex = ActiveIndex - 1;
        $("[tabindex='" + ActiveIndex + "']").focus();
        $("[tabindex='" + ActiveIndex + "']").select();
      }
    } else {
      if (maxTab - 1 === tabletab && tablerows > 0) {
        $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
      } else {
        $("[tabindex='" + maxTab + "']").focus();
        $("[tabindex='" + maxTab + "']").select();
      }
    }
  }
};
export const Validate_BillType = async (_HT) => {
  switch (_HT.iBillTypeId) {
    case 2:
    case 3:
    case 4:
      return Number(_HT.iVouchId) === 0 ? true : false;
    default:
      return false;
  }
};
export const Showtab_Container = (
  lnId,
  btncls,
  Index,
  arrDT,
  type,
  dispatch
) => {
  let line = document.getElementById(lnId);
  let lable = $(`.${btncls} label`);
  let fromleft = lable[Index].offsetLeft;
  let width = lable[Index].clientWidth;
  line.style.left = fromleft + "px";
  line.style.width = width + "px";
  $(`.${btncls} label:eq(${Index})`)
    .addClass("active")
    .siblings()
    .removeClass("active");

  let EntTab = arrDT.map((Dt, trIndex) => {
    if (Dt.Show !== undefined) {
      if (Dt.Index === Index) {
        Dt.tabShow = true;
      } else {
        Dt.tabShow = false;
      }
    } else {
      if (trIndex === Index) {
        Dt = true;
      } else {
        Dt = false;
      }
    }
    return Dt;
  });
  dispatch(Show_tabs({ arrtabbtn: EntTab, tabtype: type }));
};
export const Hidetab_Container = (arrowcls, btncls, arrDT, type, dispatch) => {
  if ($(`.${arrowcls}`).hasClass("active")) {
    let Index = $(`.${btncls} label.active`).index();
    let EntTab = arrDT.map((Dt, trIndex) => {
      if (Dt.Show !== undefined) {
        if (Dt.Index === Index) {
          Dt.tabShow = true;
        } else {
          Dt.tabShow = false;
        }
      } else {
        if (trIndex === Index) {
          Dt = true;
        } else {
          Dt = false;
        }
      }
      return Dt;
    });
    dispatch(
      Show_hidetranstabs({ arrtabbtn: EntTab, minrow: 0, tabtype: type })
    );
  } else {
    let EntTab = arrDT.map((Dt) => {
      if (Dt.Show !== undefined) {
        Dt.tabShow = false;
      } else {
        Dt = false;
      }
      return Dt;
    });
    dispatch(
      Show_hidetranstabs({ arrtabbtn: EntTab, minrow: 5, tabtype: type })
    );
  }
  $(`.${arrowcls}`).toggleClass("active");
};
export const Update_TrasDefault = (state, Data) => {
  state.Common.BillType = Data.BillType;
  state.Common.TaxType = Data.TaxType;
  state.Header.iBillTypeId = Data.BillTypeId;
  state.AddLess.arrVendore = Data.Vendore;
  state.AddLess.arrService = Data.Service;
};
export const Validate_TransPermission = (arrtransper) => {
  const TransPer = {
    IsProCode: false,
    IsItemDisc: false,
    IsCashDisc: false,
    IsFQty: false,
    IsBatchNo: false,
    IsMftDate: false,
    IsExpDate: false,
  };
  for (var i = 0; i < arrtransper.length; i++) {
    switch (arrtransper[i].columnName) {
      case "ProCode":
        if (arrtransper[i].isShowInScr) TransPer.IsProCode = true;
        break;
      case "ItemDiscAmt":
        if (arrtransper[i].isShowInScr) TransPer.IsItemDisc = true;
        break;
      case "ItemDiscPer":
        if (arrtransper[i].isShowInScr) TransPer.IsItemDisc = true;
        break;
      case "CashDiscPer":
        if (arrtransper[i].isShowInScr) TransPer.IsCashDisc = true;
        break;
      case "CashDiscAmt":
        if (arrtransper[i].isShowInScr) TransPer.IsCashDisc = true;
        break;
      case "FQty":
        if (arrtransper[i].isShowInScr) TransPer.IsFQty = true;
        break;
      case "BatchNo":
        if (arrtransper[i].isShowInScr) TransPer.IsBatchNo = true;
        break;
      case "ManufactureDate":
        if (arrtransper[i].isShowInScr) TransPer.IsMftDate = true;
        break;
      case "ExpiryDate":
        if (arrtransper[i].isShowInScr) TransPer.IsExpDate = true;
        break;
      default:
        break;
    }
  }
  return TransPer;
};
export const Validate_Properties = async (Properties, state, dispatch) => {
  var ErrorMsg = true;
  Properties = Properties.filter((Data) => Data.isValidate === true);
  for (var i = 0; i < Properties.length; i++) {
    switch (Properties[i].strCtrlName) {
      case "txtTransNo":
        ErrorMsg = state.strTransNo === "" ? false : true;
        break;
      case "txtSalInvNo":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtSalDCNo":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtPurInvNo":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtPartyName":
        ErrorMsg = state.strPartyName === "" ? false : true;
        break;
      case "txtShippingAdd":
        ErrorMsg = state.strShippingAdd === "" ? false : true;
        break;
      case "cbBillType":
        ErrorMsg = Number(state.iBillTypeId) === 0 ? false : true;
        break;
      case "cbTaxType":
        ErrorMsg = Number(state.iTaxRelId) === 0 ? false : true;
        break;
      case "txtTransport":
        ErrorMsg = state.strTransport === "" ? false : true;
        break;
      case "txtDespatch":
        ErrorMsg = state.strDespatch === "" ? false : true;
        break;
      case "txtDestination":
        ErrorMsg = state.strDestination === "" ? false : true;
        break;
      case "txtVehicle":
        ErrorMsg = state.strVehicle === "" ? false : true;
        break;
      case "cbDeliMode":
        ErrorMsg = parseFloat(state.iDMId) === 0 ? false : true;
        break;
      case "txtRefName":
        ErrorMsg = state.strReference === "" ? false : true;
        break;
      case "txtAgentName":
        ErrorMsg = state.strAgent === "" ? false : true;
        break;
      case "txtValidity":
        ErrorMsg = state.strValidity === "" ? false : true;
        break;
      case "txtPayTerm":
        ErrorMsg = state.strPayTerm === "" ? false : true;
        break;
      case "txtDeliTerm":
        ErrorMsg = state.strDeliTerm === "" ? false : true;
        break;
      case "txtRemark1":
        ErrorMsg = state.strRemark1 === "" ? false : true;
        break;
      case "txtRemark2":
        ErrorMsg = state.strRefNum2 === "" ? false : true;
        break;
      case "txtRemark3":
        ErrorMsg = state.strRefNum3 === "" ? false : true;
        break;
      case "txtRemark4":
        ErrorMsg = state.strRefNum4 === "" ? false : true;
        break;
      case "txtLRNumber":
        ErrorMsg = state.strLRNNumber === "" ? false : true;
        break;
      case "txtRefNum1":
        ErrorMsg = state.strRefNum1 === "" ? false : true;
        break;
      case "txtRefNum2":
        ErrorMsg = state.strRefNum2 === "" ? false : true;
        break;
      case "txtRefNum3":
        ErrorMsg = state.strRefNum3 === "" ? false : true;
        break;
      case "txtRefNum4":
        ErrorMsg = state.strRefNum4 === "" ? false : true;
        break;
      case "txtSalEnqNo":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtSalOrdNo":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtSalQuoNo":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtESNo":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtPurQuoNo":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtPurOrdNo":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtPurEstNo":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtPurEnqNo":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtPurDCNo":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtSalRetNo":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtPurRetNo":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtInwDCNo":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtOutWDCNo":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtOutWInvNo":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtOutwardVouchers":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtInwardVouchers":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtPartyConvertedVouchers":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtOutSDCNo":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtInSDCNo":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtConvertedVouchers":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtOutsourceVouchers":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      case "txtInsourceVouchers":
        ErrorMsg = state.strVouchNo === "" ? false : true;
        break;
      default:
        break;
    }
    if (ErrorMsg === false) {
      Show_Message(dispatch, Properties[i].strErrorMsg, "error");
      return ErrorMsg;
    }
  }
  return ErrorMsg;
};
export const Validate_Taborder = (tabSettings, taborder) => {
  tabSettings = tabSettings.filter((Data) => Data.isTabStop === true);
  for (var i = 0; i < tabSettings.length; i++) {
    switch (tabSettings[i].strCtrlName) {
      case "txtPartyName":
        taborder.PartyName = tabSettings[i].iTabIndex;
        break;
      case "txtBillAddress":
        taborder.BillAddress = tabSettings[i].iTabIndex;
        break;
      case "txtShippingAdd":
        taborder.ShippingAdd = tabSettings[i].iTabIndex;
        break;
      case "txtBillDiscValue":
        taborder.BillDiscValue = tabSettings[i].iTabIndex;
        break;
      case "cbBillType":
        taborder.BillType = tabSettings[i].iTabIndex;
        break;
      case "cbTaxType":
        taborder.TaxType = tabSettings[i].iTabIndex;
        break;
      case "txtTransNo":
        taborder.TransNo = tabSettings[i].iTabIndex;
        break;
      case "txtAddLessDiscPer":
        taborder.AddLessDiscPer = tabSettings[i].iTabIndex;
        break;
      case "txtSalInvNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpSalInvDate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      case "txtPurInvNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpPurInvDate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      case "txtSalDCNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpSalDCDate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      case "txtAddLessDiscAmt":
        taborder.AddLessDiscAmt = tabSettings[i].iTabIndex;
        break;
      case "txtAddLessDiscValue":
        taborder.AddLessDiscValue = tabSettings[i].iTabIndex;
        break;
      case "txtTransport":
        taborder.Transport = tabSettings[i].iTabIndex;
        break;
      case "txtDespatch":
        taborder.Despatch = tabSettings[i].iTabIndex;
        break;
      case "dpDespatchDate":
        taborder.DespatchDate = tabSettings[i].iTabIndex;
        break;
      case "txtDestination":
        taborder.Destination = tabSettings[i].iTabIndex;
        break;
      case "txtVehicle":
        taborder.Vehicle = tabSettings[i].iTabIndex;
        break;
      case "cbDeliMode":
        taborder.DeliMode = tabSettings[i].iTabIndex;
        break;
      case "txtRefName":
        taborder.RefName = tabSettings[i].iTabIndex;
        break;
      case "txtAgentName":
        taborder.AgentName = tabSettings[i].iTabIndex;
        break;
      case "txtValidity":
        taborder.Validity = tabSettings[i].iTabIndex;
        break;
      case "dpValidityUpto":
        taborder.ValidityUpto = tabSettings[i].iTabIndex;
        break;
      case "txtPayTerm":
        taborder.PayTerm = tabSettings[i].iTabIndex;
        break;
      case "dpPayTermUpto":
        taborder.PayTermUpto = tabSettings[i].iTabIndex;
        break;
      case "txtDeliTerm":
        taborder.DeliTerm = tabSettings[i].iTabIndex;
        break;
      case "dpDeliTermUpto":
        taborder.DeliTermUpto = tabSettings[i].iTabIndex;
        break;
      case "txtBillDiscPer":
        taborder.BillDiscPer = tabSettings[i].iTabIndex;
        break;
      case "txtBillDiscAmt":
        taborder.BillDiscAmt = tabSettings[i].iTabIndex;
        break;
      case "btnSave":
        taborder.btnSave = tabSettings[i].iTabIndex;
        break;
      case "btnClear":
        taborder.btnClear = tabSettings[i].iTabIndex;
        break;
      case "btnView":
        taborder.btnView = tabSettings[i].iTabIndex;
        break;
      case "btnExportImport":
        taborder.btnExportImport = tabSettings[i].iTabIndex;
        break;
      case "btnPrint":
        taborder.btnPrint = tabSettings[i].iTabIndex;
        break;
      case "btnCancel":
        taborder.btnCancel = tabSettings[i].iTabIndex;
        break;
      case "btnClose":
        taborder.btnClose = tabSettings[i].iTabIndex;
        break;
      case "btnPrintSettings":
        taborder.btnPrintSettings = tabSettings[i].iTabIndex;
        break;
      case "btnHelp":
        taborder.btnHelp = tabSettings[i].iTabIndex;
        break;
      case "txtRemark1":
        taborder.Remark1 = tabSettings[i].iTabIndex;
        break;
      case "txtRemark2":
        taborder.Remark2 = tabSettings[i].iTabIndex;
        break;
      case "txtRemark3":
        taborder.Remark3 = tabSettings[i].iTabIndex;
        break;
      case "txtRemark4":
        taborder.Remark4 = tabSettings[i].iTabIndex;
        break;
      case "txtLRNumber":
        taborder.LRNumber = tabSettings[i].iTabIndex;
        break;
      case "dpLRDate":
        taborder.LRDate = tabSettings[i].iTabIndex;
        break;
      case "TopLeftParty":
        taborder.btnParty = tabSettings[i].iTabIndex;
        break;
      case "TopCenterVoucher":
        taborder.btnVoucher = tabSettings[i].iTabIndex;
        break;
      case "TopRightDelivery":
        taborder.btnDelivery = tabSettings[i].iTabIndex;
        break;
      case "TopRightTranport":
        taborder.btnTranport = tabSettings[i].iTabIndex;
        break;
      case "TopRightValidity":
        taborder.btnValidity = tabSettings[i].iTabIndex;
        break;
      case "TopRightRemarks":
        taborder.btnRemarks = tabSettings[i].iTabIndex;
        break;
      case "MiddleRightSummary":
        taborder.btnSummary = tabSettings[i].iTabIndex;
        break;
      case "MiddleRightTax":
        taborder.btnTax = tabSettings[i].iTabIndex;
        break;
      case "MiddleRightDiscount":
        taborder.btnDiscount = tabSettings[i].iTabIndex;
        break;
      case "MiddleRightAddLess":
        taborder.btnAddLess = tabSettings[i].iTabIndex;
        break;
      case "TopCenterRefVouchers":
        taborder.btnRefVouchers = tabSettings[i].iTabIndex;
        break;
      case "txtRefNum1":
        taborder.RefNum1 = tabSettings[i].iTabIndex;
        break;
      case "txtRefNum1Date":
        taborder.RefNum1Date = tabSettings[i].iTabIndex;
        break;
      case "txtRefNum2":
        taborder.RefNum2 = tabSettings[i].iTabIndex;
        break;
      case "txtRefNum2Date":
        taborder.RefNum2Date = tabSettings[i].iTabIndex;
        break;
      case "txtRefNum3":
        taborder.RefNum3 = tabSettings[i].iTabIndex;
        break;
      case "txtRefNum3Date":
        taborder.RefNum3Date = tabSettings[i].iTabIndex;
        break;
      case "txtRefNum4":
        taborder.RefNum4 = tabSettings[i].iTabIndex;
        break;
      case "txtRefNum4Date":
        taborder.RefNum4Date = tabSettings[i].iTabIndex;
        break;
      case "lbProduct":
        taborder.producttable = tabSettings[i].iTabIndex;
        break;
      case "txtProductName":
        taborder.ProductName = tabSettings[i].iTabIndex;
        break;
      case "txtQty":
        taborder.Qty = tabSettings[i].iTabIndex;
        break;
      case "cbUnit":
        taborder.Unit = tabSettings[i].iTabIndex;
        break;
      case "txtRate":
        taborder.Rate = tabSettings[i].iTabIndex;
        break;
      case "txtDiscount":
        taborder.Discount = tabSettings[i].iTabIndex;
        break;
      case "cbJob":
        taborder.cbJob = tabSettings[i].iTabIndex;
        break;
      case "btnAdd":
        taborder.btnAdd = tabSettings[i].iTabIndex;
        break;
      case "discType":
        taborder.discType = tabSettings[i].iTabIndex;
        break;
      case "btnRefresh":
        taborder.btnRefresh = tabSettings[i].iTabIndex;
        break;
      case "txtSalEnqNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpSalEnqDate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      case "txtSalOrdNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpSalDOrdate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      case "txtSalQuoNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpSalDQuoate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      case "txtESNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpESDate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      case "txtPurQuoNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpPurQuoDate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      case "txtPurOrdNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpPurOrdDate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      case "txtPurEstNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpPurEstDate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      case "txtPurEnqNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpPurEnqDate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      case "txtPurDCNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpPurDCDate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      case "txtSalRetNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpSalRetDate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      case "txtPurRetNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpPurRetDate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      case "txtInwDCNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpInwDCDate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      case "txtOutWDCNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpOutWDCDate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      case "txtOutWInvNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpOutWInvDate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      case "txtOutSDCNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpOutSDCDate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      case "txtInSDCNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpInSDCDate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      case "txtInSInvNo":
        taborder.txtInvNo = tabSettings[i].iTabIndex;
        break;
      case "dpInSInvDate":
        taborder.dpInvDate = tabSettings[i].iTabIndex;
        break;
      default:
        break;
    }
  }
  var maxTab = tabSettings.reduce(
    (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
    0
  );
  sessionStorage.maxTab = maxTab.iTabIndex;
  return tabSettings;
};
const Hide_Validitytab = (ScrId) => {
  switch (ScrId) {
    case 71:
    case 72:
    case 74:
    case 75:
      return true;
    default:
      return false;
  }
};
export const NumtoDecimal = (num) => {
  return Number(num.toFixed(2));
};
export const ConverttoDecimal = (Data) => {
  return Data.map((dt) => {
    dt.billDiscValue = NumtoDecimal(dt.billDiscValue);
    dt.cashDiscAmt = NumtoDecimal(dt.cashDiscAmt);
    dt.cashDiscPer = NumtoDecimal(dt.cashDiscPer);
    dt.cashDiscValue = NumtoDecimal(dt.cashDiscValue);
    dt.constantValue = NumtoDecimal(dt.constantValue);
    dt.convertedQty = NumtoDecimal(dt.convertedQty);
    dt.finalValue = NumtoDecimal(dt.finalValue);
    dt.fQty = NumtoDecimal(dt.fQty);
    dt.goodsValue = NumtoDecimal(dt.goodsValue);
    dt.itemAmt = NumtoDecimal(dt.itemAmt);
    dt.itemDiscAmt = NumtoDecimal(dt.itemDiscAmt);
    dt.itemDiscPer = NumtoDecimal(dt.itemDiscPer);
    dt.itemDiscValue = NumtoDecimal(dt.itemDiscValue);
    dt.rateWithoutTax = NumtoDecimal(dt.rateWithoutTax);
    dt.rateWithTax = NumtoDecimal(dt.rateWithTax);
    dt.taxableAmt = NumtoDecimal(dt.taxableAmt);
    dt.taxAmt = NumtoDecimal(dt.taxAmt);
    dt.taxPer = NumtoDecimal(dt.taxPer);
    return dt;
  });
};
export const Validate_BtnPermission = async (_Common, _HT, _DT, dispatch) => {
  const _LocalParems = await DecryptData(localStorage.jbctrlparams);
  const BtnPerm = {
    btnSave: true,
    btnView: true,
    btnDelete: true,
    btnConvert: true,
  };
  let Menu = {};
  switch (_Common.strScrType) {
    case "Sales":
      Menu = _LocalParems.SalesPer.filter(
        (Data) => Data.iScrId === _Common.iScrId
      )[0];
      break;
    case "Purchase":
      Menu = _LocalParems.PurchasePer.filter(
        (Data) => Data.iScrId === _Common.iScrId
      )[0];
      break;
    case "JobWork":
      Menu = _LocalParems.JobworkPer.filter(
        (Data) => Data.iScrId === _Common.iScrId
      )[0];
      break;
    default:
      break;
  }
  if (_HT.iVouchId > 0) {
    BtnPerm.btnConvert = true;
    if (_HT.BtnDisable === false) {
      BtnPerm.btnSave = !Menu.isModify;
      BtnPerm.btnDelete = !Menu.isDelete;
    } else {
      BtnPerm.btnSave = _HT.BtnDisable;
      BtnPerm.btnDelete = _HT.BtnDisable;
    }
    BtnPerm.btnView = !Menu.isView;
  } else {
    BtnPerm.btnSave = !Menu.isCreate;
    BtnPerm.btnDelete = !Menu.isDelete;
    BtnPerm.btnView = !Menu.isView;
    BtnPerm.btnConvert = false;
  }
  switch (_Common.iScrId) {
    case 7:
    case 12:
    case 13:
    case 18:
    case 67:
    case 78:
      BtnPerm.btnConvert = true;
      break;
    default:
      if (_HT.iPartyId > 0 && _DT.arrEntPenCon.length > 0)
        BtnPerm.btnConvert = true;
      break;
  }
  dispatch(Update_BtnPermission(BtnPerm));
};
export const Load_Transaction = async (dispatch, _Common, iScrId) => {
  try {
    await Pre_Loading(dispatch, true);
    const _LocalParems = await DecryptData(localStorage.jbctrlparams);
    const config = TransConfig(iScrId);
    const objTrans = await {
      Controller: config.Controller,
      ScrType: config.strScrType,
      ScrId: config.iScrId,
      ScrName: config.strScrName,
      PartyScrId: "",
      PartyId: 0,
      VenType: "",
      strSearch: "",
      ColName: "",
      CompanyDB: _LocalParems.DB,
      Client: Number(_LocalParems.Client),
      UserId: Number(_LocalParems.User),
      BranchId: Number(_LocalParems.Branch),
    };
    switch (objTrans.ScrType) {
      case "Purchase":
        objTrans.PartyScrId = 3;
        objTrans.VenType = 1;
        objTrans.ColName = "SuppName";
        break;
      case "JobWork":
        objTrans.PartyScrId = 50;
        objTrans.VenType = 5;
        objTrans.ColName = "VenName";
        break;
      default:
        objTrans.PartyScrId = 2;
        objTrans.VenType = 4;
        objTrans.ColName = "CustName";
        break;
    }
    const res = await API_POST(
      `Transaction/Load_Transaction`,
      objTrans,
      dispatch
    );
    if (res) {
      let Index = 1;
      let arrtabbtn = JSON.parse(JSON.stringify(_Common.Headertab));
      await arrtabbtn.map((dt) => {
        let Showtab = res.FieldPer.filter(
          (tab) => tab.iTabId === dt.TabId && tab.bIsVissible === true
        );
        if (Showtab.length > 0) {
          dt.Show = true;
          dt.Index = Index;
          Index += 1;
        } else if (dt.TabId !== 0) {
          dt.Show = false;
          dt.Index = 0;
        }
        return dt;
      });
      if (Hide_Validitytab(objTrans.ScrId)) {
        const { Show, Index } = await arrtabbtn.filter(
          (dt) => dt.TabId === 5
        )[0];
        await arrtabbtn.map((dt) => {
          if (dt.TabId === 5) {
            dt.Show = false;
            dt.Index = 7;
          } else if (dt.TabId === 6) {
            dt.Show = Show;
            dt.Index = Index;
          }
          return dt;
        });
      }
      await dispatch(
        setdafaultparems({
          Data: res,
          tabBtn: arrtabbtn,
          TransPaprem: objTrans,
        })
      );
    }
  } catch (e) {
    Show_Message(dispatch, e.message, "error");
  } finally {
    await Pre_Loading(dispatch, false);
  }
};
export const Table_Style = (dt, Item) => {
  const Style = {
    textAlign: dt.strTextAlign,
    whiteSpace: "wrap",
    color:
      dt.strColName === "tQty"
        ? Number(Item.tQty) < 0
          ? "red"
          : "black"
        : "black",
  };
  if (dt.strWidth.includes("%") || dt.strWidth.includes("auto")) {
    Style.width = dt.strWidth;
    Style.minWidth = "130px";
  } else {
    Style.width = dt.strWidth + "px";
    Style.minWidth = dt.strWidth + "px";
    Style.maxWidth = dt.strWidth + "px";
  }
  return Style;
};
function Transaction(props) {
  const _Common = useSelector((store) => store.Transstates.Common);
  return (
    <>
      <SideMenu ScrId={props.ScrId} />
      <div className="Trans-container">
        <div className="Trans-card">
          <div className="Trans-wrapper">
            <TransactionHeader />
            <TransactionInput />
            <TransactionSummary />
            <Messagedialogbox />
            {_Common.IsVenSearch && <PartySearch />}
            {_Common.IsTransView && <TransactionView />}
            {_Common.IsProSearch && <TransProductSearch />}
            {_Common.IsBatchPro && <BatchProductSearch />}
            {_Common.IsAttPro && <AttributeProductSearch />}
            {_Common.IsReturnPro && <SearchReturn />}
            {_Common.IsReceipt && <ReceiptPopup />}
            {_Common.IsPayment && <PaymentPopup />}
            {_Common.IsVouchNo && <VouchernoSetting />}
            {_Common.IsVenCreation && <PartyCreation />}
            {_Common.IsAddress && <ShippingAddressPopup />}
            {_Common.IsProRemark && <ItemRemarks />}
            {_Common.IsProCal && <RootCalculation />}
            {/* {_Common.IsConvertion && <ConvertRowManipulationRetail />} */}
            {_Common.IsPendinCon && <PendingConvertion />}
            {_Common.IsPrintShow && <PrintScreen />}
            {_Common.IsPrintSett && <PrinterSetting />}
            {_Common.IsEmail && <EmailPopup />}
            {_Common.IsWhatsApp && <WhatsApp />}
            {/* {_Common.IsEInvoice && <EInvoice />} */}
          </div>
          <TransactionButton Save={props.Save} />
        </div>
      </div>
    </>
  );
}
export default Transaction;
