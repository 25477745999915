import { useEffect, useState } from "react";
import Button from "../Master/MasterButton";
import MasterList from "../Master/MasterListItem";
import $ from "jquery";
import SideMenu from "../General/SideMenu";

import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import ReactTable from "../General/ReactTable";
import { useSelector } from "react-redux";
function Attribute() {
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const iScrId = 60;
  const strAttCodeTag = "Enter Attribute Code";
  const [strMessage, setstrMessage] = useState("");
  const [strIcon, setstrIcon] = useState("");
  const [IsNotification, setIsNotification] = useState(false);
  const [iAttId, setiAttId] = useState(0);
  const [strAttName, setstrAttName] = useState("");
  const [strQuestion, setstrQuestion] = useState("");
  const [IsDialogOpen, setIsDialogOpen] = useState(false);
  const [IsDialogFor, setIsDialogFor] = useState("");
  const [strAttValue, setstrAttValue] = useState("");
  const [strAttCode, setstrAttCode] = useState("");
  const [IsLoding, setIsLoding] = useState(true);
  const [strDescription, setstrDescription] = useState("");
  const [strRemarks, setstrRemarks] = useState("");
  const [iDimensionId, setiDimensionId] = useState(1);
  const [iValueType, setiValueType] = useState(1);
  const [strAlias, setstrAlias] = useState("");
  const [strDateAlias, setstrDateAlias] = useState("");
  const [strPrefix, setstrPrefix] = useState("");
  const [iNumSeqNo, setiNumSeqNo] = useState(0);
  const [iAttCount, setiAttCount] = useState(0);
  const [bIsDefault, setbIsDefault] = useState(false);
  const [bIsDateDefault, setbIsDateDefault] = useState(false);
  const [selected, setselected] = useState(0);
  const [ManuFacturingDate, setManuFacturingDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [ExpiryDate, setExpiryDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [BatchNo, setBatchNo] = useState("");
  const [SNo, setSNo] = useState(0);
  const [AttDTId, setAttDTId] = useState(0);
  const [AttDateTId, setAttDateTId] = useState(0);

  const [ListValueType, setListValueType] = useState([]);
  const [ListDimension, setListDimension] = useState([]);
  const [ListFilter, setListFilter] = useState([]);
  const [ListAttList, setListAttList] = useState([]);
  const [ListAddAtt, setListAddAtt] = useState([]);
  const [ListAddDate, setListAddDate] = useState([]);

  useEffect(() => {
    GetDefaultAttribute();
  }, []);
  const GetDefaultAttribute = async () => {
    const _LocalParems = await DecryptData(localStorage.jbctrlparams);

    const res = await API_FETCH(
      "Attribute/GetDefaultData/" + _LocalParems.DB
    ).then();
    if (res) {
      setListValueType(res.objAttType);
      setListDimension(res.objAttDim);
      setListFilter(res.objFilter);
      setListAttList(res.objAttList);
      setiAttCount(res.objAttList.length);
      setstrPrefix(res.objCodeFormate[0].strPrefix);
      setstrAttCode(res.objCodeFormate[0].strCode);
      setiNumSeqNo(res.objCodeFormate[0].iNumSeqNo);
      setIsLoding(false);
    }
  };
  const SaveClick = async () => {
    setIsLoding(true);
    const objAttMasterModel = {
      iAttId: iAttId,
      strAttName: strAttName,
      strAttCode: strAttCode,
      strDescription: strDescription,
      strRemarks: strRemarks,
      iDimensionId: Number(iDimensionId),
      iValueType: Number(iValueType),
      strAttValue: strAttValue,
      iTitleTag: iScrId,
      iNumSeqNo: iNumSeqNo,
      strFilter: "",
      strSearch: "",
      strFilterColumn: "All",
      strAttCodeTag: strAttCodeTag,
      iTextBoxLength: ListAddAtt.filter((e) => e.AttValue !== "").length,
      iDateLength: ListAddDate.filter((e) => e.AttValue !== "").length,
      strPrefix: strPrefix,
      iAttValueTypeId: Number(iValueType),
    };
    const InsUpd = {
      objTextBoxModel: ListAddAtt,
      objDateModel: ListAddDate,
      objAttMasterModel: objAttMasterModel,
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST("Attribute/InsUpd/", InsUpd);
    if (res) {
      ClearClick();
      MsgBox("Success", res.strMessage, "success");
    }
  };
  const ClearClick = () => {
    setiAttId(0);
    setstrAttName("");
    setAttDTId(0);
    setAttDTId(0);
    setstrAttValue("");
    setstrAttCode("");
    setiDimensionId(1);
    setiValueType(iValueType);
    setstrDescription("");
    setSNo(0);
    setstrRemarks("");
    setstrAlias("");
    setstrDateAlias("");
    setstrPrefix("");
    setiAttCount(0);
    setbIsDefault(false);
    setbIsDateDefault(false);
    setManuFacturingDate(new Date().toISOString().split("T")[0]);
    setExpiryDate(new Date().toISOString().split("T")[0]);
    setBatchNo("");
    setAttDTId(0);
    setAttDateTId(0);
    setListAttList([]);
    setListAddAtt([]);
    setListAddDate([]);
    GetDefaultAttribute();
  };
  const DeleteClick = async () => {
    setIsLoding(true);
    const res = await API_POST(
      "Attribute/Delete_Attribute/" +
        Number(_AuthParems.Branch) +
        "/" +
        iAttId +
        "/" +
        _AuthParems.DB
    );
    if (res) {
      ClearClick();
      MsgBox("Success", res.strMessage, "success");
    }
  };
  const DeleteIconClick = async (AttId) => {
    if (AttId === 0) {
      MsgBox("Validation", "Please Select Attribute", "info");
    } else {
      setIsLoding(true);
      const res = await API_POST(
        "Attribute/Delete_Attribute/" +
          Number(_AuthParems.Branch) +
          "/" +
          AttId +
          "/" +
          _AuthParems.DB
      );
      if (res) {
        ClearClick();
        MsgBox("Success", res.strMessage, "success");
      }
    }
  };
  const GetAttribute = async (AttId, Index) => {
    if (AttId === 0) {
      MsgBox("Validation", "Please Select Attribute", "info");
    } else {
      setIsLoding(true);
      const res = await API_FETCH(
        "Attribute/GetAttribute/" +
          iScrId +
          "/" +
          AttId +
          "/" +
          Index +
          "/" +
          _AuthParems.DB
      );
      if (res) {
        if (res.objAttMasterModel.iValueType === 1) {
          setiAttId(res.objAttMasterModel.iAttId);
          setstrAttName(res.objAttMasterModel.strAttName);
          setstrAttCode(res.objAttMasterModel.strAttCode);
          setiDimensionId(res.objAttMasterModel.iDimensionId);
          setiValueType(res.objAttMasterModel.iValueType);
          setstrDescription(res.objAttMasterModel.strDescription);
          setstrRemarks(res.objAttMasterModel.strRemarks);
          setstrPrefix(res.objAttMasterModel.strPrefix);
          setiNumSeqNo(res.objAttMasterModel.iNumSeqNo);
          setListAddAtt(res.objTextBoxModel);
        } else {
          setiAttId(res.objAttMasterModel.iAttId);
          setstrAttName(res.objAttMasterModel.strAttName);
          setstrAttCode(res.objAttMasterModel.strAttCode);
          setiDimensionId(res.objAttMasterModel.iDimensionId);
          setiValueType(res.objAttMasterModel.iValueType);
          setstrDescription(res.objAttMasterModel.strDescription);
          setstrRemarks(res.objAttMasterModel.strRemarks);
          setiNumSeqNo(res.objAttMasterModel.iNumSeqNo);
          setstrPrefix(res.objAttMasterModel.strPrefix);
          res.objDateModel.map((Data) => {
            Data.expiryDate = Data.expiryDate.split(" ")[0];
            Data.manufactureDate = Data.manufactureDate.split(" ")[0];
            return Data;
          });
          setListAddDate(res.objDateModel);
        }
      }
    }
  };
  const AddDate = () => {
    if (BatchNo === "") {
      MsgBox("Validation !", "Please Enter Batch No", "info");
    } else {
      var ListEntATT = ListAddDate;
      if (SNo !== 0) {
        ListEntATT.map((Data) => {
          if (Data.sNo === SNo) {
            Data.default = bIsDateDefault === true ? true : false;
            Data.attValue = BatchNo;
            Data.alias = strDateAlias;
            Data.manufactureDate = ManuFacturingDate.split("-")
              .reverse()
              .join("-");
            Data.expiryDate = ExpiryDate.split("-").reverse().join("-");
          } else {
            if (bIsDateDefault === true) Data.default = false;
          }
          return Data;
        });
        setListAddAtt(ListEntATT);
        setSNo(0);
        setBatchNo("");
        setstrDateAlias("");
        setAttDTId(0);
        setbIsDateDefault(false);
        setManuFacturingDate(new Date().toISOString().split("T")[0]);
        setExpiryDate(new Date().toISOString().split("T")[0]);
      } else {
        var RowCount = ListAddDate.length;
        if (RowCount > 0) {
          ListEntATT.map((Data) => {
            if (bIsDateDefault === true) {
              Data.default = false;
            }
            return Data;
          });
        }
        const AddItem = {
          sNo: RowCount + 1,
          attDTId: AttDateTId,
          attValue: BatchNo,
          default: bIsDateDefault,
          alias: strDateAlias,
          manufactureDate: ManuFacturingDate.split("-").reverse().join("-"),
          expiryDate: ExpiryDate.split("-").reverse().join("-"),
        };
        if (RowCount === 0) ListEntATT = [AddItem];
        else ListEntATT.push(AddItem);
        setListAddDate(ListEntATT);
        setBatchNo("");
        setstrDateAlias("");
        setAttDTId(0);
        setbIsDateDefault(false);
        setManuFacturingDate(new Date().toISOString().split("T")[0]);
        setExpiryDate(new Date().toISOString().split("T")[0]);
      }
    }
  };
  const AddAtt = () => {
    if (strAttValue === "") {
      MsgBox("Validation !", "Please Enter AttributeValue", "info");
    } else {
      var ListEntATT = ListAddAtt;
      if (SNo !== 0) {
        ListEntATT.map((Data) => {
          if (Data.sNo === SNo) {
            Data.default = bIsDefault === true ? true : false;
            Data.attValue = strAttValue;
            Data.alias = strAlias;
          } else {
            if (bIsDefault === true) Data.default = false;
          }
          return Data;
        });
        setListAddAtt(ListEntATT);
        setstrAttValue("");
        setSNo(0);
        setstrAlias("");
        setAttDTId(0);
        setbIsDefault(false);
      } else {
        var RowCount = ListAddAtt.length;
        if (RowCount > 0) {
          ListEntATT.map((Data) => {
            if (bIsDefault === true) {
              Data.default = false;
            }
            return Data;
          });
        }
        const AddItem = {
          sNo: RowCount + 1,
          default: bIsDefault,
          attValue: strAttValue,
          alias: strAlias,
          attDTId: AttDTId,
        };
        if (RowCount === 0) ListEntATT = [AddItem];
        else ListEntATT.push(AddItem);
        setListAddAtt(ListEntATT);
        setstrAttValue("");
        setstrAlias("");
        setAttDTId(0);
        setbIsDefault(false);
      }
    }
  };
  const AssignAttValue = (RowInfo) => {
    if (iValueType === 1) {
      setstrAlias(RowInfo.alias);
      setstrAttValue(RowInfo.attValue);
      setbIsDefault(RowInfo.default);
      setAttDTId(RowInfo.attDTId);
      setSNo(RowInfo.sNo);
    } else {
      setstrDateAlias(RowInfo.alias);
      setBatchNo(RowInfo.attValue);
      setbIsDateDefault(RowInfo.default);
      setAttDTId(RowInfo.attDTId);
      RowInfo.manufactureDate = RowInfo.manufactureDate
        .split("-")
        .reverse()
        .join("-");
      RowInfo.expiryDate = RowInfo.expiryDate.split("-").reverse().join("-");
      setManuFacturingDate(RowInfo.manufactureDate);
      setExpiryDate(RowInfo.expiryDate);
      setSNo(RowInfo.sNo);
    }
  };
  const RemoveRow = (rowinfo) => {
    if (iValueType === 1) {
      const newList = ListAddAtt.filter((item) => item.sNo !== rowinfo.sNo);
      newList.map((Data, Index) => {
        Data.sNo = Index + 1;
        return Data;
      });
      setListAddAtt(newList);
    } else {
      const newList = ListAddDate.filter((item) => item.sNo !== rowinfo.sNo);
      newList.map((Data, Index) => {
        Data.sNo = Index + 1;
        return Data;
      });
      setListAddDate(newList);
    }
  };
  const Search = async (Responce) => {
    if (Responce.strSearch === "") {
      Responce.strSearch = "null";
    }
    const res = await API_FETCH(
      "Attribute/Search/" +
        Responce.strFilterColumn +
        "/" +
        Responce.strSearch +
        "/" +
        "null" +
        "/" +
        _AuthParems.DB
    );
    if (res) {
      setListAttList(res);
    }
  };
  const Filter = async (Responce) => {
    if (Responce.strFilter === "") {
      Responce.strFilter = "null";
    }
    const res = await API_FETCH(
      "Attribute/Search/" +
        Responce.strFilterColumn +
        "/" +
        "null" +
        "/" +
        Responce.strFilter +
        "/" +
        _AuthParems.DB
    );
    if (res) {
      setListAttList(res);
    }
  };
  const TextBoxColumn = [
    {
      Header: "SNO",
      accessor: "sNo",
      maxWidth: 70,
    },
    {
      Header: "Default",
      id: "default",
      accessor: (rowInfo) => (
        <div>
          <input
            type="checkbox"
            id="CheckBox"
            readOnly
            checked={rowInfo.default}
            className="form-check-input"
          />
        </div>
      ),
    },
    {
      Header: "AttributeValue",
      accessor: "attValue",
    },
    {
      Header: "Alias",
      accessor: "alias",
    },
    {
      Header: "Action",
      id: "DeleteId",
      maxWidth: 100,
      accessor: (rowinfo) => (
        <div>
          <i
            onClick={() => RemoveRow(rowinfo)}
            className="far fa-trash-alt"
          ></i>
        </div>
      ),
    },
  ];
  const DateColum = [
    {
      Header: "SNO",
      accessor: "sNo",
      maxWidth: 70,
    },
    {
      Header: "Default",
      id: "Default",
      accessor: (rowInfo) => (
        <div>
          <input
            type="checkbox"
            id="CheckBox"
            readOnly
            checked={rowInfo.default}
            className="form-check-input"
          />
        </div>
      ),
    },
    {
      Header: "AttributeValue",
      accessor: "attValue",
    },
    {
      Header: "Alias",
      accessor: "alias",
    },
    {
      Header: "ManufactureDate",
      accessor: "manufactureDate",
    },
    {
      Header: "ExpiryDate",
      accessor: "expiryDate",
    },
    {
      Header: "Action",
      id: "DeleteId",
      maxWidth: 100,
      accessor: (rowinfo) => (
        <div>
          <i
            onClick={() => RemoveRow(rowinfo)}
            className="far fa-trash-alt"
          ></i>
        </div>
      ),
    },
  ];
  const TabIndex = (e) => {
    var maxTab = 17;
    var ActiveIndex = document.activeElement.tabIndex;
    let AttList = iValueType === 1 ? ListAddAtt : ListAddDate;
    var ProductListTab = iValueType === 1 ? 10 : 12;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ProductListTab && AttList.length > 0) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ProductListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ProductListTab && AttList.length > 0) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ProductListTab && AttList.length > 0) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ProductListTab && AttList.length > 0) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ProductListTab && AttList.length > 0) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ProductListTab && AttList.length > 0) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  const MsgBox = (Header, Message, Icon) => {
    setIsNotification(!IsNotification);
    setstrMessage(Message);
    setstrIcon(Icon);
  };
  return (
    <div>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9 col-sm-12 ">
              <div className="fg-card  p-2">
                <div className="container p-2" id="ClearInput">
                  <div className="row">
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>
                        Code<span className="danger">*</span>
                      </label>
                      <br />
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={strAttCode}
                        onChange={(e) => setstrAttCode(e.target.value)}
                        tabIndex={0}
                        onKeyDown={(e) => TabIndex(e)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>
                        Name <span className="danger">*</span>
                      </label>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        placeholder="Enter Attribute Name"
                        onClick={(e) => e.target.select()}
                        value={strAttName}
                        onChange={(e) => setstrAttName(e.target.value)}
                        autoFocus
                        tabIndex={1}
                        onKeyDown={(e) => TabIndex(e)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>Remark</label>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        onClick={(e) => e.target.select()}
                        placeholder="Enter Remarks"
                        value={strRemarks}
                        onChange={(e) => setstrRemarks(e.target.value)}
                        tabIndex={2}
                        onKeyDown={(e) => TabIndex(e)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 mb-2">
                      <label>Description</label>
                      <input
                        type="text"
                        onClick={(e) => e.target.select()}
                        aria-label="First name"
                        className="form-control"
                        value={strDescription}
                        placeholder="Enter Description"
                        onChange={(e) => setstrDescription(e.target.value)}
                        tabIndex={3}
                        onKeyDown={(e) => TabIndex(e)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 mb-2">
                      <label>
                        Value Type<span className="danger">*</span>
                      </label>
                      <br />
                      <div className="btn-group">
                        <select
                          className="select"
                          value={iValueType}
                          onChange={(e) =>
                            setiValueType(Number(e.target.value))
                          }
                          tabIndex={4}
                          onKeyDown={(e) => TabIndex(e)}
                        >
                          {ListValueType.map((ValueType, Index) => (
                            <option
                              key={Index}
                              value={ValueType.iAttValueTypeId}
                            >
                              {ValueType.strAttValueTypeName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 mb-2">
                      <label>
                        Dimension<span className="danger">*</span>
                      </label>
                      <br />
                      <div className="btn-group">
                        <select
                          className="select"
                          tabIndex={5}
                          onKeyDown={(e) => TabIndex(e)}
                          value={iDimensionId}
                          onChange={(e) => setiDimensionId(e.target.value)}
                        >
                          {ListDimension.map((Dimension, Index) => (
                            <option key={Index} value={Dimension.iAttDimId}>
                              {Dimension.strAttDimName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {iValueType === 1 && (
                      <div className="col-md-12 col-sm-12 mb-2">
                        <div className="row">
                          <div className="col-md-4 col-sm-12 mb-2">
                            <label>Attribute Value</label>
                            <input
                              type="text"
                              aria-label="First name"
                              className="form-control"
                              placeholder="Enter Attribute Value"
                              onClick={(e) => e.target.select()}
                              value={strAttValue}
                              tabIndex={6}
                              onKeyDown={(e) => TabIndex(e)}
                              onChange={(e) => setstrAttValue(e.target.value)}
                            />
                          </div>
                          <div className="col-md-4 col-sm-12 mb-2">
                            <label>Alias Name</label>
                            <input
                              type="text"
                              aria-label="First name"
                              placeholder="Enter Attribute Alias Name"
                              className="form-control"
                              tabIndex={7}
                              onClick={(e) => e.target.select()}
                              onKeyDown={(e) => TabIndex(e)}
                              value={strAlias}
                              onChange={(e) => setstrAlias(e.target.value)}
                            />
                          </div>
                          <div className="col-md-2 col-sm-12 mb-2">
                            <label>Defalut</label>
                            <br />
                            <div className="form-check">
                              <input
                                className="form-check-input mlt-2"
                                type="checkbox"
                                tabIndex={8}
                                onKeyDown={(e) => TabIndex(e)}
                                checked={bIsDefault}
                                onChange={(e) => setbIsDefault(!bIsDefault)}
                              />
                            </div>
                          </div>
                          <div className="col-md-1 col-sm-12 mb-2">
                            <br />
                            <button
                              type="button"
                              onClick={AddAtt}
                              className="btn btn-outline"
                              tabIndex={9}
                              onKeyDown={(e) => TabIndex(e)}
                            >
                              Add
                            </button>
                          </div>
                          <div className="col-md-12 col-sm-12 mt-2">
                            <ReactTable
                              columns={TextBoxColumn}
                              data={ListAddAtt}
                              minRows={5}
                              row_click={(rowInfo, Index) => {
                                setselected(Index);
                              }}
                              row_doubleclick={(rowInfo) => {
                                AssignAttValue(rowInfo);
                              }}
                              background={true}
                              className="full-table"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {iValueType !== 1 && (
                      <div className="col-md-12 col-sm-12 mb-2">
                        <div className="row">
                          <div className="col-md-4 col-sm-12 mb-2">
                            <label>Batch No</label>
                            <input
                              type="text"
                              aria-label="First name"
                              className="form-control"
                              placeholder="Enter Batch Number"
                              onClick={(e) => e.target.select()}
                              value={BatchNo}
                              onChange={(e) => setBatchNo(e.target.value)}
                              tabIndex={6}
                              onKeyDown={(e) => TabIndex(e)}
                            />
                          </div>
                          <div className="col-md-4 col-sm-12 mb-2">
                            <label>Alias Name</label>
                            <input
                              type="text"
                              aria-label="First name"
                              className="form-control"
                              placeholder="Enter Alias Name"
                              onClick={(e) => e.target.select()}
                              value={strDateAlias}
                              onChange={(e) => setstrDateAlias(e.target.value)}
                              tabIndex={7}
                              onKeyDown={(e) => TabIndex(e)}
                            />
                          </div>
                          <div className="col-md-4 col-sm-12 mb-2">
                            <label>Manufacturing Date</label>
                            <input
                              type="date"
                              aria-label="First name"
                              className="form-control"
                              value={ManuFacturingDate}
                              onChange={(e) =>
                                setManuFacturingDate(e.target.value)
                              }
                              tabIndex={8}
                              onKeyDown={(e) => TabIndex(e)}
                            />
                          </div>
                          <div className="col-md-4 col-sm-12 mb-2">
                            <label>Expiry Date</label>
                            <input
                              type="date"
                              aria-label="First name"
                              className="form-control"
                              value={ExpiryDate}
                              onChange={(e) => setExpiryDate(e.target.value)}
                              tabIndex={9}
                              onKeyDown={(e) => TabIndex(e)}
                            />
                          </div>
                          <div className="col-md-2 col-sm-12 mb-2">
                            <label>Defalut</label>
                            <br />
                            <div className="form-check">
                              <input
                                className="form-check-input mlt-2"
                                type="checkbox"
                                checked={bIsDateDefault}
                                onChange={(e) =>
                                  setbIsDateDefault(!bIsDateDefault)
                                }
                                tabIndex={10}
                                onKeyDown={(e) => TabIndex(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-1 col-sm-12 mb-2">
                            <br />
                            <button
                              type="button"
                              onClick={AddDate}
                              className="btn btn-outline mt-3"
                              tabIndex={11}
                              onKeyDown={(e) => TabIndex(e)}
                            >
                              Add
                            </button>
                          </div>
                          <div className="col-md-12 col-sm-12 mt-3">
                            <ReactTable
                              columns={DateColum}
                              data={ListAddDate}
                              minRows={5}
                              row_click={(rowInfo, Index) => {
                                setselected(Index);
                              }}
                              row_doubleclick={(rowInfo) => {
                                AssignAttValue(rowInfo);
                              }}
                              background={true}
                              tabIndex={12}
                              className="full-table"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <Button
                  Save={(e) => {
                    if (strAttCode === "") {
                      MsgBox(
                        "Validation !",
                        "Please Enter Attribute Code",
                        "info"
                      );
                    } else if (strAttName === "") {
                      MsgBox(
                        "Validation !",
                        "Please Enter Attribute Name",
                        "info"
                      );
                    } else {
                      if (iAttId > 0) {
                        setIsDialogFor("Save");
                        setstrQuestion("Do You Want to Update ?");
                        setIsDialogOpen(!IsDialogOpen);
                      } else {
                        setIsDialogFor("Save");
                        setstrQuestion("Do You Want to Save ?");
                        setIsDialogOpen(!IsDialogOpen);
                      }
                    }
                  }}
                  Clear={ClearClick}
                  Delete={(e) => {
                    if (iAttId === 0) {
                      MsgBox("Validation", "Please Select Attribute", "info");
                    } else {
                      setIsDialogFor("Delete");
                      setstrQuestion("Do You Want to Delete ?");
                      setIsDialogOpen(!IsDialogOpen);
                    }
                  }}
                  ScrId={iScrId}
                  SaveIndex={11}
                  DeleteIndex={12}
                  CloseIndex={14}
                  ClearIndex={13}
                  ExportIndex={""}
                  onKeyDown={(e) => TabIndex(e)}
                />
              </div>
            </div>
            <MasterList
              ComBoxValue={ListFilter}
              EntList={ListAttList}
              GetDetails={GetAttribute}
              Delete={DeleteIconClick}
              Count={iAttCount}
              Filter={Filter}
              Search={Search}
              ScreenID={iScrId}
              accessor="strAttName"
              accessorid="iAttId"
              ListTab={17}
              FilterTab={15}
              SearchTab={16}
              onKeyDown={(e) => TabIndex(e)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Attribute;
