import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import {
  refresh_AccDeCrParty,
  setAccDeCr_party,
  setDebCrdDeafult,
  setDebCrdVenType,
} from "../features/Accounts";
import { useEffect } from "react";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";

function DebitCreditBody(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _AccParem = useSelector((state) => state.Accounts.DebitCredit);
  const _TabParem = useSelector((state) => state.Accounts.DebCrdTab);
  const _HTParem = useSelector((state) => state.Accounts.DebCrdHT);
  const _Selectparem = useSelector((state) => state.SelectOption.parems);
  const [arrVendore, setarrVendore] = useState([]);
  useEffect(() => {
    if (arrVendore.length === 0)
      View_AllVendores(_AccParem.iScrId === 44 ? 1 : 4, "");
  }, [props]);

  const View_AllVendores = async (VenType, strtext) => {
    let strcolname = "";
    const _LocalParems = await DecryptData(localStorage.jbctrlparams);

    let ScrId = 0;
    switch (VenType) {
      case 4:
        ScrId = 2;
        strcolname = "CustName";
        break;
      case 5:
        ScrId = 50;
        strcolname = "VenName";
        break;
      default:
        ScrId = 3;
        strcolname = "SuppName";
        break;
    }
    const parems = {
      iScrId: ScrId,
      iPartyId: VenType,
      strColName: strcolname,
      strSearch: strtext,
      strDBName: _LocalParems.DB,
      iBranchId: Number(_LocalParems.Branch),
    };
    const res = await API_POST(`PartyTrans/Search_Party`, parems, dispatch);
    if (res) {
      setarrVendore(res.EntParty);
    }
  };
  const View_Party = async (iVenId) => {
    const res = await API_FETCH(
      `${props.PartyUrl}${_AccParem.iVenTypeId}/${iVenId}/${Number(
        _AuthParems.Branch
      )}/${_AuthParems.DB}`
    );
    if (res) {
      dispatch(
        setAccDeCr_party({
          strVenName: res.objEntParty[0],
          iVenId: Number(iVenId),
        })
      );
    }
  };
  const Input_OnChange = async (val, type) => {
    await dispatch(
      setDebCrdDeafult({
        iScrId: _AccParem.iScrId,
        iBranchId: _AccParem.iBranchId,
        iDebCrdId: _AccParem.iDebCrdId,
        strTransNo: _AccParem.strTransNo,
        strVouchNo: type === "VouchNo" ? val : _AccParem.strVouchNo,
        strVouchDate: type === "VouchDate" ? val : _AccParem.strVouchDate,
        iVenTypeId: type === "VenType" ? val : _AccParem.iVenTypeId,
        iVenId: _AccParem.iVenId,
        strVenName: _AccParem.strVenName,
        strRemark: type === "Remark" ? val : _AccParem.strRemark,
        dAmount: type === "Amount" ? val : _AccParem.dAmount,
        isCancel: _AccParem.isCancel,
      })
    );
  };
  return (
    <div className="container p-2">
      <div className="row">
        <div className="col-md-3 col-sm-6 mt-1">
          <label>
            Vocher no <span className="danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Voucher Number"
            defaultValue={_AccParem.strVouchNo}
            tabIndex={_TabParem.VoucherTab}
            onKeyDown={(e) => props.TabIndex(e)}
            readOnly
          />
        </div>
        <div className="col-md-3 col-sm-6 mt-1">
          <label>
            Trans No <span className="danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            tabIndex={_TabParem.TransNoTab}
            onKeyDown={(e) => props.TabIndex(e)}
            readOnly
            defaultValue={_AccParem.strTransNo}
          />
        </div>
        <div className="col-md-3 col-sm-6 mt-1">
          <label>
            Date <span className="danger">*</span>
          </label>
          <input
            type="date"
            name="strVouchDate"
            tabIndex={_TabParem.VouchDateTap}
            onKeyDown={(e) => props.TabIndex(e)}
            className="form-control"
            value={_AccParem.strVouchDate}
            onChange={(e) => Input_OnChange(e.target.value, "VouchDate")}
          />
        </div>
        <div className="col-md-3 col-sm-6 mt-1">
          <label>
            Vendor Type <span className="danger">*</span>
          </label>
          <CustomizedSelectoption
            optionparem="isoptionShow"
            showoption={_Selectparem.isoptionShow}
            placeholder="Select Vendore Type"
            taborder={_TabParem.VenTypeTab}
            keydown={(e) => props.KeyDown(e)}
            select_value={(val) => {
              dispatch(refresh_AccDeCrParty());
              dispatch(setDebCrdVenType(val));
              View_AllVendores(val, "");
            }}
            btnname="Vendor"
            btnshow={false}
            show_popup=""
            valueonly={true}
            defaultval={_AccParem.iVenTypeId}
            displayName="strVenTypeName"
            disvalue="iVenTypeId"
            arrydata={_HTParem.arrVenType}
            EmptVal="Vendor Type "
          />
        </div>
        <div className="col-md-6 col-sm-12 mt-1">
          <label>
            Vendor Name <span className="danger">*</span>
          </label>
          <CustomizedSelectoption
            optionparem="isoptionShow1"
            showoption={_Selectparem.isoptionShow1}
            placeholder="Select Vendore"
            taborder={_TabParem.VenNameTab}
            keydown={(e) => props.KeyDown(e)}
            onChange={(val) => {
              if (val.length > 0) View_AllVendores(_AccParem.iVenTypeId, val);
            }}
            select_value={(val) => View_Party(val)}
            btnname="Vendor"
            btnshow={false}
            show_popup=""
            valueonly={true}
            defaultval={_AccParem.iVenId}
            displayName="partyName"
            disvalue="partyId"
            arrydata={arrVendore}
            EmptVal="Vendor "
          />
        </div>
        <div className="col-md-3 col-sm-6 mt-1">
          <label>
            Amount<span className="danger">*</span>
          </label>
          <input
            type="number"
            className="form-control"
            tabIndex={_TabParem.AmountTab}
            onKeyDown={(e) => props.KeyDown(e)}
            style={{ textAlign: "right" }}
            value={_AccParem.dAmount}
            onClick={(e) => e.target.select()}
            onChange={(e) => Input_OnChange(e.target.value, "Amount")}
          />
        </div>
        <div className="col-md-4 col-sm-6 mt-1">
          <label>
            Remarks <span className="danger">*</span>
          </label>
          <textarea
            style={{ height: "100px" }}
            className="form-control rounded"
            placeholder="Enter Remarks"
            tabIndex={_TabParem.Remarktab}
            onKeyDown={(e) => props.TabIndex(e)}
            onClick={(e) => e.target.select()}
            value={_AccParem.strRemark}
            onChange={(e) => Input_OnChange(e.target.value, "Remark")}
          ></textarea>
        </div>
      </div>
    </div>
  );
}

export default DebitCreditBody;
