import React from "react";
import { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { Show_Message } from "../General/Messagedialogbox";
import { API_POST, DecryptData } from "../General/Utility";
import ReportTable from "./ReportTable";
function AccountsReport() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [iRptId, setiRptId] = useState(0);
  const [iTotalRows, setiTotalRows] = useState(0);
  const [RowPerPage, setRowPerPage] = useState(0);
  const [CurrPage, setCurrPage] = useState(1);
  const [strFromDate, setstrFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [strLedger, setstrLedger] = useState("");
  const [strToDate, setstrToDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [strReportName, setstrReportName] = useState("Accounts Reports");
  const [strRptSearch, setstrRptSearch] = useState("");
  const [strFilterColName, setstrFilterColName] = useState("");

  const [IsLedgOption, setIsLedgOption] = useState(false);

  const [arrLedger, setarrLedger] = useState([]);
  const [arrLedgerOption, setarrLedgerOption] = useState([]);
  const [arrReports, setarrReports] = useState([]);
  const [arrReportDesing, setarrReportDesing] = useState([]);
  const [arrReportData, setarrReportData] = useState([]);
  const [arrSummarydata, setarrSummarydata] = useState([]);

  const [iBranchId, setiBranchId] = useState(0);
  const [iWHId, setiWHId] = useState(0);
  const [arrBranch, setarrBranch] = useState([]);
  const [arrWH, setarrWH] = useState([]);
  const [IsWHdisable, setIsWHdisable] = useState(true);

  useEffect(() => {
    Load_Reports("");
  }, []);
  const Load_Reports = async (strtxt) => {
    try {
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);
      setstrRptSearch(strtxt);
      const parems = {
        iScrId: 0,
        iPartyId: 0,
        strColName: "AccountsReport",
        strSearch: strtxt,
        strDBName: _LocalParems.DB,
        iUserId: Number(_LocalParems.User),
        iClientId: Number(_LocalParems.Client),
        iRoleId: Number(_LocalParems.Role),
        iBranchId: Number(_LocalParems.Branch),
      };
      const res = await API_POST(`Reports/Load_Reports/`, parems, dispatch);
      if (res) {
        setiBranchId(Number(_LocalParems.Branch));
        await setarrReports(res.objEntRpt);
        setarrBranch(res.EntBranch);
        setarrWH(res.EntWareHouse);
      }
    } catch (e) {
      await Show_Message(dispatch, e.message, "error");
    }
  };
  const View_Report = async (
    iId,
    strName,
    Branch,
    WH,
    LedgerName,
    Fromdate,
    ToDate
  ) => {
    try {
      setstrReportName(strName);
      setiRptId(Number(iId));
      const parems = {
        dpFrom: Fromdate,
        dpTo: ToDate,
        iRptId: Number(iId),
        iPartyId: 0,
        strColName: "",
        strLedger: LedgerName,
        strSearch: "",
        strDBName: _AuthParems.DB,
        iUserId: Number(_AuthParems.User),
        CurrtPage: 1,
        iBranchId: Number(Branch),
        iWHId: WH,
      };
      const res = await API_POST(
        `Reports/Load_AccountsReport/`,
        parems,
        dispatch
      );
      if (res) {
        let RptDesign = await SortArray(res.EntDesign);
        let RptData = JsontoArray(res.EntReport);
        setarrReportDesing(RptDesign);
        setarrReportData(RptData);
        setstrFromDate(res.strFromDate);
        setstrFilterColName(res.strSearchCol);
        setiTotalRows(res.TotalRows);
        setRowPerPage(res.RowPerPage);
        await validatereportsummary(res.EntSummary);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const View_PageReport = async (Page) => {
    try {
      setCurrPage(Page);
      const parems = {
        iRptId: Number(iRptId),
        dpFrom: strFromDate,
        dpTo: strToDate,
        strLedger: strLedger,
        strColName: strFilterColName,
        strSearch: "",
        strDBName: _AuthParems.DB,
        iUserId: Number(_AuthParems.User),
        CurrtPage: Page,
      };
      const res = await API_POST(`Reports/Load_AccountsReport/`, parems);
      if (res) {
        setarrReportData(JsontoArray(res.EntReport));
        await validatereportsummary(res.EntSummary);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const Clear_Report = () => {
    setstrLedger("");
    setstrFilterColName("");
    setIsLedgOption(false);
    setstrFromDate(new Date().toISOString().split("T")[0]);
    setstrToDate(new Date().toISOString().split("T")[0]);
    setarrLedger([]);
    setarrLedgerOption([]);
    setarrReportData([]);
  };
  const Search_Report = async (strtxt, strFilterCol) => {
    try {
      const parems = {
        iRptId: Number(iRptId),
        iBranchId: Number(_AuthParems.Branch),
        dpFrom: strFromDate,
        dpTo: strToDate,
        strLedger: strLedger,
        strColName: strFilterCol,
        strSearch: strtxt,
        strDBName: _AuthParems.DB,
        iUserId: Number(_AuthParems.User),
        CurrtPage: 1,
      };
      const res = await API_POST(
        `Reports/Load_AccountsReport/`,
        parems,
        dispatch
      );
      if (res) {
        setarrReportData(JsontoArray(res.EntReport));
        await validatereportsummary(res.EntSummary);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const JsontoArray = (DataArray) => {
    return DataArray !== null ? JSON.parse(DataArray) : [];
  };
  const validatereportsummary = (arrSumm) => {
    if (arrSumm === undefined) setarrSummarydata([]);
    else setarrSummarydata(JSON.parse(arrSumm));
  };
  const Load_Ledger = async () => {
    const objSearch = {
      iScrId: 0,
      iPartyId: 0,
      strColName: "Ledger",
      strSearch: strRptSearch,
      strDBName: _AuthParems.DB,
      iUserId: Number(_AuthParems.User),
      iBranchId: Number(_AuthParems.Branch),
    };
    const res = await API_POST("Reports/Load_Reports/", objSearch, dispatch);
    if (res) {
      setarrLedger(res.objEntRpt);
      setarrLedgerOption(res.objEntRpt);
    }
  };
  const SortArray = (EntArr) => {
    let RptDesign = EntArr.sort((a, b) => a.inOrderTo - b.inOrderTo);
    RptDesign = RptDesign.filter((rdt) => rdt.isShowInReport);
    return RptDesign;
  };
  const Branch_Onchange = async (branch) => {
    try {
      setiBranchId(Number(branch.iBranchId));
      setarrReportData([]);
      setiTotalRows(0);
      setRowPerPage(0);
      setarrSummarydata([]);
      setiWHId(0);
      if (iRptId === 49 || iRptId === 50) {
        const parems = {
          iUserId: Number(_AuthParems.User),
          iRoleId: Number(_AuthParems.Role),
          iClientId: Number(_AuthParems.Client),
          iBranchId: Number(branch.iBranchId),
          strDBName: _AuthParems.DB,
        };
        const res = await API_POST(
          `WarehouseMaster/Load_Warehouse/`,
          parems,
          dispatch
        );
        if (res) {
          setarrWH(res.EntWarehouse);
        }
      } else {
        View_Report(
          iRptId,
          strReportName,
          branch.iBranchId,
          0,
          strLedger,
          strFromDate,
          strToDate
        );
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  return (
    <>
      <SideMenu ScrId={87} />
      <ReportTable
        ReportData={arrReportData}
        ReportDesing={arrReportDesing}
        EntReports={arrReports}
        EntLedger={arrLedger}
        EntLedgerOption={arrLedgerOption}
        Party_OptionShow={(e) => {}}
        View_Report={View_Report}
        Load_Reports={Load_Reports}
        Load_Ledger={Load_Ledger}
        Search_Report={Search_Report}
        EntSum={arrSummarydata}
        iRptId={iRptId}
        strReportName={strReportName}
        FilterColName={strFilterColName}
        setstrFilterColName={(e) => setstrFilterColName(e)}
        IsAccounts={true}
        strFromDate={strFromDate}
        strToDate={strToDate}
        strLedger={strLedger}
        IsLedOption={IsLedgOption}
        setIsLedOption={(e) => setIsLedgOption(e)}
        setarrLedgerOption={(e) => setarrLedgerOption(e)}
        setstrLedger={(e) => setstrLedger(e)}
        Clear_Report={Clear_Report}
        TotalRows={iTotalRows}
        RowPerPage={RowPerPage}
        View_PageReport={View_PageReport}
        CurrPage={CurrPage}
        arrWH={arrWH}
        arrBranch={arrBranch}
        iBranchId={iBranchId}
        iWHId={iWHId}
        Branch_Onchange={Branch_Onchange}
        setiWHId={(WHId) => {
          setiWHId(WHId);
          View_Report(
            iRptId,
            strReportName,
            iBranchId,
            WHId,
            strLedger,
            strFromDate,
            strToDate
          );
        }}
        IsWHdisable={IsWHdisable}
      />
    </>
  );
}

export default AccountsReport;
