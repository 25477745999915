import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShowHide_Popup, setParty } from "../features/Transactionstates";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import { API_POST } from "../General/Utility";
import PopupSearch from "./PopupSearch";

function PartySearch(props) {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.Transstates.Common);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [strColName, setstrColName] = useState("All");
  const [strSearch, setstrSearch] = useState("");
  const [arrParty, setarrParty] = useState([]);
  const [arrFilter, setarrFilter] = useState([]);
  const [arrEntTableCol, setarrEntTableCol] = useState([]);

  useEffect(() => {
    Load_Party("", props.Close ? props.ColumnName : _Common.ColName);
  }, []);
  const Load_Party = async (strtxt, ColName) => {
    try {
      await Pre_Loading(dispatch, true);
      setstrSearch(strtxt);
      setstrColName(ColName);
      const parems = {
        iScrId: props.Close ? props.ScrId : _Common.PartyScrId,
        iVenType: props.Close ? props.VenTypeId : _Common.VenTypeId,
        strColName: ColName,
        strSearch: strtxt,
        strDBName: _AuthParems.DB,
        iBranchId: Number(_AuthParems.Branch),
      };
      const res = await API_POST(`PartyTrans/Load_Party`, parems, dispatch);
      if (res) {
        setarrFilter(res.EntFilter);
        setarrEntTableCol(res.EntTablDesign);
        setarrParty(res.EntParty);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const View_Party = async (PartyId) => {
    try {
      if (props.Close) {
        props.GetData(PartyId);
      } else {
        const objTrans = {
          ClientId: Number(_AuthParems.Client),
          UserId: Number(_AuthParems.User),
          ScrId: _Common.iScrId,
          ScrType: _Common.strScrType,
          PartyScrId: _Common.PartyScrId,
          PartyId: PartyId,
          VenType: _Common.VenTypeId,
          strSearch: "",
          ColName: "",
          CompanyDB: _AuthParems.DB,
          BranchId: Number(_AuthParems.Branch),
        };
        const res = await API_POST(`PartyTrans/View_Party`, objTrans, dispatch);
        if (res) {
          dispatch(setParty(res));
          await Popup_Close();
          if (_Common.iScrId === 12 || _Common.iScrId === 18)
            await dispatch(ShowHide_Popup("IsReturnPro"));
          else if (
            _Common.iScrId === 72 ||
            _Common.iScrId === 73 ||
            _Common.iScrId === 75 ||
            _Common.iScrId === 76
          )
            await dispatch(ShowHide_Popup("IsPendinCon"));
        }
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const Popup_Close = async () => {
    if (props.Close) props.Close();
    else await dispatch(ShowHide_Popup("IsVenSearch"));
  };

  return (
    <PopupSearch
      ScrName="Party Master"
      FilterCol={arrFilter}
      FilterVal={strColName}
      FilterShow={true}
      strSearch={strSearch}
      Columns={arrEntTableCol}
      Data={arrParty}
      UpdateFilterCol={(e) => setstrColName(e)}
      Search_Data={(e) => Load_Party(e)}
      View_Data={(e) => View_Party(e.partyId)}
      Close={() => Popup_Close()}
      IsParty={true}
    />
  );
}
export default PartySearch;
