import React, { useEffect, useState } from "react";
import $ from "jquery";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { ShowHide_Popup } from "../features/Transactionstates";
import { Table_Style } from "./Transaction";

function PopupSearch(props) {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _Common = useSelector((store) => store.Transstates.Common);
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const [ShowParty, setShowParty] = useState(false);
  const [ShowProduct, setShowProduct] = useState(false);
  const [ShowJobwork, setShowJobwork] = useState(false);
  const [ShowService, setShowService] = useState(false);

  useEffect(() => {
    View_BtnPerission();
  });
  const View_BtnPerission = async () => {
    const Menu = _AuthParems.MasterPer;
    if (Menu.length > 0) {
      if (props.IsParty) {
        let PartyShow = 0;
        switch (_Common.strScrType) {
          case "Sales":
            PartyShow = await Filte_Screen(Menu, 2);
            break;
          case "Purchase":
            PartyShow = await Filte_Screen(Menu, 3);
            break;
          case "JobWork":
            PartyShow = await Filte_Screen(Menu, 50);
            break;
          case "ExpenseIncome":
            PartyShow = await Filte_Screen(Menu, 3);
            break;
          default:
            break;
        }
        setShowParty(PartyShow);
      } else if (props.IsProduct) {
        setShowProduct(await Filte_Screen(Menu, 1));
      } else if (props.IsJobWork) {
        setShowJobwork(await Filte_Screen(Menu, 50));
      } else if (props.IsService) {
        setShowService(await Filte_Screen(Menu, 59));
      }
    }
  };
  const Filte_Screen = (Menu, iScrId) => {
    return Menu.filter((dt) => dt.iScrId === iScrId)[0].isCreate;
  };
  return (
    <div className="popup-container">
      <div className="popup-content medium">
        <div className="popup-header">
          <h5>{props.ScrName}</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div
            className={
              props.FilterShow
                ? "search-card "
                : props.IsWareHouse
                ? "search-card "
                : "search-card full"
            }
          >
            <div className="m-hsearch">
              <input
                type="text"
                autoCorrect="off"
                autoComplete="off"
                spellCheck={false}
                placeholder="Search Product"
                tabIndex={52}
                id="SearchProduct"
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown")
                    $(".option-list [tabindex=0]").focus();
                  else if (e.key === "Escape") props.Close();
                }}
                value={props.strSearch}
                onChange={(e) => props.Search_Data(e.target.value)}
                autoFocus
              />
              <i
                className="fas fa-search"
                onClick={(e) => props.Search_Data(props.strSearch)}
              ></i>
            </div>
            {props.FilterShow && (
              <CustomizedSelectoption
                optionparem="isoptionShow7"
                showoption={_selecrparem.isoptionShow7}
                placeholder="Filter Party"
                select_value={(val) => props.UpdateFilterCol(val)}
                btnname="Product"
                btnshow={false}
                show_popup=""
                valueonly={true}
                defaultval={props.FilterVal}
                displayName="strDisplayName"
                disvalue="strValueName"
                arrydata={props.FilterCol}
                disabled={false}
                EmptVal="Product"
              />
            )}
            {props.IsWareHouse && (
              <CustomizedSelectoption
                optionparem="isoptionShow8"
                showoption={_selecrparem.isoptionShow8}
                placeholder="Select Warehouse"
                select_value={(val) => props.Wharehouse_Onchange(val)}
                btnname="Product"
                btnshow={false}
                show_popup=""
                valueonly={true}
                defaultval={props.WHId}
                displayName="strWHName"
                disvalue="iWHId"
                arrydata={props.Warehouse}
                disabled={props.disableWH}
                EmptVal="Warehouse"
              />
            )}
          </div>
          <div className="table-card">
            <table className="option-list">
              <thead>
                <tr>
                  {props.Columns.map(
                    (ht, Index) =>
                      ht.isShow && <th key={Index}>{ht.strAliasName}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {props.Data.map((Item, Index) => (
                  <tr
                    key={Index}
                    tabIndex={Index}
                    onKeyDown={(e) => {
                      const arrlength = props.Data.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.option-list [tabindex=${0}]`).focus();
                        else $(`.option-list [tabindex=${Index + 1}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.option-list [tabindex=${arrlength}]`).focus();
                        else $(`.option-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "Enter") props.View_Data(Item);
                    }}
                    onDoubleClick={(e) => props.View_Data(Item)}
                  >
                    {props.Columns.map(
                      (dt, Index) =>
                        dt.isShow && (
                          <td key={Index} style={Table_Style(dt, Item)}>
                            {dt.strDataType === "Decimal"
                              ? Number(Item[dt.strColName]).toFixed(2)
                              : Item[dt.strColName]}
                          </td>
                        )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            {ShowParty && (
              <button
                className="btn-fabgreen"
                onClick={(e) =>
                  dispatch(
                    ShowHide_Popup({
                      isshow: "IsVenCreation",
                      Scrtype: _Common.strScrType,
                    })
                  )
                }
              >
                Create Party&nbsp;<i className="bx bx-user-plus"></i>
              </button>
            )}
            {ShowProduct && (
              <button
                className="btn-fabgreen"
                onClick={(e) => dispatch(ShowHide_Popup("IsNewPro"))}
              >
                Create Product&nbsp; <i className="bx bx-cube-alt"></i>
              </button>
            )}
            {ShowJobwork && (
              <button
                className="btn-fabgreen"
                onClick={async (e) => {
                  dispatch(
                    ShowHide_Popup({
                      isshow: "IsVenCreation",
                      Scrtype: "Job",
                    })
                  );
                }}
              >
                Create Job Work&nbsp;<i className="fas fa-tools"></i>
              </button>
            )}{" "}
            {ShowService && (
              <button
                className="btn-fabgreen"
                onClick={async (e) => {
                  dispatch(
                    ShowHide_Popup({
                      isshow: "IsVenCreation",
                      Scrtype: "Service",
                    })
                  );
                }}
              >
                Create Service&nbsp;<i className="fas fa-tools"></i>
              </button>
            )}
            {props.IsProduct && (
              <button
                className="btn-fabgreen"
                onClick={(e) => dispatch(ShowHide_Popup("IsProSearch"))}
              >
                <i className="bx bx-arrow-back"></i>&nbsp;Back to Product
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default PopupSearch;
