import React, { useEffect, useState } from "react";

import SideMenu from "../General/SideMenu";
import ServiceRemark from "../Master/ServiceRemark";
import { useDispatch, useSelector } from "react-redux";
import { Ask_Question, Show_Message } from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import Messagedialogbox from "../General/Messagedialogbox";
import $ from "jquery";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import ReactTable from "../General/ReactTable";
function Forecast() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _SelectParem = useSelector((state) => state.SelectOption.parems);
  const [IsRemarkOpen, setIsRemarkOpen] = useState(false);
  const [objBOMList, setobjBOMList] = useState([]);
  const [objFCDTList, setobjFCDTList] = useState([]);
  const [objFCSearchList, setobjFCSearchList] = useState([]);
  const [strSearch, setstrSearch] = useState("");
  const iScrId = 83;
  const [iForecastId, setiForecastId] = useState(0);
  const [strForecastName, setstrForecastName] = useState("");
  const [iBomId, setiBomId] = useState(0);
  const [dProValue, setdProValue] = useState(0);
  const [dEstValue, setdEstValue] = useState(0);
  const [strRemark, setstrRemark] = useState("");
  const [strRemarkType, setstrRemarkType] = useState("");
  const [strServiceRemark1, setstrServiceRemark1] = useState("");
  const [strServiceRemark2, setstrServiceRemark2] = useState("");
  const [strServiceRemark3, setstrServiceRemark3] = useState("");
  const [strServiceRemark4, setstrServiceRemark4] = useState("");
  const [dProductionQty, setdProductionQty] = useState(0);
  const [dServiceCharge1, setdServiceCharge1] = useState(0);
  const [dServiceCharge2, setdServiceCharge2] = useState(0);
  const [dServiceCharge3, setdServiceCharge3] = useState(0);
  const [dServiceCharge4, setdServiceCharge4] = useState(0);
  const [strProductionQty, setstrProductionQty] = useState("0");
  useEffect(() => {
    Load_Forecast();
  }, []);
  const Load_Forecast = async () => {
    const _LocalParems = await DecryptData(localStorage.jbctrlparams);
    const res = await API_FETCH(
      "Forecast/Load_Forecast/" +
        Number(_LocalParems.Branch) +
        "/" +
        _LocalParems.DB,
      dispatch
    );
    if (res) {
      setobjBOMList(res.objEntBOM);
      setobjFCSearchList(res.objEntFC);
    }
  };
  const Select_BOM = async (BomId) => {
    setiBomId(BomId);
    const res = await API_FETCH(
      "Forecast/View_BOM/" + Number(BomId) + "/" + _AuthParems.DB,
      dispatch
    );
    if (res) {
      setobjFCDTList(res);
    }
  };
  const ForecastClick = async () => {
    if (iBomId === 0) {
      Show_Message(dispatch, "Please Select Bom List", "info");
    } else {
      const res = await API_POST(
        "Forecast/Forecast_Calculation/",
        {
          dProductionQty: dProductionQty,
          dServiceCharge1: dServiceCharge1,
          dServiceCharge2: dServiceCharge2,
          dServiceCharge3: dServiceCharge3,
          dServiceCharge4: dServiceCharge4,
          strServiceRemark1: strServiceRemark1,
          strServiceRemark2: strServiceRemark2,
          strServiceRemark3: strServiceRemark3,
          strServiceRemark4: strServiceRemark4,
          strBomName: "",
          strProductionQty: dProductionQty.toString(),
          strForecastName: strForecastName,
          iBomId: Number(iBomId),
          strDBName: _AuthParems.DB,
        },
        dispatch
      );
      if (res) {
        setdEstValue(res.EstValue);
        setobjFCDTList(res.objEntFCDT);
      }
    }
  };
  const SaveClick = async () => {
    const objFCHTList = {
      iScrId: iScrId,
      iSelectedForecastId: iForecastId,
      strForecastName: strForecastName,
      strProductionQty: strProductionQty.toString(),
      dProValue: Number(dProValue),
      dEstValue: Number(dEstValue),
      strServiceRemark1: strServiceRemark1,
      strServiceRemark2: strServiceRemark2,
      strServiceRemark3: strServiceRemark3,
      strServiceRemark4: strServiceRemark4,
      dProductionQty: Number(dProductionQty),
      dServiceCharge1: Number(dServiceCharge1),
      dServiceCharge2: Number(dServiceCharge2),
      dServiceCharge3: Number(dServiceCharge3),
      dServiceCharge4: Number(dServiceCharge4),
      strBomName: "",
      iBomId: Number(iBomId),
      iBranchId: Number(_AuthParems.Branch),
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST(
      "Forecast/InsUpd_Forcast/",
      {
        iForecastId: iForecastId,
        objFOModel: objFCHTList,
        objFODTModel: objFCDTList,
      },
      dispatch
    );
    if (res) {
      ClearClick();
      Show_Message(dispatch, res.strMessage, "success");
    }
  };
  const ClearClick = () => {
    setobjFCDTList([]);
    setobjFCSearchList([]);
    setiForecastId(0);
    setstrForecastName("");
    setiBomId(0);
    setdProValue(0);
    setdEstValue(0);
    setdProductionQty(0);
    setdServiceCharge1(0);
    setdServiceCharge2(0);
    setdServiceCharge3(0);
    setdServiceCharge4(0);
    setstrServiceRemark1("");
    setstrServiceRemark2("");
    setstrServiceRemark3("");
    setstrServiceRemark4("");
    setstrProductionQty("");
    Load_Forecast();
  };
  const DeleteClick = async () => {
    const res = await API_POST(
      "Forecast/Delete_Forecast/" + Number(iForecastId) + "/" + _AuthParems.DB,
      dispatch
    );
    if (res) {
      ClearClick();
      Show_Message(dispatch, res.strMessage, "success");
    }
  };
  const View_Forecast = async (objModel) => {
    const res = await API_FETCH(
      "Forecast/View_Forecast/" +
        objModel.iSelectedForecastId +
        "/" +
        _AuthParems.DB,
      dispatch
    );
    if (res) {
      setobjFCDTList(res.objEntForcast);
      setiForecastId(objModel.iSelectedForecastId);
      setstrForecastName(objModel.strForecastName);
      setiBomId(objModel.iBomId);
      setdProValue(objModel.dProValue);
      setdEstValue(objModel.dEstValue);
      setstrServiceRemark1(objModel.strServiceRemark1);
      setstrServiceRemark2(objModel.strServiceRemark2);
      setstrServiceRemark3(objModel.strServiceRemark3);
      setstrServiceRemark4(objModel.strServiceRemark4);
      setdProductionQty(objModel.dProductionQty);
      setdServiceCharge1(objModel.dServiceCharge1);
      setdServiceCharge2(objModel.dServiceCharge2);
      setdServiceCharge3(objModel.dServiceCharge3);
      setdServiceCharge4(objModel.dServiceCharge4);
      setstrProductionQty(
        objModel.strProductionQty === null ? "" : objModel.strProductionQty
      );
    }
  };
  const Search_Forecast = async (strtxt) => {
    setstrSearch(strtxt);
    const objSearch = {
      strSearch: strtxt,
      iBranchId: Number(_AuthParems.DB),
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST("Forecast/SearchForcast/", objSearch, dispatch);
    if (res) {
      setobjFCSearchList(res.EntForecast);
    }
  };
  const onServiceRemarkChange = (event) => {
    setstrRemark(event);
  };
  const ServiceRemarkPopup = (Value) => {
    setstrRemarkType(Value);
    switch (Value) {
      case "Remark1":
        setstrRemark(strServiceRemark1);
        break;
      case "Remark2":
        setstrRemark(strServiceRemark2);
        break;
      case "Remark3":
        setstrRemark(strServiceRemark3);
        break;
      case "Remark4":
        setstrRemark(strServiceRemark4);
        break;
      default:
        break;
    }
    setIsRemarkOpen(!IsRemarkOpen);
  };
  const ServiceRemarkPopupCLose = (RemarkType) => {
    switch (RemarkType) {
      case "Remark1":
        setstrServiceRemark1(strRemark);
        break;
      case "Remark2":
        setstrServiceRemark2(strRemark);
        break;
      case "Remark3":
        setstrServiceRemark3(strRemark);
        break;
      case "Remark4":
        setstrServiceRemark4(strRemark);
        break;
      default:
        break;
    }
    setIsRemarkOpen(!IsRemarkOpen);
  };
  const Column = [
    {
      Header: "SNo",
      accessor: "sNo",
      maxWidth: 50,
    },
    {
      Header: "ProId",
      accessor: "proId",
      show: false,
    },
    {
      Header: "Product Name",
      accessor: "proName",
      minWidth: 200,
    },
    {
      Header: "Unit",
      accessor: "unitName",
    },
    {
      Header: "Bom Qty",
      accessor: "bomQty",
      Cell: (props) => props.value.toFixed(2),
      minWidth: 150,
    },
    {
      Header: "Estimate Qty",
      accessor: "estQty",
      Cell: (props) => props.value.toFixed(2),
      minWidth: 150,
    },
    {
      Header: "Available Qty",
      accessor: "availQty",
      Cell: (props) => props.value.toFixed(2),
      minWidth: 150,
    },
    {
      Header: "Required Qty",
      accessor: "reqQty",
      Cell: (props) => props.value.toFixed(2),
      minWidth: 150,
    },
    {
      Header: "Rate",
      accessor: "proRate",
      Cell: (props) => props.value.toFixed(2),
      minWidth: 150,
    },
    {
      Header: "Total Rate",
      accessor: "proValue",
      Cell: (props) => props.value.toFixed(2),
      minWidth: 200,
    },
    {
      Header: "Print Name",
      accessor: "printName",
      minWidth: 150,
    },
    {
      Header: "Description",
      accessor: "description",
      minWidth: 150,
    },
  ];
  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <div className="Master-card">
          <div className="m-header p-2">
            <div className="m-hsearch">
              <input
                type="text"
                autoCorrect="off"
                autoComplete="off"
                placeholder="Search Forecast"
                value={strSearch}
                onChange={(e) => Search_Forecast(e.target.value)}
                autoFocus
              />
              <i
                className="fas fa-search"
                onClick={(e) => Search_Forecast(strSearch)}
              ></i>
            </div>
          </div>
          <div className="data-list">
            {objFCSearchList.map((dt, Index) => (
              <button
                key={Index}
                tabIndex={Index}
                onKeyDown={(e) => {
                  const arrlength = objFCSearchList.length - 1;
                  if (e.key === "ArrowDown") {
                    if (Index === arrlength)
                      $(`.data-list [tabindex=${Index + 1}]`).focus();
                    else $(`.data-list [tabindex=${0}]`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    if (Index === 0)
                      $(`.data-list [tabindex=${arrlength}]`).focus();
                    else $(`.data-list [tabindex=${Index - 1}]`).focus();
                  }
                  if (e.key === "Enter") View_Forecast(dt);
                }}
              >
                <label onClick={(e) => View_Forecast(dt)}>
                  {dt.strForecastName}
                </label>
              </button>
            ))}
          </div>
        </div>
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  Forecast Name <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  id="strForecastName"
                  placeholder="Enter Forcat Name"
                  name="strForecastName"
                  onClick={(e) => e.target.select()}
                  value={strForecastName}
                  onChange={(e) => setstrForecastName(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  Production Qty <span className="danger">*</span>
                </label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  name="dProductionQty"
                  id="dProductionQty"
                  value={dProductionQty}
                  onChange={(e) => setdProductionQty(Number(e.target.value))}
                  onClick={(e) => e.target.select()}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>BOM</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_SelectParem.isoptionShow}
                  placeholder="Select BOM"
                  select_value={(val) => Select_BOM(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iBomId}
                  displayName="strBOMName"
                  disvalue="iBOMId"
                  arrydata={objBOMList}
                  EmptVal="BOM"
                  disabled={false}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Service Charge 1</label>
                <br />
                <div className="d-inline-flex ">
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    name="dServiceCharge1"
                    id="dServiceCharge1"
                    value={dServiceCharge1}
                    onChange={(e) => setdServiceCharge1(Number(e.target.value))}
                    onClick={(e) => e.target.select()}
                  />
                  &nbsp;
                  <i
                    className="fa-solid fa-message mt-2"
                    style={{ cursor: "pointer", color: "darkgreen" }}
                    onClick={(e) => ServiceRemarkPopup("Remark1")}
                  ></i>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Service Charge 2</label>
                <div className="d-inline-flex ">
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    name="dServiceCharge2"
                    id="dServiceCharge2"
                    value={dServiceCharge2}
                    onChange={(e) => setdServiceCharge2(Number(e.target.value))}
                    onClick={(e) => e.target.select()}
                  />
                  &nbsp;
                  <i
                    className="fa-solid fa-message mt-2"
                    style={{ cursor: "pointer", color: "darkgreen" }}
                    onClick={(e) => ServiceRemarkPopup("Remark2")}
                  ></i>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Service Charge 3</label>
                <br />
                <div className="d-inline-flex ">
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    name="dServiceCharge3"
                    id="dServiceCharge3"
                    value={dServiceCharge3}
                    onChange={(e) => setdServiceCharge3(Number(e.target.value))}
                    onClick={(e) => e.target.select()}
                  />
                  &nbsp; &nbsp;
                  <i
                    className="fa-solid fa-message mt-2"
                    style={{ cursor: "pointer", color: "darkgreen" }}
                    onClick={(e) => ServiceRemarkPopup("Remark3")}
                  ></i>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Service Charge 4</label>
                <br />
                <div className="d-inline-flex ">
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    name="dServiceCharge4"
                    id="dServiceCharge4"
                    value={dServiceCharge4}
                    onChange={(e) => setdServiceCharge4(Number(e.target.value))}
                    onClick={(e) => e.target.select()}
                  />
                  &nbsp; &nbsp;
                  <i
                    className="fa-solid fa-message mt-2"
                    style={{ cursor: "pointer", color: "darkgreen" }}
                    onClick={(e) => ServiceRemarkPopup("Remark4")}
                  ></i>
                </div>
              </div>
            </div>
            <center className="p-2">
              <div
                className="net-amount"
                style={{ maxWidth: "30%", margin: "auto" }}
              >
                <h1> ESTIMATE AMOUNT</h1>
                <h2>
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "INR",
                  }).format(Number(dEstValue).toFixed(2))}
                </h2>
              </div>
            </center>
            <div className="TransactionTable p-2">
              <ReactTable
                data={objFCDTList}
                columns={Column}
                minRows={5}
                defaultPageSize={50}
                showPaginationBottom={false}
              />
            </div>
          </div>
          <div className="btn-section">
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) => {
                if (Number(iBomId) === 0) {
                  Show_Message(dispatch, "BOM is Required", "info");
                } else if (strForecastName === "") {
                  Show_Message(dispatch, "Forcast Name Rquired", "info");
                } else {
                  Ask_Question(
                    dispatch,
                    SaveClick,
                    "Do You Want to Save Forcast?"
                  );
                }
              }}
            >
              Save &nbsp; <i className="fas fa-save"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={ForecastClick}
            >
              Forecast &nbsp; <i className="fas fa-eye"></i>
            </button>
            <button type="button" className="btn-fabgreen" onClick={ClearClick}>
              Clear &nbsp; <i className="fas fa-eraser"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) => {
                Ask_Question(
                  dispatch,
                  DeleteClick,
                  "Do You Want to Delete Forcast?"
                );
              }}
            >
              Delete &nbsp; <i className="far fa-trash-alt"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={() => window.history.back()}
            >
              Close &nbsp; <i className="fas fa-times"></i>
            </button>
          </div>
          {IsRemarkOpen && (
            <ServiceRemark
              Close={ServiceRemarkPopupCLose}
              strRemark={strRemark}
              onChangeEvent={onServiceRemarkChange}
              strRemarkType={strRemarkType}
            />
          )}
          <Messagedialogbox />
        </div>
      </div>
    </>
  );
}

export default Forecast;
