import $ from "jquery";
import { Buffer } from "buffer";
function MasterList(props) {
  const Select_Row = (rowInfo) => {
    switch (props.ScrId) {
      case 1:
        props.View_Data(rowInfo.iProId, rowInfo.iAttGrpId);
        break;
      case 2:
        props.View_Data(rowInfo.iCustId);
        break;
      case 3:
        props.View_Data(rowInfo.iSuppId);
        break;
      case 4:
        props.View_Data(rowInfo.iCatId);
        break;
      case 5:
        props.View_Data(rowInfo.iLedId);
        break;
      case 6:
        props.View_Data(rowInfo.iTaxId);
        break;
      case 41:
        props.View_Data(rowInfo.iPayId);
        break;
      case 42:
        props.View_Data(rowInfo.iRecId);
        break;
      case 43:
        props.View_Data(rowInfo.iDebId);
        break;
      case 44:
        props.View_Data(rowInfo.iCreId);
        break;
      case 50:
        props.View_Data(rowInfo.iVenId);
        break;
      case 51:
        props.View_Data(rowInfo.iJobId);
        break;
      case 59:
        props.View_Data(rowInfo.iSerId);
        break;
      case 60:
        props.View_Data(rowInfo.iAttId, rowInfo.index);
        break;
      case 61:
        props.View_Data(rowInfo.iAttGrpId, rowInfo.index);
        break;
      case 68:
        props.View_Data(rowInfo.iBOMId);
        break;
      case 69:
        props.View_Data(rowInfo.iRGId);
        break;
      case 80:
        props.View_Data(rowInfo.iPGrpId);
        break;
      case 64:
        props.View_Data(rowInfo.iUnitId);
        break;
      case 29:
        props.View_Data(rowInfo.iBankAccId);
        break;
      default:
        props.View_Data(rowInfo);
        break;
    }
  };
  return (
    <div className="Master-card">
      <div className="m-header">
        <div className="m-hsearch">
          <input
            type="text"
            autoCorrect="off"
            autoComplete="off"
            placeholder={"Search " + props.placeholder + " Name"}
            onKeyDown={(e) => {
              if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                $(".data-list [tabindex=0]").focus();
              }
            }}
            value={props.strSearchtxt}
            tabIndex={props.tabOrder}
            onChange={(e) => {
              props.Search(e.target.value);
            }}
            autoFocus
          />
          <i
            className="fas fa-search"
            onClick={(e) => props.Search(props.strSearchtxt)}
          ></i>
        </div>
      </div>
      <div className="data-list">
        {props.arrMasterData.map((dt, Index) => (
          <button
            key={Index}
            tabIndex={Index}
            onKeyDown={(e) => {
              const arrlength = props.arrMasterData.length - 1;
              if (e.key === "ArrowDown") {
                if (Index === arrlength)
                  $(`.data-list [tabindex=${Index + 1}]`).focus();
                else $(`.data-list [tabindex=${0}]`).focus();
              }
              if (e.key === "ArrowUp") {
                if (Index === 0)
                  $(`.data-list [tabindex=${arrlength}]`).focus();
                else $(`.data-list [tabindex=${Index - 1}]`).focus();
              }
              if (e.key === "Enter") {
                Select_Row(dt, Index);
              }
            }}
          >
            {props.ScrId === 1 && dt.iItemgrpId > 0 && (
              <i className="fa-regular fa-folder mr-2"></i>
            )}
            <label onClick={(e) => Select_Row(dt)}>
              {dt[props.displayName]}
            </label>
            {props.isImg && (
              <img
                src={
                  dt.imgData.length > 0
                    ? "data:image/png;base64," +
                      Buffer.from(new Uint8Array(dt.imgData)).toString("base64")
                    : ""
                }
                alt="Product Img"
              ></img>
            )}
          </button>
        ))}
      </div>
    </div>
  );
}
export default MasterList;
