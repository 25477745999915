import { createSlice } from "@reduxjs/toolkit";

export const PointofsalesSlice = createSlice({
  name: "EntTrans",
  initialState: {
    default: {
      //#region  Default Trans Array Parem
      EntProduct: [],
      EntTransHT: [],
      EntTransItemRemark: [
        {
          strRemark1: "",
          strRemark2: "",
          strRemark3: "",
          strRemark4: "",
        },
      ],
      EntTransRootCal: [
        {
          dValue1: 0,
          dValue2: 0,
          dValue3: 0,
          dConstantValue: 0,
          dFinalValue: 0,
          strOp1: "0",
          strOp2: "0",
          strOp3: "0",
        },
      ],
      EntTransVen: [],
      EntTransService: [],
      EntPartyAdd: [],
      EntBillType: [],
      EntTaxType: [],

      EntUnit: [],
      EntNoFormat: [],
      EntTransPemission: [],
      EntTransFieldPemission: [],
      //#endregion
    },
    CommonDT: {
      EntTransDT: [],
      EntTransDTHelp: [],
      EntTransPenConDT: [],
      EntTransTax: [],
      EntTransAL: [],
      EntTransSummary: [],
    },
    CommonHTParem: {
      //#region  Voucher Tab Parem
      strTransNo: "",
      strPartyDCVouch: "",
      strConvertDCVouch: "",
      //#endregion

      //#region  Reference Vouchers Tap Parem
      strRefNum1: "",
      dpRefNum1Date: new Date().toISOString().split("T")[0],
      strRefNum2: "",
      dpRefNum2Date: new Date().toISOString().split("T")[0],
      strRefNum3: "",
      dpRefNum3Date: new Date().toISOString().split("T")[0],
      strRefNum4: "",
      dpRefNum4Date: new Date().toISOString().split("T")[0],
      //#endregion

      //#region  Validity Tap Parem
      iValidity: 0,
      dpValDateUpto: new Date().toISOString().split("T")[0],
      iPayTerm: 0,
      dpPayTermDateUpto: new Date().toISOString().split("T")[0],
      iDeliTerm: 0,
      dpDelTermDateUpto: new Date().toISOString().split("T")[0],
      //#endregion

      //#region  Delivery Mode Parem
      iDMId: 0,
      strAgent: "",
      strReference: "",
      strDestination: "",
      strEComGstNo: "",
      EntDelMode: [],
      //#endregion

      //#region  Transport Tap Parem
      strTransport: "",
      strVehicle: "",
      strDespatch: "",
      dpDespatchDate: new Date().toISOString().split("T")[0],
      strLRNNumber: "",
      dpLRNoDate: new Date().toISOString().split("T")[0],
      //#endregion

      //#region  Remark Tap Parem
      strRemark1: "",
      strRemark2: "",
      strRemark3: "",
      strRemark4: "",
      //#endregion
    },
    CommonParem: {
      iScrId: 93,
      strScrType: "Sales",
      iVouchId: 0,
      iRowIndex: 0,
      iCartInvId: 0,
      iMinRow: 5,
      strSetCurrentStatus: "",
      strVouchNo: "",
      strVouchDate: new Date().toISOString().split("T")[0],
      iBillTypeId: 2,
      strBillType: "",
      iTaxCalcOnId: 0,
      isUpdate: false,
      isConRowManOpen: false,
      isReceiptOpen: false,
      isTinderOpen: false,
      isOpenReceiptWindow: false,
      isPrintOpen: false,
      isDisablebtn: false,
      isDisabled: false,
      isLoding: true,
      isNewParty: false,
      isMutipleOption: false,
    },
    BillDiscount: {
      dBillDisPer: 0,
      dBillDisAmt: 0,
      dBillDisValue: 0,
      dALBillDisPer: 0,
      dALBillDisAmt: 0,
      dALBillDisValue: 0,
      iALBillDiscId: 0,
      dCouponDiscPer: 0,
      dCouponDiscAmt: 0,
      dRewardPointper: 0,
      dRewardPointAmt: 0,
      iCouponId: 0,
      strCoupon: "",
    },
    Product: {
      iProId: 0,
      AttGrpId: 0,
      strProCode: "",
      strProName: "",
      strCommodityCode: "",
      strAttValues: "",
      iUnitId: 0,
      strUnitName: "",
      dPriceRate: (0).toFixed(2),
      dPriceRatewithTax: (0).toFixed(2),
      dMinMargin: (0).toFixed(2),
      dItemDisc: (0).toFixed(2),
      dItemDiscPer: (0).toFixed(2),
      dItemDiscAmt: (0).toFixed(2),
      dCashDisc: (0).toFixed(2),
      dCashDiscPer: (0).toFixed(2),
      dCashDiscAmt: (0).toFixed(2),
      isItemDiscPer: true,
      isCashDiscPer: true,
      iPrintTempId: 0,
      dQty: (0).toFixed(2),
      FQty: (0).toFixed(2),
      strBatchNo: "",
      dpManufacturedate: "",
      dpExpiryDate: "",
      iSGST: 0,
      iCGST: 0,
      iIGST: 0,
      iTCS: 0,
      strSGST: "",
      strCGST: "",
      strIGST: "",
      strTCS: "",
      strSGSTPer: (0).toFixed(2),
      strCGSTPer: (0).toFixed(2),
      strIGSTPer: (0).toFixed(2),
      strTCSPer: (0).toFixed(2),
      taxableOn: "",
      iTcsTaxRelId: 0,
      totalVal: 0,
    },
    Summary: {
      dnetAmt: (0).toFixed(2),
      dqty: (0).toFixed(2),
      dtotalDiscount: (0).toFixed(2),
      dgoodsValue: (0).toFixed(2),
      dtotalTax: (0).toFixed(2),
      dtotalAddLess: (0).toFixed(2),
      dtotalTCSTax: (0).toFixed(2),
      droundOff: (0).toFixed(2),
      dBillDisVal: (0).toFixed(2),
    },
    Party: {
      iPartyId: 0,
      iTaxRelId: 0,
      strPartyName: "",
      strPartyCode: "",
      strAliasName: "",
      strAdd1: "",
      strAdd2: "",
      strAdd3: "",
      strAdd4: "",
      strPincode: "",
      iState: "32",
      iCountry: "100",
      strMobile1: "",
      strMobile2: "",
      strPhone1: "",
      strPhone2: "",
      strEmail: "",
      strGSTNo: "",
      strTinNo: "",
      strShippAdd: "",
      iTaxType: 0,
      strPartyAdd: "",
      dRoyaltyPoint: 0,
      strDiscType: "Amt",
    },
    Pos: {
      iSessionId: 0,
      dpTransDate: new Date().toISOString().split("T")[0],
      TStartTime: 0,
      iCounterId: 0,
      CartInvoice: 0,
      iTransId: 0,
      strVouchNo: "",
      strBillType: "",
      dLBNetAmt: (0).toFixed(2),
      dLBPaidAmt: (0).toFixed(2),
      dLBBalAmt: (0).toFixed(2),
      iUserId: 0,
      iBranchId: 0,
      strImageName: "",
      strImageType: "",
      EntImage: [],
      imgLogo: "",
      iTaxCalcOnId: 0,
      iOthCalWQty: 0,
      iAutoRoundOff: 0,
      isRightmenu: false,
      EntBillType: [],
      EntCoupon: [],
      MinMarginValidation: "",
      NegativeStockAllow: "",
      PosTempId: 0,
    },
    OtherCharges: {
      SerId: 0,
      ServiceName: "",
      SerCode: "",
      SACcode: "",
      bUpdateStatus: false,
      iRowIndex: 0,
      RateWithoutTax: (0).toFixed(2),
      RateWithTax: (0).toFixed(2),
      dTaxPer: 0,
      EntALTax: [],
    },
    Salesperson: {
      EntEmployee: [],
      iEmpId: 0,
      strEmployeeName: "",
      iRoleId: 0,
      dRewardPoint: 0,
    },
    CustomTabOrder: {
      EntTabSettings: {
        txtPartyName: "",
        txtBillAddress: "",
        txtShippingAdd: "",
        cbBillType: "",
        cbTaxType: "",
        txtTransNo: "",
        txtInvNo: "",
        dpInvDate: "",
        txtTransport: "",
        txtDespatch: "",
        dpDespatchDate: "",
        txtDestination: "",
        txtVehicle: "",
        cbDeliMode: "",
        txtRefName: "",
        txtAgentName: "",
        txtValidity: "",
        dpValidityUpto: "",
        txtPayTerm: "",
        dpPayTermUpto: "",
        txtDeliTerm: "",
        dpDeliTermUpto: "",
        txtAddLessDiscPer: "",
        txtAddLessDiscAmt: "",
        txtAddLessDiscValue: "",
        txtBillDiscPer: "",
        txtBillDiscAmt: "",
        txtBillDiscValue: "",
        btnNew: "",
        btnModify: "",
        btnSave: "",
        btnClear: "",
        btnView: "",
        btnExportImport: "",
        btnPrint: "",
        btnCancel: "",
        btnClose: "",
        btnPrintSettings: "",
        btnHelp: "",
        txtRemark1: "",
        txtRemark2: "",
        txtRemark3: "",
        txtRemark4: "",
        txtLRNumber: "",
        dpLRDate: "",
        TabParty: "",
        TabRefVouchers: "",
        TabVoucher: "",
        TabDelivery: "",
        TabTranport: "",
        TabValidity: "",
        TabRemarks: "",
        TabSummary: "",
        TabTax: "",
        TabDiscount: "",
        TabAddLess: "",
        TabName: "Party",
        txtRefNum1: "",
        txtRefNum1Date: "",
        txtRefNum2: "",
        txtRefNum2Date: "",
        txtRefNum3: "",
        txtRefNum3Date: "",
        txtRefNum4: "",
        txtRefNum4Date: "",
        btnRefresh: "",
        ProductTableListTab: "",
        txtProductName: "",
        txtQty: "",
        cbUnit: "",
        txtRate: "",
        txtDiscount: "",
        btnAdd: "",
        discType: "",
      },
    },
    TableColumns: { EntTableColumns: [] },
  },
  reducers: {
    Add_TransHtparem: (state, action) => {
      state.CommonHTParem = action.payload;
    },
    Add_CommonDefault: (state, action) => {
      state.CommonParem = action.payload;
    },
    update_transDT: (state, action) => {
      state.CommonDT.EntTransDT = action.payload;
    },
    Add_Transdata: (state, action) => {
      state.CommonDT = action.payload;
    },
    Add_Posdefault: (state, action) => {
      state.Pos = action.payload;
    },
    Add_TransTableHeader: (state, action) => {
      state.TableColumns = action.payload;
    },
    Add_TransSummary: (state, action) => {
      state.Summary = action.payload;
    },
    Add_party: (state, action) => {
      state.Party = action.payload;
    },
    isright_menuOpen: (state, action) => {
      state.Pos.isRightmenu = action.payload;
    },
    isMultipleOption: (state, action) => {
      state.CommonParem.isMutipleOption = action.payload;
    },
    Update_Product: (state, action) => {
      state.default.EntProduct = action.payload;
    },
    Add_Product: (state, action) => {
      state.Product = action.payload;
    },
    Product_keyDown: (state, action) => {
      state.Product = action.payload;
    },
    isRecipt_Open: (state, action) => {
      state.CommonParem.isReceiptOpen = action.payload;
    },
    update_BillType: (state, action) => {
      state.CommonParem.iBillTypeId = action.payload;
    },
    redeem_coupon: (state, action) => {
      state.BillDiscount.iCouponId = action.payload.iCouponId;
      state.BillDiscount.strCoupon = action.payload.strCoupon;
      state.BillDiscount.dCouponDiscPer = action.payload.dCouponDiscPer;
      state.BillDiscount.dCouponDiscAmt = action.payload.dCouponDiscAmt;
    },
    redeem_rewardpoint: (state, action) => {
      state.BillDiscount.dRewardPointper = action.payload.dRewardPointper;
      state.BillDiscount.dRewardPointAmt = action.payload.dRewardPointAmt;
    },
    add_BillDiscount: (state, action) => {
      state.BillDiscount.dBillDisPer = action.payload.dBillDisPer;
      state.BillDiscount.dBillDisAmt = action.payload.dBillDisAmt;
    },
    add_remarks: (state, action) => {
      state.CommonHTParem.strRemark1 = action.payload.strRemark1;
      state.CommonHTParem.strRemark2 = action.payload.strRemark2;
      state.CommonHTParem.strRemark3 = action.payload.strRemark3;
      state.CommonHTParem.strRemark4 = action.payload.strRemark4;
    },
    update_CountryState: (state, action) => {
      state.StateCountry = action.payload;
    },
    Add_Salesperson: (state, action) => {
      state.Salesperson = action.payload;
    },
    Add_Delivery: (state, action) => {
      state.CommonHTParem.iDMId = action.payload.iDMId;
      state.CommonHTParem.strAgent = action.payload.strAgent;
      state.CommonHTParem.strReference = action.payload.strReference;
      state.CommonHTParem.strDestination = action.payload.strDestination;
      state.CommonHTParem.strEComGstNo = action.payload.strEComGstNo;
      state.CommonHTParem.EntDelMode = action.payload.EntDelMode;
      state.CommonHTParem.strTransport = action.payload.strTransport;
      state.CommonHTParem.strVehicle = action.payload.strVehicle;
      state.CommonHTParem.strDespatch = action.payload.strDespatch;
      state.CommonHTParem.dpDespatchDate = action.payload.dpDespatchDate;
      state.CommonHTParem.strLRNNumber = action.payload.strLRNNumber;
      state.CommonHTParem.dpLRNoDate = action.payload.dpLRNoDate;
    },
    Clear_invoice: (state) => {
      state.default = {
        //#region  Default Trans Array Parem
        EntProduct: [],
        EntTransHT: [],
        EntTransItemRemark: [
          {
            strRemark1: "",
            strRemark2: "",
            strRemark3: "",
            strRemark4: "",
          },
        ],
        EntTransRootCal: [
          {
            dValue1: 0,
            dValue2: 0,
            dValue3: 0,
            dConstantValue: 0,
            dFinalValue: 0,
            strOp1: "0",
            strOp2: "0",
            strOp3: "0",
          },
        ],
        EntTransVen: [],
        EntTransService: [],
        EntPartyAdd: [],
        EntBillType: [],
        EntTaxType: [],
        EntUnit: [],
        EntNoFormat: [],
        //#endregion
      };
      state.CommonDT = {
        EntTransDT: [],
        EntTransDTHelp: [],
        EntTransPenConDT: [],
        EntTransTax: [],
        EntTransAL: [],
        EntTransSummary: [],
      };
      state.CommonHTParem = {
        //#region  Voucher Tab Parem
        strTransNo: "",
        strPartyDCVouch: "",
        strConvertDCVouch: "",
        //#endregion

        //#region  Reference Vouchers Tap Parem
        strRefNum1: "",
        dpRefNum1Date: new Date().toISOString().split("T")[0],
        strRefNum2: "",
        dpRefNum2Date: new Date().toISOString().split("T")[0],
        strRefNum3: "",
        dpRefNum3Date: new Date().toISOString().split("T")[0],
        strRefNum4: "",
        dpRefNum4Date: new Date().toISOString().split("T")[0],
        //#endregion

        //#region  Validity Tap Parem
        iValidity: 0,
        dpValDateUpto: new Date().toISOString().split("T")[0],
        iPayTerm: 0,
        dpPayTermDateUpto: new Date().toISOString().split("T")[0],
        iDeliTerm: 0,
        dpDelTermDateUpto: new Date().toISOString().split("T")[0],
        //#endregion
      };
      state.Delivery = {
        //#region  Delivery Mode Parem
        iDMId: 0,
        strAgent: "",
        strReference: "",
        strDestination: "",
        strEComGstNo: "",
        EntDelMode: [],
        //#endregion

        //#region  Transport Tap Parem
        strTransport: "",
        strVehicle: "",
        strDespatch: "",
        dpDespatchDate: new Date().toISOString().split("T")[0],
        strLRNNumber: "",
        dpLRNoDate: new Date().toISOString().split("T")[0],
        //#endregion
      };
      state.Remarks = {
        //#region  Remark Tap Parem
        strRemark1: "",
        strRemark2: "",
        strRemark3: "",
        strRemark4: "",
        //#endregion
      };
      state.CommonParem = {
        iScrId: 93,
        strScrType: "Sales",
        iVouchId: 0,
        iCartInvId: 0,
        iRowIndex: 0,
        iMinRow: 5,
        strSetCurrentStatus: "",
        strSearchtext: "",
        strVouchNo: "",
        strVouchDate: new Date().toISOString().split("T")[0],
        iBillTypeId: 2,
        strBillType: "",
        iTaxCalcOnId: 0,
        isUpdate: false,
        isConRowManOpen: false,
        isReceiptOpen: false,
        isTinderOpen: false,
        isOpenReceiptWindow: false,
        isPrintOpen: false,
        isDisablebtn: false,
        isDisabled: false,
        isLoding: true,
        isNewParty: false,
        isMutipleOption: false,
      };
      state.BillDiscount = {
        dBillDisPer: 0,
        dBillDisAmt: 0,
        dBillDisValue: 0,
        dALBillDisPer: 0,
        dALBillDisAmt: 0,
        dALBillDisValue: 0,
        iALBillDiscId: 0,
        dCouponDiscPer: 0,
        dCouponDiscAmt: 0,
        dRewardPointper: 0,
        dRewardPointAmt: 0,
        iCouponId: 0,
        strCoupon: "",
      };
      state.Product = {
        iProId: 0,
        AttGrpId: 0,
        strProCode: "",
        strProName: "",
        strCommodityCode: "",
        strAttValues: "",
        iUnitId: 0,
        strUnitName: "",
        dPriceRate: (0).toFixed(2),
        dPriceRatewithTax: (0).toFixed(2),
        dMinMargin: (0).toFixed(2),
        dItemDisc: (0).toFixed(2),
        dItemDiscPer: (0).toFixed(2),
        dItemDiscAmt: (0).toFixed(2),
        dCashDisc: (0).toFixed(2),
        dCashDiscPer: (0).toFixed(2),
        dCashDiscAmt: (0).toFixed(2),
        isItemDiscPer: true,
        isCashDiscPer: true,
        iPrintTempId: 0,
        dQty: (0).toFixed(2),
        FQty: (0).toFixed(2),
        strBatchNo: "",
        dpManufacturedate: "",
        dpExpiryDate: "",
        iSGST: 0,
        iCGST: 0,
        iIGST: 0,
        iTCS: 0,
        strSGST: "",
        strCGST: "",
        strIGST: "",
        strTCS: "",
        strSGSTPer: (0).toFixed(2),
        strCGSTPer: (0).toFixed(2),
        strIGSTPer: (0).toFixed(2),
        strTCSPer: (0).toFixed(2),
        taxableOn: "",
        iTcsTaxRelId: 0,
        totalVal: 0,
      };
      state.Summary = {
        dnetAmt: (0).toFixed(2),
        dqty: (0).toFixed(2),
        dtotalDiscount: (0).toFixed(2),
        dgoodsValue: (0).toFixed(2),
        dtotalTax: (0).toFixed(2),
        dtotalAddLess: (0).toFixed(2),
        dtotalTCSTax: (0).toFixed(2),
        droundOff: (0).toFixed(2),
        dBillDisVal: (0).toFixed(2),
      };
      state.Party = {
        iPartyId: 0,
        iTaxRelId: 0,
        strPartyName: "",
        strPartyCode: "",
        strAliasName: "",
        strAdd1: "",
        strAdd2: "",
        strAdd3: "",
        strAdd4: "",
        strPincode: "",
        iState: "32",
        iCountry: "100",
        strMobile1: "",
        strMobile2: "",
        strPhone1: "",
        strPhone2: "",
        strEmail: "",
        strGSTNo: "",
        strTinNo: "",
        strShippAdd: "",
        iTaxType: 0,
        strPartyAdd: "",
        dRoyaltyPoint: 0,
        strDiscType: "Amt",
      };
      state.Pos = {
        dpTransDate: new Date().toISOString().split("T")[0],
        CartInvoice: 0,
        iTransId: 0,
        strVouchNo: "",
        strBillType: "",
        dLBNetAmt: (0).toFixed(2),
        dLBPaidAmt: (0).toFixed(2),
        dLBBalAmt: (0).toFixed(2),
        iTaxCalcOnId: 0,
        iOthCalWQty: 0,
        iAutoRoundOff: 0,
        isRightmenu: false,
        EntBillType: [],
        EntCoupon: [],
        MinMarginValidation: "",
        NegativeStockAllow: "",
        PosTempId: 0,
      };
    },
    setothercharges: (state, action) => {
      state.OtherCharges = action.payload;
    },
    setpostemplate: (state, action) => {
      state.Pos.PosTempId = action.payload;
    },
  },
});
export const {
  Add_Transdefault,
  Add_CommonDefault,
  Add_Transdata,
  update_transDT,
  Add_Posdefault,
  Add_TransTableHeader,
  Add_TransSummary,
  Add_party,
  Add_Product,
  isright_menuOpen,
  Update_Product,
  isMultipleOption,
  isRecipt_Open,
  update_BillType,
  redeem_coupon,
  redeem_rewardpoint,
  add_BillDiscount,
  Add_TransHtparem,
  add_remarks,
  Add_Salesperson,
  Add_Delivery,
  Clear_invoice,
  setothercharges,
  setpostemplate,
} = PointofsalesSlice.actions;
export default PointofsalesSlice.reducer;
