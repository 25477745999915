import { useEffect, useState } from "react";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { Ask_Question, Show_Message } from "../General/Messagedialogbox";
import { API_DELETE, API_POST } from "../General/Utility";

function CounterMaster() {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _Config = useSelector((state) => state.Config.Config);

  const [iWHId, setiWHId] = useState(0);
  const [iCounterId, setiCounterId] = useState(0);
  const [strCounterName, setstrCounterName] = useState("");
  const [strCounterCode, setstrCounterCode] = useState("");
  const [strDescription, setstrDescription] = useState("");
  const [iStatus, setiStatus] = useState(1);
  const [IsEdit, setIsEdit] = useState(false);

  const [arrEntWH, setarrEntWH] = useState([]);
  const [arrCounter, setarrCounter] = useState([]);
  const [arrStatus, setarrStatus] = useState([]);

  useEffect(() => {
    Load_CounterMaster();
  }, []);

  const Load_CounterMaster = async () => {
    try {
      const parems = {
        iUserId: _AuthParems.User,
        iRoleId: _AuthParems.Role,
        iClientId: _AuthParems.Client,
        iBranchId: _AuthParems.Branch,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        `CounterMaster/Load_CounterMaster`,
        parems,
        dispatch
      );
      if (res) {
        setarrEntWH(res.EntWarehouse);
        setarrCounter(res.EntCounter);
        setarrStatus(res.EntStatus);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Delete_Counter = async () => {
    try {
      const res = await API_DELETE(
        `CounterMaster/Delete_Counter/${Number(iCounterId)}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Click();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const View_Counter = async (counter) => {
    setIsEdit(true);
    setiCounterId(counter.iCounterId);
    setstrCounterCode(counter.strCounterCode);
    setstrCounterName(counter.strCounterName);
    setstrDescription(counter.strDescription);
    setiWHId(counter.iWHId);
    setiStatus(counter.iStatus);
  };
  const Save_Counter = async () => {
    try {
      const parems = {
        iCounterId: iCounterId,
        strCounterName: strCounterName,
        strCounterCode: strCounterCode,
        strDescription: strDescription,
        iBranchId: _AuthParems.Branch,
        iWHId: iWHId,
        iStatus: iStatus,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        `CounterMaster/InsUpd_Counter`,
        parems,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Click();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Add_NewCounter = () => {
    setiWHId(0);
    setiCounterId(0);
    setstrCounterName("");
    setstrCounterCode("");
    setstrDescription("");
    setiStatus(1);
    setIsEdit(true);
  };
  const Clear_Click = () => {
    setiWHId(0);
    setiCounterId(0);
    setstrCounterName("");
    setstrCounterCode("");
    setstrDescription("");
    setiStatus(1);
    setIsEdit(false);
    Load_CounterMaster();
  };
  return (
    <div className="Config-card">
      <div className="config-h">
        <h3>{_Config.Header}</h3>
        <div className="Config-btn">
          <button
            tabIndex={20}
            className="btn-fabgreen"
            onClick={(e) => Add_NewCounter()}
          >
            Add New Counter&nbsp;<i className="fas fa-save"></i>
          </button>
        </div>
      </div>
      <div className="Config-Wrapper">
        {!IsEdit && (
          <div className="wh-container">
            {arrCounter.map((Counter, Index) => (
              <div className="wh-cart" key={Index}>
                <div className="wh-header">
                  <h5>{Counter.strCounterName}</h5>
                  <div className="wh-cartbtn">
                    <button onClick={(e) => View_Counter(Counter)}>Edit</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {IsEdit && (
          <div className="row p-2">
            <div className="col-sm-12 col-md-4 mt-2">
              <label>Counter Name</label>
              <input
                type="text"
                value={strCounterName}
                className="form-control"
                onChange={(e) => setstrCounterName(e.target.value)}
                placeholder="Counter Name"
                spellCheck={false}
                autoComplete="off"
              />
            </div>
            <div className="col-sm-12 col-md-4 mt-2">
              <label>Description</label>
              <input
                type="text"
                value={strDescription}
                className="form-control"
                onChange={(e) => setstrDescription(e.target.value)}
                placeholder="Description"
                spellCheck={false}
                autoComplete="off"
              />
            </div>
            <div className="col-sm-6 col-md-4 mt-2">
              <label>Warehouse</label>
              <CustomizedSelectoption
                optionparem="isoptionShow1"
                showoption={_parem.isoptionShow1}
                placeholder="Select From Warehouse"
                select_value={(val) => {
                  setiWHId(Number(val));
                }}
                btnname="Warehouse"
                btnshow={false}
                show_popup=""
                valueonly={true}
                defaultval={iWHId}
                displayName="strWHName"
                disvalue="iWHId"
                arrydata={arrEntWH}
                disabled={false}
                EmptVal="Warehouse"
              />
            </div>
            <div className="col-md-4 col-sm-12 mt-2">
              <label>Status</label>
              <CustomizedSelectoption
                optionparem="isoptionShow3"
                showoption={_parem.isoptionShow3}
                placeholder="Select Status"
                select_value={(val) => setiStatus(val)}
                btnname="State"
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iStatus}
                displayName="strStatusName"
                disvalue="iStatusId"
                arrydata={arrStatus}
                disabled={false}
                EmptVal="Status "
              />
            </div>
          </div>
        )}
      </div>
      <div className="btn-section mt-1">
        <button
          className="btn-fabgreen"
          onClick={(e) => {
            if (strCounterName === "") {
              Show_Message(dispatch, "Please Enter Counter Name", "error");
            } else {
              Ask_Question(
                dispatch,
                Save_Counter,
                "Do You Want to  Save Counter?"
              );
            }
          }}
        >
          Save&nbsp;<i className="fas fa-save"></i>
        </button>
        <button
          className="btn-fabgreen"
          onClick={(e) => {
            if (Number(iCounterId) === 0)
              Show_Message(dispatch, "Please Select Counter", "info");
            else
              Ask_Question(
                dispatch,
                Delete_Counter,
                "Do You Want to Delete Counter?"
              );
          }}
        >
          Delete&nbsp; <i className="bx bx-trash-alt"></i>
        </button>
        <button className="btn-fabgreen" onClick={(e) => Clear_Click()}>
          Close&nbsp;<i className="fas fa-times"></i>
        </button>
      </div>
    </div>
  );
}

export default CounterMaster;
