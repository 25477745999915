import { createSlice } from "@reduxjs/toolkit";
import {
  Update_TrasDefault,
  Validate_Taborder,
  Validate_TransPermission,
} from "../Transaction/Transaction";
import { Buffer } from "buffer";
export const Transactionstates = createSlice({
  name: "Transactionstates",
  initialState: {
    Common: {
      Controller: "",
      iScrId: 0,
      strScrType: "",
      strScrName: "",

      VenTypeId: 0,
      PartyScrId: 0,
      ColName: "",
      PartyScrName: "",
      PartyPlaceHolder: "",
      JSScrId: 0,
      JSType: "",

      iSessionId: 0,
      TStartTime: 0,
      TDateTime: 0,
      iCounterId: 0,
      CartInvoice: 0,
      IsTabletMode: false,

      LasBillType: "",
      LastInvNo: "",
      LastInvDate: "",
      dLBNetAmt: (0).toFixed(2),
      dLBPaidAmt: (0).toFixed(2),
      dLBBalAmt: (0).toFixed(2),
      EntCoupon: [],
      EntEmployee: [],

      iTaxCalcOnId: 0,
      iALBillDiscId: 0,
      IsMultiWarehouse: false,
      MinRow: 5,
      iPrintTempId: 0,

      strCurrentStatus: "",

      IsPOS: false,
      EnableReceipt: false,
      EnablePayment: false,
      IsPrint: false,
      IsTinder: false,
      IsHideBill: false,
      IsHideJob: true,
      IsHideInvDc: true,
      IsJobWorkDc: false,

      IsVenSearch: false,
      IsProSearch: false,
      IsPosPro: false,
      IsMultiWH: false,
      IsBatchPro: false,
      IsAttPro: false,
      IsVenCreation: false,
      IsAddress: false,
      IsVouchNo: false,
      IsProduct: false,
      IsProRemark: false,
      IsProCal: false,
      IsConvertion: false,
      IsReceipt: false,
      IsPayment: false,
      IsHideParty: false,
      IsPendinCon: false,
      IsTransView: false,
      IsEmail: false,
      IsWhatsApp: false,
      IsEInvoice: false,
      IsPrintSett: false,
      IsPrintShow: false,
      IsReturnPro: false,
      IsQtyReadonly: false,
      IsRightMenu: false,
      IsSession: false,
      IsPOSCustomer: false,
      IsEmployee: false,

      objFieldPerm: {
        TransNo: true,
        ConvDCV: true,
        PDC: true,
        RefN1: true,
        RefND1: true,
        RefN2: true,
        RefND2: true,
        RefN3: true,
        RefND3: true,
        RefN4: true,
        RefND4: true,
        Delmode: true,
        Ref: true,
        AName: true,
        Destination: true,
        Transport: true,
        Vechicle: true,
        Despatch: true,
        DespDate: true,
        LNo: true,
        LDa: true,
        Vali: false,
        ValiD: false,
        PayT: false,
        PayD: false,
        DelT: false,
        DelD: false,
        R1: true,
        R2: true,
        R3: true,
        R4: true,
      },
      objFieldName: {
        TransNo: "Trans No",
        ConvDCV: "Convert Dc Voucher",
        PDC: "party Dc Voucher",
        RefN1: "PO No",
        RefND1: "PO Date",
        RefN2: "Validity",
        RefND2: "Ref Date 1",
        RefN3: "Ref No3",
        RefND3: "Ref Date 2",
        RefN4: "Ref No3",
        RefND4: "Ref Date 3",
        Delmode: "Delivery Mode",
        Ref: "Reference",
        AName: "Agent Name",
        Destination: "Destination",
        Transport: "TransPort",
        Vechicle: "Vechicle",
        Despatch: "Despatch",
        DespDate: "Despatch Date",
        LNo: "License No",
        LDa: "License Date",
        Vali: "Validity",
        ValiD: "Validity Date",
        PayT: "Pay Term",
        PayD: "Payment Date",
        DelT: "Delivery Term",
        DelD: "Delivery Date",
        R1: "Remark1",
        R2: "Remark2",
        R3: "Remark3",
        R4: "Remark4",
      },
      BillType: [],
      TaxType: [],
      DelMode: [],
      arrValidation: [],
      arrtransper: [],
      arrfieldPer: [],
      arrTableCol: [],
      arrtaborder: {
        //Voucher Tab Order
        txtInvNo: "",
        dpInvDate: "",
        txtTransNo: "",
        cbBillType: "",
        //Party Tab Order
        txtPartyName: "",
        txtBillAddress: "",
        txtShippingAdd: "",
        cbTaxType: "",
        //Reference Tab Order
        txtRefNum1: "",
        dpRefNum1Date: "",
        txtRefNum2: "",
        dpRefNum2Date: "",
        txtRefNum3: "",
        dpRefNum3Date: "",
        txtRefNum4: "",
        dpRefNum4Date: "",
        btnRefresh: "",
        //Transport Tab Order
        cbDeliMode: "",
        txtTransport: "",
        txtDespatch: "",
        dpDespatchDate: "",
        txtDestination: "",
        txtVehicle: "",
        txtRefName: "",
        txtAgentName: "",
        txtLRNumber: "",
        dpLRDate: "",
        //Validity Tab Order
        txtValidity: "",
        dpValidityUpto: "",
        txtPayTerm: "",
        dpPayTermUpto: "",
        txtDeliTerm: "",
        dpDeliTermUpto: "",
        //Remark Tab Order
        txtRemark1: "",
        txtRemark2: "",
        txtRemark3: "",
        txtRemark4: "",
        // Product Tab Order
        producttable: "",
        txtProductName: "",
        txtQty: "",
        cbUnit: "",
        txtRate: "",
        txtDiscount: "",
        btnAdd: "",
        discType: "",
        //Discount Tab Order
        txtAddLessDiscPer: "",
        txtAddLessDiscAmt: "",
        txtAddLessDiscValue: "",
        txtBillDiscPer: "",
        txtBillDiscAmt: "",
        txtBillDiscValue: "",
        //Button Tab Order
        btnNew: "",
        btnModify: "",
        btnSave: "",
        btnClear: "",
        btnView: "",
        btnExportImport: "",
        btnPrint: "",
        btnCancel: "",
        btnClose: "",
        btnPrintSettings: "",
        btnHelp: "",

        // Tab Button Tab Order
        TabParty: "",
        TabRefVouchers: "",
        TabVoucher: "",
        TabDelivery: "",
        TabTranport: "",
        TabValidity: "",
        TabRemarks: "",
        TabSummary: "",
        TabTax: "",
        TabDiscount: "",
        TabAddLess: "",
        TabName: "Party",
      },
      Headertab: [
        { TabId: 0, Index: 0, Show: true, tabShow: true },
        { TabId: 1, Index: 0, Show: false, tabShow: false },
        { TabId: 2, Index: 0, Show: false, tabShow: false },
        { TabId: 3, Index: 0, Show: false, tabShow: false },
        { TabId: 4, Index: 0, Show: false, tabShow: false },
        { TabId: 5, Index: 0, Show: false, tabShow: false },
        { TabId: 6, Index: 0, Show: false, tabShow: false },
      ],
      Summarytab: [true, false, false],
      Commontab: [true, false, false, false],
      TransPer: {
        IsProCode: false,
        IsItemDisc: false,
        IsCashDisc: false,
        IsFQty: false,
        IsBatchNo: false,
        IsMftDate: false,
        IsExpDate: false,
      },
      btnSave: true,
      btnView: true,
      btnDelete: true,
      btnConvert: true,
    },
    Header: {
      iNumSeqNo: "",
      strPrefix: "",
      strCompCode: "",
      strFinanFrom: "",
      strTransNo: "",
      strVouchNo: "",
      isManualCode: false,

      // Voucher Parems
      iVouchId: 0,
      iCartInvId: 0,
      SelectedVouchId: 0,
      iBillTypeId: 1,
      iDMId: 0,
      BtnDisable: false,
      strVouchDate: new Date().toISOString().split("T")[0],
      strPartyDCVouch: "",
      strConvertDCVouch: "",
      strInWDCVouchers: "",

      // Party Parems
      iPartyId: 0,
      strPartyName: "",
      PartyAddId: 0,
      strPartyAdd: "",
      ShippingAddId: 0,
      strShippingAdd: "",
      iTaxRelId: 0,
      dRoyaltyPoint: 0,
      RewardDiscType: "Amt",
      AddressType: "Shipp",
      arrAddress: [],

      //Reference Parems

      strRefNum1: "",
      disablebtn: false,
      dpRefNum1Date: new Date().toISOString().split("T")[0],
      strRefNum2: "",
      dpRefNum2Date: new Date().toISOString().split("T")[0],
      strRefNum3: "",
      dpRefNum3Date: new Date().toISOString().split("T")[0],
      strRefNum4: "",
      dpRefNum4Date: new Date().toISOString().split("T")[0],

      // Remark Parems
      strRemark1: "",
      strRemark2: "",
      strRemark3: "",
      strRemark4: "",

      // Validity parems
      strValidity: "0",
      dpValDateUpto: new Date().toISOString().split("T")[0],
      strPayTerm: "0",
      dpPayTermDateUpto: new Date().toISOString().split("T")[0],
      strDeliTerm: "0",
      dpDelTermDateUpto: new Date().toISOString().split("T")[0],

      //Transport Parem
      strEComGstNo: "",
      strTransport: "",
      strVehicle: "",
      strDespatch: "",
      dpDespatchDate: new Date().toISOString().split("T")[0],
      strLRNNumber: "",
      dpLRNoDate: new Date().toISOString().split("T")[0],
      strAgent: "",
      strReference: "",
      strDestination: "",
    },
    Data: {
      arrEntDT: [],
      arrEntDTCol: [],
      arrEntDTHelp: [],
      arrEntTax: [],
      arrEntAL: [],
      arrEntPenCon: [],
      arrEntConDT: [],
    },
    Product: {
      iProId: 0,
      WHId: 0,
      TBranchId: 0,
      TWHId: 0,
      iAttGrpId: 0,
      iCmpsDTId: 0,
      strProCode: "",
      strProName: "",
      strAttValue: "",
      iUnitId: 0,
      strUnitName: "",
      dQty: 0,
      FQty: 0,
      dRate: 0,
      dRatewithTax: 0,
      dItemDisc: 0,
      dCashDisc: 0,
      JobId: "0",
      isItemDiscPer: true,
      isCashDiscPer: true,
      BatchNo: "",
      MftDate: new Date().toISOString().split("T")[0],
      ExpDate: new Date().toISOString().split("T")[0],
      iRowIndex: 0,
      isBatchPro: false,
      isComposPro: false,
      isAttPro: false,
      Remarks: {
        strRemark1: "",
        strRemark2: "",
        strRemark3: "",
        strRemark4: "",
      },
      Calculation: {
        dValue1: 0,
        dValue2: 0,
        dValue3: 0,
        dConstantValue: 0,
        dFinalValue: 0,
        strOp1: "0",
        strOp2: "0",
        strOp3: "0",
      },
      Unit: [],
      AttCol: [],
      AttVal: [],
      Product: {},
      bUpdateStatus: false,
      isDiscChanged: false,
      Reason: "",
      iReasonId: 0,
      arrJobWork: {},
    },
    Summary: {
      NetAmt: 0,
      Qty: 0,
      GoodsValue: 0,
      Discount: 0,
      Tax: 0,
      AddLess: 0,
      TCSTax: 0,
      RoundOff: 0,
    },
    Discount: {
      dBillDisPer: (0).toFixed(2),
      dBillDisAmt: (0).toFixed(2),
      dBillDisValue: (0).toFixed(2),
      dALBillDisPer: (0).toFixed(2),
      dALBillDisAmt: (0).toFixed(2),
      dALBillDisValue: (0).toFixed(2),
      dThdiscount: (0).toFixed(2),
      iThDiscId: 0,
      iCouponId: 0,
      strCouponCode: "",
      dCouponDiscPer: 0,
      dCouponDiscAmt: 0,
      dRewardPointper: 0,
      dRewardPointAmt: 0,
      isDiscChanged: false,
      iALBillDiscId: 0,
    },
    AddLess: {
      iSerVenId: 0,
      VenCode: "",
      VenName: "",
      iRowIndex: 0,
      iSerId: 0,
      ServName: "",
      dServAmt: (0).toFixed(2),
      UpdateStatus: false,
      arrVendore: [],
      arrService: [],
    },
    Employee: {
      iEmpId: 0,
      strEmployeeName: "",
      iRoleId: 0,
      strEmpRole: "",
      dRewardPoint: 0,
    },
    Customer: {
      PartyId: 0,
      PartyName: "",
      PartyCode: "",
      AliasName: "",
      Address1: "",
      Address2: "",
      Address3: "",
      Address4: "",
      State: "Tamil Nadu",
      Country: "India",
      Pincode: "",
      Mobile: "",
      Phone: "",
      Email: "",
      GST: "",
      Remark: "",
      RoyaltyPoint: 0,
      ShippAdd: "",
      IsSameAdd: true,
      Status: "",
      CreatedBy: 0,
      RewardType: "",
    },
  },
  reducers: {
    setdafaultparems: (state, { payload }) => {
      const { Data, tabBtn, TransPaprem } = payload;
      state.Common.Controller = TransPaprem.Controller;
      state.Common.iScrId = TransPaprem.ScrId;
      state.Common.strScrName = TransPaprem.ScrName;
      state.Common.strScrType = TransPaprem.ScrType;
      state.Common.PartyScrId = TransPaprem.PartyScrId;
      state.Common.VenTypeId = TransPaprem.VenType;
      state.Common.ColName = TransPaprem.ColName;
      state.Common.arrValidation = Data.Transtab;
      state.Common.IsPOS = false;
      state.Common.arrtransper = Data.TransPer;
      state.Common.arrfieldPer = Data.FieldPer;
      state.Common.TransPer = Validate_TransPermission(Data.TransPer);

      state.Common.arrtaborder = Validate_Taborder(
        Data.Transtab,
        JSON.parse(JSON.stringify(state.Common.arrtaborder))
      );

      state.Common.DelMode = Data.DelMode;
      state.Common.Headertab = tabBtn;

      state.Common.strCurrentStatus = Data.CurrStatus;
      state.Common.iTaxCalcOnId = Data.TaxCalcOnId;
      state.Common.iALBillDiscId = Data.ALBillDiscId;
      state.Common.IsMultiWarehouse = Data.IsMultiWarehouse;
      state.Header.iNumSeqNo = Data.NoFormat[0].iNumSeqNo;
      state.Header.strPrefix = Data.NoFormat[0].strPrefix;
      state.Header.strCompCode = Data.NoFormat[0].strCompCode;
      state.Header.strFinanFrom = Data.NoFormat[0].strFinanFrom;
      state.Header.strTransNo = Data.NoFormat[0].strTransNo;
      state.Header.strVouchNo = Data.NoFormat[0].strUserNo;
      state.Header.isManualCode = !Data.NoFormat[0].isManualNumber;

      switch (TransPaprem.ScrId) {
        case 12:
        case 18:
        case 71:
        case 74:
          state.Common.IsJobWorkDc = true;
          state.Common.IsHideBill = true;
          Update_TrasDefault(state, Data);
          break;
        case 73:
          state.Common.IsHideInvDc = false;
          state.Common.EnableReceipt = Data.ReceiptShow;
          Update_TrasDefault(state, Data);
          break;
        case 76:
          state.Common.IsHideInvDc = false;
          state.Common.EnablePayment = Data.PaymentShow;
          Update_TrasDefault(state, Data);
          break;
        case 72:
        case 75:
          state.Common.IsHideBill = true;
          state.Common.IsHideJob = false;
          state.Common.IsQtyReadonly = true;
          state.Common.IsJobWorkDc = true;
          state.Product.arrJobWork = Data.JobWork;
          break;
        case 11:
          state.Common.EnablePayment = Data.PaymentShow;
          Update_TrasDefault(state, Data);
          break;
        case 17:
          state.Common.EnableReceipt = Data.ReceiptShow;
          state.Common.IsPrint = Data.AutoPrint;
          if (Data.isDefaultCust) {
            const {
              partyInfo,
              partyAddId,
              partyaddress,
              shippAddId,
              shipAddress,
              entAdress,
            } = Data;
            // Load Default Customer for Sales Invoice
            state.Header.iPartyId = partyInfo.partyId;
            state.Header.iTaxRelId = partyInfo.taxRelId;
            state.Header.strPartyName = partyInfo.partyName;
            state.Header.PartyAddId = partyAddId;
            state.Header.strPartyAdd = partyaddress;
            state.Header.ShippingAddId = shippAddId;
            state.Header.strShippingAdd = shipAddress;
            state.Header.arrAddress = entAdress;
          }
          Update_TrasDefault(state, Data);
          break;
        default:
          state.Common.IsHideBill = false;
          state.Common.IsHideInvDc = true;
          state.Common.IsHideJob = true;
          state.Common.IsQtyReadonly = false;
          Update_TrasDefault(state, Data);
          break;
      }
    },
    setPOS_DefaultParems: (state, { payload }) => {
      const { POS, LastInv, Transparem } = payload;
      state.Common.iScrId = Transparem.iScrId;
      state.Common.Controller = Transparem.strController;
      state.Common.strScrType = Transparem.strScrType;
      state.Common.strScrName = Transparem.strScrName;
      state.Common.PartyScrId = Transparem.iPartyScrId;
      state.Common.IsMultiWarehouse = payload.IsMultiWarehouse;
      state.Common.VenTypeId = payload.iVenType;
      state.Common.IsPOS = true;

      state.Common.iSessionId = POS.sessionId;
      state.Common.TStartTime = POS.startTime;
      state.Common.TDateTime = POS.transDate;
      state.Common.iCounterId = POS.counterId;
      state.Common.CartInvoice = POS.cartInvoice;
      state.Common.IsTabletMode = Boolean(POS.isTabletMode);
      state.Employee.iEmpId = POS.empId;
      state.Employee.strEmployeeName = POS.employeeName;
      state.Employee.iRoleId = POS.iRoleId;
      state.Employee.strEmpRole = POS.empRole;
      state.Employee.dRewardPoint = POS.rewardPoint;

      state.Common.strCurrentStatus = payload.CurrStatus;
      state.Common.iTaxCalcOnId = POS.taxCalBasedon;
      state.Common.iALBillDiscId = payload.ALBillDiscId;
      state.Header.iTaxRelId = payload.iTaxRelId;

      state.Common.arrtransper = payload.TransPer.filter(
        (Data) => Data.isShowInScr === true
      );
      state.Common.DelMode = payload.DelMode;
      state.Common.BillType = payload.BillType;
      state.Common.EntCoupon = payload.Coupons;
      state.Common.EntEmployee = payload.Employee;
      state.AddLess.arrService = payload.Service;

      if (LastInv.length > 0) {
        let PrevInv = LastInv[0];
        state.Header.iBillTypeId = PrevInv.bT_Id;
        state.Common.LasBillType = PrevInv.strBillType;
        state.Common.LastInvNo = PrevInv.transNo;
        state.Common.LastInvDate = PrevInv.transDate;
        state.Common.dLBNetAmt = Number(PrevInv.netAmt).toFixed(2);
        state.Common.dLBPaidAmt = Number(PrevInv.paidAmt).toFixed(2);
        state.Common.dLBBalAmt = Number(PrevInv.balAmt).toFixed(2);
      }
    },
    setScrId: (state, { payload }) => {
      state.Common.iScrId = payload;
      state.Common.strScrName = "General";
    },
    Stock_Parems: (state, { payload }) => {
      state.Common.iScrId = payload.iScrId;
      state.Common.strScrType = payload.strScrType;
      state.Common.strScrName = payload.strScrName;
      state.Header.iPartyId = payload.iPartyId;
      state.Header.iTaxRelId = payload.iTaxRelId;

      let NoFormat = payload.NoFormat;
      state.Header.iNumSeqNo = NoFormat.iNumSeqNo;
      state.Header.strPrefix = NoFormat.strPrefix;
      state.Header.strCompCode = NoFormat.strCompCode;
      state.Header.strFinanFrom = NoFormat.strFinanFrom;
      state.Header.strTransNo = NoFormat.strTransNo;
      state.Header.strVouchNo = NoFormat.strUserNo;
      state.Header.isManualCode = NoFormat.isManualNo;

      state.Common.Headertab = payload.arrtabbtn;
    },
    setPayRecepDefault: (state, { payload }) => {
      state.Common.strScrType = payload.ScrType;
      state.Common.PartyScrId = payload.PartyScrId;
      state.Common.VenTypeId = payload.VenType;
      state.Common.ColName = payload.ColName;
    },
    Update_BtnPermission: (state, { payload }) => {
      state.Common.btnSave = payload.btnSave;
      state.Common.btnView = payload.btnView;
      state.Common.btnDelete = payload.btnDelete;
      state.Common.btnConvert = payload.btnConvert;
    },
    settableCol: (state, { payload }) => {
      state.Data.arrEntDTCol = payload;
    },
    setParty: (state, { payload }) => {
      const {
        partyInfo,
        partyAddId,
        partyaddress,
        shippAddId,
        shipAddress,
        entAdress,
      } = payload;
      state.Header.iPartyId = partyInfo.partyId;
      state.Header.iTaxRelId = partyInfo.taxRelId;
      state.Header.strPartyName = partyInfo.partyName;
      state.Header.PartyAddId = partyAddId;
      state.Header.strPartyAdd = partyaddress;
      state.Header.ShippingAddId = shippAddId;
      state.Header.strShippingAdd = shipAddress;
      state.Header.arrAddress = entAdress;
    },
    Update_ShipAdd: (state, { payload }) => {
      state.Header.strShippingAdd = payload;
    },
    Input_Onchange: (state, { payload }) => {
      state.Header[payload.Name] = payload.Value;
    },
    CustomerInput_Onchange: (state, { payload }) => {
      state.Customer[payload.Name] = payload.Value;
    },
    EmployeeInput_Onchange: (state, { payload }) => {
      state.Employee = payload;
    },
    ProductInput_Onchange: (state, { payload }) => {
      state.Product[payload.Name] = payload.Value;
    },
    ProductRate_Onchange: (state, { payload }) => {
      if (payload.Name === "dRate") {
        state.Product.dRate = Number(payload.Value);
        state.Product.dRatewithTax = Number((0).toFixed(2));
      } else {
        state.Product.dRate = Number((0).toFixed(2));
        state.Product.dRatewithTax = Number(payload.Value);
      }
    },
    ProductRate_LostFocus: (state, { payload }) => {
      if (payload.Name === "dRate") {
        state.Product.dRate =
          Number(payload.Value) === 0
            ? Number((0).toFixed(2))
            : Number(payload.Value);
      } else {
        state.Product.dRatewithTax =
          Number(payload.Value) === 0
            ? Number((0).toFixed(2))
            : Number(payload.Value);
      }
    },
    Merge_ConvertedVouchers: (state, { payload }) => {
      const {
        EntDT,
        EntPenDT,
        EntTaxDT,
        EntALDT,
        Summary,
        ConvertedDC,
        PartyDc,
        InWDC,
      } = payload;
      state.Data.arrEntDT = EntDT;
      state.Data.arrEntTax = EntTaxDT;
      state.Data.arrEntAL = EntALDT;
      state.Data.arrEntPenCon = EntPenDT;
      state.Header.strConvertDCVouch = ConvertedDC;
      state.Header.strPartyDCVouch = PartyDc;
      state.Header.strInWDCVouchers = InWDC;

      state.Summary.NetAmt = Summary.netAmt;
      state.Summary.Qty = Summary.qty;
      state.Summary.Discount = Summary.totalDiscount;
      state.Summary.GoodsValue = Summary.goodsValue;
      state.Summary.Tax = Summary.totalTax;
      state.Summary.AddLess = Summary.totalAddLess;
      state.Summary.TCSTax = Summary.totalTCSTax;
      state.Summary.RoundOff = Summary.roundOff;
      state.Common.IsConvertion = false;
    },
    Select_Product: (state, { payload }) => {
      const { ProInfo, ProUnit } = payload;
      const Product = ProInfo[0];
      state.Product.iProId = Product.iProId;
      state.Product.strProCode = Product.strProCode;
      state.Product.strProName = Product.strProName;
      state.Product.iUnitId = Product.iUnitId;
      state.Product.strUnitName = Product.strUnitName;
      state.Product.dQty = Product.dQty;
      state.Product.FQty = Product.dFQty;
      state.Product.dRate = Product.dRateWithTax;
      state.Product.dRatewithTax = 0;
      state.Product.dItemDisc = Product.dItemDiscPer;
      state.Product.dCashDisc = 0;
      state.Product.isItemDiscPer = true;
      state.Product.isCashDiscPer = true;
      state.Product.BatchNo = "";
      state.Product.MftDate = "0000-00-00";
      state.Product.ExpDate = "0000-00-00";
      state.Product.isComposPro = Boolean(Product.isComposPro);
      state.Product.isBatchPro = Boolean(Product.isBatchPro);
      state.Product.WHId = payload.WHId;
      if (Product.isComposPro === 0) state.Product.Unit = ProUnit;
      else
        state.Product.Unit = [
          {
            bIsChecked: false,
            iLeastUnitId: 0,
            iUnitId: Product.iUnitId,
            strUnitName: Product.strUnitName,
          },
        ];
    },
    Select_BatchProduct: (state, { payload }) => {
      state.Product.Product = payload;
      state.Product.isBatchPro = true;
      state.Product.WHId = payload.WHId;
    },
    Select_AttProduct: (state, { payload }) => {
      state.Product.isAttPro = payload.isAttPro;
      state.Product.iAttGrpId = payload.iAttGrpId;
      state.Product.WHId = payload.WHId;
    },
    Update_BatchProduct: (state, { payload }) => {
      const { BatchInfo, Product } = payload;
      const ProInfo = Product.ProInfo[0];
      state.Product.iProId = ProInfo.iProId;
      state.Product.strProCode = ProInfo.strProCode;
      state.Product.strProName = ProInfo.strProName;
      state.Product.iUnitId = ProInfo.iUnitId;
      state.Product.strUnitName = ProInfo.strUnitName;
      state.Product.dQty = ProInfo.dQty;
      state.Product.FQty = ProInfo.dFQty;
      state.Product.dRate = ProInfo.dRateWithTax;
      state.Product.dRatewithTax = 0;
      state.Product.dItemDisc = ProInfo.dItemDiscPer;
      state.Product.dCashDisc = 0;
      state.Product.isItemDiscPer = true;
      state.Product.isCashDiscPer = true;
      state.Product.BatchNo = BatchInfo.batchNo;
      if (BatchInfo.batchNo.length > 0) {
        state.Product.MftDate = BatchInfo.mftDate;
        state.Product.ExpDate = BatchInfo.expDate;
      } else {
        state.Product.MftDate = new Date().toISOString().split("T")[0];
        state.Product.ExpDate = new Date().toISOString().split("T")[0];
      }
      state.Product.isBatchPro = Boolean(ProInfo.isBatchPro);
      state.Product.isComposPro = Boolean(ProInfo.isComposPro);
      if (ProInfo.isComposPro === 0) state.Product.Unit = Product.ProUnit;
      else
        state.Product.Unit = [
          {
            bIsChecked: false,
            iLeastUnitId: 0,
            iUnitId: ProInfo.iUnitId,
            strUnitName: ProInfo.strUnitName,
          },
        ];
    },
    Select_Unit: (state, { payload }) => {
      const { dPriceRate, UnitId, strUnitName } = payload;
      state.Product.iUnitId = UnitId;
      state.Product.dRate = dPriceRate;
      state.Product.dRatewithTax = Number(0).toFixed(2);
      state.Product.strUnitName = strUnitName ? strUnitName : "";
    },
    Change_Unit: (state, { payload }) => {
      state.Product.iUnitId = payload;
    },
    Clear_Product: (state) => {
      state.Product = {
        iProId: 0,
        WHId: 0,
        TBranchId: 0,
        TWHId: 0,
        iAttGrpId: 0,
        iCmpsDTId: 0,
        strProCode: "",
        strProName: "",
        strAttValue: "",
        iUnitId: 0,
        strUnitName: "",
        dQty: 0,
        FQty: 0,
        dRate: 0,
        dRatewithTax: 0,
        dItemDisc: 0,
        dCashDisc: 0,
        JobId: "0",
        isItemDiscPer: true,
        isCashDiscPer: true,
        BatchNo: "",
        MftDate: new Date().toISOString().split("T")[0],
        ExpDate: new Date().toISOString().split("T")[0],
        iRowIndex: 0,
        isBatchPro: false,
        isComposPro: false,
        isAttPro: false,
        Remarks: {
          strRemark1: "",
          strRemark2: "",
          strRemark3: "",
          strRemark4: "",
        },
        Calculation: {
          dValue1: 0,
          dValue2: 0,
          dValue3: 0,
          dConstantValue: 0,
          dFinalValue: 0,
          strOp1: "0",
          strOp2: "0",
          strOp3: "0",
        },
        Unit: [],
        AttCol: [],
        AttVal: [],
        Product: {},
        bUpdateStatus: false,
        isDiscChanged: false,
        Reason: "",
        iReasonId: 0,
        arrJobWork: {},
      };
    },
    Modify_Product: (state, { payload }) => {
      const { ProInfo, Unit, Index } = payload;
      state.Product.iProId = ProInfo.proId;
      state.Product.JobId = ProInfo.jobId;
      state.Product.strProCode = ProInfo.proCode;
      state.Product.strProName = ProInfo.proName;
      state.Product.iUnitId = ProInfo.unitId;
      state.Product.strUnitName = ProInfo.unitName;
      state.Product.dQty = ProInfo.qty;
      state.Product.FQty = ProInfo.fQty;
      state.Product.dRate = ProInfo.rateWithoutTax;
      state.Product.dRatewithTax = ProInfo.rateWithTax;
      state.Product.dItemDisc = ProInfo.itemDiscPer + ProInfo.itemDiscAmt;
      state.Product.dCashDisc = ProInfo.cashDiscPer + ProInfo.cashDiscAmt;
      state.Product.isItemDiscPer =
        ProInfo.itemDiscPer >= ProInfo.itemDiscAmt ? true : false;
      state.Product.isCashDiscPer =
        ProInfo.cashDiscPer >= ProInfo.cashDiscAmt ? true : false;
      state.Product.strProAttDTId = "";
      state.Product.strStoAttDTId = "";
      state.Product.strTraAttDTId = "";
      state.Product.iRowIndex = Index;
      state.Product.bUpdateStatus = true;
      state.Product.isComposPro = ProInfo.isComposPro;
      state.Product.WHId = ProInfo.whId;

      if (ProInfo.isBatchPro === 1) {
        state.Product.isBatchPro = ProInfo.isBatchPro;
        state.Product.BatchNo = ProInfo.batchNo;
        state.Product.MftDate = ProInfo.manufactureDate;
        state.Product.ExpDate = ProInfo.expiryDate;
      }

      state.Product.Remarks = {
        strRemark1: ProInfo.remark1,
        strRemark2: ProInfo.remark2,
        strRemark3: ProInfo.remark3,
        strRemark4: ProInfo.remark4,
      };
      state.Product.Calculation = {
        dValue1: ProInfo.value1,
        dValue2: ProInfo.value2,
        dValue3: ProInfo.value3,
        dConstantValue: ProInfo.constantValue,
        dFinalValue: ProInfo.finalValue,
        strOp1: "",
        strOp2: "",
        strOp3: "",
      };
      state.Product.Unit = Unit;
      state.Product.AttCol = [];
      state.Product.AttVal = [];
      state.Product.Product = {};
    },
    Modify_CompositeProduct: (state, { payload }) => {
      const { Product, Unit, iRowIndex } = payload;
      state.Product.iProId = Product.iProId;
      state.Product.strProName = Product.strProName;
      state.Product.strAttValue = Product.strAttValues;
      state.Product.strUnitName = Product.strUnitName;
      state.Product.iUnitId = Product.iUnitId;
      state.Product.dQty = Product.dQty;
      state.Product.iCmpsDTId = Product.iCmpsDTId;
      state.Product.Unit = Unit;
      state.Product.iRowIndex = iRowIndex;
      state.Product.WHId = Product.whId;
      state.Product.bUpdateStatus = true;
    },
    setAttValues: (state, { Data }) => {
      state.Product.strProAttDTId = Data.strProAttDTId;
      state.Product.strStoAttDTId = Data.strStoAttDTId;
      state.Product.strTraAttDTId = Data.strTraAttDTId;
      state.Product.strProName = state.Product.strProName + Data.strAttValue;
    },
    AddProduct: (state, { payload }) => {
      const Data = payload;
      state.Data.arrEntDT = Data.EntDT;
      state.Data.arrEntTax = Data.EntTaxDT;
      state.Data.arrEntAL = Data.EntALDT;
      state.Data.arrEntPenCon = Data.EntPenDT;

      state.Summary.NetAmt = Data.Summary.netAmt;
      state.Summary.Qty = Data.Summary.qty;
      state.Summary.Discount = Data.Summary.totalDiscount;
      state.Summary.GoodsValue = Data.Summary.goodsValue;
      state.Summary.Tax = Data.Summary.totalTax;
      state.Summary.AddLess = Data.Summary.totalAddLess;
      state.Summary.TCSTax = Data.Summary.totalTCSTax;
      state.Summary.RoundOff = Data.Summary.roundOff;

      state.Discount.dBillDisValue = Data.dDiscValue;
      state.Discount.iThDiscId = Data.iThDiscId;
      state.Discount.dThdiscount = Data.dThDiscPer;

      state.Product = {
        iProId: 0,
        WHId: 0,
        TBranchId: 0,
        TWHId: 0,
        iAttGrpId: 0,
        iCmpsDTId: 0,
        strProCode: "",
        strProName: "",
        strAttValue: "",
        iUnitId: 0,
        strUnitName: "",
        dQty: 0,
        FQty: 0,
        dRate: 0,
        dRatewithTax: 0,
        dItemDisc: 0,
        dCashDisc: 0,
        JobId: "0",
        isItemDiscPer: true,
        isCashDiscPer: true,
        BatchNo: "",
        MftDate: new Date().toISOString().split("T")[0],
        ExpDate: new Date().toISOString().split("T")[0],
        iRowIndex: 0,
        isBatchPro: false,
        isComposPro: false,
        isAttPro: false,
        Remarks: {
          strRemark1: "",
          strRemark2: "",
          strRemark3: "",
          strRemark4: "",
        },
        Calculation: {
          dValue1: 0,
          dValue2: 0,
          dValue3: 0,
          dConstantValue: 0,
          dFinalValue: 0,
          strOp1: "0",
          strOp2: "0",
          strOp3: "0",
        },
        Unit: [],
        AttCol: [],
        AttVal: [],
        Product: {},
        bUpdateStatus: false,
        isDiscChanged: false,
        Reason: "",
        iReasonId: 0,
        arrJobWork: state.Product.arrJobWork,
      };
    },
    Add_StockTransProduct: (state, { payload }) => {
      const Data = payload;
      state.Data.arrEntDT = Data.EntDT;
      state.Summary.NetAmt = Data.Summary.netAmt;
      state.Summary.Qty = Data.Summary.qty;
      state.Summary.Discount = Data.Summary.totalDiscount;
      state.Summary.GoodsValue = Data.Summary.goodsValue;
      state.Summary.RoundOff = Data.Summary.roundOff;
      state.Product = {
        iProId: 0,
        WHId: 0,
        TBranchId: 0,
        TWHId: 0,
        iAttGrpId: 0,
        iCmpsDTId: 0,
        strProCode: "",
        strProName: "",
        strAttValue: "",
        iUnitId: 0,
        strUnitName: "",
        dQty: 0,
        FQty: 0,
        dRate: 0,
        dRatewithTax: 0,
        dItemDisc: 0,
        dCashDisc: 0,
        JobId: "0",
        isItemDiscPer: true,
        isCashDiscPer: true,
        BatchNo: "",
        MftDate: new Date().toISOString().split("T")[0],
        ExpDate: new Date().toISOString().split("T")[0],
        iRowIndex: 0,
        isBatchPro: false,
        isComposPro: false,
        isAttPro: false,
        Remarks: {
          strRemark1: "",
          strRemark2: "",
          strRemark3: "",
          strRemark4: "",
        },
        Calculation: {
          dValue1: 0,
          dValue2: 0,
          dValue3: 0,
          dConstantValue: 0,
          dFinalValue: 0,
          strOp1: "0",
          strOp2: "0",
          strOp3: "0",
        },
        Unit: [],
        AttCol: [],
        AttVal: [],
        Product: {},
        bUpdateStatus: false,
        isDiscChanged: false,
        Reason: "",
        iReasonId: 0,
        arrJobWork: state.Product.arrJobWork,
      };
    },
    Remove_product: (state, { payload }) => {
      const Data = payload;
      state.Data.arrEntDT = Data.EntDT;
      state.Data.arrEntTax = Data.EntTaxDT;
      state.Data.arrEntAL = Data.EntALDT;
      state.Data.arrEntPenCon = Data.EntPenDT;

      state.Summary.NetAmt = Data.Summary.netAmt;
      state.Summary.Qty = Data.Summary.qty;
      state.Summary.Discount = Data.Summary.totalDiscount;
      state.Summary.GoodsValue = Data.Summary.goodsValue;
      state.Summary.Tax = Data.Summary.totalTax;
      state.Summary.AddLess = Data.Summary.totalAddLess;
      state.Summary.TCSTax = Data.Summary.totalTCSTax;
      state.Summary.RoundOff = Data.Summary.roundOff;

      state.Discount.dBillDisValue = Data.dDiscValue;
      state.Discount.iThDiscId = Data.iThDiscId;
      state.Discount.dThdiscount = Data.dThDiscPer;
      state.Product = {
        iProId: 0,
        WHId: 0,
        TBranchId: 0,
        TWHId: 0,
        iAttGrpId: 0,
        iCmpsDTId: 0,
        strProCode: "",
        strProName: "",
        strAttValue: "",
        iUnitId: 0,
        strUnitName: "",
        dQty: 0,
        FQty: 0,
        dRate: 0,
        dRatewithTax: 0,
        dItemDisc: 0,
        dCashDisc: 0,
        JobId: "0",
        isItemDiscPer: true,
        isCashDiscPer: true,
        BatchNo: "",
        MftDate: new Date().toISOString().split("T")[0],
        ExpDate: new Date().toISOString().split("T")[0],
        iRowIndex: 0,
        isBatchPro: false,
        isComposPro: false,
        isAttPro: false,
        Remarks: {
          strRemark1: "",
          strRemark2: "",
          strRemark3: "",
          strRemark4: "",
        },
        Calculation: {
          dValue1: 0,
          dValue2: 0,
          dValue3: 0,
          dConstantValue: 0,
          dFinalValue: 0,
          strOp1: "0",
          strOp2: "0",
          strOp3: "0",
        },
        Unit: [],
        AttCol: [],
        AttVal: [],
        Product: {},
        bUpdateStatus: false,
        isDiscChanged: false,
        Reason: "",
        iReasonId: 0,
        arrJobWork: {},
      };
    },
    setRootCalculation: (state, action) => {
      state.Product.Calculation = action.payload;
    },
    setItemRemark: (state, action) => {
      state.Product.Remarks = action.payload;
    },
    Select_ReturnProduct: (state, { payload }) => {
      const Data = payload;
      state.Data.arrEntDT = Data.EntDT;
      state.Data.arrEntTax = Data.EntTaxDT;
      state.Data.arrEntAL = Data.EntALDT;

      state.Summary.NetAmt = Data.Summary.netAmt;
      state.Summary.Qty = Data.Summary.qty;
      state.Summary.Discount = Data.Summary.totalDiscount;
      state.Summary.GoodsValue = Data.Summary.goodsValue;
      state.Summary.Tax = Data.Summary.totalTax;
      state.Summary.AddLess = Data.Summary.totalAddLess;
      state.Summary.TCSTax = Data.Summary.totalTCSTax;
      state.Summary.RoundOff = Data.Summary.roundOff;
    },
    Update_DiscountCalculation: (state, { payload }) => {
      const { Data, Disc } = payload;
      state.Data.arrEntDT = Data.EntDT;
      state.Data.arrEntTax = Data.EntTaxDT;
      state.Data.arrEntAL = Data.EntALDT;

      state.Summary.NetAmt = Data.Summary.netAmt;
      state.Summary.Qty = Data.Summary.qty;
      state.Summary.Discount = Data.Summary.totalDiscount;
      state.Summary.GoodsValue = Data.Summary.goodsValue;
      state.Summary.Tax = Data.Summary.totalTax;
      state.Summary.AddLess = Data.Summary.totalAddLess;
      state.Summary.TCSTax = Data.Summary.totalTCSTax;
      state.Summary.RoundOff = Data.Summary.roundOff;

      state.Discount.isDiscChanged = false;
      state.Discount.iThDiscId = Data.iThDiscId;
      state.Discount.dThdiscount = Data.dThDiscPer;

      if (Disc === "BillDicPer" || Disc === "BillDicAmt") {
        state.Discount.dBillDisPer = Data.dDiscPer;
        state.Discount.dBillDisAmt = Data.dDiscAmt;
        state.Discount.dBillDisValue = Data.dDiscValue;
      } else {
        state.Discount.dALBillDisPer = Data.dDiscPer;
        state.Discount.dALBillDisAmt = Data.dDiscAmt;
        state.Discount.dALBillDisValue = Data.dDiscValue;
      }
    },
    Discount_onchanged: (state, { payload }) => {
      state.Discount.isDiscChanged = payload;
    },
    AddLess_Onchange: (state, { payload }) => {
      state.AddLess[payload.Name] = payload.Value;
    },
    POSAddLess_Onchange: (state, { payload }) => {
      state.AddLess.iSerId = payload.iId;
      state.AddLess.ServName = payload.strName;
    },
    AddLess_LostFocus: (state, { payload }) => {
      state.Product.dServAmt =
        Number(payload.target.value) === 0
          ? Number((0).toFixed(2))
          : Number(payload.target.value);
    },
    Select_ServiceVendore: (state, { payload }) => {
      state.AddLess.iSerVenId = payload.partyId;
      state.AddLess.VenCode = payload.partyCode;
      state.AddLess.VenName = payload.partyName;
    },
    Update_ServiceVendor: (state, { payload }) => {
      state.AddLess.arrEntVendore = payload.EntVendore;
      state.AddLess.iSerVenId = payload.VenId;
    },
    Update_Service: (state, { payload }) => {
      state.AddLess.arrEntService = payload.EntService;
      state.AddLess.iSerId = payload.ServId;
    },
    AddUpdate_AddLess: (state, { payload }) => {
      const Data = payload;
      state.Data.arrEntDT = Data.EntDT;
      state.Data.arrEntTax = Data.EntTaxDT;
      state.Data.arrEntAL = Data.EntALDT;

      state.Summary.NetAmt = Data.Summary.netAmt;
      state.Summary.Qty = Data.Summary.qty;
      state.Summary.Discount = Data.Summary.totalDiscount;
      state.Summary.GoodsValue = Data.Summary.goodsValue;
      state.Summary.Tax = Data.Summary.totalTax;
      state.Summary.AddLess = Data.Summary.totalAddLess;
      state.Summary.TCSTax = Data.Summary.totalTCSTax;
      state.Summary.RoundOff = Data.Summary.roundOff;
      state.Discount.dALBillDisValue = Data.dDiscValue;

      state.AddLess = {
        iSerVenId: 0,
        VenCode: "",
        VenName: "",
        iRowIndex: 0,
        iSerId: 0,
        ServName: "",
        dServAmt: (0).toFixed(2),
        UpdateStatus: false,
      };
    },
    Clear_AddLess: (state) => {
      state.AddLess = {
        iSerVenId: 0,
        VenCode: "",
        VenName: "",
        iRowIndex: 0,
        iSerId: 0,
        ServName: "",
        dServAmt: (0).toFixed(2),
        UpdateStatus: false,
      };
    },
    Modify_AddLess: (state, { payload }) => {
      const { Data, Index } = payload;
      state.AddLess.iRowIndex = Index;
      state.AddLess.iSerVenId = Data.venId;
      state.AddLess.iSerId = Data.serId;
      state.AddLess.ServName = Data.serName;
      state.AddLess.dServAmt = Number(Data.rateWithoutTax).toFixed(2);
      state.AddLess.UpdateStatus = true;
    },
    Remove_AddLess: (state, { payload }) => {
      const Data = payload;
      state.Data.arrEntDT = Data.EntDT;
      state.Data.arrEntTax = Data.EntTaxDT;
      state.Data.arrEntAL = Data.EntALDT;

      state.Summary.NetAmt = Data.Summary.netAmt;
      state.Summary.Qty = Data.Summary.qty;
      state.Summary.Discount = Data.Summary.totalDiscount;
      state.Summary.GoodsValue = Data.Summary.goodsValue;
      state.Summary.Tax = Data.Summary.totalTax;
      state.Summary.AddLess = Data.Summary.totalAddLess;
      state.Summary.TCSTax = Data.Summary.totalTCSTax;
      state.Summary.RoundOff = Data.Summary.roundOff;
    },
    Modify_Vouchers: (state, { payload }) => {
      const { Data, VouchId } = payload;
      const EntHT = Data.EntHT[0];

      state.Header.iVouchId = VouchId;
      state.Header.iNumSeqNo = EntHT.iNumSeqNo;
      state.Header.strPrefix = EntHT.strPrefix;
      state.Header.strCompCode = EntHT.strCompCode;
      state.Header.strFinanFrom = EntHT.strFinanFrom;
      state.Header.strTransNo = EntHT.strTransNo;
      state.Header.strVouchNo = EntHT.strVouchNo;
      state.Header.strVouchDate = EntHT.strVouchDate;

      state.Header.strRefNum1 = EntHT.strRefNum1;
      state.Header.strRefNum2 = EntHT.strRefNum2;
      state.Header.strRefNum3 = EntHT.strRefNum3;
      state.Header.strRefNum4 = EntHT.strRefNum4;
      state.Header.dpRefNum1Date = EntHT.dpRefNum1Date;
      state.Header.dpRefNum2Date = EntHT.dpRefNum2Date;
      state.Header.dpRefNum3Date = EntHT.dpRefNum3Date;
      state.Header.dpRefNum4Date = EntHT.dpRefNum4Date;
      state.Header.strRemark1 = EntHT.strRemark1;
      state.Header.strRemark2 = EntHT.strRemark2;
      state.Header.strRemark3 = EntHT.strRemark3;
      state.Header.strRemark4 = EntHT.strRemark4;
      state.Header.iDMId = EntHT.iDMId;

      switch (state.Common.iScrId) {
        case 71:
        case 72:
        case 74:
        case 75:
          state.Header.strConvertDCVouch = EntHT.strConvertDCVouch;
          state.Header.strPartyDCVouch = EntHT.strPartyDCVouch;
          break;
        case 73:
        case 76:
          state.Header.strInWDCVouchers = EntHT.inwDCConvertedVouchers;
          state.Header.strConvertDCVouch = EntHT.outWDCConvertedVouchers;
          state.Header.strPartyDCVouch = EntHT.partyConvertedVouchers;
          state.Header.iBillTypeId = EntHT.iBillTypeId;
          state.Header.iTaxRelId = EntHT.iTaxTypeId;
          state.Common.iALBillDiscId = EntHT.iAddLessBillDiscId;
          state.Common.iTaxCalcOnId = EntHT.iTaxCalcOnId;

          state.Header.strValidity = EntHT.strValidity;
          state.Header.dpValDateUpto = EntHT.dpValDateUpto;
          state.Header.strPayTerm = EntHT.strPayTerm;
          state.Header.dpPayTermDateUpto = EntHT.dpPayTermDateUpto;
          state.Header.strDeliTerm = EntHT.strDeliTerm;
          state.Header.dpDelTermDateUpto = EntHT.dpDelTermDateUpto;

          state.Discount.dBillDisPer = EntHT.dTBillDiscPer;
          state.Discount.dBillDisAmt = EntHT.dTBillDiscAmt;
          state.Discount.dBillDisValue = EntHT.dTBillDiscValue;
          state.Discount.dALBillDisPer = EntHT.dTAddLessDiscPer;
          state.Discount.dALBillDisAmt = EntHT.dTAddLessDiscAmt;
          state.Discount.dALBillDisValue = EntHT.dTAddLessDiscValue;
          break;
        default:
          state.Header.iBillTypeId = EntHT.iBillTypeId;
          state.Header.iTaxRelId = EntHT.iTaxTypeId;
          state.Header.strConvertDCVouch = EntHT.strConvertDCVouch;
          state.Header.strPartyDCVouch = EntHT.strPartyDCVouch;
          state.Common.iALBillDiscId = EntHT.iAddLessBillDiscId;
          state.Common.iTaxCalcOnId = EntHT.iTaxCalcOnId;

          state.Header.strValidity = EntHT.strValidity;
          state.Header.dpValDateUpto = EntHT.dpValDateUpto;
          state.Header.strPayTerm = EntHT.strPayTerm;
          state.Header.dpPayTermDateUpto = EntHT.dpPayTermDateUpto;
          state.Header.strDeliTerm = EntHT.strDeliTerm;
          state.Header.dpDelTermDateUpto = EntHT.dpDelTermDateUpto;
          state.Header.strEComGstNo = EntHT.strEComGstNo;

          state.Discount.dBillDisPer = EntHT.dTBillDiscPer;
          state.Discount.dBillDisAmt = EntHT.dTBillDiscAmt;
          state.Discount.dBillDisValue = EntHT.dTBillDiscValue;
          state.Discount.dALBillDisPer = EntHT.dTAddLessDiscPer;
          state.Discount.dALBillDisAmt = EntHT.dTAddLessDiscAmt;
          state.Discount.dALBillDisValue = EntHT.dTAddLessDiscValue;
          state.Discount.iThDiscId = EntHT.iThDiscId;
          state.Discount.dThdiscount = EntHT.dThDiscPer;
          break;
      }

      state.Header.strTransport = EntHT.strTransport;
      state.Header.strVehicle = EntHT.strVehicle;
      state.Header.dpDespatchDate = EntHT.dpDespatchDate;
      state.Header.strLRNNumber = EntHT.strLRNNumber;
      state.Header.dpLRNoDate = EntHT.dpLRNoDate;
      state.Header.strAgent = EntHT.strAgent;
      state.Header.strReference = EntHT.strReference;
      state.Header.strDestination = EntHT.strDestination;

      state.Header.strPartyName = EntHT.strPartyName;
      state.Header.iPartyId = EntHT.iPartyId;
      state.Header.PartyAddId = EntHT.billAddId;
      state.Header.strPartyAdd = EntHT.billAdd;
      state.Header.ShippingAddId = EntHT.shippAddId;
      state.Header.strShippingAdd = EntHT.shippAdd;
      state.Header.BtnDisable = !EntHT.isAccess;

      state.Data.arrEntDT = Data.EntDT;
      state.Data.arrEntTax = Data.EntTax;
      state.Data.arrEntPenCon = Data.EntPenCon;
      state.Data.arrEntAL = Data.EntAL;
      state.Data.arrEntDTHelp = Data.EntDTHelp;

      state.Summary.NetAmt = Data.Summary.netAmt;
      state.Summary.Qty = Data.Summary.qty;
      state.Summary.Discount = Data.Summary.totalDiscount;
      state.Summary.GoodsValue = Data.Summary.goodsValue;
      state.Summary.Tax = Data.Summary.totalTax;
      state.Summary.AddLess = Data.Summary.totalAddLess;
      state.Summary.TCSTax = Data.Summary.totalTCSTax;
      state.Summary.RoundOff = Data.Summary.roundOff;
    },
    Clear_Transaction: (state) => {
      state.Common = {
        Controller: state.Common.Controller,
        iScrId: state.Common.iScrId,
        strScrType: state.Common.strScrType,
        strScrName: "",

        VenTypeId: 0,
        PartyScrId: 0,
        ColName: "",
        PartyScrName: "",
        PartyPlaceHolder: "",
        JSScrId: 0,
        JSType: "",

        iTaxCalcOnId: 0,
        iALBillDiscId: 0,
        IsMultiWarehouse: false,
        MinRow: 5,
        iPrintTempId: 0,

        strSetCurrentStatus: "",

        EnableReceipt: false,
        EnablePayment: false,
        IsPrint: false,
        IsTinder: false,
        IsHideBill: false,
        IsHideJob: true,
        IsHideInvDc: true,
        IsJobWorkDc: false,

        IsVenSearch: false,
        IsProSearch: false,
        IsVenCreation: false,
        IsAddress: false,
        IsVouchNo: false,
        IsProduct: false,
        IsProRemark: false,
        IsProCal: false,
        IsConvertion: false,
        IsReceipt: false,
        IsPayment: false,
        IsHideParty: false,
        IsPendinCon: false,
        IsTransView: false,
        IsEmail: false,
        IsWhatsApp: false,
        IsEInvoice: false,
        IsPrintSett: false,
        IsPrintShow: false,
        IsReturnPro: false,
        IsQtyReadonly: false,

        BillType: [],
        TaxType: [],
        DelMode: [],
        arrValidation: [],
        arrtransper: [],
        arrfieldPer: [],
        arrTableCol: [],
        arrtaborder: {
          txtPartyName: "",
          txtBillAddress: "",
          txtShippingAdd: "",
          cbBillType: "",
          cbTaxType: "",
          txtTransNo: "",
          txtInvNo: "",
          dpInvDate: "",

          txtTransport: "",
          txtDespatch: "",
          dpDespatchDate: "",
          txtDestination: "",
          txtVehicle: "",
          cbDeliMode: "",
          txtRefName: "",
          txtAgentName: "",
          txtValidity: "",
          dpValidityUpto: "",
          txtPayTerm: "",
          dpPayTermUpto: "",
          txtDeliTerm: "",
          dpDeliTermUpto: "",

          txtAddLessDiscPer: "",
          txtAddLessDiscAmt: "",
          txtAddLessDiscValue: "",
          txtBillDiscPer: "",
          txtBillDiscAmt: "",
          txtBillDiscValue: "",

          btnNew: "",
          btnModify: "",
          btnSave: "",
          btnClear: "",
          btnView: "",
          btnExportImport: "",
          btnPrint: "",
          btnCancel: "",
          btnClose: "",
          btnPrintSettings: "",
          btnHelp: "",
          txtRemark1: "",
          txtRemark2: "",
          txtRemark3: "",
          txtRemark4: "",
          txtLRNumber: "",
          dpLRDate: "",

          TabParty: "",
          TabRefVouchers: "",
          TabVoucher: "",
          TabDelivery: "",
          TabTranport: "",
          TabValidity: "",
          TabRemarks: "",
          TabSummary: "",
          TabTax: "",
          TabDiscount: "",
          TabAddLess: "",
          TabName: "Party",

          txtRefNum1: "",
          txtRefNum1Date: "",
          txtRefNum2: "",
          txtRefNum2Date: "",
          txtRefNum3: "",
          txtRefNum3Date: "",
          txtRefNum4: "",
          txtRefNum4Date: "",
          btnRefresh: "",

          producttable: "",
          txtProductName: "",
          txtQty: "",
          cbUnit: "",
          txtRate: "",
          txtDiscount: "",
          btnAdd: "",
          discType: "",
        },
        Headertab: [
          { TabId: 0, Index: 0, Show: true, tabShow: true },
          { TabId: 1, Index: 0, Show: false, tabShow: false },
          { TabId: 2, Index: 0, Show: false, tabShow: false },
          { TabId: 3, Index: 0, Show: false, tabShow: false },
          { TabId: 4, Index: 0, Show: false, tabShow: false },
          { TabId: 5, Index: 0, Show: false, tabShow: false },
          { TabId: 6, Index: 0, Show: false, tabShow: false },
        ],
        Summarytab: [true, false, false],
        Commontab: [true, false, false, false],
        TransPer: {
          IsProCode: false,
          IsItemDisc: false,
          IsCashDisc: false,
          IsFQty: false,
        },
        btnSave: true,
        btnView: true,
        btnDelete: true,
        btnConvert: true,
      };
      state.Header = {
        iNumSeqNo: "",
        strPrefix: "",
        strCompCode: "",
        strFinanFrom: "",
        strTransNo: "",
        strVouchNo: "",
        isManualCode: false,

        iVouchId: 0,
        SelectedVouchId: 0,
        iBillTypeId: 0,
        iDMId: 0,
        BtnDisable: false,
        strVouchDate: new Date().toISOString().split("T")[0],
        strPartyDCVouch: "",
        strConvertDCVouch: "",

        iPartyId: 0,
        strPartyName: "",
        strPartyAdd: "",
        strShippingAdd: "",
        iTaxRelId: 0,
        arrAddress: [],

        strRefNum1: "",
        disablebtn: false,
        dpRefNum1Date: new Date().toISOString().split("T")[0],
        strRefNum2: "",
        dpRefNum2Date: new Date().toISOString().split("T")[0],
        strRefNum3: "",
        dpRefNum3Date: new Date().toISOString().split("T")[0],
        strRefNum4: "",
        dpRefNum4Date: new Date().toISOString().split("T")[0],

        strRemark1: "",
        strRemark2: "",
        strRemark3: "",
        strRemark4: "",

        strValidity: "0",
        dpValDateUpto: new Date().toISOString().split("T")[0],
        strPayTerm: "0",
        dpPayTermDateUpto: new Date().toISOString().split("T")[0],
        strDeliTerm: "0",
        dpDelTermDateUpto: new Date().toISOString().split("T")[0],

        strEComGstNo: "",
        strTransport: "",
        strVehicle: "",
        strDespatch: "",
        dpDespatchDate: new Date().toISOString().split("T")[0],
        strLRNNumber: "",
        dpLRNoDate: new Date().toISOString().split("T")[0],
        strAgent: "",
        strReference: "",
        strDestination: "",
      };
      state.Product = {
        iProId: 0,
        WHId: 0,
        TBranchId: 0,
        TWHId: 0,
        iAttGrpId: 0,
        iCmpsDTId: 0,
        strProCode: "",
        strProName: "",
        strAttValue: "",
        iUnitId: 0,
        strUnitName: "",
        dQty: 0,
        FQty: 0,
        dRate: 0,
        dRatewithTax: 0,
        dItemDisc: 0,
        dCashDisc: 0,
        JobId: "0",
        isItemDiscPer: true,
        isCashDiscPer: true,
        BatchNo: "",
        MftDate: new Date().toISOString().split("T")[0],
        ExpDate: new Date().toISOString().split("T")[0],
        iRowIndex: 0,
        isBatchPro: false,
        isComposPro: false,
        isAttPro: false,
        Remarks: {
          strRemark1: "",
          strRemark2: "",
          strRemark3: "",
          strRemark4: "",
        },
        Calculation: {
          dValue1: 0,
          dValue2: 0,
          dValue3: 0,
          dConstantValue: 0,
          dFinalValue: 0,
          strOp1: "0",
          strOp2: "0",
          strOp3: "0",
        },
        Unit: [],
        AttCol: [],
        AttVal: [],
        Product: {},
        bUpdateStatus: false,
        isDiscChanged: false,
        Reason: "",
        iReasonId: 0,
        arrJobWork: {},
      };
      state.Data = {
        arrEntDT: [],
        arrEntDTCol: [],
        arrEntDTHelp: [],
        arrEntTax: [],
        arrEntAL: [],
        arrEntPenCon: [],
        arrEntConDT: [],
      };
      state.Summary = {
        NetAmt: 0,
        Qty: 0,
        GoodsValue: 0,
        Discount: 0,
        Tax: 0,
        AddLess: 0,
        TCSTax: 0,
        RoundOff: 0,
      };
      state.Discount = {
        dBillDisPer: 0,
        dBillDisAmt: 0,
        dBillDisValue: 0,
        dALBillDisPer: 0,
        dALBillDisAmt: 0,
        dALBillDisValue: 0,
        isDiscChanged: false,
      };
      state.AddLess = {
        iSerVenId: 0,
        VenCode: "",
        VenName: "",
        iRowIndex: 0,
        iSerId: 0,
        dServAmt: (0).toFixed(2),
        UpdateStatus: false,
        arrVendore: [],
        arrService: [],
      };
      sessionStorage.removeItem("VoucherId");
      sessionStorage.removeItem("VoucherType");
    },
    Show_tabs: (state, { payload }) => {
      const { arrtabbtn, tabtype } = payload;
      switch (tabtype) {
        case "transht":
          state.Common.Headertab = arrtabbtn;
          break;
        case "transsum":
          state.Common.Summarytab = arrtabbtn;
          break;
        default:
          state.Common.Commontab = arrtabbtn;
          break;
      }
    },
    Show_hidetranstabs: (state, { payload }) => {
      const { arrtabbtn, minrow, tabtype } = payload;
      switch (tabtype) {
        case "transht":
          state.Common.Headertab = arrtabbtn;
          state.Common.MinRow = minrow;
          break;
        case "transsum":
          state.Common.Summarytab = arrtabbtn;
          state.Common.MinRow = minrow;
          break;
        default:
          state.Common.Commontab = arrtabbtn;
          break;
      }
    },
    ShowHide_Popup: (state, { payload }) => {
      const EntPopup = [
        "IsVenSearch",
        "IsProSearch",
        "IsPosPro",
        "IsBatchPro",
        "IsAttPro",
        "IsVenCreatio",
        "IsAddress",
        "IsVouchNo",
        "IsProduct",
        "IsProRemark ",
        "IsProCal",
        "IsConvertion",
        "IsReceipt",
        "IsPayment",
        "IsHideParty",
        "IsPendinCon",
        "IsTransView",
        "IsEmail",
        "IsWhatsApp",
        "IsEInvoice",
        "IsPrintSett",
        "IsPrintShow",
        "IsReturnPro",
        "IsRightMenu",
        "IsSession",
        "IsPOSCustomer",
        "IsEmployee",
      ];
      EntPopup.forEach((Isshow) => {
        if (Isshow !== payload && Isshow !== payload.isshow)
          state.Common[Isshow] = false;
      });
      if (payload.Scrtype) {
        switch (payload.Scrtype) {
          case "Purchase":
            state.Common.JSScrId = 3;
            state.Common.JSType = "Purchase";
            state.Common.PartyScrName = "Supplier Master";
            state.Common.PartyPlaceHolder = "Enter Supplier Name";
            state.Common.IsHideParty = false;
            break;
          case "SerVen":
            state.Common.JSScrId = 3;
            state.Common.JSType = "SerVen";
            state.Common.PartyScrName = "Service Vendor";
            state.Common.PartyPlaceHolder = "Enter Vendore Name";
            state.Common.IsHideParty = false;
            break;
          case "Jobwork":
            state.Common.JSScrId = 50;
            state.Common.JSType = "Jobwork";
            state.Common.PartyScrName = "Vendor Master";
            state.Common.PartyPlaceHolder = "Enter Vendor Name";
            state.Common.IsHideParty = false;
            break;
          case "Job":
            state.Common.JSScrId = 51;
            state.Common.JSType = "Job";
            state.Common.PartyScrName = "JobWork Master";
            state.Common.PartyPlaceHolder = "Enter Job Name";
            state.Common.IsHideParty = true;
            break;
          case "Service":
            state.Common.JSScrId = 59;
            state.Common.JSType = "Service";
            state.Common.PartyScrName = "Service Master";
            state.Common.PartyPlaceHolder = "Enter Service Name";
            state.Common.IsHideParty = true;
            break;
          default:
            state.Common.JSScrId = 2;
            state.Common.JSType = "Sales";
            state.Common.PartyScrName = "Customer Master";
            state.Common.PartyPlaceHolder = "Enter Customer Name";
            state.Common.IsHideParty = false;
            break;
        }
        state.Common[payload.isshow] = !state.Common[payload.isshow];
      } else if (payload.default === true || payload.default === false) {
        state.Common[payload.isshow] = payload.default;
      } else {
        state.Common[payload] = !state.Common[payload];
      }
    },
    Selecte_Voucher: (state, { payload }) => {
      state.Header.SelectedVouchId = payload;
    },
    Select_ProducttoConvert: (state, { payload }) => {
      state.Data.arrEntConDT = payload;
      state.Common.IsPendinCon = false;
      state.Common.IsConvertion = true;
    },
    StockAdjutment_Onchange: (state, { payload }) => {
      state.Product.Reason = payload;
    },
    StockQty_Onchange: (state, { payload }) => {
      state.Product.dQty = payload;
    },
    Update_AddresType: (state, { payload }) => {
      state.Header.AddressType = payload;
    },
    Update_Addres: (state, { payload }) => {
      if (state.Header.AddressType === "Shipp") {
        state.Header.strShippingAdd = payload.Address;
        state.Header.ShippingAddId = payload.AddId;
      } else {
        state.Header.strPartyAdd = payload.Address;
        state.Header.PartyAddId = payload.AddId;
      }
    },
    Update_POStemplate: (state, action) => {
      state.Common.IsTabletMode = action.payload;
    },
    Update_ProductVal: (state, { payload }) => {
      state.Data.arrEntDT = payload;
    },
    Clear_DeiveryParems: (state) => {
      state.Header.strEComGstNo = "";
      state.Header.strTransport = "";
      state.Header.strVehicle = "";
      state.Header.strDespatch = "";
      state.Header.dpDespatchDate = new Date().toISOString().split("T")[0];
      state.Header.strLRNNumber = "";
      state.Header.dpLRNoDate = new Date().toISOString().split("T")[0];
      state.Header.strAgent = "";
      state.Header.strReference = "";
      state.Header.strDestination = "";
    },
    Clear_RemarkParems: (state) => {
      state.Header.strRemark1 = "";
      state.Header.strRemark2 = "";
      state.Header.strRemark3 = "";
      state.Header.strRemark4 = "";
    },
    Clear_EmployeeParems: (state) => {
      state.Employee = {
        iEmpId: 0,
        strEmployeeName: "",
        iRoleId: 0,
        strEmpRole: "",
        dRewardPoint: 0,
      };
    },
    Apply_CouponDiscount: (state, { payload }) => {
      state.Discount.iCouponId = payload.iCouponId;
      state.Discount.strCouponCode = payload.strCouponCode;
      state.Discount.dCouponDiscPer = payload.dCouponDiscPer;
      state.Discount.dCouponDiscAmt = payload.dCouponDiscAmt;
    },
    Apply_RewardDiscount: (state, { payload }) => {
      state.Discount.dRewardPointper = payload.dRewardPointper;
      state.Discount.dRewardPointAmt = payload.dRewardPointAmt;
    },
    Clear_POSCustomer: (state) => {
      state.Customer = {
        PartyId: 0,
        PartyName: "",
        PartyCode: "",
        AliasName: "",
        Address1: "",
        Address2: "",
        Address3: "",
        Address4: "",
        State: "Tamil Nadu",
        Country: "India",
        Pincode: "",
        Mobile: "",
        Phone: "",
        Email: "",
        GST: "",
        Remark: "",
        RoyaltyPoint: 0,
        ShippAdd: "",
        IsSameAdd: true,
        Status: "",
        CreatedBy: 0,
        RewardType: state.Customer.RewardType,
      };
    },
    Close_Session: (state) => {
      state.Common.iSessionId = 0;
      state.Common.TStartTime = "";
      state.Common.TDateTime = "";
    },
    Load_NewSession: (state, { payload }) => {
      state.Common.iSessionId = 0;
      state.Common.TStartTime = "";
      state.Common.TDateTime = "";
      state.Common.EntEmployee = payload.Employee;
      state.Common.iCounterId = payload.Counter;
    },
    Update_BillType: (state, action) => {
      state.Header.iBillTypeId = action.payload;
    },
    Modify_InventoryProduct: (state, { payload }) => {
      const { ProInfo, Unit, Index } = payload;
      state.Product.iProId = ProInfo.proId;
      state.Product.JobId = ProInfo.jobId;
      state.Product.strProCode = ProInfo.proCode;
      state.Product.strProName = ProInfo.proName;
      state.Product.iUnitId = ProInfo.unitId;
      state.Product.strUnitName = ProInfo.unitName;
      state.Product.dQty = ProInfo.qty;
      state.Product.FQty = ProInfo.fQty;
      state.Product.dRate = ProInfo.rateWithoutTax;
      state.Product.dRatewithTax = ProInfo.rateWithTax;
      state.Product.dItemDisc = ProInfo.itemDiscPer + ProInfo.itemDiscAmt;
      state.Product.dCashDisc = ProInfo.cashDiscPer + ProInfo.cashDiscAmt;
      state.Product.isItemDiscPer =
        ProInfo.itemDiscPer >= ProInfo.itemDiscAmt ? true : false;
      state.Product.isCashDiscPer =
        ProInfo.cashDiscPer >= ProInfo.cashDiscAmt ? true : false;
      state.Product.strProAttDTId = "";
      state.Product.strStoAttDTId = "";
      state.Product.strTraAttDTId = "";
      state.Product.iRowIndex = Index;
      state.Product.bUpdateStatus = true;
      state.Product.isComposPro = ProInfo.isComposPro;
      state.Product.WHId = ProInfo.whId;
      state.Product.TWHId = ProInfo.twhId;
      state.Product.TBranchId = ProInfo.tBranchId;
      state.Product.Reason = ProInfo.reason;
      state.Product.iReasonId = ProInfo.reasonId;
      state.Product.TBranchId = ProInfo.tBranchId;

      if (ProInfo.isBatchPro === 1) {
        state.Product.isBatchPro = ProInfo.isBatchPro;
        state.Product.BatchNo = ProInfo.batchNo;
        state.Product.MftDate = ProInfo.manufactureDate;
        state.Product.ExpDate = ProInfo.expiryDate;
      }

      state.Product.Remarks = {
        strRemark1: ProInfo.remark1,
        strRemark2: ProInfo.remark2,
        strRemark3: ProInfo.remark3,
        strRemark4: ProInfo.remark4,
      };
      state.Product.Unit = Unit;
      state.Product.AttCol = [];
      state.Product.AttVal = [];
      state.Product.Product = {};
    },
    Modify_StockTransfer: (state, { payload }) => {
      const { entHT, entDT } = payload;
      let Data = entHT[0];
      state.Header.iVouchId = Data.iStoTraId;
      state.Header.iNumSeqNo = Data.iNumSeqNo;
      state.Header.strPrefix = Data.strPrefix;
      state.Header.strCompCode = Data.strCompCode;
      state.Header.strFinanFrom = Data.strFinanFrom;
      state.Header.strTransNo = Data.strTransNo;
      state.Header.strVouchNo = Data.strVouchNo;
      state.Header.strVouchDate = Data.strVouchDate;
      state.Header.strRemark1 = Data.strRemark1;
      state.Header.strRemark2 = Data.strRemark2;
      state.Header.strRemark3 = Data.strRemark3;
      state.Header.strRemark4 = Data.strRemark4;

      state.Summary.NetAmt = Data.dNetAmt;
      state.Summary.Qty = Data.dTQty;
      state.Summary.GoodsValue = Data.dTGoodsValue;
      state.Summary.RoundOff = Data.dRoundOff;

      state.Header.isManualCode = false;
      state.Data.arrEntDT = entDT;
    },
    Modify_StockReceive: (state, { payload }) => {
      const { entHT, entDT, entPenCon } = payload;
      let Data = entHT[0];
      state.Header.iVouchId = Data.iStoRecId;
      state.Header.iNumSeqNo = Data.iNumSeqNo;
      state.Header.strPrefix = Data.strPrefix;
      state.Header.strCompCode = Data.strCompCode;
      state.Header.strFinanFrom = Data.strFinanFrom;
      state.Header.strTransNo = Data.strTransNo;
      state.Header.strVouchNo = Data.strVouchNo;
      state.Header.strVouchDate = Data.strVouchDate;
      state.Header.strRemark1 = Data.strRemark1;
      state.Header.strRemark2 = Data.strRemark2;
      state.Header.strRemark3 = Data.strRemark3;
      state.Header.strRemark4 = Data.strRemark4;
      state.Header.strConvertDCVouch = Data.strConvertVoucher;

      state.Summary.NetAmt = Data.dNetAmt;
      state.Summary.Qty = Data.dTQty;
      state.Summary.GoodsValue = Data.dTGoodsValue;
      state.Summary.RoundOff = Data.dRoundOff;

      state.Header.isManualCode = false;
      state.Data.arrEntDT = entDT;
      state.Data.arrEntPenCon = entPenCon;
    },
    Modify_StockTransAdjustment: (state, { payload }) => {
      const { entHT, entDT, entPenCon } = payload;
      let Data = entHT[0];
      state.Header.iVouchId = Data.iStoAdjId;
      state.Header.iNumSeqNo = Data.iNumSeqNo;
      state.Header.strPrefix = Data.strPrefix;
      state.Header.strCompCode = Data.strCompCode;
      state.Header.strFinanFrom = Data.strFinanFrom;
      state.Header.strTransNo = Data.strTransNo;
      state.Header.strVouchNo = Data.strVouchNo;
      state.Header.strVouchDate = Data.strVouchDate;
      state.Header.strRemark1 = Data.strRemark1;
      state.Header.strRemark2 = Data.strRemark2;
      state.Header.strRemark3 = Data.strRemark3;
      state.Header.strRemark4 = Data.strRemark4;
      state.Header.strConvertDCVouch = Data.strConvertVoucher;

      state.Summary.NetAmt = Data.dNetAmt;
      state.Summary.Qty = Data.dTQty;
      state.Summary.GoodsValue = Data.dTGoodsValue;
      state.Summary.RoundOff = Data.dRoundOff;

      state.Header.isManualCode = false;
      state.Data.arrEntDT = entDT;
      state.Data.arrEntPenCon = entPenCon;
    },
    Merger_StockConvertion: (state, { payload }) => {
      state.Header.strConvertDCVouch = payload.strPentVouch;

      state.Summary.NetAmt = payload.Summary.netAmt;
      state.Summary.Qty = payload.Summary.qty;
      state.Summary.GoodsValue = payload.Summary.goodsValue;
      state.Summary.RoundOff = payload.Summary.roundOff;

      state.Data.arrEntDT = payload.EntDT;
      state.Data.arrEntPenCon = payload.EntPenDT;
    },
    Add_AdjustmentReason: (state, { payload }) => {
      state.Product.Reason = payload.Reason;
      state.Product.iReasonId = payload.iReasonId;
    },
  },
});
export const {
  setdafaultparems,
  Stock_Parems,
  setPOS_DefaultParems,
  Update_BtnPermission,
  setScrId,
  setParty,
  Update_AddresType,
  Update_ProductVal,
  Update_Addres,
  settableCol,
  Input_Onchange,
  CustomerInput_Onchange,
  Clear_Transaction,
  ProductInput_Onchange,
  ProductRate_Onchange,
  ProductRate_LostFocus,
  AddProduct,
  Modify_Product,
  Update_BatchProduct,
  Select_Product,
  Select_BatchProduct,
  Select_AttProduct,
  Modify_CompositeProduct,
  Clear_EmployeeParems,
  Merge_ConvertedVouchers,
  Select_Unit,
  Clear_Product,
  setAttValues,
  setRootCalculation,
  setItemRemark,
  Remove_product,
  Select_ReturnProduct,

  Update_ServiceVendor,
  Update_Service,
  Select_ServiceVendore,
  AddLess_LostFocus,
  AddUpdate_AddLess,
  AddLess_Onchange,
  Modify_AddLess,
  Remove_AddLess,

  Update_DiscountCalculation,
  Discount_onchanged,

  Modify_Vouchers,
  Update_ShipAdd,

  Show_tabs,
  Show_hidetranstabs,
  ShowHide_Popup,
  Selecte_Voucher,
  Select_ProducttoConvert,
  setPayRecepDefault,
  Change_Unit,
  StockAdjutment_Onchange,
  StockQty_Onchange,
  Update_POStemplate,
  POSAddLess_Onchange,
  Clear_AddLess,
  Clear_DeiveryParems,
  Clear_RemarkParems,
  EmployeeInput_Onchange,
  Apply_CouponDiscount,
  Apply_RewardDiscount,
  Clear_POSCustomer,
  Close_Session,
  Load_NewSession,
  Update_BillType,
  Add_StockTransProduct,
  Modify_InventoryProduct,
  Modify_StockTransfer,
  Modify_StockReceive,
  Merger_StockConvertion,
  Modify_StockTransAdjustment,
  Add_AdjustmentReason,
} = Transactionstates.actions;
export default Transactionstates.reducer;
