import React, { useEffect, useState } from "react";
import { DecryptData } from "../General/Utility";
function Button(props) {
  const [BtnExportDisplay, setBtnExportDisplay] = useState("none");
  const [BtnExport, setBtnExport] = useState(false);
  const [BtnSaveDisable, setBtnSaveDisable] = useState(false);
  const [BtnDeleteDisable, setBtnDeleteDisable] = useState(false);
  useEffect(() => {
    if (props.ScrId === undefined || props.ScrId === 1 || props.ScrId === 2)
      setBtnExportDisplay("");
    else setBtnExportDisplay("none");
    if (props.ScrId > 0) ButtonPermission();
  }, [props]);

  const ButtonPermission = async () => {
    const AuthParems = await DecryptData(localStorage.jbctrlparams);
    let Menu = [];
    if (props.ScrId === 102)
      Menu = AuthParems.PosPer.filter((Data) => Data.iScrId === props.ScrId);
    else
      Menu = AuthParems.MasterPer.filter((Data) => Data.iScrId === props.ScrId);
    if (Menu.length > 0 && props.Iid > 0) {
      setBtnSaveDisable(!Menu[0].isModify);
      setBtnDeleteDisable(!Menu[0].isDelete);
    } else if (props.Iid === 0) {
      setBtnSaveDisable(!Menu[0].isCreate);
      setBtnExport(!Menu[0].isExport);
    }
  };
  return (
    <div className="btn-section">
      <button
        type="button"
        disabled={BtnSaveDisable}
        className="btn-fabgreen"
        onClick={props.Save}
        tabIndex={props.SaveIndex}
        onKeyDown={props.onKeyDown}
      >
        Save&nbsp; <i className="bx bxs-save"></i>
      </button>
      <button
        style={{ display: BtnExportDisplay }}
        disabled={BtnExport}
        type="button"
        className="btn-fabgreen"
        tabIndex={props.ExportIndex}
        onKeyDown={props.onKeyDown}
        onClick={props.Export}
      >
        Export&nbsp; <i className="bx bxs-file-export"></i>
      </button>
      <button
        type="button"
        disabled={BtnDeleteDisable}
        className="btn-fabgreen"
        onClick={props.Delete}
        onKeyDown={props.onKeyDown}
        tabIndex={props.DeleteIndex}
      >
        Delete&nbsp; <i className="bx bx-trash-alt"></i>
      </button>
      <button
        type="button"
        className="btn-fabgreen"
        onClick={(e) => {
          setBtnSaveDisable(false);
          setBtnDeleteDisable(false);
          setBtnExport(false);
          props.Clear();
        }}
        tabIndex={props.ClearIndex}
        onKeyDown={props.onKeyDown}
      >
        Clear&nbsp; <i className="fas fa-eraser"></i>
      </button>
      <button
        type="button"
        className="btn-fabgreen"
        onKeyDown={props.onKeyDown}
        onClick={(e) => {
          if (props.Close) props.Close();
          else window.location.href = `/JustBill/DashBoard`;
        }}
        tabIndex={props.CloseIndex}
      >
        Close&nbsp; <i className="fas fa-times"></i>
      </button>
    </div>
  );
}
export default Button;
