import React, { useEffect, useState } from "react";
import Button from "../Master/MasterButton";
import MasterList from "../Master/MasterListItem";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import { useDispatch, useSelector } from "react-redux";
function OtherHeadMaster() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const iScrId = 5;
  const strScreenName = "OTHER HEAD MASTER";
  const [IsLoding, setIsLoding] = useState(true);
  const [strMessage, setstrMessage] = useState("");
  const [strIcon, setstrIcon] = useState("");
  const [IsNotification, setIsNotification] = useState(false);
  const [IsDialogOpen, setIsDialogOpen] = useState(false);
  const [IsDialogFor, setIsDialogFor] = useState("");
  const [strQuestion, setstrQuestion] = useState("");

  const [iLedId, setiLedId] = useState(0);
  const [strLedCode, setstrLedCode] = useState("");
  const [strLedName, setstrLedName] = useState("");
  const [strAliasName, setstrAliasName] = useState("");
  const [iAccGrpId, setiAccGrpId] = useState(1);
  const [iStatusId, setiStatusId] = useState(1);
  const [strRemarks, setstrRemarks] = useState("");
  const [dOpBal, setdOpBal] = useState(0);
  const [iBalTypeId, setiBalTypeId] = useState(3);
  const [strLedCodeTag, setstrLedCodeTag] = useState("Enter Ledger Code");
  const [strSearch, setstrSearch] = useState("");
  const [strFilter, setstrFilter] = useState("");
  const [strFilterColumn, setstrFilterColumn] = useState("");
  const [iNumSeqNo, setiNumSeqNo] = useState(0);
  const [strPrefix, setstrPrefix] = useState("");
  const [RecordCount, setRecordCount] = useState(0);

  const [ListLedger, setListLedger] = useState([]);
  const [ListFilter, setListFilter] = useState([]);
  const [ListStatus, setListStatus] = useState([]);
  const [ListAccGrp, setListAccGrp] = useState([]);
  const [ListBalType, setListBalType] = useState([]);

  useEffect(() => {
    return Load();
  }, []);
  const Load = async () => {
    const _LocalParems = await DecryptData(localStorage.jbctrlparams);

    setiLedId(0);
    setstrLedCode("");
    setstrLedName("");
    setstrAliasName("");
    setiAccGrpId(1);
    setiStatusId(1);
    setstrRemarks("");
    setdOpBal(0);
    setiBalTypeId(3);
    setstrLedCodeTag("Enter Ledger Code");
    setstrSearch("");
    setstrFilter("");
    setstrFilterColumn("All");
    setiNumSeqNo(0);
    setstrPrefix("");
    setIsLoding(true);
    const res = await API_FETCH(
      "OtherHeaderMaster/GetDefaultData/" + iScrId + "/" + _LocalParems.DB,
      dispatch
    );
    if (res) {
      setListLedger(res.objLedger);
      setListFilter(res.objFilter);
      setstrLedCode(res.objCodeFormate);
      setListStatus(res.objStatus);
      setListAccGrp(res.objAccGrp);
      setListBalType(res.objBalTyp);
      setRecordCount(res.objLedger.length);
      setIsLoding(false);
    }
  };
  const SaveClick = async () => {
    const ObjInstUpd = {
      iLedId: iLedId,
      strLedCode: strLedCode,
      strLedName: strLedName,
      strAliasName: strAliasName,
      iAccGrpId: Number(iAccGrpId),
      iStatusId: Number(iStatusId),
      strRemarks: strRemarks,
      dOpBal: Number(dOpBal),
      iBalTypeId: Number(iBalTypeId),
      strLedCodeTag: strLedCodeTag,
      strSearch: strSearch,
      strFilter: strFilter,
      strFilterColumn: strFilterColumn,
      iNumSeqNo: Number(iNumSeqNo),
      strPrefix: strPrefix,
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST(
      "OtherHeaderMaster/InsertUpd/",
      ObjInstUpd,
      dispatch
    );
    if (res) {
      MsgBox("Success", res.strMessage, "success");
      Load();
    }
  };
  const ClearClick = () => {
    Load();
  };
  const DeleteClick = async () => {
    const res = await API_POST(
      "OtherHeaderMaster/Delete/" +
        iLedId +
        "/" +
        iScrId +
        "/" +
        _AuthParems.DB,
      dispatch
    );
    if (res) {
      MsgBox("Success", res.strMessage, "success");
      Load();
    }
  };

  const DeleteIconClick = async (iLedId) => {
    const res = await API_POST(
      "OtherHeaderMaster/Delete/" +
        iLedId +
        "/" +
        iScrId +
        "/" +
        _AuthParems.DB,
      dispatch
    );
    if (res) {
      MsgBox("Success", res.strMessage, "success");
      Load();
    }
  };

  const Search = async (data) => {
    const res = await API_FETCH(
      "OtherHeaderMaster/Search/" +
        data.strSearch +
        "/" +
        data.strFilterColumn +
        "/" +
        iScrId +
        "/" +
        _AuthParems.DB,
      dispatch
    );
    if (res) {
      setListLedger(res);
    }
  };

  const GetLedger = async (iLedId) => {
    const res = await API_FETCH(
      "OtherHeaderMaster/GetOtherHeader/" +
        iLedId +
        "/" +
        iScrId +
        "/" +
        _AuthParems.DB,
      dispatch
    );
    if (res) {
      setiLedId(res[0].iLedId);
      setstrLedCode(res[0].strLedCode);
      setstrLedName(res[0].strLedName);
      setstrAliasName(res[0].strAliasName);
      setiAccGrpId(res[0].iAccGrpId);
      setiStatusId(res[0].iStatusId);
      setstrRemarks(res[0].strRemarks);
      setdOpBal(res[0].dOpBal);
      setiBalTypeId(res[0].iBalTypeId);
      setstrLedCodeTag(res[0].strLedCodeTag);
      setstrSearch(res[0].strSearch);
      setstrFilter(res[0].strFilter);
      setstrFilterColumn(res[0].strFilterColumn);
      setiNumSeqNo(res[0].iNumSeqNo);
      setstrPrefix(res[0].strPrefix);
    }
  };

  const MsgBox = (Header, Message, Icon) => {
    setIsNotification(!IsNotification);
    setstrMessage(Message);
    setstrIcon(Icon);
  };

  return (
    <div className="Master-container">
      <div className="row">
        <MasterList
          ComBoxValue={ListFilter}
          EntList={ListLedger}
          GetDetails={GetLedger}
          Delete={DeleteIconClick}
          Count={RecordCount}
          Search={Search}
          ScreenID={iScrId}
          accessor="strLedName"
          accessorid="iLedId"
        />
        <div className="col-md-9 col-sm-12  mt-3 ">
          <div className="fg-card  mb-3">
            <div className="container p-2" id="ClearInput">
              <div className="row">
                <div className="row">
                  <div className="col-md-3 col-sm-12 mb-2">
                    <label>
                      Code<span className="danger">*</span>
                    </label>
                    <input
                      type="text"
                      aria-label="First name"
                      className="form-control"
                      value={strLedCode}
                      onChange={(e) => setstrLedCode(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 mb-2">
                    <label>Name</label>
                    <input
                      type="text"
                      aria-label="First name"
                      className="form-control"
                      placeholder="Enter Ledger Name"
                      value={strLedName}
                      onChange={(e) => setstrLedName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 mb-2">
                    <label>Alias</label>
                    <input
                      type="text"
                      aria-label="First name"
                      className="form-control"
                      placeholder="Enter Alias Name"
                      value={strAliasName}
                      onChange={(e) => setstrAliasName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 mb-2">
                    <label>Remarks</label>
                    <input
                      type="text"
                      aria-label="First name"
                      className="form-control"
                      placeholder="Enter Remarks"
                      value={strRemarks}
                      onChange={(e) => setstrRemarks(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 col-sm-12 mb-2">
                    <label>A/c Group</label>
                    <br />
                    <div className="btn-group" style={{ width: "100%" }}>
                      <select
                        className="selectcus"
                        value={iAccGrpId}
                        onChange={(e) => setiAccGrpId(e.target.value)}
                        style={{
                          width: "100%",
                          height: "100%",
                          textAlign: "left",
                        }}
                      >
                        {ListAccGrp.map((AccGrp, Index) => (
                          <option key={Index} value={AccGrp.iAccGrpId}>
                            {AccGrp.strAccGrpName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 mb-2">
                    <label>Status</label>
                    <br />
                    <div className="btn-group" style={{ width: "100%" }}>
                      <select
                        className="selectcus"
                        value={iStatusId}
                        onChange={(e) => setiStatusId(e.target.value)}
                        style={{
                          width: "100%",
                          height: "100%",
                          textAlign: "left",
                        }}
                      >
                        {ListStatus.map((Status, Index) => (
                          <option key={Index} value={Status.iStatusId}>
                            {Status.strStatusName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 col-sm-12 mb-2">
                    <label>Opening Bal</label>
                    <input
                      type="number"
                      aria-label="First name"
                      className="form-control"
                      value={dOpBal}
                      onChange={(e) => setdOpBal(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mb-2">
                    <label>Balance Type</label>
                    <br />
                    <div className="btn-group" style={{ width: "100%" }}>
                      <select
                        className="selectcus"
                        value={iBalTypeId}
                        onChange={(e) => setiBalTypeId(e.target.value)}
                        style={{
                          width: "100%",
                          height: "100%",
                          textAlign: "left",
                        }}
                      >
                        {ListBalType.map((BalTyp, Index) => (
                          <option key={Index} value={BalTyp.iBalTypeId}>
                            {BalTyp.strBalType}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Button
            Save={(e) => {
              if (strLedName === "") {
                MsgBox("Validation", "Please Enter Name", "info");
              } else if (iAccGrpId === 0) {
                MsgBox("Validation", "Please Select Account Group", "info");
              } else {
                setIsDialogFor("Save");
                setstrQuestion("Do You Want to Save ?");
                setIsDialogOpen(!IsDialogOpen);
              }
            }}
            Clear={ClearClick}
            Delete={(e) => {
              if (iLedId === 0) {
                MsgBox("Please Select OtherHeaderMaster");
              } else {
                setIsDialogFor("Delete");
                setstrQuestion("Do You Want to Delete ?");
                setIsDialogOpen(!IsDialogOpen);
              }
            }}
            ScrId={iScrId}
          />
        </div>
      </div>
    </div>
  );
}
export default OtherHeadMaster;
