import React, { useEffect, useState } from "react";

import SideMenu from "../General/SideMenu";
import JsBarcode from "jsbarcode";
import $ from "jquery";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { Show_Message } from "../General/Messagedialogbox";
import CustomeIndexSelectOption from "../General/CustomeIndexSelectOption";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import ReactTable from "../General/ReactTable";
function BarcodeSetting(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _selectparem = useSelector((state) => state.SelectOption.parems);
  const [iPrintArea, setiPrintArea] = useState(2);
  const [iPrintAreaColumId, setiPrintAreaColumId] = useState(0);
  const [PrintAreaSelected, setPrintAreaSelected] = useState(0);
  const [RemarkEnable, setRemarkEnable] = useState(true);

  const [strColumName, setstrColumName] = useState("");
  const [iColumId, setiColumId] = useState(0);
  const [ColumType, setColumType] = useState(0);
  const [strLineColor, setstrLineColor] = useState("black");
  const [X1Position, setX1Position] = useState(0);
  const [Y1Position, setY1Position] = useState(0);
  const [ColumWidth, setColumWidth] = useState(0);
  const [FontSize, setFontSize] = useState(12);
  const [iInOrder, setiInOrder] = useState(1);
  const [strPrefix, setstrPrefix] = useState("");
  const [strSuffix, setstrSuffix] = useState("");
  const [strTextAlign, setstrTextAlign] = useState("center");
  const [strFontWeight, setstrFontWeight] = useState("normal");
  const [ListBarcodePrintArea, setListBarcodePrintArea] = useState([]);
  const [ListBarcodePrintAreaDesign, setListBarcodePrintAreaDesign] = useState(
    []
  );

  useEffect(() => {
    SearchPrintArea(2);
  }, []);
  const SearchPrintArea = async (Value) => {
    try {
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);

      setiPrintArea(Value);
      setListBarcodePrintArea([]);
      const res = await API_FETCH(
        "Barcode/SearchPrintArea/" + Number(Value) + "/" + _LocalParems.DB,
        dispatch
      );
      if (res) {
        let printArea = [];
        ShowBarCode(res.objbarCodeDesign, res.objEntPrintDesign);
        setListBarcodePrintAreaDesign(res.objEntPrintDesign);
        res.objEntPrintArea.map((Data, Index) => {
          const AreaObject = {
            BSettId: Data.bSettId,
            ColumName: Data.columName,
            DisplayName: Data.displayName,
            ColumType: Data.columType,
            PrintStatus: Data.printStatus === 1 ? true : false,
          };
          printArea.push(AreaObject);
          return Data;
        });
        setListBarcodePrintArea(printArea);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const ClearClick = () => {
    setiPrintArea(2);
    setiPrintAreaColumId(0);
    setPrintAreaSelected(0);
    setstrColumName("");
    setstrLineColor("#000000");
    setiColumId(0);
    setColumType(0);
    setX1Position("");
    setY1Position("");
    setColumWidth("");
    setFontSize(12);
    setiInOrder(1);
    setstrPrefix("");
    setstrSuffix("");
    setstrTextAlign("center");
    setstrFontWeight("bold");
    setListBarcodePrintArea([]);
    setListBarcodePrintAreaDesign([]);
    SearchPrintArea(2);
  };
  const PrintColumn_Checked = (Value, RownInfo) => {
    let Product = ListBarcodePrintArea;
    Product.map((Data) => {
      if (RownInfo.iProId === Data.iProId) Data.SelectProduct = Value;
      return Data;
    });
    setListBarcodePrintArea(Product);
  };
  const SaveClick = async () => {
    try {
      const InsUpdSetting = {
        PrintAreaId: Number(iPrintAreaColumId),
        iColumnId: Number(iColumId),
        strColumName: strColumName,
        InOrder: Number(iInOrder),
        dWidth: parseFloat(ColumWidth),
        dYAxis: parseFloat(Y1Position),
        dXAxis: parseFloat(X1Position),
        iFontSize: Number(FontSize),
        strFontWeight: strFontWeight,
        strColor: strLineColor,
        ColumType: ColumType,
        strPrefix: strPrefix,
        strSuffix: strSuffix,
        strTextAlign: strTextAlign,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "Barcode/InserPrintArea/",
        InsUpdSetting,
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const DeleteClick = async () => {
    try {
      const res = await API_POST(
        "Barcode/DeletePrintArea/" +
          Number(iPrintAreaColumId) +
          "/" +
          _AuthParems.DB,
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const AssignPrintDesign = (rowinfo) => {
    setColumWidth(rowinfo.dWidth);
    setX1Position(rowinfo.dXAxis);
    setY1Position(rowinfo.dYAxis);
    setiColumId(rowinfo.iColumnId);
    setColumType(rowinfo.columType);
    setFontSize(rowinfo.iFontSize);
    setiInOrder(rowinfo.inOrder);
    setiPrintAreaColumId(rowinfo.printAreaId);
    setstrLineColor(rowinfo.strColor);
    setstrFontWeight(rowinfo.strFontWeight);
    setstrPrefix(rowinfo.strPrefix);
    setstrSuffix(rowinfo.strSuffix);
    setstrTextAlign(rowinfo.strTextAlign);
    setstrColumName(rowinfo.strColumName);
  };
  const PrintAreaDesignColum = [
    {
      Header: "Design Name",
      id: "printAreaId",
      accessor: "strColumName",
      style: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
      },
    },
    {
      Header: "XPosition",
      accessor: "dXAxis",
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      Header: "YPosition",
      accessor: "dYAxis",
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      Header: "Width",
      accessor: "dWidth",
      style: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
      },
    },
    {
      Header: "InOrder",
      accessor: "inOrder",
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      Header: "Font Size",
      accessor: "iFontSize",
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      Header: "Color",
      accessor: "strColor",
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      Header: "Font Style",
      accessor: "strFontWeight",
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      Header: "Prefix",
      accessor: "strPrefix",
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      Header: "Suffix",
      accessor: "strSuffix",
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      Header: "Text Align",
      accessor: "strTextAlign",
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  ];
  const ListPrintArea = [
    { Value: 2, AreaName: "Product" },
    { Value: 1, AreaName: "Company" },
  ];
  const arrColor = [
    "aliceblue",
    "antiquewhite",
    "aqua",
    "aquamarine	",
    "azure",
    "beige",
    "bisque",
    "black",
    "blanchedalmond",
    "blue",
    "blueviolet",
    "brown",
    "burlywood",
    "cadetblue",
    "chartreuse",
    "chocolate",
    "coral",
    "cornflowerblue",
    "cornsilk",
    "crimson",
    "cyan",
    "darkblue",
    "darkcyan",
    "darkgoldenrod",
    "darkgray",
    "darkgrey",
    "darkgreen",
    "darkkhaki",
    "darkmagenta",
    "darkolivegreen",
    "darkorange",
    "darkorchid",
    "darkred",
    "darksalmon",
    "darkseagreen",
    "darkslateblue",
    "darkslategray",
    "darkslategrey",
    "darkturquoise",
    "darkviolet",
    "deeppink",
    "deepskyblue",
    "dimgray",
    "dimgrey",
    "dodgerblue",
    "firebrick",
    "floralwhite",
    "forestgreen",
    "fuchsia",
    "gainsboro",
    "ghostwhite",
    "gold",
    "goldenrod",
    "gray",
    "grey",
    "green",
    "greenyellow",
    "honeydew",
    "hotpink",
    "indianred",
    "indigo",
    "ivory",
    "khaki",
    "lavender",
    "lavenderblush",
    "lawngreen",
    "lemonchiffon",
    "lightblue",
    "lightcoral",
    "lightcyan",
    "lightgoldenrodyellow",
    "lightgray",
    "lightgrey",
    "lightgreen",
    "lightpink",
    "lightsalmon",
    "lightseagreen",
    "lightskyblue",
    "lightslategray",
    "lightslategrey",
    "lightyellow",
    "lime",
    "limegreen",
    "linen",
    "magenta",
    "maroon",
    "mediumaquamarine",
    "mediumblue",
    "mediumorchid",
    "mediumpurple",
    "mediumseagreen",
    "mediumslateblue",
    "mediumspringgreen",
    "mediumturquoise",
    "mediumvioletred",
    "midnightblue",
    "mintcream",
    "mistyrose",
    "moccasin",
    "navajowhite",
    "navy",
    "oldlace",
    "olive",
    "olivedrab",
    "orange",
    "orangered",
    "orchid",
    "palegoldenrod",
    "palegreen",
    "paleturquoise",
    "palevioletred",
    "papayawhip",
    "peachpuff",
    "peru",
    "pink",
    "plum",
    "powderblue",
    "purple",
    "rebeccapurple",
    "red",
    "rosybrown",
    "royalblue",
    "saddlebrown",
    "salmon",
    "sandybrown",
    "seagreen",
    "seashell",
    "sienna",
    "silver",
    "skyblue",
    "slateblue",
    "slategray",
    "slategrey",
    "snow",
    "springgreen",
    "steelblue",
    "tan",
    "teal",
    "thistle",
    "tomato",
    "turquoise",
    "violet",
    "wheat",
    "white",
    "whitesmoke",
    "yellow",
    "yellowgreen",
  ];
  const arrTextAlignment = ["center", "left", "right", "justify"];
  const arrFontWeight = [
    100,
    200,
    300,
    400,
    500,
    600,
    700,
    800,
    900,
    "bold",
    "normal",
  ];
  const KeyDown = (e) => {
    try {
      var RowCount = ListBarcodePrintArea.length - 1;
      if (e.key === "ArrowDown") {
        if (RowCount === PrintAreaSelected) setPrintAreaSelected(0);
        else setPrintAreaSelected(PrintAreaSelected + 1);
      } else if (e.key === "ArrowUp") {
        if (PrintAreaSelected === 0) setPrintAreaSelected(RowCount);
        else setPrintAreaSelected(PrintAreaSelected - 1);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };

  const ShowBarCode = (CodeDesign, BarCodeContent) => {
    $("#Barcode").empty();
    var BarCode = document.getElementById("Barcode");

    //#endregion
    //#region Append  BarcodeImage to BarcodeDataContainer
    var Img = document.createElement("canvas");
    Img.setAttribute("id", "Bar-Img");
    BarCode.appendChild(Img);
    JsBarcode("#Bar-Img", "Value", {
      width: CodeDesign[0].dwidth,
      height: CodeDesign[0].dHeight,
      position: "absolute",
      displayValue: false,
      font: "verdona",
      textAlign: "center",
      textMargin: 15,
      fontSize: CodeDesign[0].iFontSize,
      lineColor: CodeDesign[0].strLineColor,
    });
    //#endregion
    //#region Append  BarcodeData to BarcodeDataContainer
    for (var BD = 0; BD < BarCodeContent.length; BD++) {
      const contentStyle =
        "position: absolute;" +
        "width: " +
        BarCodeContent[BD].dWidth +
        "px;" +
        "left: " +
        BarCodeContent[BD].dXAxis +
        "px;" +
        "top: " +
        BarCodeContent[BD].dYAxis +
        "px;" +
        "font-size:" +
        BarCodeContent[BD].iFontSize +
        "px;" +
        "text-align:" +
        BarCodeContent[BD].strTextAlign +
        "px;" +
        "color: " +
        BarCodeContent[BD].strColor +
        ";" +
        "Font-weight: " +
        BarCodeContent[BD].strFontWeight +
        "";
      var ElementP = document.createElement("p");
      ElementP.setAttribute("style", contentStyle);
      ElementP.innerHTML =
        BarCodeContent[BD].strPrefix +
        "Value" +
        BD +
        "" +
        BarCodeContent[BD].strSuffix;
      BarCode.appendChild(ElementP);
    }
    //#endregion
  };
  return (
    <>
      <SideMenu ScrId={120} />
      <div className="Master-container">
        <div className="Master-card">
          <div className="p-2">
            <CustomizedSelectoption
              optionparem="isoptionShow"
              showoption={_selectparem.isoptionShow}
              placeholder="Select Print Area"
              select_value={(val) => SearchPrintArea(val)}
              btnname=""
              btnshow={false}
              show_popup={""}
              valueonly={true}
              defaultval={iPrintArea}
              displayName="AreaName"
              disvalue="Value"
              arrydata={ListPrintArea}
              EmptVal="Print Area"
            />
          </div>
          <div className="data-list">
            {ListBarcodePrintArea.map((dt, Index) => (
              <button
                key={Index}
                tabIndex={Index}
                onKeyDown={(e) => {
                  const arrlength = ListBarcodePrintArea.length - 1;
                  if (e.key === "ArrowDown") {
                    if (Index === arrlength)
                      $(`.data-list [tabindex=${Index + 1}]`).focus();
                    else $(`.data-list [tabindex=${0}]`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    if (Index === 0)
                      $(`.data-list [tabindex=${arrlength}]`).focus();
                    else $(`.data-list [tabindex=${Index - 1}]`).focus();
                  }
                  if (e.key === "Enter") {
                    setiColumId(dt.BSettId);
                    setColumType(dt.ColumType);
                    if (dt.BSettId === 12 || dt.BSettId === 21) {
                      setRemarkEnable(false);
                      setstrColumName("");
                    } else {
                      setRemarkEnable(true);
                      setstrColumName(dt.ColumName);
                    }
                  }
                }}
              >
                <label
                  onClick={(e) => {
                    setiColumId(dt.BSettId);
                    setColumType(dt.ColumType);
                    if (dt.BSettId === 12 || dt.BSettId === 21) {
                      setRemarkEnable(false);
                      setstrColumName("");
                    } else {
                      setRemarkEnable(true);
                      setstrColumName(dt.ColumName);
                    }
                  }}
                >
                  {dt.DisplayName}
                </label>
                <input
                  type="checkbox"
                  defaultChecked={dt.PrintStatus}
                  className="form-check"
                  onChange={(e) => PrintColumn_Checked(e.target.checked, dt)}
                />
              </button>
            ))}
          </div>
        </div>
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-sm-12 col-md-3 mb-1">
                <label>Colum Name</label>
                <input
                  type="text"
                  value={strColumName}
                  onChange={(e) => setstrColumName(e.target.value)}
                  className="form-control rounded"
                  onClick={(e) => e.target.select()}
                  readOnly={RemarkEnable}
                />
              </div>
              <div className="col-sm-6 col-md-2 mt-1">
                <label>Prefix</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Prefix"
                  onClick={(e) => e.target.select()}
                  value={strPrefix}
                  onChange={(e) => setstrPrefix(e.target.value)}
                />
              </div>
              <div className="col-sm-6 col-md-2 mt-1">
                <label>Suffix</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Suffix"
                  onClick={(e) => e.target.select()}
                  value={strSuffix}
                  onChange={(e) => setstrSuffix(e.target.value)}
                />
              </div>
              <div className="col-sm-6 col-md-2 mt-1">
                <label>X Axis</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Line Start Point"
                  value={X1Position}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setX1Position(e.target.value)}
                />
              </div>
              <div className="col-sm-6 col-md-2 mt-1">
                <label>Y Axis</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Height Start Point"
                  value={Y1Position}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setY1Position(e.target.value)}
                />
              </div>
              <div className="col-sm-6 col-md-2 mt-1">
                <label>Width</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Colum Width"
                  onClick={(e) => e.target.select()}
                  value={ColumWidth}
                  onChange={(e) => setColumWidth(e.target.value)}
                />
              </div>
              <div className="col-sm-6 col-md-2 mt-1">
                <label>Font Size</label>
                <input
                  type="text"
                  className="form-control"
                  onClick={(e) => e.target.select()}
                  value={FontSize}
                  onChange={(e) => setFontSize(e.target.value)}
                />
              </div>
              <div className="col-sm-6 col-md-2 p-2">
                <label>In Order</label>
                <input
                  type="text"
                  className="form-control"
                  onClick={(e) => e.target.select()}
                  value={iInOrder}
                  onChange={(e) => setiInOrder(Number(e.target.value))}
                />
              </div>
              <div className="col-sm-6 col-md-2 mt-1">
                <label>Font Weight</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow2"
                  showoption={_selectparem.isoptionShow2}
                  placeholder="Select Font Weight"
                  select_value={(val) => setstrFontWeight(val)}
                  defaultval={strFontWeight}
                  arrydata={arrFontWeight}
                  EmptVal="Font Weight"
                />
              </div>
              <div className="col-sm-6 col-md-2 mt-1">
                <label>Alignment</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow3"
                  showoption={_selectparem.isoptionShow3}
                  placeholder="Select Text Align"
                  select_value={(val) => setstrTextAlign(val)}
                  defaultval={strTextAlign}
                  arrydata={arrTextAlignment}
                  EmptVal="Text Align"
                />
              </div>
              <div className="col-sm-6 col-md-6 mt-1">
                <label>
                  Color<span className="danger"></span>
                </label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow3"
                  showoption={_selectparem.isoptionShow3}
                  placeholder="Select Color"
                  select_value={(val) => setstrLineColor(val)}
                  defaultval={strLineColor}
                  arrydata={arrColor}
                  EmptVal="Color"
                />
              </div>
              <div className="col-sm-12 col-md-6 p-1">
                <div id="Barcode"></div>
              </div>
            </div>
            <ReactTable
              columns={PrintAreaDesignColum}
              data={ListBarcodePrintAreaDesign}
              minRows={5}
              row_click={(Barcode, Index) => {}}
              row_doubleclick={(Barcode, Index) => {
                AssignPrintDesign(Barcode);
              }}
              background={true}
              className="full-table"
            />
          </div>
          <div className="btn-section">
            <button className="btn-fabgreen" onClick={SaveClick}>
              Save
            </button>
            <button className="btn-fabgreen" onClick={DeleteClick}>
              Delete
            </button>
            <button className="btn-fabgreen" onClick={ClearClick}>
              Clear
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) =>
                (window.location.href = "/Others/BarcodeGenerator")
              }
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BarcodeSetting;
