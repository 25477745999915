import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { Show_Message } from "../General/Messagedialogbox";
import {
  refresh_Settlementdt,
  refresh_Settlementtab,
  setSettlementVenParem,
  setSettlementVendor,
  setSettlementVoucher,
  setnewsettlment,
  setselectedpayrec,
  setselectedvoucher,
  setsettlementdAmount,
  setsettlementfromdate,
  setsettlementtodate,
  setsettlementvouchtype,
} from "../features/Accounts";

import { Ask_Question } from "../General/Messagedialogbox";
import { API_FETCH, API_POST } from "../General/Utility";
import ReactTable from "../General/ReactTable";
function SettlementInput(props) {
  const dispatch = useDispatch();

  useEffect(() => {}, [props]);
  const _HTParem = useSelector((state) => state.Accounts.SettleMentHT);
  const _DTParem = useSelector((state) => state.Accounts.SettleMentDT);
  const _Selectparem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const [strSearch, setstrSearch] = useState("");
  const [iHTselected, setiHTselected] = useState(-1);
  const [iDTselected, setiDTselected] = useState(-1);
  const [arrVendore, setarrVendore] = useState([]);

  const Ven_typeonchange = (VenTypeId) => {
    switch (VenTypeId) {
      case 4:
        dispatch(
          setSettlementVenParem({
            iScrId: 17,
            iVenScrId: 2,
            strVenScrCol: "CustName",
            iVenTypeId: VenTypeId,
          })
        );
        break;
      case 5:
        dispatch(
          setSettlementVenParem({
            iScrId: 73,
            iVenScrId: 50,
            strVenScrCol: "VenName",
            iVenTypeId: VenTypeId,
          })
        );
        break;
      default:
        dispatch(
          setSettlementVenParem({
            iScrId: 11,
            iVenScrId: 3,
            strVenScrCol: "SuppName",
            iVenTypeId: VenTypeId,
          })
        );
        break;
    }
    LoadPayVouchers(VenTypeId, _HTParem.iScrId);
  };
  const View_AllVendores = async (VenType, strtext) => {
    setstrSearch(strtext);
    let strcolname = "";
    let ScrId = 0;
    switch (VenType) {
      case 4:
        ScrId = 2;
        strcolname = "CustName";
        break;
      case 5:
        ScrId = 50;
        strcolname = "VenName";
        break;
      default:
        ScrId = 3;
        strcolname = "SuppName";
        break;
    }
    const parems = {
      iScrId: ScrId,
      iPartyId: VenType,
      strColName: strcolname,
      strSearch: strtext,
      strDBName: _AuthParems.DB,
      iBranchId: Number(_AuthParems.Branch),
    };
    const res = await API_POST(`PartyTrans/Search_Party`, parems, dispatch);
    if (res) setarrVendore(res.EntParty);
  };
  const View_Party = async (iVenId) => {
    const res = await API_FETCH(
      `${props.PartyUrl}/View_Party/${_HTParem.iVenTypeId}/${iVenId}/${Number(
        _AuthParems.Branch
      )}/${_AuthParems.DB}`,
      dispatch
    );
    if (res) {
      dispatch(
        setSettlementVendor({
          iVenId: Number(iVenId),
          strVenName: res.EntParty[0],
        })
      );
      LoadPayVouchers(iVenId, _HTParem.iScrId);
    }
  };
  const LoadPayVouchers = async (VenId, Vouchtype) => {
    const objPaySett = {
      iScrId: Vouchtype,
      iVenType: _HTParem.iVenTypeId,
      iVenId: VenId,
      dpFrom: _HTParem.strFromDate,
      dpTo: _HTParem.strToDate,
      strSearch: strSearch,
      iBranchId: Number(_AuthParems.Branch),
      strDBName: _AuthParems.DB,
    };
    const PaySettURL = _HTParem.isUnSettle
      ? "View_UnAdjustedPayVouch/"
      : "View_AdjustedPayVouch/";
    const res = await API_POST(
      `${props.PartyUrl}/View_Party/${PaySettURL}`,
      objPaySett,
      dispatch
    );
    if (res) {
      if (res.EntPayRecHT.length > 0) {
        dispatch(
          setSettlementVoucher({
            iHTCount: res.EntPayRecHT.length,
            iDTCount: res.EntPayRecDT.length,
            arrHTdata: res.EntPayRecHT,
            arrDTdata: res.EntPayRecDT,
          })
        );
      } else {
        if (_HTParem.isUnSettle)
          Show_Message(dispatch, "No Payments are found", "info");
        else if (!_HTParem.isUnSettle)
          Show_Message(dispatch, "No Settlement are found", "info");
      }
    }
  };
  const LoadVouchers = async (objPayRec) => {
    let payrecid =
      props.PartyUrl === "ReceiptSettlement"
        ? objPayRec.recId
        : objPayRec.payId;
    if (props.PartyUrl === "ReceiptSettlement") {
      const res = await API_FETCH(
        `${props.PartyUrl}/View_SettledVouchers/${payrecid}/${_HTParem.iScrId}/${_AuthParems.DB}`
      );
      if (res) {
        dispatch(
          setselectedpayrec({
            iPayRecId:
              props.PartyUrl === "ReceiptSettlement"
                ? objPayRec.recId
                : objPayRec.payId,
            dBalAmount: objPayRec.unAdjustAmt.toFixed(2),
            arrDTdata: res.EntPayRecDT,
          })
        );
      }
    }
  };
  const Adjust_Voucher = async () => {
    if (_HTParem.iPayRecId === 0) {
      Show_Message(dispatch, "Please Select  Payment", "info");
    } else if (_HTParem.iVouchId === 0) {
      Show_Message(dispatch, "Please Select Voucher", "info");
    } else {
      const res = await API_POST(
        "PaymentSettlement/Add_Settlement/",
        {
          dAmount: _HTParem.dAmount,
          dUnAdjAmt: _HTParem.dUnSettleAmount,
          strVouchType: _HTParem.strVouchType,
          objPayDetails: _DTParem.arrDTdata,
          iRowIndex: iDTselected,
          dBalAmt: _HTParem.dBalAmount,
          strDBName: _AuthParems.DB,
          iBranchId: Number(_AuthParems.Branch),
        },
        dispatch
      );
      if (res) {
        dispatch(refresh_Settlementdt());
        dispatch(
          setnewsettlment({
            arrDTdata: res.EntPayRecDT,
            dBalAmount: res.dBalAmt,
          })
        );
      }
    }
  };
  const Delete_Settlement = async (PaydtId) => {
    const res = await API_POST(
      `${props.PartyUrl}/Delete_Settlement/${PaydtId}/${_AuthParems.DB}`
    );
    if (res) {
      dispatch(refresh_Settlementtab());
      Show_Message(dispatch, res.strMessage, "success");
    }
  };
  const PayHTColumn = [
    {
      Header: "S.No",
      accessor: "sNo",
      style: { justifyContent: "center" },
      maxWidth: 70,
    },
    {
      Header: "Payment Mode",
      accessor: "paymentMode",
      style: { justifyContent: "center" },
    },
    {
      Header: "Payment Date",
      accessor: "payDate",
      style: { justifyContent: "center" },
    },
    {
      Header: "Payment No",
      accessor: "payNo",
      style: { justifyContent: "left" },
    },
    {
      Header: "Payment Amount",
      accessor: "payAmt",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Settled Amount",
      accessor: "adjustAmt",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Balance Amount",
      accessor: "unAdjustAmt",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
  ];
  const PayDTColumn = [
    {
      Header: "S.No",
      accessor: "sNo",
      style: { justifyContent: "center" },
      maxWidth: 70,
    },
    {
      Header: "Vouch Type",
      accessor: "vouchType",
      style: { justifyContent: "left" },
    },
    {
      Header: "Bill Type",
      accessor: "billType",
      style: { justifyContent: "center" },
    },
    {
      Header: "Vouch Date",
      accessor: "vouchDate",
      style: { justifyContent: "center" },
    },
    {
      Header: "Vouch No",
      accessor: "vouchNo",
      minWidth: 200,
      style: { justifyContent: "left" },
    },
    {
      Header: "Vouch Amount",
      accessor: "vouchAmt",
      style: { justifyContent: "right" },
      Cell: (props) => props.value.toFixed(2),
    },
    {
      Header: "Settled Amount",
      accessor: "adjustAmt",
      style: { justifyContent: "right" },
      Cell: (props) => props.value.toFixed(2),
    },
    {
      Header: "Balance Amount",
      accessor: "unAdjustAmt",
      style: { justifyContent: "right" },
      Cell: (props) => props.value.toFixed(2),
    },
    {
      Header: "Amount",
      accessor: "amount",
      style: { justifyContent: "right" },
      Cell: (props) => props.value.toFixed(2),
      show: _HTParem.isUnSettle,
    },
    {
      Header: "Options",
      id: "payDTId",
      maxWidth: 70,
      style: { justifyContent: "center" },
      show: !_HTParem.isUnSettle,
      accessor: (rowInfo) => (
        <div>
          <i
            onClick={(e) => {
              Ask_Question(
                dispatch,
                Delete_Settlement,
                "Do You Want to  Delete Settlement?",
                rowInfo.payDTId
              );
            }}
            className="far fa-trash-alt"
          ></i>
        </div>
      ),
    },
  ];
  const RecHTColumn = [
    {
      Header: "SNo",
      accessor: "sNo",
      maxWidth: 70,
      style: { justifyContent: "center" },
    },

    {
      Header: "Receipt Mode",
      accessor: "paymentMode",
      style: { justifyContent: "center" },
    },
    {
      Header: "Receipt Date",
      accessor: "recDate",
      style: { justifyContent: "center" },
    },
    {
      Header: "Receipt No",
      accessor: "recNo",
      style: { justifyContent: "center" },
    },
    {
      Header: "Receipt Amount",
      accessor: "recAmt",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Settled Amount",
      accessor: "adjustAmt",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Balance Amount",
      accessor: "unAdjustAmt",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
  ];
  const RecDTColumn = [
    {
      Header: "SNo",
      accessor: "sNo",
      maxWidth: 70,
      style: { justifyContent: "center" },
    },
    {
      Header: "Vouch Type",
      accessor: "vouchType",
      style: { justifyContent: "center" },
    },
    {
      Header: "Bill Type",
      accessor: "billType",
      style: { justifyContent: "center" },
    },
    {
      Header: "Vouch Date",
      accessor: "vouchDate",
      style: { justifyContent: "center" },
    },
    {
      Header: "Vouch No",
      accessor: "vouchNo",
      style: { justifyContent: "left" },
    },
    {
      Header: "Vouch Amount",
      accessor: "vouchAmt",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Settled Amount",
      accessor: "adjustAmt",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Balance Amount",
      accessor: "unAdjustAmt",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: (props) => props.value.toFixed(2),
      show: _HTParem.isUnSettle,
      style: { justifyContent: "right" },
    },
    {
      Header: "Options",
      id: "recDTId",
      maxWidth: 70,
      style: { justifyContent: "center" },
      show: !_HTParem.isUnSettle,
      accessor: (rowInfo) => (
        <div>
          <i
            onClick={() => {
              Ask_Question(
                dispatch,
                Delete_Settlement,
                "Do You Want to  Delete Settlement?",
                rowInfo.recDTId
              );
            }}
            className="far fa-trash-alt"
          ></i>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="row">
        <div className="col-md-3 col-sm-6 mt-1">
          <label>
            Vendor Name <span className="danger">*</span>
          </label>
          <CustomizedSelectoption
            optionparem="isoptionShow"
            showoption={_Selectparem.isoptionShow}
            placeholder="Select Vendore"
            onChange={(val) => {
              View_AllVendores(_HTParem.iVenTypeId, val);
            }}
            select_value={(val) => View_Party(val)}
            btnname="Vendor"
            btnshow={false}
            show_popup=""
            valueonly={true}
            defaultval={_HTParem.iVenId}
            displayName="partyName"
            disvalue="partyId"
            arrydata={arrVendore}
            EmptVal="Vendor "
          />
        </div>
        <div className="col-md-2 col-sm-6">
          <label>Vendor Type</label>
          <CustomizedSelectoption
            optionparem="isoptionShow1"
            showoption={_Selectparem.isoptionShow1}
            placeholder="Select Vendore Type"
            select_value={(val) => Ven_typeonchange(Number(val))}
            btnname="Vendor type"
            btnshow={false}
            show_popup=""
            valueonly={true}
            defaultval={_HTParem.iVenTypeId}
            displayName="strVenTypeName"
            disvalue="iVenTypeId"
            arrydata={_DTParem.arrVenType}
            EmptVal="Vendor Type "
          />
        </div>
        <div className="col-md-2 col-sm-6">
          <label>Voucher Type</label>
          <CustomizedSelectoption
            optionparem="isoptionShow2"
            showoption={_Selectparem.isoptionShow2}
            placeholder="Select Voucher Type"
            select_value={async (val) => {
              await dispatch(setsettlementvouchtype(Number(val)));
              LoadPayVouchers(_HTParem.iVenId, Number(val));
            }}
            btnname="Voucher Type"
            btnshow={false}
            show_popup=""
            valueonly={true}
            defaultval={_HTParem.iScrId}
            displayName="strScrName"
            disvalue="iScrId"
            arrydata={_DTParem.arrVouchType}
            EmptVal="Voucher Type"
          />
        </div>
        <div className="col-md-2 col-sm-6">
          <label>From Date</label>
          <input
            type="date"
            name="FromDate"
            className="form-control"
            value={_HTParem.strFromDate}
            onChange={async (e) => {
              await dispatch(setsettlementfromdate(e.target.value));
              LoadPayVouchers(_HTParem.iVenId, _HTParem.iScrId);
            }}
          />
        </div>
        <div className="col-md-2 col-sm-6">
          <label>To Date</label>
          <input
            type="date"
            name="ToDate"
            className="form-control"
            value={_HTParem.strToDate}
            onChange={async (e) => {
              await dispatch(setsettlementtodate(e.target.value));
              LoadPayVouchers(_HTParem.iVenId, _HTParem.iScrId);
            }}
          />
        </div>
      </div>
      <ReactTable
        columns={
          props.PartyUrl === "ReceiptSettlement" ? RecHTColumn : PayHTColumn
        }
        data={_DTParem.arrHTdata}
        minRows={5}
        row_click={(rowInfo, Index) => {
          setiHTselected(Index);
        }}
        row_doubleclick={(rowInfo, Index) => {
          if (!_HTParem.isUnSettle) LoadVouchers(rowInfo);
          else {
            dispatch(
              setselectedpayrec({
                iPayRecId:
                  props.PartyUrl === "ReceiptSettlement"
                    ? rowInfo.recId
                    : rowInfo.payId,
                dBalAmount: rowInfo.unAdjustAmt.toFixed(2),
                arrDTdata: _DTParem.arrDTdata,
              })
            );
          }
        }}
        background={true}
        className="full-table"
      />
      {_HTParem.isUnSettle && (
        <div className="p-2">
          <div className="row">
            <div className="col-sm-6 col-md-2">
              <label>Voucher Number </label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                name="VenName"
                id="VenName"
                value={_HTParem.strVouchNo}
                onClick={(e) => e.target.select()}
                readOnly
              />
            </div>
            <div className="col-sm-6 col-md-2">
              <label>UN Settled Amount </label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                name="dUnSettleAmount"
                id="dUnSettleAmount"
                value={_HTParem.dUnSettleAmount}
                readOnly
              />
            </div>
            <div className="col-sm-6 col-md-2">
              <label>Amount </label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                name="dAmount"
                id="dAmount"
                value={_HTParem.dAmount}
                onChange={(e) =>
                  dispatch(setsettlementdAmount(Number(e.target.value)))
                }
                onClick={(e) => e.target.select()}
              />
            </div>
            <div className="col-sm-6 col-md-2">
              <br />
              <button
                type="button"
                onClick={Adjust_Voucher}
                className="trans-add-btn "
              >
                Adjust
              </button>
            </div>
            <div className="col-sm-12 col-md-3">
              <div className="net-amount">
                <h1>Available Amount</h1>
                <h2>
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "INR",
                  }).format(Number(_HTParem.dBalAmount).toFixed(2))}
                </h2>
              </div>
            </div>
          </div>
        </div>
      )}
      <ReactTable
        columns={
          props.PartyUrl === "ReceiptSettlement" ? RecDTColumn : PayDTColumn
        }
        data={_DTParem.arrDTdata}
        minRows={5}
        row_click={(rowInfo, Index) => {
          setiHTselected(Index);
        }}
        row_doubleclick={(rowInfo, Index) => {
          dispatch(
            setselectedvoucher({
              iVouchId: rowInfo.vouchId,
              strVouchNo: rowInfo.vouchNo,
              dUnSettleAmount: rowInfo.unAdjustAmt.toFixed(2),
              strVouchType: rowInfo.vouchType,
              dAmount: rowInfo.amount,
            })
          );
        }}
        background={true}
        className="full-table"
      />
    </>
  );
}
export default SettlementInput;
