import React, { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import MasterList from "./MasterListItem";
import $ from "jquery";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import Messagedialogbox from "../General/Messagedialogbox";
import MasterCodeSetting from "./MasterCodeSetting";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
function JobWorkMaster() {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const iScrId = 51;

  const [strJobCode, setstrJobCode] = useState("");
  const [strJobName, setstrJobName] = useState("");
  const [strSACCode, setstrSACCode] = useState("");
  const [strPrintName, setstrPrintName] = useState("");
  const [strRemark, setstrRemark] = useState("");
  const [iStatusId, setiStatusId] = useState(1);
  const [strJobCodeTag, setstrJobCodeTag] = useState(" Enter Job Code");
  const [iJobId, setiJobId] = useState(0);
  const [iSGSTTaxId, setiSGSTTaxId] = useState(24);
  const [iCGSTTaxId, setiCGSTTaxId] = useState(27);
  const [iIGSTTaxId, setiIGSTTaxId] = useState(18);
  const [iTaxTypeId, setiTaxTypeId] = useState(1);
  const [iTcsTaxId, setiTcsTaxId] = useState(43);
  const [strSearch, setstrSearch] = useState("");
  const [strFilter, setstrFilter] = useState("");
  const [strFilterColum, setstrFilterColum] = useState("All");
  const [listCodeFormat, setlistCodeFormat] = useState([]);
  const [listEntJobList, setlistEntJobList] = useState([]);
  const [listEntStatus, setlistEntStatus] = useState([]);
  const [listSGST, setlistSGST] = useState([]);
  const [listCGST, setlistCGST] = useState([]);
  const [listIGST, setlistIGST] = useState([]);
  const [listTaxType, setlistTaxType] = useState([]);
  const [ManualCode, setManualCode] = useState(true);
  const [IsMasterCode, setIsMasterCode] = useState(false);
  const [ListTcsTax, setListTcsTax] = useState([]);
  const [ListValidateProperties, setListValidateProperties] = useState([]);

  const [BtnSaveDisable, setBtnSaveDisable] = useState(false);
  const [BtnDeleteDisable, setBtnDeleteDisable] = useState(false);
  const [isShowTcs, setisShowTcs] = useState(false);

  useEffect(() => {
    Load_JobworkMaster();
  }, []);
  const Load_JobworkMaster = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);
      const res = await API_FETCH(
        `JobWorkMaster/Load_JobworkMaster/${Number(_LocalParems.Branch)}/${
          _LocalParems.DB
        }`,
        dispatch
      );
      if (res) {
        setlistEntStatus(res.objEntStatus);
        setlistEntJobList(res.objEntJob);
        setlistTaxType(res.objEntTaxType);
        setlistSGST(res.objEntSGSTTax);
        setlistCGST(res.objEntCGSTTax);
        setlistIGST(res.objEntIGSTTax);
        setListTcsTax(res.objEntTcs);
        setlistCodeFormat(res.objCodeFormat);
        setManualCode(!res.objCodeFormat[0].isManualNo);
        setstrJobCode(res.objCodeFormat[0].strCode);
        TabOrderAssign(res.Transtab);
        setListValidateProperties(res.Transtab);
        ButtonPermission(_LocalParems.MasterPer, iJobId);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const ClearClick = () => {
    try {
      Pre_Loading(dispatch, false);
      setstrJobCode("");
      setstrJobName("");
      setstrSACCode("");
      setstrPrintName("");
      setstrRemark("");
      setiStatusId(1);
      setstrJobCodeTag(" Enter Job Code");
      setiJobId(0);
      setiSGSTTaxId(24);
      setiCGSTTaxId(27);
      setiIGSTTaxId(18);
      setiTcsTaxId(43);
      setstrSearch("");
      setstrFilter("");
      setstrFilterColum("All");
      setBtnDeleteDisable(false);
      setBtnSaveDisable(false);
      Load_JobworkMaster();
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const SaveClick = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const ObjInsUpdJob = {
        strJobCode: strJobCode,
        strJobName: strJobName,
        strSACCode: strSACCode,
        strPrintName: strPrintName,
        strRemark: strRemark,
        iStatusId: iStatusId,
        strJobCodeTag: strJobCodeTag,
        iTitleTag: iScrId,
        iJobId: iJobId,
        iNumSeqNo: listCodeFormat[0].iNumSeqNo,
        strPrefix: listCodeFormat[0].strPrefix,
        strSearch: strSearch,
        strFilter: strFilter,
        strFilterColumn: strFilterColum,
        iTaxTypeId: Number(iTaxTypeId),
        iSGSTTaxId: Number(iSGSTTaxId),
        iCGSTTaxId: Number(iCGSTTaxId),
        iIGSTTaxId: Number(iIGSTTaxId),
        strDBName: _AuthParems.DB,
        iBranchId: Number(_AuthParems.Branch),
      };
      const res = await API_POST(
        "JobWorkMaster/InsUpd_JobWork/",
        ObjInsUpdJob,
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const DeleteClick = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_POST(
        "JobWorkMaster/Delete_JobWork/" +
          iJobId +
          "/" +
          Number(_AuthParems.Branch) +
          "/" +
          _AuthParems.DB,
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const View_JobWork = async (iJobId) => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        `JobWorkMaster/View_JobWork/${iJobId}/${Number(_AuthParems.Branch)}/${
          _AuthParems.DB
        }`,
        dispatch
      );
      if (res) {
        let EntJob = res.EntJobWork[0];
        setstrJobCode(EntJob.strJobCode);
        setstrJobName(EntJob.strJobName);
        setstrSACCode(EntJob.strSACCode);
        setstrPrintName(EntJob.strPrintName);
        setstrRemark(EntJob.strRemark);
        setiStatusId(EntJob.iStatusId);
        setiJobId(EntJob.iJobId);
        setstrJobCode(EntJob.strJobCode);
        setiSGSTTaxId(EntJob.iSGSTTaxId);
        setiCGSTTaxId(EntJob.iCGSTTaxId);
        setiIGSTTaxId(EntJob.iIGSTTaxId);
        ButtonPermission(_AuthParems.MasterPer, EntJob.strJobId);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      Pre_Loading(dispatch, false);
    }
  };
  const Search_JobWork = async (strtext) => {
    try {
      setstrSearch(strtext);
      const objSearch = {
        iScrId: iScrId,
        iBranchId: Number(_AuthParems.Branch),
        iPartyId: 0,
        strColName: "All",
        strSearch: strtext,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "JobWorkMaster/Search_JobWork/",
        objSearch,
        dispatch
      );
      if (res) {
        setlistEntJobList(res.EntJobWork);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const TaxOnChange = (objtax) => {
    let dTaxPer = Number(objtax.dTaxPer);
    var TaxType = objtax.strTaxDesc.split(" ")[0];
    switch (TaxType) {
      case "SGST":
        setiSGSTTaxId(objtax.iTaxId);
        setiCGSTTaxId(
          listCGST.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setiIGSTTaxId(
          listIGST.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      case "CGST":
        setiSGSTTaxId(
          listSGST.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setiCGSTTaxId(objtax.iTaxId);
        setiIGSTTaxId(
          listIGST.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      default:
        setiIGSTTaxId(objtax.iTaxId);
        setiSGSTTaxId(
          listSGST.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        setiCGSTTaxId(
          listCGST.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        break;
    }
  };
  //#region TabSetting
  const [ListTabOrder, setListTabOrder] = useState([
    {
      JobCodeTab: "",
      JobNameTab: "",
      SACcodeTab: "",
      RemarkTab: "",
      PrintNameTab: "",
      StatusTab: "",
      TaxTypeTab: "",
      CGSTTab: "",
      IGSTTab: "",
      SGSTTab: "",
      TcsTaxTab: "",
      JobListTab: "",
      JobFIlterTab: "",
      JobSearchTab: "",
      BtnSavtab: "",
      BtnCleartab: "",
      BtnDeletetab: "",
      BtnClosetab: "",
      BtnExporttab: "",
    },
  ]);
  const TabOrderAssign = (EntProperties) => {
    let TabOrder = ListTabOrder;
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      switch (EntProperties[i].strCtrlName) {
        case "txtJobCode":
          TabOrder[0].JobCodeTab = EntProperties[i].iTabIndex;
          break;
        case "txtSAC":
          TabOrder[0].SACcodeTab = EntProperties[i].iTabIndex;
          break;
        case "txtJobName":
          TabOrder[0].JobNameTab = EntProperties[i].iTabIndex;
          break;
        case "txtPrintName":
          TabOrder[0].PrintNameTab = EntProperties[i].iTabIndex;
          break;
        case "cbTaxType":
          TabOrder[0].TaxTypeTab = EntProperties[i].iTabIndex;
          break;
        case "cbCgst":
          TabOrder[0].CGSTTab = EntProperties[i].iTabIndex;
          break;
        case "cbIgst":
          TabOrder[0].IGSTTab = EntProperties[i].iTabIndex;
          break;
        case "cbSgst":
          TabOrder[0].SGSTTab = EntProperties[i].iTabIndex;
          break;
        case "txtRemarks":
          TabOrder[0].RemarkTab = EntProperties[i].iTabIndex;
          break;
        case "cbStatus":
          TabOrder[0].StatusTab = EntProperties[i].iTabIndex;
          break;
        case "btnSave":
          TabOrder[0].BtnSavtab = EntProperties[i].iTabIndex;
          break;
        case "btnClear":
          TabOrder[0].BtnCleartab = EntProperties[i].iTabIndex;
          break;
        case "btnDelete":
          TabOrder[0].BtnDeletetab = EntProperties[i].iTabIndex;
          break;
        case "btnClose":
          TabOrder[0].BtnClosetab = EntProperties[i].iTabIndex;
          break;
        case "cbFilter":
          TabOrder[0].JobFIlterTab = EntProperties[i].iTabIndex;
          break;
        case "txtSearch":
          TabOrder[0].JobSearchTab = EntProperties[i].iTabIndex;
          break;
        case "lbJobName":
          TabOrder[0].JobListTab = EntProperties[i].iTabIndex;
          break;
        case "btnExportImport":
          TabOrder[0].BtnExporttab = EntProperties[i].iTabIndex;
          break;
        default:
          break;
      }
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    ); //returns object
    sessionStorage.maxTab = maxTab.iTabIndex;
    setListTabOrder(TabOrder);
  };
  const TabIndex = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].JobListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].JobListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].JobListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].JobListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].JobListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].JobListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].JobListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  //#endregion
  const PropertiesValidation = () => {
    var ErrorMsg = true;
    let ValidateItem = ListValidateProperties.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtJobName":
          ErrorMsg = strJobName === "" ? false : true;
          break;
        case "txtPrintName":
          ErrorMsg = strPrintName === "" ? false : true;
          break;
        case "txtSAC":
          ErrorMsg = strSACCode === "" ? false : true;
          break;
        case "txtRemarks":
          ErrorMsg = strRemark === "" ? false : true;
          break;
        case "cbSgst":
          ErrorMsg = iSGSTTaxId === "" ? false : true;
          break;
        case "cbIgst":
          ErrorMsg = iIGSTTaxId === "" ? false : true;
          break;
        case "cbCgst":
          ErrorMsg = iCGSTTaxId === "" ? false : true;
          break;
        case "cbTaxType":
          ErrorMsg = iTaxTypeId === "" ? false : true;
          break;

        default:
          break;
      }
      if (ErrorMsg === false) {
        Show_Message(dispatch, ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };
  const ButtonPermission = (Menu, JobId) => {
    Menu = Menu.filter((Data) => Data.iScrId === 51);
    if (Menu.length > 0) {
      if (Number(JobId) > 0) {
        setBtnSaveDisable(!Menu[0].isModify);
        setBtnDeleteDisable(!Menu[0].isDelete);
      }
      if (Number(JobId) === 0) setBtnSaveDisable(!Menu[0].isCreate);
    }
  };
  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <MasterList
          ScrId={iScrId}
          strSearchtxt={strSearch}
          tabOrder={ListTabOrder[0].JobSearchTab}
          Search={(val) => Search_JobWork(val)}
          arrMasterData={listEntJobList}
          isImg={false}
          displayName="strJobName"
          Parem1="iJobId"
          View_Data={(val) => View_JobWork(val)}
          placeholder="JobWork"
        />
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-4 col-sm-4 mb-2">
                <label>
                  Name <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strJobName}
                  placeholder="Please Enter Job Name"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].JobNameTab}
                  onChange={(e) => setstrJobName(e.target.value)}
                  autoFocus
                  spellCheck={false}
                />
              </div>
              <div className="col-md-4 col-sm-4 mb-2">
                <label>SAC</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Please Enter SAC Code"
                  value={strSACCode}
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].SACcodeTab}
                  onChange={(e) => setstrSACCode(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-4 mb-2">
                <label>
                  Code <span className="danger">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    id="txtProductCode"
                    readOnly={ManualCode}
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].JobCodeTab}
                    value={strJobCode}
                    onChange={(e) => setstrJobCode(e.target.value)}
                    name="strProCode"
                  />
                  <span
                    className="inv-no"
                    onClick={(e) => setIsMasterCode(!IsMasterCode)}
                  >
                    <i className="bx bx-cog"></i>
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 mb-2">
                <label>Print Name </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strPrintName}
                  onKeyDown={(e) => TabIndex(e)}
                  placeholder="Please Enter Print Name"
                  tabIndex={ListTabOrder[0].PrintNameTab}
                  onChange={(e) => setstrPrintName(e.target.value)}
                  spellCheck={false}
                />
              </div>
              <div className="col-md-4 col-sm-4 mb-2">
                <label>Remark</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strRemark}
                  onKeyDown={(e) => TabIndex(e)}
                  placeholder="Please Enter Remark"
                  tabIndex={ListTabOrder[0].RemarkTab}
                  onChange={(e) => setstrRemark(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-4 mb-2">
                <label>Status</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_parem.isoptionShow}
                  placeholder="Select Status"
                  select_value={(val) => setiStatusId(val)}
                  keydown={(e) => TabIndex(e)}
                  taborder={ListTabOrder[0].StatusTab}
                  btnname="State"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iStatusId}
                  displayName="strStatusName"
                  disvalue="iStatusId"
                  arrydata={listEntStatus}
                  disabled={false}
                  EmptVal="Status "
                />
              </div>
              <div className="col-md-3 col-sm-3">
                <label>Tax Type</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow1"
                  showoption={_parem.isoptionShow1}
                  placeholder="Select Tax Type"
                  taborder={ListTabOrder[0].Taxtytab}
                  keydown={(e) => TabIndex(e)}
                  select_value={(val) => {
                    setiTaxTypeId(val);
                    setisShowTcs(Number(val) === 1 ? false : true);
                  }}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iTaxTypeId}
                  displayName="strTaxLvlType"
                  disvalue="iTaxLvlId"
                  arrydata={listTaxType}
                  EmptVal="Tax Type "
                />
              </div>
              {/* {!isShowTcs && (
                <div className="col-md-3 col-sm-3">
                  <label>SGST</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow2"
                    showoption={_parem.isoptionShow2}
                    placeholder="Select SGST Tax"
                    select_value={(val) => TaxOnChange(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={false}
                    defaultval={iSGSTTaxId}
                    displayName="strTaxDesc"
                    disvalue="iTaxId"
                    arrydata={listSGST}
                    EmptVal="SGST "
                  />
                </div>
              )}
              {!isShowTcs && (
                <div className="col-md-3 col-sm-3">
                  <label>CGST</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow3"
                    showoption={_parem.isoptionShow3}
                    placeholder="Select CGST Tax"
                    select_value={(val) => TaxOnChange(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={false}
                    defaultval={iCGSTTaxId}
                    displayName="strTaxDesc"
                    disvalue="iTaxId"
                    arrydata={listCGST}
                    EmptVal="CGST "
                  />
                </div>
              )} */}
              {!isShowTcs && (
                <div className="col-md-3 col-sm-3">
                  <label>GST Tax %</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow4"
                    showoption={_parem.isoptionShow4}
                    placeholder="Select IGST Tax"
                    keydown={(e) => TabIndex(e)}
                    select_value={(val) => TaxOnChange(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={false}
                    defaultval={iIGSTTaxId}
                    displayName="strTaxDesc"
                    disvalue="iTaxId"
                    arrydata={listIGST}
                    EmptVal="IGST "
                    replace={["IGST", "GST"]}
                  />
                </div>
              )}
              {isShowTcs && (
                <div className="col-md-3 col-sm-3">
                  <label>TCS Tax %</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow5"
                    showoption={_parem.isoptionShow5}
                    placeholder="Select TCS Tax"
                    keydown={(e) => TabIndex(e)}
                    select_value={(val) => setiTcsTaxId(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iTcsTaxId}
                    displayName="strTaxDesc"
                    disvalue="iTaxId"
                    arrydata={ListTcsTax}
                    EmptVal="TCS "
                  />
                </div>
              )}
            </div>
          </div>
          {IsMasterCode && (
            <MasterCodeSetting
              Close={(e) => setIsMasterCode(!IsMasterCode)}
              iScrId={iScrId}
            />
          )}
          <div className="btn-section">
            <button
              type="button"
              className="btn-fabgreen"
              disabled={BtnSaveDisable}
              onKeyDown={(e) => TabIndex(e)}
              tabIndex={ListTabOrder[0].BtnSavtab}
              onClick={(e) => {
                if (PropertiesValidation()) {
                  Ask_Question(
                    dispatch,
                    SaveClick,
                    "Do You Want to  Save JobWork?"
                  );
                }
              }}
            >
              Save &nbsp; <i className="fas fa-Save"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onKeyDown={(e) => TabIndex(e)}
              disabled={BtnDeleteDisable}
              tabIndex={ListTabOrder[0].BtnDeletetab}
              onClick={(e) => {
                if (Number(iJobId) === 0) {
                  Show_Message(dispatch, "Please Select a Job", "info");
                } else {
                  Ask_Question(
                    dispatch,
                    DeleteClick,
                    "Do You Want to  Delete JobWork?"
                  );
                }
              }}
            >
              Delete &nbsp; <i className="fas fa-print"></i>
            </button>
            <button
              type="reset"
              className="btn-fabgreen"
              onKeyDown={(e) => TabIndex(e)}
              tabIndex={ListTabOrder[0].BtnCleartab}
              onClick={ClearClick}
            >
              Clear &nbsp; <i className="fas fa-eraser"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) => (window.location.href = `/JustBill/DashBoard`)}
              onKeyDown={(e) => TabIndex(e)}
              tabIndex={ListTabOrder[0].BtnClosetab}
            >
              Close &nbsp; <i className="fas fa-times"></i>
            </button>
          </div>
          <Messagedialogbox />
        </div>
      </div>
    </>
  );
}
export default JobWorkMaster;
