import { useState, useEffect } from "react";

import SearchLedger from "./SearchLedger";
import JournalView from "./JournalView";
import SideMenu from "../General/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import Messagedialogbox from "../General/Messagedialogbox";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import ReactTable from "../General/ReactTable";
function JournalEntry() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const iScrId = 79;
  const [IsLedgerOpen, setIsLedgerOpen] = useState(false);
  const [IsJournalOpen, setIsJournalOpen] = useState(false);
  const [iJEId, setiJEId] = useState(0);
  const [bStatus, setbStatus] = useState(true);
  const [iUserId, setiUserId] = useState(0);
  const [strEditMode, setstrEditMode] = useState("New");
  const [strVoucherNo, setstrVoucherNo] = useState("");
  const [strTransNo, setstrTransNo] = useState("");
  const [strPrefix, setstrPrefix] = useState("");
  const [iNumSeqNo, setiNumSeqNo] = useState(0);
  const [strCompCode, setstrCompCode] = useState("");
  const [strFinanFromTo, setstrFinanFromTo] = useState("");
  const [strDate, setstrDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [iLedId, setiLedId] = useState(0);
  const [strLedName, setstrLedName] = useState("");
  const [iDebit, setiDebit] = useState(0);
  const [iCredit, setiCredit] = useState(0);
  const [strRemarks, setstrRemarks] = useState("");
  const [iSumDiffAmt, setiSumDiffAmt] = useState(0);
  const [iSumDebit, setiSumDebit] = useState(0);
  const [iSumCredit, setiSumCredit] = useState(0);
  const [strSumRemarks, setstrSumRemarks] = useState("");
  const [ListJournals, setListJournals] = useState([]);
  const [ListLedger, setListLedger] = useState([]);
  const [isledgOpen, setisledgOpen] = useState(false);
  useEffect(() => {
    Load_JournalEntry();
  }, []);
  const Load_JournalEntry = async () => {
    const _LocalParems = await DecryptData(localStorage.jbctrlparams);

    const res = await API_FETCH(
      `JournalEntry/Load_JournalEntry/${Number(_LocalParems.Branch)}/${
        _LocalParems.DB
      }`,
      dispatch
    );
    if (res) {
      setstrVoucherNo(res.objNoFormate[0].strUserNo);
      setstrTransNo(res.objNoFormate[0].strTransNo);
      setstrPrefix(res.objNoFormate[0].strPrefix);
      setiNumSeqNo(res.objNoFormate[0].iNumSeqNo);
      setstrCompCode(res.objNoFormate[0].strCompCode);
      setstrFinanFromTo(res.objNoFormate[0].strFinanFrom);
    }
  };
  const Save_JournalEntry = async () => {
    var Date = strDate.split("-");
    var VoucherDate = Date[1] + "-" + Date[2] + "-" + Date[0];
    const objEntJE = {
      iScrId: iScrId,
      iJEId: Number(iJEId),
      strPrefix: strPrefix,
      strCompCode: strCompCode,
      strFinanFrom: strFinanFromTo,
      iNumSeqNo: Number(iNumSeqNo),
      strTransNo: strTransNo,
      strVouchNo: strVoucherNo,
      strVouchDate: VoucherDate,
      iUserId: Number(iUserId),
      status: bStatus,
      dDiffAmt: Number(iSumDiffAmt),
      strDBName: _AuthParems.DB,
      iBranchId: Number(_AuthParems.Branch),
    };
    Pre_Loading(dispatch, true);
    /* Read more about isConfirmed, isDenied below */
    const res = await API_POST(
      "JournalEntry/InsUpd_JournelEntry",
      {
        objEntJE: objEntJE,
        objEntJEDT: ListJournals,
      },
      dispatch
    );
    if (res) {
      Clear_JournalEntry();
      Show_Message(dispatch, res.strMessage, "success");
    }
  };
  const Delete_JournalEntry = async () => {
    const res = await API_POST(
      "JournalEntry/Delete_JournelEntry/" +
        Number(iJEId) +
        "/" +
        _AuthParems.DB,
      dispatch
    );
    if (res) {
      Clear_JournalEntry();
      Show_Message(dispatch, res.strMessage, "success");
    }
  };
  const Clear_JournalEntry = () => {
    setiJEId(0);
    setstrVoucherNo("");
    setstrDate(new Date().toISOString().split("T")[0]);
    setListJournals([]);
    setListLedger([]);
    setiLedId(0);
    setstrLedName("");
    setstrSumRemarks("");
    Calculation([]);
    Load_JournalEntry();
  };
  const SelectLedger = (objled) => {
    setIsLedgerOpen(!IsLedgerOpen);
    setiLedId(objled.ledId);
    setstrLedName(objled.ledName);
    setListLedger(objled);
  };
  const AddTable = () => {
    if (parseFloat(iDebit) > 0 && parseFloat(iCredit) > 0) {
      Show_Message(dispatch, "Please Enter Credit or Debit Amount", "info");
    } else if (parseFloat(iDebit) === 0 && parseFloat(iCredit) === 0) {
      Show_Message(dispatch, "Please Enter Credit or Debit Amount", "info");
    } else {
      switch (strEditMode) {
        case "New":
          if (Number(iLedId) === 0) {
            Show_Message(dispatch, "Please Select Ledger", "info");
          } else {
            const AddLedger = {
              sNo: ListJournals.length + 1,
              ledName: ListLedger.ledName,
              ledCode: ListLedger.ledCode,
              ledId: ListLedger.ledId,
              dr: parseFloat(iDebit),
              cr: parseFloat(iCredit),
              remarks: strRemarks,
              vouId: 0,
            };
            if (ListJournals.length === 0) {
              setListJournals([AddLedger]);
              Calculation([AddLedger]);
            } else {
              setListJournals([...ListJournals, AddLedger]);
              Calculation([...ListJournals, AddLedger]);
            }
          }
          break;
        case "Modify":
          let LedgerFilter = ListJournals.filter(
            (Data) => Data.ledId === iLedId
          );
          if (LedgerFilter.length > 0) {
            ListJournals.map((Data) => {
              if (Data.ledId === iLedId) {
                Data.dr = parseFloat(iDebit);
                Data.cr = parseFloat(iCredit);
                Data.remarks = strRemarks;
              }
              return Data;
            });
            Calculation(ListJournals);
          }
          break;
        default:
          break;
      }
    }
  };
  const Calculation = (listJournal) => {
    try {
      let SumDebit = 0;
      let SumCredit = 0;
      let SumDiffAmt = 0;
      listJournal.map((Data) => {
        SumDebit += Data.dr;
        SumCredit += Data.cr;
        return Data;
      });
      SumDiffAmt = SumDebit - SumCredit;
      setiSumCredit(SumCredit.toFixed(2));
      setiSumDebit(SumDebit.toFixed(2));
      setiSumDiffAmt(SumDiffAmt.toFixed(2));
      setiLedId(0);
      setstrLedName("");
      setiDebit(0);
      setiCredit(0);
      setstrRemarks("");
      setstrEditMode("New");
      setListLedger([]);
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const RemoveTableRow = (RowInfo) => {
    try {
      const newList = ListJournals.filter(
        (Data) => Data.ledId !== RowInfo.ledId
      );
      newList.map((Data) => {
        if (Data.sNo > RowInfo.sNo && Data.sNo > 1) {
          Data.sNo = Data.sNo - 1;
        }
        return Data;
      });
      setListJournals(newList);
      Calculation(newList);
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const View__JournalEntry = async (iJEId) => {
    setIsJournalOpen(!IsJournalOpen);
    Pre_Loading(dispatch, true);
    const res = await API_FETCH(
      "JournalEntry/View_JournelEntry/" + Number(iJEId) + "/" + _AuthParems.DB,
      dispatch
    );
    if (res) {
      let EntJournel = res.EntJournel;
      let EntHT = EntJournel.objEntHT[0];
      setstrDate(EntHT.strVouchDate);
      setiJEId(EntHT.iJEId);
      setstrPrefix(EntHT.strPrefix);
      setstrCompCode(EntHT.strCompCode);
      setstrFinanFromTo(EntHT.strFinanFrom);
      setiNumSeqNo(EntHT.iNumSeqNo);
      setstrTransNo(EntHT.strTransNo);
      setstrVoucherNo(EntHT.strVouchNo);
      setiSumDiffAmt(EntHT.dDiffAmt);
      setbStatus(EntHT.status);
      setiUserId(EntHT.iUserId);
      setListJournals(EntJournel.objEntDT);
      Calculation(EntJournel.objEntDT);
    }
  };
  const Modify_JournalEntry = (RowInfo) => {
    try {
      setstrLedName(RowInfo.ledName);
      setiCredit(RowInfo.cr);
      setiDebit(RowInfo.dr);
      setstrRemarks(RowInfo.remarks);
      setiLedId(Number(RowInfo.ledId));
      setstrEditMode("Modify");
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const Columns = [
    {
      Header: "SNO",
      accessor: "sNo",
      maxWidth: 70,
    },
    {
      Header: "LedgerCode",
      accessor: "ledCode",
      show: false,
    },
    {
      Header: "LedegerName",
      accessor: "ledName",
    },
    {
      Header: "Debit",
      accessor: "dr",
    },
    {
      Header: "Credit",
      accessor: "cr",
    },
    {
      Header: "Remarks",
      accessor: "remarks",
    },
    {
      Header: "Action",
      id: "ledId",
      maxWidth: 100,
      accessor: (rowinfo) => (
        <div>
          <i
            onClick={() => RemoveTableRow(rowinfo)}
            className="far fa-trash-alt"
          ></i>
        </div>
      ),
    },
  ];
  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <div className="Master-card1">
          <div className="row p-2">
            <div className="col-md-9 col-sm-12 ">
              <div className="fg-card  p-2">
                <div className="row">
                  <div className="col-md-4 col-sm-6 mb-2">
                    <label>Voucher No</label>
                    <input
                      type="text"
                      aria-label="First name"
                      className="form-control"
                      placeholder=" Voucher No"
                      value={strVoucherNo}
                      onChange={(e) => setstrVoucherNo(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6 mb-2">
                    <label>Date</label>
                    <input
                      type="date"
                      aria-label="First name"
                      className="form-control"
                      value={strDate}
                      onChange={(e) => setstrDate(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 mb-2">
                    <label>Ledger</label>
                    <SearchLedger
                      Close={(e) => setisledgOpen(!isledgOpen)}
                      SelectLedger={SelectLedger}
                      defaultval={strLedName}
                      tabIndex=""
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 mb-2">
                    <label>Debit</label>
                    <input
                      type="number"
                      aria-label="First name"
                      className="form-control"
                      placeholder="Enter Debit Amount"
                      onClick={(e) => e.target.select()}
                      value={iDebit}
                      onChange={(e) => setiDebit(e.target.value)}
                      onBlur={(e) => {
                        if (e.target.value === "")
                          setiDebit(parseInt(0).toFixed(2));
                      }}
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 mb-2">
                    <label>Credit</label>
                    <input
                      type="number"
                      aria-label="First name"
                      onClick={(e) => e.target.select()}
                      className="form-control"
                      placeholder="Enter Credit Amount"
                      value={iCredit}
                      onChange={(e) => setiCredit(e.target.value)}
                      onBlur={(e) => {
                        if (e.target.value === "")
                          setiCredit(parseInt(0).toFixed(2));
                      }}
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mb-2">
                    <label>Remarks</label>
                    <input
                      type="text"
                      aria-label="First name"
                      onClick={(e) => e.target.select()}
                      className="form-control"
                      placeholder=" Remarks"
                      value={strRemarks}
                      onChange={(e) => setstrRemarks(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6 col-md-2 mb-2">
                    <div className="trans-inputbtn">
                      <button type="button" onClick={AddTable}>
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <ReactTable
                  columns={Columns}
                  data={ListJournals}
                  minRows={10}
                  row_click={(rowInfo) => {}}
                  row_doubleclick={(rowInfo) => {
                    Modify_JournalEntry(rowInfo);
                  }}
                  background={true}
                  className="full-table"
                />
                <div className="btn-section">
                  <button
                    type="button"
                    className="btn-fabgreen"
                    onClick={(e) => {
                      if (parseFloat(iSumDiffAmt) !== 0) {
                        Show_Message(
                          dispatch,
                          "Credit and Debit Not Tally",
                          "info"
                        );
                      } else if (ListJournals.length < 2) {
                        Show_Message(
                          dispatch,
                          "Atleast Two Ledger Required",
                          "info"
                        );
                      } else {
                        Ask_Question(
                          dispatch,
                          Save_JournalEntry,
                          "Do You Want to  Save JournalEntry?"
                        );
                      }
                    }}
                  >
                    Save &nbsp; <i className="fas fa-Save"></i>
                  </button>
                  <button
                    type="button"
                    className="btn-fabgreen"
                    onClick={(e) => setIsJournalOpen(!IsJournalOpen)}
                  >
                    View &nbsp; <i className="fas fa-Save"></i>
                  </button>
                  <button
                    type="button"
                    className="btn-fabgreen"
                    onClick={(e) => {
                      if (Number(iJEId) === 0) {
                        Show_Message(
                          dispatch,
                          "Please Select Journal Entry",
                          "info"
                        );
                      } else {
                        Ask_Question(
                          dispatch,
                          Delete_JournalEntry,
                          "Do You Want to  Delete JournalEntry?"
                        );
                      }
                    }}
                  >
                    Delete &nbsp; <i className="fas fa-print"></i>
                  </button>
                  <button
                    type="button"
                    className="btn-fabgreen"
                    onClick={Clear_JournalEntry}
                  >
                    Clear &nbsp; <i className="fas fa-eraser"></i>
                  </button>
                  <button
                    type="button"
                    className="btn-fabgreen"
                    onClick={(e) => {
                      window.location.href = `/JustBill/DashBoard`;
                    }}
                  >
                    Close &nbsp; <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 ">
              <div className="fg-card ">
                <div className="col-md-12 col-sm-12 mb-2">
                  <label>Debit</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={iSumDebit}
                    readOnly
                  />
                </div>
                <div className="col-md-12 col-sm-12 mb-2">
                  <label>Credit</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={iSumCredit}
                    readOnly
                  />
                </div>
                <div className="col-md-12 col-sm-12 mb-2">
                  <div className="net-amount">
                    <h1>NET AMOUNT</h1>
                    <h2>
                      {new Intl.NumberFormat("en-GB", {
                        style: "currency",
                        currency: "INR",
                      }).format(Number(iSumDiffAmt).toFixed(2))}
                    </h2>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 mb-2">
                  <label>Remark</label>
                  <input
                    type="text"
                    aria-label="First name"
                    onClick={(e) => e.target.select()}
                    className="form-control"
                    placeholder="Enter Remark"
                    value={strSumRemarks}
                    onChange={(e) => setstrSumRemarks(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {IsJournalOpen && (
              <JournalView
                Close={(e) => setIsJournalOpen(!IsJournalOpen)}
                JournalView={View__JournalEntry}
              />
            )}
            <Messagedialogbox />
          </div>
        </div>
      </div>
    </>
  );
}
export default JournalEntry;
