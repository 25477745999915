import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import PartyCreation from "./PartyCreation";
import { API_POST } from "../General/Utility";
function ServiceSearch(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const _DFParem = useSelector((state) => state.Transstates.Default);
  const [arrEntService, setarrEntService] = useState([]);
  const [strSearch, setstrSearch] = useState("");
  const [isService, setisService] = useState(false);

  useEffect(() => {
    Search_Service("");
  }, []);
  const Search_Service = async (strtxt) => {
    setstrSearch(strtxt);
    const parems = {
      strSearch: strtxt,
      strDBName: _AuthParems.DB,
      iScrId: props.ScrId,
      iBranchId: Number(_AuthParems.Branch),
    };
    const res = await API_POST(`${props.Url}/Load_Service/`, parems, dispatch);
    if (res) {
      setarrEntService(res.EntSimpleSearch);
    }
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Service SEARCH </h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row pro-card">
            <div className="col-sm-12 col-md-12 mt-2">
              <div className="m-hsearch">
                <input
                  type="text"
                  autoCorrect="off"
                  autoComplete="off"
                  placeholder="Search Party"
                  tabIndex={52}
                  id="SearchParty"
                  onKeyDown={(e) => {
                    if (e.key === "ArrowDown" || e.key === "ArrowUp")
                      $(".option-list [tabindex=0]").focus();
                    else if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
                      $("#SearchParty").focus();
                    } else if (e.key === "Enter") {
                      if (arrEntService.length > 0) {
                      } else props.Close();
                    } else if (e.key === "Escape") {
                      props.Close();
                    }
                  }}
                  value={strSearch}
                  onChange={(e) => Search_Service(e.target.value)}
                  autoFocus
                />
                <i
                  className="fas fa-search"
                  onClick={(e) => Search_Service(strSearch)}
                ></i>
              </div>
            </div>
            <div className="option-list">
              {arrEntService.map((Service, Index) => (
                <button
                  key={Index}
                  tabIndex={Index}
                  onKeyDown={(e) => {
                    const arrlength = arrEntService.length - 1;
                    if (e.key === "ArrowDown") {
                      if (Index === arrlength) {
                        $(`.option-list [tabindex=${0}]`).focus();
                      } else {
                        $(`.option-list [tabindex=${Index + 1}]`).focus();
                      }
                    }
                    if (e.key === "ArrowUp") {
                      if (Index === 0) {
                        $(`.option-list [tabindex=${arrlength}]`).focus();
                      } else {
                        $(`.option-list [tabindex=${Index - 1}]`).focus();
                      }
                    }
                    if (e.key === "Enter") {
                      props.Close();
                    }
                  }}
                  onDoubleClick={(e) => props.Close()}
                >
                  <label>{Service.strName}</label>
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              onClick={(e) => setisService(!isService)}
            >
              <i className="fa-solid fa-circle-plus"></i>
              Create Service
            </button>
          </div>
        </div>
        {isService && !_DFParem.isJobWork && (
          <PartyCreation
            Close={(e) => setisService(!isService)}
            strScrType="Service"
          />
        )}
      </div>
    </div>
  );
}

export default ServiceSearch;
