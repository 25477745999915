import React, { useState } from "react";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import $ from "jquery";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import {
  API_FETCH,
  API_POST,
  DecryptData,
  EncryptData,
} from "../General/Utility";
import {
  Close_Session,
  EmployeeInput_Onchange,
  ShowHide_Popup,
} from "../features/Transactionstates";

function Sessionopenclose(props) {
  const _parem = useSelector((state) => state.SelectOption.parems);
  const _AuthParem = useSelector((state) => state.Common.Admin);
  const _Common = useSelector((store) => store.Transstates.Common);
  const _Employee = useSelector((store) => store.Transstates.Employee);

  const [dcashindrawer, setdcashindrawer] = useState((0).toFixed(2));
  const [strRemark, setstrRemark] = useState("");
  const [dCashout, setdCashout] = useState(0);
  const [dCashIn, setdCashIn] = useState(0);
  const [dSalAmt, setdSalAmt] = useState(0);
  const [dBalAmt, setdBalAmt] = useState(0);
  const [dOpenBal, setdOpenBal] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    Load_SessionMaster(_Common.iSessionId);
  }, []);
  const Load_SessionMaster = async (SessionId) => {
    try {
      await Pre_Loading(dispatch, true);
      if (Number(SessionId) > 0) {
        const res = await API_FETCH(
          `SessionMaster/Load_Session/${Number(SessionId)}/${Number(
            _AuthParem.Branch
          )}/${Number(_Common.iCounterId)}/${_AuthParem.DB}`,
          dispatch
        );
        if (res) {
          let objSession = res.EntSession[0];
          setdOpenBal(objSession.openBal);
          setdCashout(objSession.cashOut);
          setdSalAmt(objSession.salAmt);
          setdBalAmt(
            objSession.openBal + objSession.salAmt - objSession.cashOut
          );
          setdCashIn(objSession.salAmt);
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Clear_SessionParem = (SessionId) => {
    setdcashindrawer(0);
    setdOpenBal(0);
    setdCashout(0);
    setdSalAmt(0);
    setdBalAmt(0);
    setstrRemark("");
    Load_SessionMaster(SessionId);
  };
  const Session_Opening_Click = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const objEntSession = {
        iSessionId: 0,
        OpenBy: Number(_Employee.iEmpId),
        ActiveEmpId: Number(_Employee.iEmpId),
        OpenBal: Number(dcashindrawer),
        ClosigBal: 0,
        ClosedBy: 0,
        CounterId: Number(_Common.iCounterId),
        Remark: strRemark,
        strDBName: _AuthParem.DB,
        IsTabletMode: _Common.IsTabletMode,
        iUserId: Number(_AuthParem.User),
      };
      const res = await API_POST(
        `SessionMaster/InsUpd_Session`,
        objEntSession,
        dispatch
      );
      if (res) {
        await dispatch(ShowHide_Popup("IsSession"));
        await Show_Message(dispatch, res.strMessage, "success");
        const _LocalParems = await DecryptData(localStorage.jbctrlparams);
        _LocalParems.Session = res.iSessionId;
        localStorage.jbctrlparams = await EncryptData(_LocalParems);
        props.SessionOpen();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Session_Closing_Click = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const objEntSession = {
        iSessionId: Number(_Common.iSessionId),
        OpenBy: 0,
        OpenBal: 0,
        ClosigBal: Number(dcashindrawer),
        ClosedBy: Number(_Employee.iEmpId),
        CounterId: Number(_Common.iCounterId),
        Remark: strRemark,
        strDBName: _AuthParem.DB,
        IsTabletMode: _Common.IsTabletMode,
        iUserId: Number(_AuthParem.User),
      };
      const res = await API_POST(
        `SessionMaster/InsUpd_Session`,
        objEntSession,
        dispatch
      );
      if (res) {
        await dispatch(Close_Session(0));
        await Show_Message(dispatch, res.strMessage, "success");
        await Clear_SessionParem(0);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Amounttorupeess = (amt) => {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "INR",
    }).format(Number(amt).toFixed(2));
  };
  const Select_Employee = async (Emp) => {
    await dispatch(
      EmployeeInput_Onchange({
        iEmpId: Emp.iEmpId,
        strEmployeeName: Emp.strEmpName,
        iRoleId: Emp.iRoleId,
        strEmpRole: Emp.strEmpRole,
        dRewardPoint: Emp.dRewardPoint,
      })
    );
  };
  return (
    <div className="popup-container">
      <div className="popup-content sub">
        <div className="popup-header">
          <h5>Session Master</h5>
          {Number(_Common.iSessionId) !== 0 && (
            <div className="icon-popup-btn-grp">
              <span
                className="icon-btn"
                onClick={() => dispatch(ShowHide_Popup("IsSession"))}
              >
                <i className="fa-regular fa-rectangle-xmark"></i>
              </span>
            </div>
          )}
        </div>
        <div className="popup-body p-0 pb-2">
          <div className="row p-0">
            {Number(_Common.iSessionId) === 0 && (
              <div className="col-md-12 col-sm-12 mt-1">
                <label>Employee Name</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_parem.isoptionShow}
                  placeholder="Select Sales Person"
                  select_value={(emp) => Select_Employee(emp)}
                  btnname="Employee"
                  btnshow={false}
                  show_popup={""}
                  valueonly={false}
                  defaultval={_Employee.iEmpId}
                  displayName="strEmpName"
                  disvalue="iEmpId"
                  arrydata={_Common.EntEmployee}
                  disabled={false}
                  EmptVal="Employee"
                />
              </div>
            )}
            <div className="col-md-12 col-sm-12 mt-1">
              <label>Cash In Drawer</label>
              <input
                type="number"
                className="form-control"
                value={dcashindrawer}
                onChange={(e) => setdcashindrawer(e.target.value)}
                onClick={(e) => e.target.select()}
                placeholder="Cash In Drawer"
                tabIndex={998}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "ArrowDown") {
                    $("[tabindex=999]").focus();
                  }
                  if (e.key === "ArrowUp") {
                    $("[tabindex=1000]").focus();
                  }
                }}
                autoFocus
              />
            </div>
            <ul className="session-summary">
              <li>
                <p>Opening Balance</p>
                <p>{Amounttorupeess(dOpenBal)}</p>
              </li>
              <li>
                <p>Sales Amount</p>
                <p>{Amounttorupeess(dSalAmt)}</p>
              </li>
              <li>
                <p>Cash in</p>
                <p>{Amounttorupeess(dCashIn)}</p>
              </li>
              <li>
                <p>Cash Out</p>
                <p>{Amounttorupeess(dCashout)}</p>
              </li>
              <li>
                <p>Balance Amount</p>
                <p>{Amounttorupeess(dBalAmt)}</p>
              </li>
            </ul>
            <div className="col-md-12 col-sm-12">
              <label>Notes</label>
              <textarea
                type="text"
                className="form-control"
                style={{ minHeight: "70px" }}
                value={strRemark}
                onChange={(e) => setstrRemark(e.target.value)}
                placeholder="Notes"
                onClick={(e) => e.target.select()}
                tabIndex={999}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "ArrowDown") {
                    $("[tabindex=1000]").focus();
                  }
                  if (e.key === "ArrowUp") {
                    $("[tabindex=998]").focus();
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            {Number(_Common.iSessionId) === 0 && (
              <button
                className="btn-fabgreen"
                tabIndex={1000}
                onKeyDown={(e) => {
                  if (e.key === "ArrowDown") {
                    $("[tabindex=1001]").focus();
                  }
                  if (e.key === "ArrowUp") {
                    $("[tabindex=999]").focus();
                  }
                }}
                onClick={(e) => {
                  if (Number(dcashindrawer) === 0)
                    Show_Message(
                      dispatch,
                      "Please Enter Amount in Cash Drawer",
                      "info"
                    );
                  else if (Number(_Employee.iEmpId) === 0)
                    Show_Message(
                      dispatch,
                      "Please Select Sales Person",
                      "info"
                    );
                  else
                    Ask_Question(
                      dispatch,
                      Session_Opening_Click,
                      "Do You Want to Start Session?"
                    );
                }}
              >
                Start Session &nbsp; <i className="fa-regular fa-clock"></i>
              </button>
            )}
            {Number(_Common.iSessionId) === 0 && (
              <button
                onClick={(e) => Clear_SessionParem(_Common.iSessionId)}
                className="btn-fabgreen"
                tabIndex={1001}
                onKeyDown={(e) => {
                  if (e.key === "ArrowDown") {
                    $("[tabindex=998]").focus();
                  }
                  if (e.key === "ArrowUp") {
                    $("[tabindex=1000]").focus();
                  }
                }}
              >
                Clear&nbsp;<i className="fa-solid fa-broom"></i>
              </button>
            )}
            {Number(_Common.iSessionId) > 0 && (
              <button
                className="btn-fabgreen"
                tabIndex={1000}
                onKeyDown={(e) => {
                  if (e.key === "ArrowDown") {
                    $("[tabindex=998]").focus();
                  }
                  if (e.key === "ArrowUp") {
                    $("[tabindex=999]").focus();
                  }
                }}
                onClick={(e) => {
                  if (Number(dcashindrawer) === 0)
                    Show_Message(
                      dispatch,
                      "Please Enter Amount in Cash Drawer",
                      "info"
                    );
                  else if (Number(dcashindrawer) !== Number(dBalAmt))
                    Show_Message(
                      dispatch,
                      "Cash In Drawer Amount Does't Match With Balance Amount",
                      "info"
                    );
                  else
                    Ask_Question(
                      dispatch,
                      Session_Closing_Click,
                      "Do You Want to Close Session?"
                    );
                }}
              >
                End Session&nbsp; <i className="fa-regular fa-clock"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sessionopenclose;
