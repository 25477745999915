//#region Import Default from React Library
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
//#endregion

//#region  Css Scripts
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "./Components/Css/MasterScreen.css";
import "./Components/Css/TransactionScreen.css";
import "./Components/Css/DashBoard.css";
import "./Components/Css/MenuBar.css";
import "./Components/Css/Common.css";
import "./Components/Css/POS.css";
//#endregion

//#region Global Storage ManageMent-Redux

import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import PointofsalesReducer from "./Components/features/POS";
import SelectReducer from "./Components/features/Customeselect";
import TransactionReducer from "./Components/features/Transactionstates";
import MessageReducer from "./Components/features/Message";
import MasterReducer from "./Components/features/Master";
import AccountsReducer from "./Components/features/Accounts";
import ManualInvoiceReducer from "./Components/features/ManualInvoice";
import ConfigurationReducer from "./Components/features/Configuration";
import CommonStatesReducer from "./Components/features/CommonStates";
const store = configureStore({
  reducer: {
    Pointofsales: PointofsalesReducer,
    SelectOption: SelectReducer,
    Transstates: TransactionReducer,
    Message: MessageReducer,
    Master: MasterReducer,
    Accounts: AccountsReducer,
    Invoice: ManualInvoiceReducer,
    Config: ConfigurationReducer,
    Common: CommonStatesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
//#endregion

//#region  Render React Application
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
reportWebVitals();
//#endregion
