import React, { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import $ from "jquery";
import { Ask_Question, Show_Message } from "../General/Messagedialogbox";
import { useDispatch, useSelector } from "react-redux";
import PartyCreation from "../Transaction/PartyCreation";
import ServiceSearch from "../Transaction/ServiceSearch";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import CustomTransView from "../Transaction/CustomTransView";
import Messagedialogbox from "../General/Messagedialogbox";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import ReactTable from "../General/ReactTable";
function ExpenseIncome() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _Selectparem = useSelector((state) => state.SelectOption.parems);
  //#region  Parems
  const [iScrId, setiScrId] = useState(66);
  const [iPartyId, setiPartyId] = useState(0);
  const [iEIId, setiEIId] = useState(0);
  const [strPartyName, setstrPartyName] = useState("");
  const [strPartyAdd, setstrPartyAdd] = useState("");
  const [strVouchNo, setstrVouchNo] = useState("");
  const [strVouchDate, setstrVouchDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [strTransNo, setstrTransNo] = useState("");
  const [strRemark1, setstrRemark1] = useState("");
  const [strRemark2, setstrRemark2] = useState("");
  const [strRemark3, setstrRemark3] = useState("");
  const [strRemark4, setstrRemark4] = useState("");
  const [iEITypeId, setiEITypeId] = useState(1);
  const [iVenTypeId, setiVenTypeId] = useState(2);
  const [iTaxCalcOnId, setiTaxCalcOnId] = useState(0);
  const [iTaxRelId, setiTaxRelId] = useState(0);
  const [iBillTypeId, setiBillTypeId] = useState(0);

  const [iSerId, setiSerId] = useState(0);
  const [strSerName, setstrSerName] = useState("");
  const [dPriceRate, setdPriceRate] = useState(0);
  const [isDiscPer, setisDiscPer] = useState(true);
  const [dItemDisc, setdItemDisc] = useState(0);
  const [dItemDiscPer, setdItemDiscPer] = useState(0);
  const [dItemDiscAmt, setdItemDiscAmt] = useState(0);
  const [strNetAmt, setstrNetAmt] = useState("");
  const [dBillDisPer, setdBillDisPer] = useState(0);
  const [dBillDisAmt, setdBillDisAmt] = useState(0);
  const [dBillDisValue, setdBillDisValue] = useState(0);
  const [iGoodsValue, setiGoodsValue] = useState(0);
  const [iTotalDisc, setiTotalDisc] = useState(0);
  const [iTotalTax, setiTotalTax] = useState(0);
  const [iRountoff, setiRountoff] = useState(0);
  const [iRowIndex, setiRowIndex] = useState(0);

  const [strSetCurrentStatus, setstrSetCurrentStatus] = useState("");
  const [bUpdateStatus, setbUpdateStatus] = useState(false);
  const [isNewParty, setisNewParty] = useState(false);
  const [isService, setisService] = useState(false);
  const [isServiceVendore, setisServiceVendore] = useState(false);
  const [isOption, setisOption] = useState(false);
  const [strSearch, setstrSearch] = useState("");

  const [selected, setselected] = useState(0);
  const [EIListDT, setEIListDT] = useState([]);
  const [EIListTax, setEIListTax] = useState([]);
  const [EIListSummary, setEIListSummary] = useState([]);
  const [EIItemRemark, setEIItemRemark] = useState([]);
  const [EIRootCalc, setEIRootCalc] = useState([]);
  const [BillType, setBillType] = useState([]);
  const [VenType, setVenType] = useState([]);
  const [EIType, setEIType] = useState([]);
  const [TaxType, setTaxType] = useState([]);
  const [NoFormat, setNoFormat] = useState([]);
  const [arrParty, setarrParty] = useState([]);
  const [ListValidateProperties, setListValidateProperties] = useState([]);
  const [ListTabOrder, setListTabOrder] = useState({
    txtPartyName: "",
    txtBillAddress: "",
    cbBillType: "",
    cbTaxType: "",
    txtTransNo: "",
    txtEINo: "",
    dpEIDate: "",

    txtBillDiscPer: "",
    txtBillDiscAmt: "",
    txtBillDiscValue: "",

    btnNew: "",
    btnModify: "",
    btnSave: "",
    btnClear: "",
    btnView: "",
    btnExportImport: "",
    btnPrint: "",
    btnCancel: "",
    btnClose: "",
    btnPrintSettings: "",
    btnHelp: "",
    txtRemark1: "",
    txtRemark2: "",
    txtRemark3: "",
    txtRemark4: "",
    btnRefresh: "",

    TabParty: "",
    TabVoucher: "",
    TabRemarks: "",
    TabSummary: "",
    TabTax: "",
    TabDiscount: "",
    TabName: "Party",

    ServiceListTab: "",
    txtServiceName: "",
    txtRate: "",
    txtDiscount: "",
    btnAdd: "",
    isDiscPer: "",
  });

  const [isTransView, setisTransView] = useState(false);
  const [isViewBtn, setisViewBtn] = useState(false);
  const [isSaveBtn, setisSaveBtn] = useState(false);
  const [isDeleteBtn, setisDeleteBtn] = useState(false);

  //#endregion
  useEffect(() => {
    Load_ExponeIncome();
    ButtonPermission();
  }, []);

  const Load_ExponeIncome = async () => {
    const _LocalParems = await DecryptData(localStorage.jbctrlparams);
    const res = await API_FETCH(
      `ExpenseIncome/Load_ExpensseIncome/${Number(_LocalParems.Branch)}/${
        _LocalParems.DB
      }`,
      dispatch
    );
    if (res) {
      setBillType(res.objBillType);
      setTaxType(res.objTaxType);
      setVenType(res.objVenType);
      setEIType(res.objEIType);
      setNoFormat(res.objNoFormat);
      setstrTransNo(res.objNoFormat[0].strTransNo);
      setstrVouchNo(res.objNoFormat[0].strUserNo);
      setiTaxCalcOnId(res.iTaxCalcOnId);
      setiBillTypeId(Number(res.lastBillType));
      setListValidateProperties(res.Transtab);
      TabOrderAssign(res.Transtab);

      setEIListSummary([
        {
          netAmt: 0,
          totalDiscount: 0,
          goodsValue: 0,
          totalTax: 0,
          roundOff: 0,
        },
      ]);
      setstrNetAmt("₹0.00");
      setEIItemRemark([
        {
          strRemark1: "",
          strRemark2: "",
          strRemark3: "",
          strRemark4: "",
        },
      ]);
      setEIRootCalc([
        {
          dValu1: 1,
          dValue2: 1,
          dValue3: 1,
          dConstantValue: 1,
          dFinalValue: 1,
          strOp1: "0",
          strOp2: "0",
          strOp3: "0",
        },
      ]);
    }
  };
  const View_Party = async (iPartyId) => {
    setiPartyId(iPartyId);
    const res = await API_FETCH(
      `ExpenseIncome/View_Party/${iPartyId}/${iVenTypeId}/${Number(
        _AuthParems.Branch
      )}/${_AuthParems.DB}`,
      dispatch
    );
    if (res) {
      setstrPartyAdd(res.objPartyDetails[1]);
      setstrPartyName(res.objPartyDetails[0]);
      setiTaxRelId(res.iTaxRelId);
    }
  };
  const Add_Service = async () => {
    const res = await API_POST(
      "ExpenseIncome/Add_Service/",
      {
        bUpdateStatus: bUpdateStatus,
        iRowIndex: iRowIndex,
        iScrId: iScrId,
        iTaxRelId: iTaxRelId,
        iPartyId: iPartyId,
        iTaxCalcOnId: iTaxCalcOnId,
        iSerId: Number(iSerId),
        dPriceRate: Number(dPriceRate),
        dDiscountPer: isDiscPer ? Number(dItemDisc) : 0,
        dDiscountAmt: !isDiscPer ? Number(dItemDisc) : 0,
        objBaseTransDT: EIListDT,
        objEntAddLess: [],
        objEntConRow: [],
        objEntTax: [],
        objItemRemark: EIItemRemark[0],
        objPenCon: [],
        objRootCalc: EIRootCalc[0],
        strColumn: "",
        strJobId: "",
        strProAttDTId: "",
        strReason: "",
        strSearch: "",
        strSetCurrentStatus: strSetCurrentStatus,
        strStoAttDTId: "",
        strTraAttDTId: "",
        iYearId: Number(_AuthParems.Year),
        strDBName: _AuthParems.DB,
        iRoleId: Number(_AuthParems.User),
        iBranchId: Number(_AuthParems.Branch),
      },
      dispatch
    );
    if (res) {
      setEIListDT(res.objEntDT);
      setEIListTax(res.objEntTax);
      setEIListSummary(res.objEntSummary);

      let Summary = res.objEntSummary[0];
      setiGoodsValue(Summary.goodsValue);
      setiTotalTax(Summary.totalTax);
      setiRountoff(Summary.roundOff);
      setiTotalDisc(Summary.totalDiscount);
      setstrNetAmt(
        new Intl.NumberFormat("en-GB", {
          style: "currency",
          currency: "INR",
        }).format(Summary.netAmt)
      );
      Clear_Service();
    }
  };
  const Clear_Service = () => {
    setiSerId(0);
    setstrSerName("");
    setdItemDiscPer(0);
    setdItemDisc(0);
    setdItemDiscAmt(0);
    setdPriceRate(0);
    setbUpdateStatus(false);
    setisDiscPer(true);
  };
  const AssignSelectedService = (SerInfo, Index) => {
    setiRowIndex(Index);
    setbUpdateStatus(true);
    setiSerId(SerInfo.SerId);
    setstrSerName(SerInfo.SerName);
    setdPriceRate(SerInfo.Rate);
    setdItemDisc(
      SerInfo.ItemDiscPer > SerInfo.ItemDiscAmt
        ? SerInfo.ItemDiscPer
        : SerInfo.ItemDiscAmt
    );
    setisDiscPer(SerInfo.ItemDiscPer > SerInfo.ItemDiscAmt ? true : false);
  };
  const DeleteService = async (iIndex) => {
    const res = await API_POST(
      "ExpenseIncome/DeleteService/",
      {
        iRowIndex: iIndex,
        iScrId: iScrId,
        iTaxRelId: iTaxRelId,
        iPartyId: iPartyId,
        iTaxCalcOnId: iTaxCalcOnId,
        iSerId: iSerId,
        dPriceRate: Number(dPriceRate),
        dDiscountPer: Number(dItemDiscPer),
        dDiscountAmt: Number(dItemDiscAmt),
        objBaseTransDT: EIListDT,
        objEntAddLess: [],
        objEntConRow: [],
        objEntTax: [],
        objItemRemark: EIItemRemark[0],
        objPenCon: [],
        objRootCalc: EIRootCalc[0],
        strColumn: "",
        strJobId: "",
        strProAttDTId: "",
        strReason: "",
        strSearch: "",
        strSetCurrentStatus: strSetCurrentStatus,
        strStoAttDTId: "",
        strTraAttDTId: "",
        strDBName: _AuthParems.DB,
      },
      dispatch
    );
    if (res) {
      setEIListDT(res.objEIDT);
      setEIListTax(res.objTaxDT);
      setEIListSummary(res.objEISummary);
      setstrNetAmt(
        new Intl.NumberFormat("en-GB", {
          style: "currency",
          currency: "INR",
        }).format(res.objEISummary[0].netAmt)
      );
    }
  };
  const ViewClick = async (iVouchId) => {
    setiEIId(iVouchId);
    const res = await API_FETCH(
      `ExpenseIncome/View_Voucher/${iVouchId}/${Number(_AuthParems.Branch)}/${
        _AuthParems.DB
      }`,
      dispatch
    );
    if (res) {
      setstrPartyName(res.objPartyList[0]);
      setstrPartyAdd(res.objPartyList[1]);
      setiPartyId(res.objEntHT[0].iPartyId);
      setNoFormat([
        {
          iNumSeqNo: res.objEntHT[0].iNumSeqNo,
          strPrefix: res.objEntHT[0].strPrefix,
          strCompCode: res.objEntHT[0].strCompCode,
          strFinanFrom: res.objEntHT[0].strFinanFrom,
          strTransNo: res.objEntHT[0].strTransNo,
        },
      ]);
      setstrVouchNo(res.objEntHT[0].strVouchNo);
      setstrVouchDate(res.objEntHT[0].strVouchDate);
      setiBillTypeId(res.objEntHT[0].iBillTypeId);
      setiTaxCalcOnId(res.objEntHT[0].iTaxCalcOnId);
      setiTaxRelId(res.objEntHT[0].iTaxTypeId);
      setstrRemark1(res.objEntHT[0].strRemark1);
      setstrRemark2(res.objEntHT[0].strRemark2);
      setstrRemark3(res.objEntHT[0].strRemark3);
      setstrRemark4(res.objEntHT[0].strRemark4);
      setdBillDisPer(res.objEntHT[0].dTBillDiscPer);
      setdBillDisAmt(res.objEntHT[0].dTBillDiscAmt);
      setdBillDisValue(res.objEntHT[0].dTBillDiscValue);
      let objEIDT = res.objEntDT;
      for (var i = 0; i < objEIDT.length; i++) {
        var Keys = Object.keys(objEIDT[i]);
        for (var D = 1; D < Keys.length; D++) {
          objEIDT[i][Keys[D]] =
            objEIDT[i][Keys[D]] === null ? "" : objEIDT[i][Keys[D]];
        }
      }
      setEIListDT(objEIDT);
      setEIListTax(res.objEntTax);
      let Summary = res.objEntSummary[0];
      setiGoodsValue(Summary.goodsValue);
      setiTotalTax(Summary.totalTax);
      setiRountoff(Summary.roundOff);
      setiTotalDisc(Summary.totalDiscount);
      setEIListSummary(res.objEntSummary);
      setstrNetAmt(
        new Intl.NumberFormat("en-GB", {
          style: "currency",
          currency: "INR",
        }).format(Summary.netAmt)
      );
    }
  };
  const DeleteClick = async () => {
    if (iEIId === 0) {
      Show_Message(dispatch, "Please Select Voucher to Delete!", "error");
    } else {
      const res = await API_FETCH(
        `ExpenseIncome/Delete_ExpenseIncome/${iEIId}/${Number(
          _AuthParems.Branch
        )}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res, "success");
      }
    }
  };
  const SaveClick = async () => {
    if (iBillTypeId === 0) {
      Show_Message(dispatch, "Bill Type is Required", "error");
    } else if (EIListDT.length === 0) {
      Show_Message(dispatch, "Atleast One Item is Required per Bill", "error");
    } else {
      const EIListHT = [
        {
          iEIId: iEIId,
          iBranchId: Number(_AuthParems.Branch),
          iTitleTag: iScrId,
          iPartyId: iPartyId,
          iNumSeqNo: NoFormat[0].iNumSeqNo,
          strPrefix: NoFormat[0].strPrefix,
          strCompCode: NoFormat[0].strCompCode,
          strFinanFrom: NoFormat[0].strFinanFrom,
          strTransNo: NoFormat[0].strTransNo,
          strVouchNo: strVouchNo,
          strVouchDate: strVouchDate,
          iBillTypeId: iBillTypeId,
          iTaxCalcOnId: iTaxCalcOnId,
          iTaxTypeId: iTaxRelId,
          iVenTypeId: iVenTypeId,
          iEITypeId: iEITypeId,
          strRemark1: strRemark1,
          strRemark2: strRemark2,
          strRemark3: strRemark3,
          strRemark4: strRemark4,
          dTBillDiscPer: Number(dBillDisPer),
          dTBillDiscAmt: Number(dBillDisAmt),
          dTBillDiscValue: Number(dBillDisValue),
          dTotGoodval: Number(EIListSummary[0].goodsValue),
          dTotDiscount: Number(EIListSummary[0].totalDiscount),
          dTotTax: Number(EIListSummary[0].totalTax),
          dRoundOff: Number(EIListSummary[0].roundOff),
          dNetAmt: Number(EIListSummary[0].netAmt),
        },
      ];
      const res = await API_POST(
        "ExpenseIncome/InsUpd_ExpenseIncome/",
        {
          objEntSI: EIListHT[0],
          objBaseTransDT: EIListDT,
          objEntTax: EIListTax,
          strDBName: _AuthParems.DB,
        },
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    }
  };
  const ClearClick = () => {
    setstrPartyName("");
    setstrPartyAdd("");
    setstrTransNo("");
    setstrVouchNo("");
    setstrVouchDate(new Date().toISOString().split("T")[0]);
    setiTaxRelId(0);
    setiBillTypeId(0);
    setiEITypeId(1);
    setiVenTypeId(2);
    setstrRemark1("");
    setiEIId(0);
    setiRowIndex(0);
    setisDiscPer(true);
    setiScrId(66);
    setiPartyId(0);
    setstrRemark2("");
    setstrRemark3("");
    setstrRemark4("");
    setdBillDisPer(0);
    setdBillDisAmt(0);
    setdBillDisValue(0);
    setstrNetAmt(0);
    setEIListDT([]);
    setEIListDT([]);
    setEIListTax([]);
    setiGoodsValue(0);
    setiTotalDisc(0);
    setiTotalTax(0);
    setiRountoff(0);
    Clear_Service();
    Load_ExponeIncome();
  };
  const BillDiscPer_onKeyDown = async (e) => {
    setdBillDisPer(Number(e.target.value));
    const res = await API_POST(
      "ExpenseIncome/CalculateBillDiscount/",
      {
        strCase: "BillDicPer",
        iScrId: iScrId,
        iTaxTypeId: iTaxRelId,
        iTaxCalcOnId: iTaxCalcOnId,
        iPartyId: iPartyId,
        dTBillDiscPer: Number(e.target.value),
        dTBillDiscAmt: Number(dBillDisAmt),
        dTBillDiscValue: Number(dBillDisValue),
        objBaseTransDT: EIListDT,
        strDBName: _AuthParems.DB,
      },
      dispatch
    );
    if (res) {
      setEIListDT(res.objEIDT);
      setEIListTax(res.objTaxDT);
      setEIListSummary(res.objEISummary);
      setstrNetAmt(
        new Intl.NumberFormat("en-GB", {
          style: "currency",
          currency: "INR",
        }).format(res.objEISummary[0].netAmt)
      );
      setdBillDisAmt(parseFloat(res.dBillDiscAmt).toFixed(2));
      setdBillDisValue(parseFloat(res.dBillDiscValue).toFixed(2));
    }
  };
  const BillDiscAmt_onKeyDown = async (e) => {
    setdBillDisAmt(Number(e.target.value));
    const res = await API_POST(
      "ExpenseIncome/CalculateBillDiscount/",
      {
        strCase: "BillDicAmt",
        iScrId: iScrId,
        iTaxTypeId: iTaxRelId,
        iTaxCalcOnId: iTaxCalcOnId,
        iPartyId: iPartyId,
        dTBillDiscPer: Number(dBillDisPer),
        dTBillDiscAmt: Number(e.target.value),
        dTBillDiscValue: Number(dBillDisValue),
        objBaseTransDT: EIListDT,
        strDBName: _AuthParems.DB,
      },
      dispatch
    );
    if (res) {
      setEIListDT(res.objEIDT);
      setEIListTax(res.objTaxDT);
      setEIListSummary(res.objEISummary);
      setstrNetAmt(
        new Intl.NumberFormat("en-GB", {
          style: "currency",
          currency: "INR",
        }).format(res.objEISummary[0].netAmt)
      );
      setdBillDisPer(parseFloat(res.dBillDiscPer).toFixed(2));
      setdBillDisValue(parseFloat(res.dBillDiscValue).toFixed(2));
    }
  };
  const TabOrderAssign = (EntProperties) => {
    let TabOrder = ListTabOrder;
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      switch (EntProperties[i].strCtrlName) {
        case "txtPartyName":
          TabOrder.txtPartyName = EntProperties[i].iTabIndex;
          break;
        case "txtBillAddress":
          TabOrder.txtBillAddress = EntProperties[i].iTabIndex;
          break;
        case "txtBillDiscValue":
          TabOrder.txtBillDiscValue = EntProperties[i].iTabIndex;
          break;
        case "cbBillType":
          TabOrder.cbBillType = EntProperties[i].iTabIndex;
          break;
        case "cbTaxType":
          TabOrder.cbTaxType = EntProperties[i].iTabIndex;
          break;
        case "txtTransNo":
          TabOrder.txtTransNo = EntProperties[i].iTabIndex;
          break;
        case "txtEINo":
          TabOrder.txtEINo = EntProperties[i].iTabIndex;
          break;
        case "dpEIDate":
          TabOrder.dpEIDate = EntProperties[i].iTabIndex;
          break;
        case "txtBillDiscPer":
          TabOrder.txtBillDiscPer = EntProperties[i].iTabIndex;
          break;
        case "txtBillDiscAmt":
          TabOrder.txtBillDiscAmt = EntProperties[i].iTabIndex;
          break;
        case "btnNew":
          TabOrder.btnNew = EntProperties[i].iTabIndex;
          break;
        case "btnModify":
          TabOrder.btnModify = EntProperties[i].iTabIndex;
          break;
        case "btnSave":
          TabOrder.btnSave = EntProperties[i].iTabIndex;
          break;
        case "btnClear":
          TabOrder.btnClear = EntProperties[i].iTabIndex;
          break;
        case "btnView":
          TabOrder.btnView = EntProperties[i].iTabIndex;
          break;
        case "btnExportImport":
          TabOrder.btnExportImport = EntProperties[i].iTabIndex;
          break;
        case "btnPrint":
          TabOrder.btnPrint = EntProperties[i].iTabIndex;
          break;
        case "btnCancel":
          TabOrder.btnCancel = EntProperties[i].iTabIndex;
          break;
        case "btnClose":
          TabOrder.btnClose = EntProperties[i].iTabIndex;
          break;
        case "btnPrintSettings":
          TabOrder.btnPrintSettings = EntProperties[i].iTabIndex;
          break;
        case "btnHelp":
          TabOrder.btnHelp = EntProperties[i].iTabIndex;
          break;
        case "txtRemark1":
          TabOrder.txtRemark1 = EntProperties[i].iTabIndex;
          break;
        case "txtRemark2":
          TabOrder.txtRemark2 = EntProperties[i].iTabIndex;
          break;
        case "txtRemark3":
          TabOrder.txtRemark3 = EntProperties[i].iTabIndex;
          break;
        case "txtRemark4":
          TabOrder.txtRemark4 = EntProperties[i].iTabIndex;
          break;
        case "TopLeftParty":
          TabOrder.TabParty = EntProperties[i].iTabIndex;
          break;
        case "TopCenterVoucher":
          TabOrder.TabVoucher = EntProperties[i].iTabIndex;
          break;
        case "TopRightRemarks":
          TabOrder.TabRemarks = EntProperties[i].iTabIndex;
          break;
        case "MiddleRightSummary":
          TabOrder.TabSummary = EntProperties[i].iTabIndex;
          break;
        case "MiddleRightTax":
          TabOrder.TabTax = EntProperties[i].iTabIndex;
          break;
        case "MiddleRightDiscount":
          TabOrder.TabDiscount = EntProperties[i].iTabIndex;
          break;
        case "btnRefresh            ":
          TabOrder.btnRefresh = EntProperties[i].iTabIndex;
          break;
        default:
          break;
      }
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    ); //returns object
    sessionStorage.maxTab = maxTab.iTabIndex;
    setListTabOrder(TabOrder);
  };
  const KeyDown = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "Enter") {
      if (ActiveIndex >= 0 && ActiveIndex < maxTab) {
        if (
          ActiveIndex + 1 === ListTabOrder.ServiceListTab &&
          EIListDT.length > 0
        ) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          ActiveIndex = ActiveIndex + 1;
          $("[tabindex='" + ActiveIndex + "']").focus();
          $("[tabindex='" + ActiveIndex + "']").select();
        }
      } else {
        $("[tabindex='" + 0 + "']").focus();
        $("[tabindex='" + 0 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 0 && ActiveIndex < maxTab) {
        if (
          ActiveIndex + 1 === ListTabOrder.ServiceListTab &&
          EIListDT.length > 0
        ) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          ActiveIndex = ActiveIndex + 1;
          $("[tabindex='" + ActiveIndex + "']").focus();
          $("[tabindex='" + ActiveIndex + "']").select();
        }
      } else {
        $("[tabindex='" + 0 + "']").focus();
        $("[tabindex='" + 0 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (
          ActiveIndex - 1 === ListTabOrder.ServiceListTab &&
          EIListDT.length > 0
        ) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          ActiveIndex = ActiveIndex - 1;
          $("[tabindex='" + ActiveIndex + "']").focus();
          $("[tabindex='" + ActiveIndex + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder.ServiceListTab && EIListDT.length > 0) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + maxTab + "']").focus();
          $("[tabindex='" + maxTab + "']").select();
        }
      }
    }
  };
  const PropertiesValidation = () => {
    var ErrorMsg = true;
    let ValidateItem = ListValidateProperties.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtPartyName":
          ErrorMsg = strPartyName === "" ? false : true;
          break;
        case "cbBillType":
          ErrorMsg = parseFloat(iBillTypeId) === 0 ? false : true;
          break;
        case "cbTaxType":
          ErrorMsg = parseFloat(iTaxRelId) === 0 ? false : true;
          break;
        case "txtTransNo":
          ErrorMsg = strTransNo === "" ? false : true;
          break;
        case "txtEINo":
          ErrorMsg = strVouchNo === "" ? false : true;
          break;
        case "txtRemark1":
          ErrorMsg = strRemark1 === "" ? false : true;
          break;
        case "txtRemark2":
          ErrorMsg = strRemark2 === "" ? false : true;
          break;
        case "txtRemark3":
          ErrorMsg = strRemark3 === "" ? false : true;
          break;
        case "txtRemark4":
          ErrorMsg = strRemark4 === "" ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        Show_Message(dispatch, ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };
  const Searchparty = async (SearchText) => {
    setstrSearch(SearchText);
    const parems = {
      iScrId: 3,
      iPartyId: iVenTypeId,
      strColName: "SuppName",
      strSearch: SearchText,
      strDBName: _AuthParems.DB,
      iBranchId: Number(_AuthParems.Branch),
    };
    const res = await API_POST(`PartyTrans/Search_Party`, parems, dispatch);
    if (res) {
      setarrParty(res.EntParty);
    }
  };
  const ButtonPermission = async () => {
    const AuthParems = await DecryptData(localStorage.jbctrlparams);
    let ListMenu = AuthParems.AccountsPer.filter(
      (Data) => Data.iScrId === 66
    )[0];
    setisSaveBtn(iEIId > 0 ? !ListMenu.isModify : !ListMenu.isCreate);
    setisDeleteBtn(!ListMenu.isDelete);
    setisViewBtn(!ListMenu.isView);
  };
  const columns = [
    {
      Header: "SNo",
      accessor: "sNo",
      maxWidth: 70,
      style: { justifyContent: "center" },
    },
    {
      Header: "Service Name",
      accessor: "serName",
      minWidth: 200,
      style: { justifyContent: "left" },
    },
    {
      Header: "Rate W/O Tax",
      accessor: "rateWithoutTax",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Rate + Tax",
      accessor: "rateWithTax",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Goods Value",
      accessor: "goodsValue",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Disc %",
      accessor: "itemDiscPer",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Disc Amt",
      accessor: "itemDiscAmt",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Disc Value",
      accessor: "itemDiscValue",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Bill Disc",
      accessor: "billDiscValue",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Tax %",
      accessor: "taxPer",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "center" },
    },
    {
      Header: "Tax Amt",
      accessor: "taxAmt",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Amount",
      accessor: "itemAmt",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Options",
      id: "proId",
      maxWidth: 70,
      style: { justifyContent: "center" },
      accessor: (rowInfo) => (
        <div>
          <i
            onClick={() => DeleteService(rowInfo.sNo)}
            className="far fa-trash-alt"
          ></i>
        </div>
      ),
    },
  ];
  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Trans-container">
        <div className="row mt-2">
          <div className="col-md-4 col-sm-12 mt-1">
            <label>
              Name <span className="danger">*</span>
            </label>
            <div
              className="custom-select"
              onMouseLeave={(e) => setisOption(false)}
            >
              <input
                className="form-control"
                disabled={
                  iEIId > 0
                    ? true
                    : iPartyId > 0 && EIListDT.length > 0
                    ? true
                    : false
                }
                type="text"
                placeholder="Select Service Vendore"
                defaultValue={strPartyName}
                onClick={(e) => {
                  setisOption(!isOption);
                  Searchparty("");
                }}
                tabIndex={ListTabOrder.txtPartyName}
              />
              <i className="fa-solid fa-caret-down"></i>
              {isOption && (
                <div className="custom-option">
                  <div className="search-option">
                    <input
                      type="text"
                      placeholder="Search Service Vendore"
                      value={strSearch}
                      onChange={(e) => Searchparty(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "ArrowDown") {
                          $(".option-list [tabindex=0]").focus();
                        }
                      }}
                      autoFocus
                    />
                    {arrParty.length === 0 && <span> No Records Found</span>}
                  </div>
                  <div className="option-list">
                    {arrParty.map((Vendor, Index) => (
                      <button
                        key={Index}
                        tabIndex={Index}
                        onClick={(e) => View_Party(Vendor.partyId)}
                        onKeyDown={(e) => {
                          let arrlength = arrParty.length - 1;
                          if (e.key === "ArrowDown") {
                            if (arrlength === Index)
                              $(`.option-list [tabindex=0]`).focus();
                            else
                              $(`.option-list [tabindex=${Index + 1}]`).focus();
                          }
                          if (e.key === "ArrowUp") {
                            if (Index === 0)
                              $(`.option-list [tabindex=${arrlength}]`).focus();
                            else
                              $(`.option-list [tabindex=${Index - 1}]`).focus();
                          }
                          if (e.key === "Enter") View_Party(Vendor.partyId);
                        }}
                      >
                        {Vendor.partyName}
                      </button>
                    ))}
                  </div>
                  <div className="popup-footer">
                    <div className="popup-btn-group">
                      <button
                        className="btn-fabgreen"
                        onClick={(e) => setisServiceVendore(!isServiceVendore)}
                      >
                        <i className="fa-solid fa-circle-plus"></i>
                        Create Service Vendore
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-2 col-sm-6 mt-1">
            <label>
              Vocher no <span className="danger">*</span>
            </label>
            <div className="d-inline-flex " style={{ width: "100%" }}>
              <input
                type="text"
                className="form-control"
                placeholder="Voucher Number"
                value={strVouchNo}
                tabIndex={ListTabOrder.txtEINo}
                onKeyDown={(e) => KeyDown(e)}
                onChange={(e) => setstrVouchNo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-2 col-sm-6 mt-1">
            <label>
              Date <span className="danger">*</span>
            </label>
            <input
              type="date"
              name="strVouchDate"
              className="form-control"
              value={strVouchDate}
              onChange={(e) => setstrVouchNo(e.target.value)}
              tabIndex={ListTabOrder.dpEIDate}
              onKeyDown={(e) => KeyDown(e)}
            />
          </div>
          <div className="col-md-2 col-sm-6 mt-1">
            <label>
              Trans No <span className="danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              defaultValue={strTransNo}
              tabIndex={ListTabOrder.txtTransNo}
              onKeyDown={(e) => KeyDown(e)}
              readOnly
            />
          </div>
          <div className="col-md-2 col-sm-6 mt-1">
            <label>
              Vendor Type <span className="danger">*</span>
            </label>
            <CustomizedSelectoption
              optionparem="isoptionShow"
              showoption={_Selectparem.isoptionShow}
              placeholder="Select Vendore Type"
              select_value={(val) => setiVenTypeId(val)}
              btnname="Location"
              btnshow={false}
              show_popup=""
              valueonly={true}
              defaultval={iVenTypeId}
              displayName="strVenTypeName"
              disvalue="iVenTypeId"
              arrydata={VenType}
              EmptVal="Vendor Type "
            />
          </div>
          <div className="col-md-2 col-sm-6 mt-1">
            <label>
              Bill Type <span className="danger">* </span>
            </label>
            <CustomizedSelectoption
              optionparem="isoptionShow1"
              showoption={_Selectparem.isoptionShow1}
              placeholder="Bill Type"
              select_value={(val) => setiBillTypeId(val)}
              taborder={ListTabOrder.cbBillType}
              keydown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown");
                else KeyDown(e);
              }}
              btnname="Bill Type"
              btnshow={false}
              show_popup=""
              valueonly={true}
              defaultval={iBillTypeId}
              displayName="strBillType"
              disvalue="iBT_Id"
              arrydata={BillType}
              disabled={false}
              EmptVal="Bill Type"
            />
          </div>
          <div className="col-md-2 col-sm-6 mt-1">
            <label>
              Tax Type <span className="danger">* </span>
            </label>
            <CustomizedSelectoption
              optionparem="isoptionShow2"
              showoption={_Selectparem.isoptionShow2}
              placeholder="Tax Type"
              taborder={ListTabOrder.cbTaxType}
              keydown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown");
                else KeyDown(e);
              }}
              btnname="Tax Type"
              btnshow={false}
              show_popup=""
              valueonly={true}
              defaultval={iTaxRelId}
              displayName="strTaxType"
              disvalue="iTaxRelId"
              arrydata={TaxType}
              disabled={true}
              EmptVal="Tax Type"
            />
          </div>
          <div className="col-md-2 col-sm-6 mt-1">
            <label>
              Expense Income Type <span className="danger">* </span>
            </label>
            <CustomizedSelectoption
              optionparem="isoptionShow3"
              showoption={_Selectparem.isoptionShow3}
              placeholder="Expense Income Type"
              select_value={(val) => setiEITypeId(val)}
              btnname="Expense Income"
              btnshow={false}
              show_popup=""
              valueonly={true}
              defaultval={iEITypeId}
              displayName="strEITypeName"
              disvalue="iEITypeId"
              arrydata={EIType}
              disabled={false}
              EmptVal="Expense Income"
            />
          </div>
          <div className="col-md-2 col-sm-6 mt-1">
            <label>Remark 1</label>
            <input
              type="text"
              aria-label="First name"
              className="form-control"
              placeholder="Enter Remark1"
              name="strRemark1"
              onChange={(e) => setstrRemark1(e.target.value)}
              value={strRemark1}
              tabIndex={ListTabOrder.txtRemark1}
              onKeyDown={(e) => KeyDown(e)}
            />
          </div>
          <div className="col-md-2 col-sm-6 mt-1">
            <label>Remark 2</label>
            <input
              type="text"
              aria-label="First name"
              className="form-control"
              placeholder="Enter Remark2"
              name="strRemark2"
              onChange={(e) => setstrRemark2(e.target.value)}
              value={strRemark2}
              tabIndex={ListTabOrder.txtRemark2}
              onKeyDown={(e) => KeyDown(e)}
            />
          </div>
          <div className="col-md-2 col-sm-6 mt-1">
            <label>Remark 3</label>
            <input
              type="text"
              aria-label="First name"
              className="form-control"
              placeholder="Enter Remark3"
              name="strRemark3"
              onChange={(e) => setstrRemark3(e.target.value)}
              value={strRemark3}
              tabIndex={ListTabOrder.txtRemark3}
              onKeyDown={(e) => KeyDown(e)}
            />
          </div>
          <div className="col-md-2 col-sm-6 mt-1">
            <label>Remark 4</label>
            <input
              type="text"
              aria-label="First name"
              className="form-control"
              placeholder="Enter Remark4"
              name="strRemark4"
              onChange={(e) => setstrRemark4(e.target.value)}
              value={strRemark4}
              tabIndex={ListTabOrder.txtRemark4}
              onKeyDown={(e) => KeyDown(e)}
            />
          </div>
          <div className="col-md-2 col-sm-12 mt-1">
            <label>Address</label>
            <textarea
              className="form-control rounded"
              aria-label="With textarea"
              placeholder="Address of Party"
              style={{
                height: "100px",
                whiteSpace: "break-spaces",
                overflow: "auto",
                maxHeight: "100px",
                background: "rgb(238,238,238)",
              }}
              id="txtPartyDetails"
              value={strPartyAdd}
              onChange={(e) => setstrPartyAdd(e.target.value)}
              tabIndex={ListTabOrder.txtBillAddress}
              onKeyDown={(e) => KeyDown(e)}
            ></textarea>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-4 col-sm-12">
            <label>Service Name</label>
            <div className="custom-select">
              <input
                className="form-control"
                disabled={false}
                type="text"
                placeholder="Select Service"
                value={strSerName}
                onClick={(e) => setisService(!isService)}
                onChange={(e) => setisService(!isService)}
              />
              <i className="fa-solid fa-caret-down"></i>
            </div>
          </div>
          <div className="col-md-2 col-sm-6 ">
            <label>Cost Rate</label>
            <input
              type="text"
              aria-label="First name"
              className="form-control"
              name="dPriceRate"
              id="txtSerRate"
              value={dPriceRate}
              onChange={(e) => setdPriceRate(e.target.value)}
              onClick={(e) => e.target.select()}
              tabIndex={ListTabOrder.txtRate}
              onKeyDown={(e) => KeyDown(e)}
            />
          </div>
          <div className="col-md-2 col-sm-6 ">
            <label>Discount</label>
            <div className="trancdisc">
              <input
                type="number"
                className="form-control"
                value={dItemDisc}
                onClick={(e) => e.target.select()}
                onChange={(e) => setdItemDisc(e.target.value)}
                tabIndex={ListTabOrder.txtDiscount}
                onKeyDown={(e) => KeyDown(e)}
                onBlur={(e) => setdItemDisc(Number(e.target.value).toFixed(2))}
              />
              <button
                className="btn-fabgreen active"
                onClick={(e) => {
                  $(".trancdisc .btn-fabgreen").toggleClass("active");
                  setisDiscPer(!isDiscPer);
                }}
                onKeyDown={(e) => {
                  if (e.key === "ArrowRight" || e.key === "ArrowLeft")
                    setisDiscPer(!isDiscPer);
                  else KeyDown(e);
                }}
              >
                {isDiscPer ? "%" : "₹"}
              </button>
            </div>
          </div>
          <div className="col-md-2 col-sm-6 ">
            <div className="trans-inputbtn">
              <button
                type="button"
                onClick={Add_Service}
                tabIndex={ListTabOrder.btnAdd}
                onKeyDown={(e) => {
                  if (e.key === "Enter") Add_Service();
                  else KeyDown(e);
                }}
              >
                Add
              </button>
              <button type="button" onClick={(e) => Clear_Service()}>
                Clear
              </button>
            </div>
          </div>
          <ReactTable
            columns={columns}
            data={EIListDT}
            minRows={5}
            row_click={(ServInfo, Index) => {}}
            row_doubleclick={async (ServInfo, Index) => {
              setselected(Index);
              AssignSelectedService(ServInfo, Index);
            }}
            background={true}
            className="full-table"
            tabIndex={ListTabOrder.ProductTableListTab}
            KeyDown={(e) => KeyDown(e)}
          />
        </div>
        <div className="row mt-2">
          <div className="col-sm-12 col-md-4 ">
            <div className="sum-container">
              <div className="Exp-sum-lbl">
                <label>Goods Value</label>
                <label>{Number(iGoodsValue).toFixed(2)}</label>
              </div>
              <div className="Exp-sum-lbl">
                <label>Discount</label>
                <label>{Number(iTotalDisc).toFixed(2)}</label>
              </div>
              <div className="Exp-sum-lbl">
                <label>Tax</label>
                <label>{Number(iTotalTax).toFixed(2)}</label>
              </div>
              <div className="Exp-sum-lbl">
                <label>Round Off</label>
                <label>{Number(iRountoff).toFixed(2)}</label>
              </div>
              <div className="Exp-sum-foot">
                <label>Net Amount</label>
                <label>{strNetAmt}</label>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 ">
            <div className="sum-container">
              {EIListTax.map((Tax, Index) => (
                <div className="Exp-sum-lbl" key={Index}>
                  <label>{Tax.taxDesc}</label>
                  <label>{Tax.taxableAmt.toFixed(2)}</label>
                  <label>{Tax.taxAmt.toFixed(2)}</label>
                </div>
              ))}
              <div className="Exp-sum-foot">
                <label>Tax Amount</label>
                <label>
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "INR",
                  }).format(parseFloat(iTotalTax).toFixed(2))}
                </label>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 ">
            <div className="sum-container">
              <div className="row p-2">
                <div className="col-sm-6 col-md-6">
                  <label>Bill Discount Per</label>
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    name="dBillDisPer"
                    value={dBillDisPer}
                    tabIndex={ListTabOrder.txtBillDiscPer}
                    onKeyDown={(e) => KeyDown(e)}
                    onChange={(e) => BillDiscPer_onKeyDown(e)}
                    onClick={(e) => e.target.select()}
                  />
                </div>
                <div className="col-sm-6 col-md-6">
                  <label>Bill Discount Amt</label>
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    name="dBillDisAmt"
                    value={dBillDisAmt}
                    tabIndex={ListTabOrder.txtBillDiscAmt}
                    onChange={(e) => BillDiscAmt_onKeyDown(e)}
                    onClick={(e) => e.target.select()}
                    onKeyDown={(e) => KeyDown(e)}
                  />
                </div>
              </div>
              <div className="Exp-sum-foot">
                <label>Bill Discount</label>
                <label>
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "INR",
                  }).format(parseFloat(dBillDisValue).toFixed(2))}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-section mt-2">
          <button
            type="button"
            className="btn-fabgreen"
            disabled={isSaveBtn}
            onClick={(e) => {
              if (PropertiesValidation()) {
                Ask_Question(
                  dispatch,
                  SaveClick,
                  iEIId > 0
                    ? "Do You Want to Update ?"
                    : "Do You Want to Save ?"
                );
              }
            }}
            tabIndex={ListTabOrder.btnSave}
            onKeyDown={(e) => KeyDown(e)}
          >
            Save &nbsp; <i className="fas fa-save"></i>
          </button>
          <button
            type="button"
            className="btn-fabgreen"
            tabIndex={ListTabOrder.btnClear}
            onKeyDown={(e) => KeyDown(e)}
            onClick={ClearClick}
          >
            Clear &nbsp; <i className="fas fa-eraser"></i>
          </button>
          <button
            disabled={isViewBtn}
            type="button"
            className="btn-fabgreen"
            onClick={(e) => setisTransView(!isTransView)}
            tabIndex={ListTabOrder.btnView}
            onKeyDown={(e) => KeyDown(e)}
          >
            View &nbsp; <i className="fas fa-eye"></i>
          </button>
          <button
            type="button"
            disabled={isDeleteBtn}
            className="btn-fabgreen"
            onClick={(e) => {
              Ask_Question(dispatch, DeleteClick, "Do You Want to Delete ?");
            }}
            tabIndex={ListTabOrder.btnCancel}
            onKeyDown={(e) => KeyDown(e)}
          >
            Delete &nbsp; <i className="far fa-trash-alt"></i>
          </button>
          <button
            type="button"
            className="btn-fabgreen"
            tabIndex={ListTabOrder.btnClose}
            onKeyDown={(e) => KeyDown(e)}
            onClick={() => window.history.back()}
          >
            Close &nbsp; <i className="fas fa-times"></i>
          </button>
        </div>
        {isNewParty && (
          <PartyCreation
            Close={(e) => setisNewParty(!isNewParty)}
            strScrType="ServiceVendore"
          />
        )}
        {isService && (
          <ServiceSearch
            Url="ExpenseIncome"
            ScrId={59}
            Close={(e) => setisService(!isService)}
          />
        )}
        {isTransView && (
          <CustomTransView
            Close={(e) => setisTransView(!isTransView)}
            ScrId={iScrId}
            Title="Expense Income"
            View_Invoice={ViewClick}
          />
        )}
        <Messagedialogbox />
      </div>
    </>
  );
}
export default ExpenseIncome;
