import React, { useEffect, useState } from "react";
import AccountsButton from "../Accounts/AccountsButton";
import SideMenu from "../General/SideMenu";
import MasterList from "../Master/MasterListItem";
import DebitCreditBody from "./DebitCreditBody";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import Messagedialogbox, { Ask_Question } from "../General/Messagedialogbox";
import {
  refresh_AccDebCrd,
  setDebCrdHT,
  setEntDebCrdHT,
  setAccDeCr_Taborder,
  setDebCrdDeafult,
} from "../features/Accounts";
import { Show_Message } from "../General/Messagedialogbox";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
function DebitNote() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _AccParem = useSelector((state) => state.Accounts.DebitCredit);
  const _TabParem = useSelector((state) => state.Accounts.DebCrdTab);
  const _HTParem = useSelector((state) => state.Accounts.DebCrdHT);
  const [strSearch, setstrSearch] = useState("");

  useEffect(() => {
    Load_DebitNote();
  }, []);
  const Load_DebitNote = async () => {
    const _LocalParems = await DecryptData(localStorage.jbctrlparams);
    const res = await API_FETCH(
      `DebitNote/Load_DebitNote/${Number(_LocalParems.Branch)}/${
        _LocalParems.DB
      }`,
      dispatch
    );
    if (res) {
      await dispatch(
        setDebCrdHT({
          iScrId: 43,
          iBranchId: Number(_LocalParems.Branch),
          iVenTypeId: 4,
          strTransNo: res.objEntNoFormat[0].strTransNo,
          strVouchNo: res.objEntNoFormat[0].strUserNo,
          arrNoFormat: res.objEntNoFormat,
          arrEntHT: res.objEntDebitNote,
          arrVenType: res.objEntVenType,
          arrValidation: res.Transtab,
        })
      );
      TabOrderAssign(res.Transtab);
    }
  };
  const Search_DebitNote = async (strtext) => {
    setstrSearch(strtext);
    const objSearch = {
      iBranchId: Number(_AuthParems.Branch),
      iScrId: "",
      iPartyId: "",
      strColName: "All",
      strSearch: strtext,
      strDBName: _AuthParems.DB,
    };
    const res = await API_FETCH(
      "DebitNote/Search_DebittNote/",
      objSearch,
      dispatch
    );
    if (res) dispatch(setEntDebCrdHT(res.objEntDebitNote));
  };
  const ClearClick = () => {
    dispatch(refresh_AccDebCrd());
    Load_DebitNote();
  };
  const Cancel_DebitNote = async () => {
    const res = await API_FETCH(
      `DebitNote/Cancel_DebittNote/${_AccParem.iDebCrdId}/${_AuthParems.DB}`,
      dispatch
    );
    if (res) {
      ClearClick();
      Show_Message(dispatch, res.strMessage, "success");
    }
  };
  const Save_DebitNote = async () => {
    const objInsUpdDeb = {
      strPrefix: _HTParem.arrNoFormat[0].strPrefix,
      iBranchId: _AccParem.iBranchId,
      iNumSeqNo: _HTParem.arrNoFormat[0].iNumSeqNo,
      strCompCode: _HTParem.arrNoFormat[0].strCompCode,
      strFinanFrom: _HTParem.arrNoFormat[0].strFinanFrom,
      strDebNo: _AccParem.strVouchNo,
      strTransNo: _AccParem.strTransNo,
      dpDebDate: _AccParem.strVouchDate,
      iVenType: _AccParem.iVenTypeId,
      iVenId: _AccParem.iVenId,
      iDebId: _AccParem.iDebCrdId,
      dAmount: _AccParem.dAmount,
      strRemark: _AccParem.strRemark,
      iVouchId: 0,
      iCallerScrId: 0,
      strFilter: "",
      strFilterColumn: "All",
      strSearch: "",
      strVenName: "",
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST(
      "DebitNote/InsUpd_DebittNote/",
      objInsUpdDeb,
      dispatch
    );
    if (res) {
      ClearClick();
      Show_Message(dispatch, res.strMessage, "success");
    }
  };
  const View_DebitNote = async (DebId) => {
    const res = await API_FETCH(
      `DebitNote/View_DebitNote/${DebId}/${_AuthParems.DB}`,
      dispatch
    );
    if (res) {
      dispatch(
        setDebCrdDeafult({
          iDebCrdId: DebId,
          iScrId: _AccParem.iScrId,
          iBranchId: _AccParem.iBranchId,
          strTransNo: res.objDebDetails.strTransNo,
          strVouchNo: res.objDebDetails.strDebNo,
          strVouchDate: res.objDebDetails.dpDebDate,
          iVenTypeId: res.objDebDetails.iVenType,
          iVenId: res.objDebDetails.iVenId,
          strVenName: res.objDebDetails.strVenName,
          strRemark: res.objDebDetails.strRemark,
          dAmount: res.objDebDetails.dAmount,
          isCancel: res.objDebDetails.bCancelStatus,
        })
      );
    }
  };
  const TabOrderAssign = (EntProperties) => {
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      let colname = EntProperties[i].strCtrlName;
      let Index = EntProperties[i].iTabIndex;
      dispatch(
        setAccDeCr_Taborder({
          TransNoTab: colname === "txtEntNo" ? Index : _TabParem.VoucherTab,
          TranDate: colname === "dpEntDate" ? Index : _TabParem.VoucherTab,
          VoucherTab: colname === "txtVouchNo" ? Index : _TabParem.VoucherTab,
          VouchDateTap:
            colname === "dpVouchDate" ? Index : _TabParem.VoucherTab,
          VenNameTab: colname === "txtVenName" ? Index : _TabParem.VoucherTab,
          VenTypeTab: colname === "cbVenType" ? Index : _TabParem.VoucherTab,
          AmountTab: colname === "txtAmt" ? Index : _TabParem.VoucherTab,
          Remarktab: colname === "txtRemark" ? Index : _TabParem.VoucherTab,
          ArrTab: colname === "ArrTab" ? Index : _TabParem.VoucherTab,
          FilterTab: colname === "FilterTab" ? Index : _TabParem.VoucherTab,
          SearchTab: colname === "SearchTab" ? Index : _TabParem.VoucherTab,
          BtnSavtab: colname === "btnSave" ? Index : _TabParem.VoucherTab,
          BtnCleartab: colname === "btnClear" ? Index : _TabParem.VoucherTab,
          BtnDeletetab:
            colname === "BtnDeletetab" ? Index : _TabParem.VoucherTab,
          BtnClosetab: colname === "BtnClosetab" ? Index : _TabParem.VoucherTab,
          BtnCancel: colname === "btnCancel" ? Index : _TabParem.VoucherTab,
        })
      );
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    );
    sessionStorage.maxTab = maxTab.iTabIndex;
  };
  const KeyDown = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === _TabParem.ArrTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === _TabParem.ArrTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === _TabParem.ArrTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === _TabParem.ArrTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  const PropertiesValidation = () => {
    var ErrorMsg = true;
    let ValidateItem = _HTParem.arrValidation.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtVouchNo":
          ErrorMsg = _AccParem.strVouchNo === "" ? false : true;
          break;
        case "dpVouchDate":
          ErrorMsg = _AccParem.strVouchDate === "" ? false : true;
          break;
        case "cbVenType":
          ErrorMsg = _AccParem.iVenTypeId.toString() === "0" ? false : true;
          break;
        case "txtVenName":
          ErrorMsg = _AccParem.strVenName === "" ? false : true;
          break;
        case "txtRemark":
          ErrorMsg = _AccParem.strRemark === "" ? false : true;
          break;
        case "txtAmt":
          ErrorMsg = _AccParem.dAmount === 0 ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        Show_Message(dispatch, ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };
  return (
    <>
      <SideMenu ScrId={43} />
      <div className="Master-container">
        <MasterList
          ScrId={43}
          strSearchtxt={strSearch}
          tabOrder={_TabParem.SearchTab}
          Search={(val) => Search_DebitNote(val)}
          arrMasterData={_HTParem.arrEntHT}
          isImg={false}
          displayName="strDebNo"
          Parem1="iDebId"
          View_Data={(iDebid) => View_DebitNote(iDebid)}
          placeholder="Debit Note"
        />
        <div className="Master-card">
          <div className="Master-wrapper">
            <DebitCreditBody
              PartyUrl="DebitNote/View_Party/"
              KeyDown={KeyDown}
            />
            <Messagedialogbox />
          </div>
          <AccountsButton
            iVouchId={_AccParem.iDebCrdId}
            iScrId={43}
            Save={(e) => {
              if (PropertiesValidation()) {
                Ask_Question(
                  dispatch,
                  Save_DebitNote,
                  "Do You Want to  Save DebitNote?"
                );
              }
            }}
            Clear={ClearClick}
            Cancel={(e) => {
              if (_AccParem.iDebCrdId === 0) {
                Show_Message(dispatch, "Please Select DebitNote", "info");
              } else {
                Ask_Question(
                  Cancel_DebitNote,
                  "Do You Want to  Cancel DebitNote?"
                );
              }
            }}
            KeyDown={KeyDown}
          />
        </div>
      </div>
    </>
  );
}
export default DebitNote;
