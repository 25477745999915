import React, { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import BarcodePreview from "./BarcodePreview";
import CustomeIndexSelectOption from "../General/CustomeIndexSelectOption";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { Show_Message } from "../General/Messagedialogbox";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import ReactTable from "../General/ReactTable";
function BarcodeGenerator(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _selectparem = useSelector((state) => state.SelectOption.parems);
  const iScrId = 120;
  const [selected, setselected] = useState(0);

  const [iDesignId, setiDesignId] = useState(0);
  const [iFormateId, setiFormateId] = useState(1);
  const [dwidth, setdwidth] = useState(0);
  const [dHeight, setdHeight] = useState(0);
  const [dRowGap, setdRowGap] = useState(0);
  const [dColumGap, setdColumGap] = useState(0);
  const [iFontSize, setiFontSize] = useState(0);
  const [iNoColumn, setiNoColumn] = useState(0);
  const [strLineColor, setstrLineColor] = useState("black");
  const [strSearch, setstrSearch] = useState("");
  const [strFilter, setstrFilter] = useState("All");
  const [BarCodeView, setBarCodeView] = useState(false);
  const [ListProduct, setListProduct] = useState([]);
  const [ListComBoxValue, setListComBoxValue] = useState([]);
  const [ListBarcodeFormate, setListBarcodeFormate] = useState([]);

  useEffect(() => {
    Load_Productlist();
  }, []);
  const Load_Productlist = async () => {
    try {
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);

      var SearchText = "null";
      const res = await API_FETCH(
        "Barcode/LoadProduct/" +
          strFilter +
          "/" +
          SearchText +
          "/" +
          _LocalParems.DB,
        dispatch
      );
      if (res) {
        let Product = [];
        res.objEntProduct.map((Data, Index) => {
          const ProductObject = {
            iProId: Data.iProId,
            strProCode: Data.strProCode,
            strProName: Data.strProName,
            strCommodityCode: Data.strCommodityCode,
            strUnitName: Data.strUnitName,
            dPriceRate: Data.dPriceRate,
            strPrintName: Data.strPrintName,
            Qty: 10,
            SelectProduct: false,
            dDiscount: Data.dDiscount,
            Remarks: Data.strRemark,
          };
          Product.push(ProductObject);
          return Data;
        });
        setListProduct(Product);
        setListComBoxValue(res.objEntComboBox);
        setListBarcodeFormate(res.objEntCodeFormate);
        if (res.objbarCodeDesign.length > 0) {
          setiFormateId(res.objbarCodeDesign[0].iFormateId);
          setiDesignId(res.objbarCodeDesign[0].iDesignId);
          setdwidth(res.objbarCodeDesign[0].dwidth);
          setdHeight(res.objbarCodeDesign[0].dHeight);
          setdColumGap(res.objbarCodeDesign[0].columGap);
          setdRowGap(res.objbarCodeDesign[0].rowGap);
          setiFontSize(res.objbarCodeDesign[0].iFontSize);
          setstrLineColor(res.objbarCodeDesign[0].strLineColor);
          setiNoColumn(res.objbarCodeDesign[0].iNoColumn);
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const KeyDown = (e) => {
    try {
      var RowCount = ListProduct.length - 1;
      if (e.key === "ArrowDown") {
        if (RowCount === selected) setselected(0);
        else setselected(selected + 1);
      } else if (e.key === "ArrowUp") {
        if (selected === 0) setselected(RowCount);
        else setselected(selected - 1);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const SearchProduct = async (SearchText, SearchFor) => {
    try {
      var Search = "";
      var Filter = "";
      switch (SearchFor) {
        case "Filter":
          setstrFilter(SearchText);
          Filter = SearchText;
          Search = strSearch === "" ? "null" : strSearch;
          break;
        default:
          setstrSearch(SearchText);
          Search = SearchText === "" ? "null" : SearchText;
          Filter = strFilter;
          break;
      }

      const res = await API_FETCH(
        "Barcode/SearchProduct/" + Filter + "/" + Search + "/" + _AuthParems.DB,
        dispatch
      );
      if (res) {
        setListProduct(res.objEntProduct);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const PrintClick = () => {
    let Product = ListProduct.filter((Data) => Data.SelectProduct === true);
    let QtyCheck = Product.filter((Data) => Data.Qty === 0);
    if (QtyCheck.length > 0)
      Show_Message(dispatch, "Please Enter No of Copy", "error");
    else if (Product.length === 0)
      Show_Message(dispatch, "Please Select Product", "error");
    else setBarCodeView(!BarCodeView);
  };
  const QtyOn_Change = (Value, RownInfo) => {
    let Product = ListProduct;
    Product.map((Data) => {
      if (RownInfo.iProId === Data.iProId) Data.Qty = Number(Value);
      return Data;
    });
    setListProduct(Product);
  };
  const Product_Checked = (Value, RownInfo) => {
    let Product = ListProduct;
    Product.map((Data) => {
      if (RownInfo.iProId === Data.iProId) Data.SelectProduct = Value;
      return Data;
    });
    setListProduct(Product);
  };
  const ClearClick = () => {
    setListProduct([]);
    setListComBoxValue([]);
    setselected(0);
    setiDesignId(0);
    setiFormateId(1);
    setdwidth(0);
    setdHeight(0);
    setdRowGap(0);
    setdColumGap(0);
    setiFontSize(0);
    setstrLineColor("black");
    Load_Productlist();
  };
  const SaveClick = async () => {
    try {
      const InsUpdBCD = {
        iDesignId: iDesignId,
        dwidth: dwidth,
        dHeight: dHeight,
        RowGap: dRowGap,
        ColumGap: dColumGap,
        iFontSize: iFontSize,
        strLineColor: strLineColor,
        iFormateId: iFormateId,
        iNoColumn: iNoColumn,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "Barcode/InsUpdBarDesign",
        InsUpdBCD,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const ProductColumn = [
    {
      Header: "",
      accessor: "SelectProduct",
      maxWidth: 70,
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      Cell: (rowInfo) => (
        <input
          type="checkbox"
          defaultChecked={rowInfo.original.SelectProduct}
          className="form-check"
          onChange={(e) => Product_Checked(e.target.checked, rowInfo.original)}
        />
      ),
    },
    {
      Header: "ProName",
      minWidth: 200,
      accessor: "strProName",
      style: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
      },
    },
    {
      Header: "Unit",
      minWidth: 100,
      accessor: "strUnitName",
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      Header: "Price",
      minWidth: 100,
      accessor: "dPriceRate",
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      Header: "Discount",
      minWidth: 100,
      accessor: "dDiscount",
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      Header: "Copy",
      accessor: "Qty",
      maxWidth: 120,
      style: {
        display: "flex",
        alignItems: "center",
      },
      Cell: (rowInfo) => (
        <input
          type="number"
          className="form-control"
          style={{
            width: "130px",
            padding: "0",
            borderRadius: "5px",
            textAlign: "center",
            border: "1px solid #ddd",
          }}
          onClick={(e) => e.target.select()}
          onChange={(e) => QtyOn_Change(e.target.value, rowInfo.original)}
          defaultValue={rowInfo.original.Qty}
        />
      ),
    },
  ];
  const arrColor = [
    "aliceblue",
    "antiquewhite",
    "aqua",
    "aquamarine	",
    "azure",
    "beige",
    "bisque",
    "black",
    "blanchedalmond",
    "blue",
    "blueviolet",
    "brown",
    "burlywood",
    "cadetblue",
    "chartreuse",
    "chocolate",
    "coral",
    "cornflowerblue",
    "cornsilk",
    "crimson",
    "cyan",
    "darkblue",
    "darkcyan",
    "darkgoldenrod",
    "darkgray",
    "darkgrey",
    "darkgreen",
    "darkkhaki",
    "darkmagenta",
    "darkolivegreen",
    "darkorange",
    "darkorchid",
    "darkred",
    "darksalmon",
    "darkseagreen",
    "darkslateblue",
    "darkslategray",
    "darkslategrey",
    "darkturquoise",
    "darkviolet",
    "deeppink",
    "deepskyblue",
    "dimgray",
    "dimgrey",
    "dodgerblue",
    "firebrick",
    "floralwhite",
    "forestgreen",
    "fuchsia",
    "gainsboro",
    "ghostwhite",
    "gold",
    "goldenrod",
    "gray",
    "grey",
    "green",
    "greenyellow",
    "honeydew",
    "hotpink",
    "indianred",
    "indigo",
    "ivory",
    "khaki",
    "lavender",
    "lavenderblush",
    "lawngreen",
    "lemonchiffon",
    "lightblue",
    "lightcoral",
    "lightcyan",
    "lightgoldenrodyellow",
    "lightgray",
    "lightgrey",
    "lightgreen",
    "lightpink",
    "lightsalmon",
    "lightseagreen",
    "lightskyblue",
    "lightslategray",
    "lightslategrey",
    "lightyellow",
    "lime",
    "limegreen",
    "linen",
    "magenta",
    "maroon",
    "mediumaquamarine",
    "mediumblue",
    "mediumorchid",
    "mediumpurple",
    "mediumseagreen",
    "mediumslateblue",
    "mediumspringgreen",
    "mediumturquoise",
    "mediumvioletred",
    "midnightblue",
    "mintcream",
    "mistyrose",
    "moccasin",
    "navajowhite",
    "navy",
    "oldlace",
    "olive",
    "olivedrab",
    "orange",
    "orangered",
    "orchid",
    "palegoldenrod",
    "palegreen",
    "paleturquoise",
    "palevioletred",
    "papayawhip",
    "peachpuff",
    "peru",
    "pink",
    "plum",
    "powderblue",
    "purple",
    "rebeccapurple",
    "red",
    "rosybrown",
    "royalblue",
    "saddlebrown",
    "salmon",
    "sandybrown",
    "seagreen",
    "seashell",
    "sienna",
    "silver",
    "skyblue",
    "slateblue",
    "slategray",
    "slategrey",
    "snow",
    "springgreen",
    "steelblue",
    "tan",
    "teal",
    "thistle",
    "tomato",
    "turquoise",
    "violet",
    "wheat",
    "white",
    "whitesmoke",
    "yellow",
    "yellowgreen",
  ];
  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <div className="Master-card">
          <div className="row p-2">
            <div className="col-md-12 col-sm-12">
              <CustomizedSelectoption
                optionparem="isoptionShow"
                showoption={_selectparem.isoptionShow}
                placeholder="Select Formate"
                select_value={(val) => setiFormateId(Number(val))}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iFormateId}
                displayName="displayName"
                disvalue="iBarCoFId"
                arrydata={ListBarcodeFormate}
                EmptVal="Formate Name"
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label>Barcode Width</label>
              <input
                className="form-control"
                type="text"
                onClick={(e) => e.target.select()}
                value={dwidth}
                onChange={(e) => setdwidth(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label>Barcode Height</label>
              <input
                className="form-control"
                type="text"
                value={dHeight}
                onClick={(e) => e.target.select()}
                onChange={(e) => setdHeight(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label>Row Space</label>
              <input
                className="form-control"
                type="text"
                onClick={(e) => e.target.select()}
                value={dRowGap}
                onChange={(e) => setdRowGap(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label>Colum Space</label>
              <input
                className="form-control"
                type="text"
                onClick={(e) => e.target.select()}
                value={dColumGap}
                onChange={(e) => setdColumGap(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label>Font Size</label>
              <input
                className="form-control"
                type="text"
                onClick={(e) => e.target.select()}
                value={iFontSize}
                onChange={(e) => setiFontSize(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label>NoOf Column</label>
              <input
                className="form-control"
                type="text"
                onClick={(e) => e.target.select()}
                value={iNoColumn}
                onChange={(e) => setiNoColumn(Number(e.target.value))}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label>
                Barcode Line Color<span className="danger"></span>
              </label>
              <CustomeIndexSelectOption
                optionparem="isoptionShow1"
                showoption={_selectparem.isoptionShow1}
                placeholder="Select Color"
                select_value={(val) => setstrLineColor(val)}
                defaultval={strLineColor}
                arrydata={arrColor}
                EmptVal="Color"
              />
            </div>
          </div>
        </div>
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-8 col-sm-12">
                <input
                  className="form-control"
                  type="text"
                  value={strSearch}
                  onChange={(e) => SearchProduct(e.target.value, "Search")}
                  placeholder="Search Product"
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <CustomizedSelectoption
                  optionparem="isoptionShow2"
                  showoption={_selectparem.isoptionShow2}
                  placeholder="Select Filter Name"
                  select_value={(val) => SearchProduct(val, "Filter")}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={strFilter}
                  displayName="strDisplayName"
                  disvalue="strValueName"
                  arrydata={ListComBoxValue}
                  EmptVal="Filter Name"
                />
              </div>
            </div>
            <div className="TransTable mt-2">
              <ReactTable
                columns={ProductColumn}
                data={ListProduct}
                minRows={15}
                row_click={(ProInfo, Index) => {
                  setselected(Index);
                }}
                row_doubleclick={(ProInfo, Index) => setselected(Index)}
                background={true}
                className="full-table"
              />
            </div>
            <img id="Barcode" alt=""></img>
            {BarCodeView && (
              <BarcodePreview
                ProductList={ListProduct}
                Close={(e) => setBarCodeView(!BarCodeView)}
              />
            )}
          </div>
          <div className="btn-section">
            <button className="btn-fabgreen" onClick={PrintClick}>
              Barcode
            </button>
            <button className="btn-fabgreen" onClick={SaveClick}>
              Save&nbsp; <i className="fa-solid fa-save"></i>
            </button>
            <button className="btn-fabgreen" onClick={ClearClick}>
              Clear&nbsp; <i className="fa-solid fa-broom"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => (window.location.href = "/Others/BarcodeSetting")}
            >
              Setting&nbsp; <i className="fa-solid fa-gear"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => (window.location.href = "/JustBill/DashBoard")}
            >
              Close &nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BarcodeGenerator;
