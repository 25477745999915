import React, { useState } from "react";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { Add_customer } from "../features/ManualInvoice";
import { API_POST } from "../General/Utility";
import { Show_Message } from "../General/Messagedialogbox";
function ManualCustomerSelect() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const _HT = useSelector((state) => state.Invoice.HT[0]);
  const [strSearch, setstrSearch] = useState("");
  const [isOption, setisOption] = useState(false);
  const [arroption, setarroption] = useState([]);

  const Search_Customer = async (strtext) => {
    try {
      setstrSearch(strtext);
      const objSearch = {
        itemId: 0,
        strSearch: strtext,
        strDBName: _AuthParems.DB,
        iBranchId: Number(_AuthParems.Branch),
      };
      const res = await API_POST(
        `ManualInvoice/Search_ManualParty`,
        objSearch,
        dispatch
      );
      if (res) {
        setarroption(res.EntParty);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Select_Customer = (cust) => {
    setisOption(false);
    if (cust) {
      dispatch(
        Add_customer({
          AddId: 0,
          CustName: cust.custName,
          AliasName: cust.aliasName,
          Add1: cust.add1,
          Add2: cust.add2,
          Add3: cust.add3,
          Add4: cust.add4,
          Pincode: cust.pincode,
          State: cust.state.toString(),
          Country: cust.country.toString(),
          Mobile: cust.mobile,
          Email: cust.email,
          GST: cust.gst,
          Remarks: cust.remarks,
        })
      );
    } else {
      dispatch(
        Add_customer({
          AddId: 0,
          CustName: strSearch,
          AliasName: "",
          Add1: "",
          Add2: "",
          Add3: "",
          Add4: "",
          Pincode: "",
          State: "32",
          Country: "100",
          Mobile: "",
          Email: "",
          GST: "",
          Remarks: "",
        })
      );
    }
  };
  return (
    <div className="custom-select" onMouseLeave={(e) => setisOption(false)}>
      <input
        className="form-control"
        disabled={false}
        type="text"
        placeholder="Select Customer"
        value={_HT.Customer}
        onChange={(e) => Search_Customer(e.target.value)}
        onClick={(e) => {
          setisOption(true);
          Search_Customer("");
        }}
      />
      <i className="fa-solid fa-caret-down"></i>
      {isOption && (
        <div className="custom-option">
          <div className="option-list">
            <div className="search-option">
              <input
                className="form-control"
                type="text"
                autoFocus
                placeholder="Search Customer"
                value={strSearch}
                onChange={(e) => Search_Customer(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "ArrowDown") {
                    $(".option-list [tabindex=0]").focus();
                  } else if (e.key === "Enter") {
                    Select_Customer(arroption[0]);
                  }
                }}
              />
              {arroption.length === 0 && (
                <span>
                  Create New Customer
                  <strong> {strSearch}</strong>
                </span>
              )}
            </div>
            {arroption.map((item, Index) => (
              <button
                key={Index}
                tabIndex={Index}
                onClick={(e) => Select_Customer(item)}
                onKeyDown={(e) => {
                  let arrlength = arroption.length - 1;
                  if (e.key === "ArrowDown") {
                    if (arrlength === Index)
                      $(`.option-list [tabindex=0]`).focus();
                    else $(`.option-list [tabindex=${Index + 1}]`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    if (Index === 0)
                      $(`.option-list [tabindex=${arrlength}]`).focus();
                    else $(`.option-list [tabindex=${Index - 1}]`).focus();
                  }
                  if (e.key === "Enter") Select_Customer(item);
                }}
              >
                {item.custName}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default ManualCustomerSelect;
