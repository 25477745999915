import React, { useEffect, useState } from "react";

import SideMenu from "../General/SideMenu";
import MasterList from "../Master/MasterListItem";
import $ from "jquery";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import Messagedialogbox, { Ask_Question } from "../General/Messagedialogbox";
import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import { API_FETCH, API_POST } from "../General/Utility";
import { Change_Unit, Select_Product } from "../features/Transactionstates";
import MasterProductSearch from "../Transaction/MasterProductSearch";
import ReactTable from "../General/ReactTable";

function ProductionOrder() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _Common = useSelector((store) => store.Transstates.Common);
  const _SelectParem = useSelector((state) => state.SelectOption.parems);
  const _Proparem = useSelector((state) => state.Transstates.Product);

  const iScrId = 70;
  const [isCompleted, setisCompleted] = useState(false);
  const [ManualCode, setManualCode] = useState(false);
  const [iPOId, setiPOId] = useState(0);
  const [strPOCode, setstrPOCode] = useState("");
  const [strBatchNo, setstrBatchNo] = useState("");
  const [iBOMId, setiBOMId] = useState(0);
  const [iRGId, setiRGId] = useState(0);
  const [iStatusId, setiStatusId] = useState(1);
  const [dPlanedQty, setdPlanedQty] = useState(0);
  const [dProductionQty, setdProductionQty] = useState(0);
  const [dSuspendedQty, setdSuspendedQty] = useState(0);
  const [dRejectedQty, setdRejectedQty] = useState(0);
  const [dConvertedQty, setdConvertedQty] = useState(0);
  const [isProduct, setisProduct] = useState(false);

  const [strDate, setstrDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [dpManufacDate, setdpManufacDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [dpExpiryDate, setdpExpiryDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [dpStartDate, setdpStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [dpEndDate, setdpEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [strRemark, setstrRemark] = useState("");
  const [strSearch, setstrSearch] = useState("");
  const [arrBomlist, setarrBomlist] = useState([]);
  const [arrRouteGrouplist, setarrRouteGrouplist] = useState([]);
  const [arrPODT, setarrPODT] = useState([]);
  const [arrNoForamt, setarrNoForamt] = useState([0]);
  const [arrStatuslist, setarrStatuslist] = useState([0]);
  const [arrProductionOrder, setarrProductionOrder] = useState([]);
  useEffect(() => {
    Load_ProductOrder();
  }, []);
  const Load_ProductOrder = async () => {
    Pre_Loading(dispatch, true);
    const res = await API_FETCH(
      `ProductionOrder/Load_ProductionOrder/${Number(_AuthParems.Branch)}/${
        _AuthParems.DB
      }`,
      dispatch
    );
    if (res) {
      setarrNoForamt(res.objEntNoFormat);
      setarrProductionOrder(res.objEntProdOrder);
      setarrStatuslist(res.objEntStatus);
      setarrBomlist(res.objEntBOM);
      setarrRouteGrouplist(res.objEntRG);
      setstrPOCode(res.objEntNoFormat[0].strCode);
      var Code = res.objEntNoFormat[0].strPrefix === "" ? false : true;
      setManualCode(Code);
      TabOrderAssign(res.Transtab);
    }
  };
  const Select_BOM = async (BomId) => {
    setiBOMId(BomId);
    const res = await API_FETCH(
      `ProductionOrder/Check_BOMProductExists/${Number(_Proparem.iProId)}/${
        _AuthParems.DB
      }`,
      dispatch
    );
    if (res) {
      setiBOMId(0);
      Show_Message(dispatch, res.strMessage, "info");
    }
  };
  const Add_RouteGroup = async () => {
    const res = await API_FETCH(
      `ProductionOrder/View_RouteGroup/${Number(
        iRGId
      )}/${dpStartDate}/${dpEndDate}/${_AuthParems.DB}`,
      dispatch
    );
    if (res) {
      setarrPODT(res.objEntRoutGroup);
    }
  };
  const Save_ProductionOrder = async () => {
    const objPOHTList = {
      iScrId: iScrId,
      iPOId: iPOId,
      dpPODate: strDate,
      strPOCode: strPOCode,
      iNumSeqNo: arrNoForamt[0].iNumSeqNo,
      strPrefix: arrNoForamt[0].strPrefix,
      strProCode: _Proparem.strProCode,
      iProId: Number(_Proparem.iProId),
      strProName: _Proparem.strProName,
      iUnitId: Number(_Proparem.iUnitId),
      strProductDimAttGroupId: "0",
      strStorageDimAttGroupId: "0",
      strTrackingDimAttGroupId: "0",
      strProductDimAttId: "",
      strStorageDimAttId: "",
      strTrackingDimAttId: "",
      strProductDimAttDTId: "",
      strStorageDimAttDTId: "",
      strTrackingDimAttDTId: "",
      PlanedQty: Number(dPlanedQty),
      RejectedQty: Number(dRejectedQty),
      SuspendedQty: Number(dSuspendedQty),
      ProductionQty: Number(dProductionQty),
      ConvertedQty: Number(dConvertedQty),
      dpStartDate: dpStartDate,
      dpEndDate: dpEndDate,
      strRemark: strRemark,
      iRGId: Number(iRGId),
      iBOMId: Number(iBOMId),
      iStatusId: Number(iStatusId),
      strBOMName: "",
      strEndTime: "",
      strRGName: "",
      strStartTime: "",
      strStsName: "",
      strUnitName: "",
      iBranchId: Number(_AuthParems.Branch),
      strBatchNo: strBatchNo,
      dpManufactureDate: dpManufacDate,
      dpExpiryDate: dpExpiryDate,
    };
    const res = await API_POST(
      "ProductionOrder/InsUpd_ProductionOrder/",
      {
        iPOId: iPOId,
        objPOModel: objPOHTList,
        objPODTModel: arrPODT,
        strDBName: _AuthParems.DB,
      },
      dispatch
    );
    if (res) {
      Clear_ProductionOrder();
      Show_Message(dispatch, res.strMessage, "success");
    }
  };
  const Clear_ProductionOrder = () => {
    setstrDate(new Date().toISOString().split("T")[0]);
    setiPOId(0);
    setiBOMId(0);
    setiRGId(0);
    setdPlanedQty(0);
    setiStatusId(1);
    setdProductionQty(0);
    setdSuspendedQty(0);
    setdRejectedQty(0);
    setdConvertedQty(0);
    setisCompleted(false);
    setdpStartDate(new Date().toISOString().split("T")[0]);
    setdpEndDate(new Date().toISOString().split("T")[0]);
    setstrRemark("");
    setdpManufacDate(new Date().toISOString().split("T")[0]);
    setdpExpiryDate(new Date().toISOString().split("T")[0]);
    setarrPODT([]);
    setarrProductionOrder([]);
    Load_ProductOrder();
  };
  const Delete_ProductionOrder = async () => {
    Pre_Loading(dispatch, true);
    const res = await API_POST(
      `ProductionOrder/Delete_ProductionOrder/${iPOId}/${Number(
        _AuthParems.Branch
      )}/${_AuthParems.DB}`,
      dispatch
    );
    if (res) {
      Clear_ProductionOrder();
      Show_Message(dispatch, res.strMessage, "success");
    }
  };
  const View_ProductionOrder = async (objPO) => {
    Pre_Loading(dispatch, true);
    const res = await API_FETCH(
      `ProductionOrder/View_ProductionOrder/${objPO.iPOId}/${objPO.iProId}/${_AuthParems.DB}`,
      dispatch
    );
    if (res) {
      dispatch(
        Select_Product({
          iProId: objPO.iProId,
          strProCode: objPO.strProCode,
          strProName: objPO.strProName,
          iUnitId: objPO.iUnitId,
          strUnitName: "",
          dPriceRate: (0).toFixed(2),
          dPriceRatewithTax: (0).toFixed(2),
          isItemDiscper: true,
          dItemDisc: (0).toFixed(2),
          isCashDiscper: true,
          dCashDisc: (0).toFixed(2),
          dQty: (0).toFixed(2),
          FQty: (0).toFixed(2),
          UnitList: res.Unit,
          iRowIndex: 0,
          bUpdateStatus: false,
          strBatchNo: "",
          dpManufactureDate: new Date().toISOString().split("T")[0],
          dpExpiryDate: new Date().toISOString().split("T")[0],
          JobName: "",
          JobId: 0,
          Reason: "",
          ItemRemark: [],
          listRootCalc: [],
        })
      );
      setiPOId(objPO.iPOId);
      setstrPOCode(objPO.strPOCode);
      setstrDate(objPO.dpPODate);
      const NoFormat = [
        {
          iNumSeqNo: objPO.iNumSeqNo,
          strPrefix: objPO.strPrefix,
        },
      ];
      setarrNoForamt(NoFormat);
      setiRGId(objPO.iRGId);
      setiBOMId(objPO.iBOMId);
      setiStatusId(objPO.iStatusId);
      setdConvertedQty(objPO.convertedQty);
      setdProductionQty(objPO.productionQty);
      setdPlanedQty(objPO.planedQty);
      setdRejectedQty(objPO.rejectedQty);
      setdSuspendedQty(objPO.suspendedQty);
      setdpStartDate(objPO.dpStartDate);
      setdpEndDate(objPO.dpEndDate);
      setstrRemark(objPO.strRemark);
      setstrBatchNo(objPO.strBatchNo);
      setdpManufacDate(objPO.dpManufactureDate);
      setdpExpiryDate(objPO.dpExpiryDate);
      setarrPODT(res.PODT);
      if (objPO.iStatusId === 2) setisCompleted(true);
    }
  };
  const Search_ProductionOrder = async (strtxt) => {
    setstrSearch(strtxt);
    const objSearch = {
      strSearch: strtxt,
      iBranchId: Number(_AuthParems.Branch),
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST(
      `ProductionOrder/Search_ProductionOrder/`,
      objSearch,
      dispatch
    );
    if (res) setarrProductionOrder(res.objEntHT);
  };
  const Column = [
    {
      Header: "SNo",
      accessor: "sNo",
      maxWidth: 70,
      style: { justifyContent: "center" },
    },

    {
      Header: "Process",
      accessor: "jobName",
      style: { justifyContent: "left" },
    },
    {
      Header: "Start Date",
      accessor: "startDate",
      maxWidth: 120,
      style: { justifyContent: "center" },
    },
    {
      Header: "End Date",
      accessor: "endDate",
      maxWidth: 120,
      style: { justifyContent: "center" },
    },
  ];
  const [ListTabOrder, setListTabOrder] = useState([
    {
      btnClose: "",
      btnSave: "",
      btnNew: "",
      btnModify: "",
      btnClear: "",
      btnDelete: "",
      btnSettings: "",
      btnHelp: "",
      txtProductName: "",
      txtProductCode: "",
      txtBOM: "",
      txtRouteGroup: "",
      cbUOM: "",
      txtPlanedQty: "",
      txtRejectedQty: "",
      txtSuspendedQty: "",
      txtProductionQty: "",
      dpProductionStartDate: "",
      dpProductionEndDate: "",
      txtProductionStartTime: "",
      txtProductionEndTime: "",
      cbProductionStatus: "",
      txtProductionCode: "",
      txtRemarks: "",
      ProductListTab: "",
      ProductFIlterTab: "",
      ProductSearchTab: "",
    },
  ]);
  const TabOrderAssign = (EntProperties) => {
    let TabOrder = ListTabOrder;
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      switch (EntProperties[i].strCtrlName) {
        case "btnClose":
          TabOrder[0].btnClose = EntProperties[i].iTabIndex;
          break;
        case "btnSave":
          TabOrder[0].btnSave = EntProperties[i].iTabIndex;
          break;
        case "btnNew":
          TabOrder[0].btnNew = EntProperties[i].iTabIndex;
          break;
        case "btnModify":
          TabOrder[0].btnModify = EntProperties[i].iTabIndex;
          break;
        case "btnClear":
          TabOrder[0].btnClear = EntProperties[i].iTabIndex;
          break;
        case "btnDelete":
          TabOrder[0].btnDelete = EntProperties[i].iTabIndex;
          break;
        case "btnSettings":
          TabOrder[0].btnSettings = EntProperties[i].iTabIndex;
          break;
        case "btnHelp":
          TabOrder[0].btnHelp = EntProperties[i].iTabIndex;
          break;
        case "txtProductName":
          TabOrder[0].txtProductName = EntProperties[i].iTabIndex;
          break;
        case "txtProductCode":
          TabOrder[0].txtProductCode = EntProperties[i].iTabIndex;
          break;
        case "txtBOM":
          TabOrder[0].txtBOM = EntProperties[i].iTabIndex;
          break;
        case "txtRouteGroup":
          TabOrder[0].txtRouteGroup = EntProperties[i].iTabIndex;
          break;
        case "cbUOM":
          TabOrder[0].cbUOM = EntProperties[i].iTabIndex;
          break;
        case "txtPlanedQty":
          TabOrder[0].txtPlanedQty = EntProperties[i].iTabIndex;
          break;
        case "txtRejectedQty":
          TabOrder[0].txtRejectedQty = EntProperties[i].iTabIndex;
          break;
        case "txtSuspendedQty":
          TabOrder[0].txtSuspendedQty = EntProperties[i].iTabIndex;
          break;
        case "txtProductionQty":
          TabOrder[0].txtProductionQty = EntProperties[i].iTabIndex;
          break;
        case "dpProductionStartDate":
          TabOrder[0].dpProductionStartDate = EntProperties[i].iTabIndex;
          break;
        case "dpProductionEndDate":
          TabOrder[0].dpProductionEndDate = EntProperties[i].iTabIndex;
          break;
        case "txtProductionStartTime":
          TabOrder[0].txtProductionStartTime = EntProperties[i].iTabIndex;
          break;
        case "txtProductionEndTime":
          TabOrder[0].txtProductionEndTime = EntProperties[i].iTabIndex;
          break;
        case "cbProductionStatus":
          TabOrder[0].cbProductionStatus = EntProperties[i].iTabIndex;
          break;
        case "txtProductionCode":
          TabOrder[0].txtProductionCode = EntProperties[i].iTabIndex;
          break;
        case "txtRemarks":
          TabOrder[0].txtRemarks = EntProperties[i].iTabIndex;
          break;
        case "lbProduct":
          TabOrder[0].ProductListTab = EntProperties[i].iTabIndex;
          break;
        case "cbFilter":
          TabOrder[0].ProductFIlterTab = EntProperties[i].iTabIndex;
          break;
        case "txtSearch":
          TabOrder[0].ProductSearchTab = EntProperties[i].iTabIndex;
          break;
        default:
          break;
      }
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    ); //returns object
    sessionStorage.maxTab = maxTab.iTabIndex;
    setListTabOrder(TabOrder);
  };
  const TabIndex = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].ProductListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].ProductListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].ProductListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].ProductListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].ProductListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].ProductListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].ProductListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };

  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <MasterList
          ScrId={iScrId}
          strSearchtxt={strSearch}
          tabOrder={ListTabOrder[0].ProductSearchTab}
          Search={(val) => Search_ProductionOrder(val)}
          arrMasterData={arrProductionOrder}
          isImg={false}
          displayName="strPOCode"
          Parem1="iPOId"
          View_Data={(iPOId) => View_ProductionOrder(iPOId)}
          placeholder="Production Order"
        />
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-3 col-sm-6 mb-2">
                <label>
                  PO Code <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  id="strPOCode"
                  name="strPOCode"
                  value={strPOCode}
                  onChange={(e) => setstrPOCode(e.target.value)}
                  readOnly={ManualCode}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Date </label>
                <input
                  type="date"
                  name="strDate"
                  className="form-control"
                  value={strDate}
                  onChange={(e) => setstrDate(e.target.value)}
                />
              </div>
              <div className="col-md-6 col-sm-12 mb-2">
                <label>
                  Product <span className="danger">*</span>
                </label>
                <div style={{ position: "relative" }}>
                  <input
                    className="form-control"
                    disabled={false}
                    type="text"
                    placeholder="Select Product"
                    defaultValue={_Proparem.strProName}
                    onClick={(e) => setisProduct(!isProduct)}
                  />
                  <i className="fa-solid fa-caret-down"></i>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>BOM</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_SelectParem.isoptionShow}
                  placeholder="Select BOM"
                  taborder={ListTabOrder[0].txtBOM}
                  keydown={(e) => TabIndex(e)}
                  select_value={(val) => Select_BOM(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iBOMId}
                  displayName="strBOMName"
                  disvalue="iBOMId"
                  arrydata={arrBomlist}
                  EmptVal="BOM"
                  disabled={_Proparem.iProId === 0 ? true : false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Unit</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow2"
                  showoption={_SelectParem.isoptionShow2}
                  placeholder="Select Unit"
                  taborder={ListTabOrder[0].cbUOM}
                  keydown={(e) => TabIndex(e)}
                  select_value={(val) => dispatch(Change_Unit(val))}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={_Proparem.iUnitId}
                  displayName="strUnitName"
                  disvalue="iUnitId"
                  arrydata={_Proparem.UnitList}
                  EmptVal="Unit"
                  disabled={_Proparem.iProId === 0 ? true : false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Status</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow3"
                  showoption={_SelectParem.isoptionShow3}
                  placeholder="Select Status"
                  select_value={(val) => setiStatusId(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iStatusId}
                  displayName="psName"
                  disvalue="psId"
                  arrydata={arrStatuslist}
                  EmptVal="Status"
                  disabled={isCompleted}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Remark </label>
                <input
                  type="text"
                  name="strRemark"
                  className="form-control"
                  value={strRemark}
                  onChange={(e) => setstrRemark(e.target.value)}
                  placeholder="Enter Remarks"
                  tabIndex={ListTabOrder[0].txtRemarks}
                  onKeyDown={(e) => TabIndex(e)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Planed Qty</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  name="dPlanedQty"
                  id="dPlanedQty"
                  value={dPlanedQty}
                  tabIndex={ListTabOrder[0].txtPlanedQty}
                  onKeyDown={(e) => TabIndex(e)}
                  onChange={(e) => setdPlanedQty(Number(e.target.value))}
                  onClick={(e) => e.target.select()}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setdPlanedQty((0).toFixed(2));
                    }
                  }}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Suspended Qty</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  name="dSuspendedQty"
                  id="dSuspendedQty"
                  value={dSuspendedQty}
                  tabIndex={ListTabOrder[0].txtSuspendedQty}
                  onKeyDown={(e) => TabIndex(e)}
                  onChange={(e) => setdSuspendedQty(Number(e.target.value))}
                  onClick={(e) => e.target.select()}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setdSuspendedQty((0).toFixed(2));
                    }
                  }}
                  disabled={iPOId === 0 ? true : false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Rejected Qty</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  name="dRejectedQty"
                  id="dRejectedQty"
                  value={dRejectedQty}
                  tabIndex={ListTabOrder[0].txtRejectedQty}
                  onKeyDown={(e) => TabIndex(e)}
                  onChange={(e) => setdRejectedQty(Number(e.target.value))}
                  onClick={(e) => e.target.select()}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setdRejectedQty((0).toFixed(2));
                    }
                  }}
                  disabled={iPOId === 0 ? true : false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Production Qty</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  name="dProductionQty"
                  id="dProductionQty"
                  value={dProductionQty}
                  tabIndex={ListTabOrder[0].txtProductionQty}
                  onKeyDown={(e) => TabIndex(e)}
                  onChange={(e) => setdProductionQty(Number(e.target.value))}
                  onClick={(e) => e.target.select()}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setdProductionQty((0).toFixed(2));
                    }
                  }}
                  disabled={iPOId === 0 ? true : false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Batch No</label>
                <input
                  type="text"
                  className="form-control"
                  value={strBatchNo}
                  onChange={(e) => setstrBatchNo(e.target.value)}
                  onClick={(e) => e.target.select()}
                  disabled={iStatusId === 1 ? true : false}
                  placeholder="Batch No"
                  spellCheck={false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Manufacture Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={dpManufacDate}
                  onChange={(e) => setdpManufacDate(e.target.value)}
                  onClick={(e) => e.target.select()}
                  disabled={iStatusId === 1 ? true : false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Expiry Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={dpExpiryDate}
                  onChange={(e) => setdpExpiryDate(e.target.value)}
                  onClick={(e) => e.target.select()}
                  disabled={iStatusId === 1 ? true : false}
                />
              </div>
              <div className="col-md-6 col-sm-12 mb-2">
                <label>Route Group</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow1"
                  showoption={_SelectParem.isoptionShow1}
                  placeholder="Select Route Group"
                  taborder={ListTabOrder[0].txtRouteGroup}
                  keydown={(e) => TabIndex(e)}
                  select_value={(val) => setiRGId(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iRGId}
                  displayName="strRGName"
                  disvalue="iRGId"
                  arrydata={arrRouteGrouplist}
                  EmptVal=" Route Group "
                  disabled={
                    iBOMId === 0 ? true : iStatusId === 2 ? true : false
                  }
                />
              </div>
              <div className="col-md-2 col-sm-6 mb-2">
                <label>Start Date </label>
                <input
                  type="date"
                  name="dpStartDate"
                  className="form-control"
                  value={dpStartDate}
                  onChange={(e) => setdpStartDate(e.target.value)}
                  tabIndex={ListTabOrder[0].dpProductionStartDate}
                  onKeyDown={(e) => TabIndex(e)}
                />
              </div>
              <div className="col-md-2 col-sm-6 mb-2">
                <label>End Date </label>
                <input
                  type="date"
                  name="dpEndDate"
                  className="form-control"
                  value={dpEndDate}
                  onChange={(e) => setdpEndDate(e.target.value)}
                  tabIndex={ListTabOrder[0].dpProductionEndDate}
                  onKeyDown={(e) => TabIndex(e)}
                />
              </div>
              <div className="col-md-2 col-sm-12 mb-2">
                <div className="trans-inputbtn">
                  <button
                    type="button"
                    onClick={Add_RouteGroup}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") Add_RouteGroup();
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
              <ReactTable
                columns={Column}
                data={arrPODT}
                minRows={5}
                row_click={(ProInfo, Index) => {}}
                row_doubleclick={(ProInfo, Index) => {}}
                background={true}
                className="full-table"
              />
            </div>
          </div>
          <Messagedialogbox />
          <div className="btn-section">
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) => {
                if (_Proparem.iProId === 0) {
                  Show_Message(dispatch, "Please Select Product", "info");
                } else if (iBOMId === 0) {
                  Show_Message(dispatch, "BOM is Required", "info");
                } else if (iRGId === 0) {
                  Show_Message(dispatch, "Route Group is Required", "info");
                } else if (Number(dPlanedQty) === 0) {
                  Show_Message(dispatch, "Planed Qty is Required", "info");
                } else if (
                  Number(iStatusId) === 2 &&
                  Number(dProductionQty) === 0
                ) {
                  Show_Message(dispatch, "Production Qty is Required", "info");
                } else {
                  Ask_Question(
                    dispatch,
                    Save_ProductionOrder,
                    "Do You Want to  Save Production Order?"
                  );
                }
              }}
              disabled={isCompleted}
              tabIndex={ListTabOrder[0].btnSave}
              onKeyDown={(e) => TabIndex(e)}
            >
              Save &nbsp; <i className="fas fa-save"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={Clear_ProductionOrder}
              tabIndex={ListTabOrder[0].btnClear}
              onKeyDown={(e) => TabIndex(e)}
            >
              Clear &nbsp; <i className="fas fa-eraser"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              tabIndex={ListTabOrder[0].btnDelete}
              onKeyDown={(e) => TabIndex(e)}
              onClick={(e) => {
                if (iPOId === 0) {
                  Show_Message(
                    dispatch,
                    "Please Select Production Order",
                    "info"
                  );
                } else {
                  Ask_Question(
                    dispatch,
                    Delete_ProductionOrder,
                    "Do You Want to  Delete Production Order?"
                  );
                }
              }}
            >
              Delete &nbsp; <i className="far fa-trash-alt"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={() => window.history.back()}
              tabIndex={ListTabOrder[0].btnClose}
              onKeyDown={(e) => TabIndex(e)}
            >
              Close &nbsp; <i className="fas fa-times"></i>
            </button>
          </div>
          {_Common.IsProSearch && (
            <MasterProductSearch
              iScrId={iScrId}
              strScrType="Sales"
              iPartyId={0}
              iTaxRelId={1}
              disabled={iPOId > 0 ? true : false}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ProductionOrder;
