import { useState, useEffect } from "react";
import ExportImport from "./ExportImport";
import MasterList from "./MasterListItem";
import AddressPopup from "../Transaction/AddressPopup";
import SideMenu from "../General/SideMenu";
import $ from "jquery";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import Messagedialogbox from "../General/Messagedialogbox";
import MasterCodeSetting from "./MasterCodeSetting";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import { Country_OnChange } from "./MasterTransaction";
function SupplierMaster() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const iScrId = 3;
  const strSuppCodeTag = "Enter Supplier Code";
  const [ManualCode, setManualCode] = useState(true);
  const [iSuppId, setiSuppId] = useState(0);
  const [strSuppCode, setstrSuppCode] = useState("");
  const [strSuppName, setstrSuppName] = useState("");
  const [strAliasName, setstrAliasName] = useState("");
  const [strOfficeNo, setstrOfficeNo] = useState("");
  const [strStreet, setstrStreet] = useState("");
  const [strCity, setstrCity] = useState("");
  const [strArea, setstrArea] = useState("");
  const [strPinCode, setstrPinCode] = useState("");
  const [strCountryId, setstrCountryId] = useState("100");
  const [strStateId, setstrStateId] = useState("32");
  const [strPhone, setstrPhone] = useState("");
  const [strPhone1, setstrPhone1] = useState("");
  const [strMobile, setstrMobile] = useState("");
  const [strMobile1, setstrMobile1] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [strRemarks, setstrRemarks] = useState("");
  const [strTinNo, setstrTinNo] = useState("");
  const [iStatusId, setiStatusId] = useState(1);
  const [dOpBal, setdOpBal] = useState((0.0).toFixed());
  const [CreditTerm, setCreditTerm] = useState("30 Days");
  const [iVenTypeId, setiVenTypeId] = useState(1);
  const [iBalTypeId, setiBalTypeId] = useState(3);
  const [strFilterColumn, setstrFilterColumn] = useState("All");
  const [strFilter, setstrFilter] = useState("");
  const [strSearch, setstrSearch] = useState("");
  const [strGst, setstrGst] = useState("");
  const [ExpImpOpen, setExpImpOpen] = useState(false);
  const [listCodeFormat, setlistCodeFormat] = useState([]);
  const [listEntStatus, setlistEntStatus] = useState([]);
  const [listEntCountry, setlistEntCountry] = useState([]);
  const [listEntState, setlistEntState] = useState([]);
  const [listEntVentTyp, setlistEntVentTyp] = useState([]);
  const [listEntBalTyp, setlistEntBalTyp] = useState([]);
  const [listEntSupList, setlistEntSupList] = useState([]);
  const [ListValidateProperties, setListValidateProperties] = useState([]);
  const [BtnExport, setBtnExport] = useState(false);
  const [BtnSaveDisable, setBtnSaveDisable] = useState(false);
  const [BtnDeleteDisable, setBtnDeleteDisable] = useState(false);
  const [IsMasterCode, setIsMasterCode] = useState(false);
  const [bIsPhone1, setbIsPhone1] = useState(false);
  const [bIsMobile1, setbIsMobile1] = useState(false);
  const [bIsRemark, setbIsRemark] = useState(false);
  const [bIsStatus, setbIsStatus] = useState(false);
  const [bIsTinNo, setbIsTinNo] = useState(false);
  const [bIsBalType, setbIsBalType] = useState(false);
  const [bIsOpenBal, setbIsOpenBal] = useState(false);
  const [bIsCreditTerm, setbIsCreditTerm] = useState(false);
  const [bIsNewAddress, setbIsNewAddress] = useState(false);

  useEffect(() => {
    Load_SupplierMaster();
  }, []);
  const Load_SupplierMaster = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);
      const res = await API_FETCH(
        `SupplierMaster/Load_SupplierMaster/${Number(_LocalParems.Branch)}/${
          _LocalParems.DB
        }`,
        dispatch
      );
      if (res) {
        setlistEntStatus(res.objEntStatus);
        setlistEntCountry(res.objEntCountry);
        setlistEntState(res.objEntState);
        setlistEntVentTyp(res.objEntVenTyp);
        setlistEntBalTyp(res.objEntBalTyp);
        setlistEntSupList(res.objEntSupplierList);
        setlistCodeFormat(res.objCodeFormat);
        setstrSuppCode(res.objCodeFormat[0].strCode);
        setManualCode(!res.objCodeFormat[0].isManualNo);

        TabOrderAssign(res.Transtab);
        setListValidateProperties(res.Transtab);

        await Pre_Loading(dispatch, false);
        ButtonPermission(_LocalParems.MasterPer, iSuppId);
        const response = await API_FETCH(
          "Home/GetFieldSetting/" + 3 + "/" + _LocalParems.DB,
          dispatch
        );
        if (response) {
          var objEntField = response.objEntFields;
          setbIsPhone1(objEntField[0].bIsVissible);
          setbIsMobile1(objEntField[1].bIsVissible);
          setbIsStatus(objEntField[2].bIsVissible);
          setbIsTinNo(objEntField[3].bIsVissible);
          setbIsRemark(objEntField[4].bIsVissible);
          setbIsBalType(objEntField[5].bIsVissible);
          setbIsOpenBal(objEntField[6].bIsVissible);
          setbIsCreditTerm(objEntField[7].bIsVissible);
        } else {
          Show_Message(dispatch, response.strMessage, "error");
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const ClearClick = () => {
    try {
      setiSuppId(0);
      setstrSuppCode("");
      setstrSuppName("");
      setstrAliasName("");
      setstrOfficeNo("");
      setstrStreet("");
      setstrCity("");
      setstrArea("");
      setstrPinCode("");
      setstrCountryId("1");
      setstrStateId("32");
      setstrPhone("");
      setBtnDeleteDisable(false);
      setBtnSaveDisable(false);
      setstrPhone1("");
      setstrMobile("");
      setstrMobile1("");
      setstrEmail("");
      setstrRemarks("");
      setstrTinNo("");
      setiStatusId(1);
      setdOpBal((0.0).toFixed());
      setCreditTerm("30 Days");
      setiVenTypeId(1);
      setiBalTypeId(3);
      setstrFilterColumn("All");
      setstrFilter("");
      setstrSearch("");
      setstrGst("");
      Load_SupplierMaster();
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const ExportImportPopup = () => {
    try {
      setExpImpOpen(!ExpImpOpen);
      ClearClick();
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const SaveClick = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const listEntSupInsUpd = {
        iSuppId: iSuppId,
        iBranchId: Number(_AuthParems.Branch),
        iNumSeqNo: listCodeFormat[0].iNumSeqNo,
        strPrefix: listCodeFormat[0].strPrefix,
        strSuppCode: strSuppCode,
        strSuppName: strSuppName,
        strAliasName: strAliasName,
        strAdd1: strOfficeNo,
        strAdd2: strStreet,
        strAdd3: strArea,
        strAdd4: strCity,
        strPincode: strPinCode,
        iCountry: Number(strCountryId),
        iState: Number(strStateId),
        strPhone1: strPhone,
        strPhone2: strPhone1,
        strMobile1: strMobile,
        strMobile2: strMobile1,
        strEmail: strEmail,
        strRemarks: strRemarks,
        strGST: strGst,
        strTinNo: strTinNo,
        iStatusId: Number(iStatusId),
        dOpBal: Number(dOpBal),
        iBalTypeId: Number(iBalTypeId),
        iVenTypeId: Number(iVenTypeId),
        CreditTerm: CreditTerm,
        strSearch: strSearch,
        strFilter: strFilter,
        strFilterColumn: strFilterColumn,
        iTitleTag: iScrId,
        strSuppCodeTag: strSuppCodeTag,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "SupplierMaster/InsUpd_Supplier/",
        listEntSupInsUpd,
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const DeleteClick = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_POST(
        `SupplierMaster/Delete_Supplier/${iSuppId}/${iVenTypeId}/${Number(
          _AuthParems.Branch
        )}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const DeleteIconClick = async (iSuppId, iVenTypeId) => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_POST(
        "SupplierMaster/Delete/" +
          iSuppId +
          "/" +
          iVenTypeId +
          "/" +
          iScrId +
          "/" +
          _AuthParems.DB,
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const View_Supplier = async (iSuppId) => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        `SupplierMaster/View_Supplier/${iSuppId}/${Number(
          _AuthParems.Branch
        )}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        let EntSupp = res.EntSupp[0];
        setiSuppId(EntSupp.iSuppId);
        setstrSuppCode(EntSupp.strSuppCode);
        setstrSuppName(EntSupp.strSuppName);
        setstrAliasName(EntSupp.strAliasName);
        setstrOfficeNo(EntSupp.strAdd1);
        setstrStreet(EntSupp.strAdd2);
        setstrArea(EntSupp.strAdd3);
        setstrCity(EntSupp.strAdd4);
        setstrPinCode(EntSupp.strPincode);
        setstrCountryId(EntSupp.iCountry.toString());
        setstrStateId(EntSupp.iState.toString());
        setstrPhone(EntSupp.strPhone1);
        setstrPhone1(EntSupp.strPhone2);
        setstrMobile(EntSupp.strMobile1);
        setstrMobile1(EntSupp.strMobile2);
        setstrEmail(EntSupp.strEmail);
        setstrGst(EntSupp.strGST);
        setstrTinNo(EntSupp.strTinNo);
        setiStatusId(EntSupp.iStatusId);
        setstrRemarks(EntSupp.strRemarks);
        setiBalTypeId(EntSupp.iBalTypeId);
        setdOpBal(EntSupp.dOpBal);
        setiVenTypeId(EntSupp.iVenTypeId);
        setCreditTerm(EntSupp.creditTerm);
        Pre_Loading(dispatch, false);
        ButtonPermission(_AuthParems.MasterPer, EntSupp.iSuppId);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Search_Supplier = async (strtxt) => {
    try {
      const objSearch = {
        iBranchId: Number(_AuthParems.Branch),
        strSearch: strtxt,
        strDBName: _AuthParems.DB,
      };
      setstrSearch(strtxt);
      const res = await API_POST(
        `SupplierMaster/Search_Supplier/`,
        objSearch,
        dispatch
      );
      if (res) setlistEntSupList(res.EntSupp);
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  //#region TabSetting
  const [ListTabOrder, setListTabOrder] = useState([
    {
      SupCodeTab: "",
      AliasTab: "",
      NameTab: "",
      OfficeNoTab: "",
      StreetTab: "",
      AreaTab: "",
      CityTab: "",
      PinCodeTab: "",
      CountryTab: "",
      StateTab: "",
      Phone1Tab: "",
      Phone2Tab: "",
      Mobile1Tab: "",
      Mobile2Tab: "",
      Emailtab: "",
      GstTab: "",
      TinNoTab: "",
      RemarksTab: "",
      CreditLimitTab: "",
      CreditTermTab: "",
      StatusTab: "",
      OpeningBalTab: "",
      BalTypeTab: "",
      VendorTypeTab: "",
      PriceGrpTab: "",
      SupplierListTab: "",
      SupplierFIlterTab: "",
      SupplierSearchTab: "",
      BtnSavtab: "",
      BtnCleartab: "",
      BtnDeletetab: "",
      BtnClosetab: "",
      BtnExporttab: "",
      btnCountry: "",
      btnPriceGrp: "",
      btnState: "",
    },
  ]);
  const TabOrderAssign = (EntProperties) => {
    let TabOrder = ListTabOrder;
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      switch (EntProperties[i].strCtrlName) {
        case "txtCode":
          TabOrder[0].SupCodeTab = EntProperties[i].iTabIndex;
          break;
        case "txtName":
          TabOrder[0].NameTab = EntProperties[i].iTabIndex;
          break;
        case "txtAlias":
          TabOrder[0].AliasTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd1":
          TabOrder[0].OfficeNoTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd2":
          TabOrder[0].StreetTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd3":
          TabOrder[0].AreaTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd4":
          TabOrder[0].CityTab = EntProperties[i].iTabIndex;
          break;
        case "cbCountry":
          TabOrder[0].CountryTab = EntProperties[i].iTabIndex;
          break;
        case "txtPinCode":
          TabOrder[0].PinCodeTab = EntProperties[i].iTabIndex;
          break;
        case "cbState":
          TabOrder[0].StateTab = EntProperties[i].iTabIndex;
          break;
        case "txtPhone1":
          TabOrder[0].Phone1Tab = EntProperties[i].iTabIndex;
          break;
        case "txtPhone2":
          TabOrder[0].Phone2Tab = EntProperties[i].iTabIndex;
          break;
        case "txtMobile1":
          TabOrder[0].Mobile1Tab = EntProperties[i].iTabIndex;
          break;
        case "txtMobile2":
          TabOrder[0].Mobile2Tab = EntProperties[i].iTabIndex;
          break;
        case "txtEmail":
          TabOrder[0].Emailtab = EntProperties[i].iTabIndex;
          break;
        case "txtTin":
          TabOrder[0].TinNoTab = EntProperties[i].iTabIndex;
          break;
        case "txtGst":
          TabOrder[0].GstTab = EntProperties[i].iTabIndex;
          break;
        case "cbStatus":
          TabOrder[0].StatusTab = EntProperties[i].iTabIndex;
          break;
        case "txtRemarks":
          TabOrder[0].RemarksTab = EntProperties[i].iTabIndex;
          break;
        case "txtOpBalance":
          TabOrder[0].OpeningBalTab = EntProperties[i].iTabIndex;
          break;
        case "cbBalType":
          TabOrder[0].BalTypeTab = EntProperties[i].iTabIndex;
          break;
        case "txtCreditLimit":
          TabOrder[0].CreditLimitTab = EntProperties[i].iTabIndex;
          break;
        case "txtCreditTerm":
          TabOrder[0].CreditTermTab = EntProperties[i].iTabIndex;
          break;
        case "cbVenType":
          TabOrder[0].VendorTypeTab = EntProperties[i].iTabIndex;
          break;
        case "btnSave":
          TabOrder[0].BtnSavtab = EntProperties[i].iTabIndex;
          break;
        case "btnClear":
          TabOrder[0].BtnCleartab = EntProperties[i].iTabIndex;
          break;
        case "btnDelete":
          TabOrder[0].BtnDeletetab = EntProperties[i].iTabIndex;
          break;
        case "btnClose":
          TabOrder[0].BtnClosetab = EntProperties[i].iTabIndex;
          break;
        case "btnExportImport":
          TabOrder[0].BtnExporttab = EntProperties[i].iTabIndex;
          break;
        case "btnCountry":
          TabOrder[0].btnCountry = EntProperties[i].iTabIndex;
          break;
        case "btnPriceGrp":
          TabOrder[0].btnPriceGrp = EntProperties[i].iTabIndex;
          break;
        case "btnState":
          TabOrder[0].btnState = EntProperties[i].iTabIndex;
          break;
        case "cbPriceGroup":
          TabOrder[0].PriceGrpTab = EntProperties[i].iTabIndex;
          break;
        case "cbFilter":
          TabOrder[0].SupplierFIlterTab = EntProperties[i].iTabIndex;
          break;
        case "txtSearch":
          TabOrder[0].SupplierSearchTab = EntProperties[i].iTabIndex;
          break;
        case "lbSupplierName":
          TabOrder[0].SupplierListTab = EntProperties[i].iTabIndex;
          break;
        default:
          break;
      }
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    ); //returns object
    sessionStorage.maxTab = maxTab.iTabIndex;
    setListTabOrder(TabOrder);
  };
  const TabIndex = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].SupplierListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].SupplierListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].SupplierListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].SupplierListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].SupplierListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].SupplierListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].SupplierListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  //#endregion

  const PropertiesValidation = () => {
    var ErrorMsg = true;
    let ValidateItem = ListValidateProperties.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtName":
          ErrorMsg = strSuppName === "" ? false : true;
          break;
        case "txtAlias":
          ErrorMsg = strAliasName === "" ? false : true;
          break;
        case "txtAdd1":
          ErrorMsg = strOfficeNo === "" ? false : true;
          break;
        case "txtAdd2":
          ErrorMsg = strStreet === "" ? false : true;
          break;
        case "txtAdd3":
          ErrorMsg = strArea === "" ? false : true;
          break;
        case "txtAdd4":
          ErrorMsg = strCity === "" ? false : true;
          break;
        case "txtPinCode":
          ErrorMsg = strPinCode === "" ? false : true;
          break;
        case "txtPhone1":
          ErrorMsg = strPhone === "" ? false : true;
          break;
        case "txtPhone2":
          ErrorMsg = strPhone1 === "" ? false : true;
          break;
        case "txtMobile1":
          ErrorMsg = strMobile === "" ? false : true;
          break;
        case "txtMobile2":
          ErrorMsg = strMobile1 === "" ? false : true;
          break;
        case "txtEmail":
          ErrorMsg = strEmail === "" ? false : true;
          break;
        case "txtTin":
          ErrorMsg = strTinNo === "" ? false : true;
          break;
        case "txtGst":
          ErrorMsg = strGst === "" ? false : true;
          break;
        case "txtRemarks":
          ErrorMsg = strRemarks === "" ? false : true;
          break;
        case "txtOpBalance":
          ErrorMsg = dOpBal === "" ? false : true;
          break;
        case "txtCreditTerm":
          ErrorMsg = CreditTerm === "" ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        Show_Message(dispatch, ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };
  const ButtonPermission = (Menu, SuppId) => {
    Menu = Menu.filter((Data) => Data.iScrId === iScrId);
    if (Menu.length > 0) {
      if (Number(SuppId) > 0) {
        setBtnSaveDisable(!Menu[0].isModify);
        setBtnDeleteDisable(!Menu[0].isDelete);
      }
      if (Number(SuppId) === 0) {
        setBtnExport(!Menu[0].isExport);
        setBtnSaveDisable(!Menu[0].isCreate);
      }
    }
  };
  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <MasterList
          ScrId={iScrId}
          strSearchtxt={strSearch}
          tabOrder={ListTabOrder[0].SupplierSearchTab}
          Search={Search_Supplier}
          arrMasterData={listEntSupList}
          isImg={false}
          displayName="strSuppName"
          Delete={DeleteIconClick}
          Parem1="iSuppId"
          Parem2="iVenTypeId"
          View_Data={(suppid) => View_Supplier(suppid)}
          placeholder="Supplier"
        />
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row p-3">
              <div className="col-md-6 col-sm-12 mb-2">
                <label>
                  Name <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strSuppName}
                  onChange={(e) => setstrSuppName(e.target.value)}
                  autoFocus
                  placeholder="Enter Supplier Name"
                  spellCheck={false}
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].name}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  Code <span className="danger">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    value={strSuppCode}
                    readOnly={ManualCode}
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].SupCodeTab}
                    onChange={(e) => setstrSuppCode(e.target.value)}
                    name="strProCode"
                  />
                  <span
                    className="inv-no"
                    onClick={(e) => setIsMasterCode(!IsMasterCode)}
                  >
                    <i className="bx bx-cog"></i>
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Alias Name</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strAliasName}
                  onChange={(e) => setstrAliasName(e.target.value)}
                  placeholder="Enter Alias Name"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].AliasTab}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Office / Door No </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strOfficeNo}
                  onChange={(e) => setstrOfficeNo(e.target.value)}
                  placeholder="Enter Address No"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].OfficeNoTab}
                />
                <span
                  className="btn-shipadd"
                  onClick={(e) => {
                    if (iSuppId === 0)
                      Show_Message(dispatch, "Please Select Party", "info");
                    else setbIsNewAddress(!bIsNewAddress);
                  }}
                >
                  <i className="fa-solid fa-location-dot"></i>
                  Add Secondary Address
                </span>
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Street</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strStreet}
                  onChange={(e) => setstrStreet(e.target.value)}
                  placeholder="Enter Street Name"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].StreetTab}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Area </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strArea}
                  onChange={(e) => setstrArea(e.target.value)}
                  placeholder="Enter Area"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].AreaTab}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>City</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strCity}
                  onChange={(e) => setstrCity(e.target.value)}
                  placeholder="Enter City Name"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].CityTab}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Pincode</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  value={strPinCode}
                  onChange={(e) => setstrPinCode(e.target.value)}
                  placeholder="Enter PinCode"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].PinCodeTab}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>
                  Country <span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_selecrparem.isoptionShow}
                  placeholder="Select Country"
                  select_value={async (val) => {
                    await setstrCountryId(val);
                    await setlistEntState(
                      await Country_OnChange(val, _AuthParems.DB, dispatch)
                    );
                  }}
                  keydown={(e) => TabIndex(e)}
                  taborder={ListTabOrder[0].CountryTab}
                  btnname="Country"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={strCountryId}
                  displayName="strCountryName"
                  disvalue="iCountryId"
                  arrydata={listEntCountry}
                  disabled={false}
                  EmptVal="Country "
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>
                  State <span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow1"
                  showoption={_selecrparem.isoptionShow1}
                  placeholder="Select State"
                  select_value={(val) => setstrStateId(val)}
                  keydown={(e) => TabIndex(e)}
                  taborder={ListTabOrder[0].StateTab}
                  btnname="State"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={strStateId}
                  displayName="strSName"
                  disvalue="iSId"
                  arrydata={listEntState}
                  disabled={false}
                  EmptVal="State "
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Phone </label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  value={strPhone}
                  onChange={(e) => setstrPhone(e.target.value)}
                  placeholder="Enter Phone No"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].Phone1Tab}
                />
              </div>
              {bIsPhone1 && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>Phone 1</label>
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    value={strPhone1}
                    onChange={(e) => setstrPhone1(e.target.value)}
                    placeholder="Enter Phone No"
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].Phone2Tab}
                  />
                </div>
              )}
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Mobile</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  value={strMobile}
                  onChange={(e) => setstrMobile(e.target.value)}
                  placeholder="Enter Mobile No"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].Mobile1Tab}
                />
              </div>
              {bIsMobile1 && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>Mobile 1</label>
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    value={strMobile1}
                    onChange={(e) => setstrMobile1(e.target.value)}
                    placeholder="Enter Mobile No"
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].Mobile2Tab}
                  />
                </div>
              )}
              <div className="col-md-3 col-sm-12 mb-2">
                <label>E-Mail </label>
                <input
                  type="email"
                  aria-label="First name"
                  className="form-control"
                  value={strEmail}
                  onChange={(e) => setstrEmail(e.target.value)}
                  placeholder="Enter Email"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].Emailtab}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>GST</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strGst}
                  onChange={(e) => setstrGst(e.target.value)}
                  placeholder="Enter GST No"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].GstTab}
                  maxlength="15"
                />
              </div>
              {bIsTinNo && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>TIN</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strTinNo}
                    onChange={(e) => setstrTinNo(e.target.value)}
                    placeholder="Enter TIN No"
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].TinNoTab}
                  />
                </div>
              )}
              {bIsStatus && (
                <div className="col-md-3 col-sm-6 mb-2">
                  <label>Status</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow2"
                    showoption={_selecrparem.isoptionShow2}
                    placeholder="Select Status"
                    select_value={(val) => setiStatusId(val)}
                    keydown={(e) => TabIndex(e)}
                    taborder={ListTabOrder[0].StatusTab}
                    btnname="Status"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iStatusId}
                    displayName="strStatusName"
                    disvalue="iStatusId"
                    arrydata={listEntStatus}
                    disabled={false}
                    EmptVal="Status "
                  />
                </div>
              )}
              {bIsRemark && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>Remarks</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strRemarks}
                    onChange={(e) => setstrRemarks(e.target.value)}
                    placeholder="Enter Remark"
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].RemarksTab}
                  />
                </div>
              )}
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Vendor Type</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow3"
                  showoption={_selecrparem.isoptionShow3}
                  placeholder="Select Vendor Type"
                  select_value={(val) => setiVenTypeId(val)}
                  keydown={(e) => TabIndex(e)}
                  taborder={ListTabOrder[0].VendorTypeTab}
                  btnname="Status"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iVenTypeId}
                  displayName="strVenTypeName"
                  disvalue="iVenTypeId"
                  arrydata={listEntVentTyp}
                  disabled={false}
                  EmptVal="Vendor Type   "
                />
              </div>
              {bIsOpenBal && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>Opening Balance</label>
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    value={dOpBal}
                    onChange={(e) => setdOpBal(e.target.value)}
                    placeholder="Enter Opening Balance"
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].OpeningBalTab}
                  />
                </div>
              )}
              {bIsBalType && (
                <div className="col-md-3 col-sm-6 mb-2">
                  <label>Balance Type</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow4"
                    showoption={_selecrparem.isoptionShow4}
                    placeholder="Select Balance Type"
                    select_value={(val) => setiBalTypeId(val)}
                    keydown={(e) => TabIndex(e)}
                    taborder={ListTabOrder[0].BalTypeTab}
                    btnname="Balance Type"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iBalTypeId}
                    displayName="strBalType"
                    disvalue="iBalTypeId"
                    arrydata={listEntBalTyp}
                    disabled={false}
                    EmptVal="Balance Type "
                  />
                </div>
              )}
              {bIsCreditTerm && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>Credit Term</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={CreditTerm}
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].CreditTermTab}
                    onChange={(e) => setCreditTerm(e.target.value)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="btn-section">
            <button
              type="button"
              className="btn-fabgreen"
              disabled={BtnSaveDisable}
              onKeyDown={(e) => TabIndex(e)}
              tabIndex={ListTabOrder[0].BtnSavtab}
              onClick={(e) => {
                if (strSuppName === "")
                  Show_Message(dispatch, "Please Enter Supplier Name", "info");
                else if (strGst.length > 0 && strGst.length < 15)
                  Show_Message(dispatch, "Please Enter Valid GST Number");
                else if (PropertiesValidation()) {
                  Ask_Question(
                    dispatch,
                    SaveClick,
                    "Do You Want to  Save Supplier?"
                  );
                }
              }}
            >
              Save &nbsp; <i className="fas fa-Save"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={ExportImportPopup}
              disabled={BtnExport}
              onKeyDown={(e) => TabIndex(e)}
              tabIndex={ListTabOrder[0].BtnExporttab}
            >
              Export &nbsp; <i className="fas fa-sync"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              disabled={BtnDeleteDisable}
              onClick={(e) => {
                if (iSuppId === 0) {
                  Show_Message(dispatch, "Please Select Supplier", "info");
                } else {
                  Ask_Question(
                    dispatch,
                    DeleteClick,
                    "Do You Want to  Delete Supplier?"
                  );
                }
              }}
              onKeyDown={(e) => TabIndex(e)}
              tabIndex={ListTabOrder[0].BtnDeletetab}
            >
              Delete &nbsp; <i className="fas fa-print"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={ClearClick}
              onKeyDown={(e) => TabIndex(e)}
              tabIndex={ListTabOrder[0].BtnCleartab}
            >
              Clear &nbsp; <i className="fas fa-eraser"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) => window.history.back()}
              onKeyDown={(e) => TabIndex(e)}
              tabIndex={ListTabOrder[0].BtnClosetab}
            >
              Close &nbsp; <i className="fas fa-times"></i>
            </button>
          </div>
          {ExpImpOpen && (
            <ExportImport
              Close={(e) => {
                setExpImpOpen(!ExpImpOpen);
                ClearClick();
              }}
              iScrId={iScrId}
            />
          )}
          {bIsNewAddress && (
            <AddressPopup
              ScrId={iScrId}
              PartyId={iSuppId}
              PartyName={strSuppName}
              Close={(e) => setbIsNewAddress(!bIsNewAddress)}
            />
          )}
          {IsMasterCode && (
            <MasterCodeSetting
              Close={(e) => setIsMasterCode(!IsMasterCode)}
              iScrId={iScrId}
            />
          )}
          <Messagedialogbox />
        </div>
      </div>
    </>
  );
}
export default SupplierMaster;
