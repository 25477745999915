import React, { useEffect, useState } from "react";
import Button from "../Master/MasterButton";
import SideMenu from "../General/SideMenu";
import Messagedialogbox, { Ask_Question } from "../General/Messagedialogbox";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import { Country_OnChange } from "./MasterTransaction";
function BankAccount() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const iScrId = 29;
  const [iVenId, setiVenId] = useState(0);
  const [strVendoreName, setstrVendoreName] = useState("");
  const [strvendoreType, setstrvendoreType] = useState("");
  const [iBankAccId, setiBankAccId] = useState(0);
  const [strBankName, setstrBankName] = useState("");
  const [iBankNameId, setiBankNameId] = useState("");
  const [strBranch, setstrBranch] = useState("");
  const [strIFSC, setstrIFSC] = useState("");
  const [strMICR, setstrMICR] = useState("");
  const [strAccHolderName, setstrAccHolderName] = useState("");
  const [strAccNo, setstrAccNo] = useState("");
  const [iAccType, setiAccType] = useState(2);
  const [iCountry, setiCountry] = useState("100");
  const [iState, setiState] = useState("32");
  const [iAccHolderType, setiAccHolderType] = useState(1);
  const [isPrintdefault, setisPrintdefault] = useState(true);
  const [isPaydefault, setisPaydefault] = useState(true);
  const [isRecdefault, setisRecdefault] = useState(true);
  const [isVendore, setisVendore] = useState(false);
  const [IsInsert, setIsInsert] = useState(true);
  const [arrAccHoldertype, setarrAccHoldertype] = useState([]);
  const [arrState, setarrState] = useState([]);
  const [arrCountry, setarrCountry] = useState([]);
  const [arrAcctype, setarrAcctype] = useState([]);
  const [arrEntPartyBank, setarrEntPartyBank] = useState([]);
  const [arrEntBank, setarrEntBank] = useState([]);
  const [arrVendore, setarrVendore] = useState([]);

  useEffect(() => {
    Load_BankAccount(iAccHolderType);
  }, []);
  const Load_BankAccount = async (iVenType) => {
    try {
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);
      setisVendore(false);
      const res = await API_FETCH(
        `BankAccount/Load_BankAccount/${iVenType}/${_LocalParems.Branch}/${_LocalParems.DB}`,
        dispatch
      );
      if (res) {
        setarrAccHoldertype(res.EntAccHolderType);
        setarrState(res.EntState);
        setarrCountry(res.EntCountry);
        setarrAcctype(res.EntAccType);
        setarrEntPartyBank(res.EntBankAcc);
        setarrEntBank(res.EntBankList);
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  //#region  Click Event
  const Save_BankAccount = async () => {
    try {
      Pre_Loading(dispatch, true);
      const InsUpdBank = {
        iBankAccId: iBankAccId,
        strBankName: strBankName,
        iBankNameId: iBankNameId,
        strBranch: strBranch,
        strIFSC: strIFSC,
        strMICR: strMICR,
        strAccHolderName: strAccHolderName,
        strAccNo: strAccNo,
        iAccType: Number(iAccType),
        iCountry: Number(iCountry),
        iState: Number(iState),
        bPrintDefault: isPrintdefault,
        bPayDefault: isPaydefault,
        bRecDefault: isRecdefault,
        iAccHolderType: iAccHolderType,
        iTitleTag: iScrId,
        iPartyId: iVenId,
        strPartyName: strVendoreName,
        IsInsert: IsInsert,
        iBranchId: _AuthParems.Branch,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "BankAccount/InsUpd_BankAccount/",
        InsUpdBank,
        dispatch
      );
      if (res) {
        Clear_BankAccount();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const View_BankAccount = async (AccId) => {
    try {
      Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        `BankAccount/View_BankAccount/${AccId}/${iAccHolderType}/${iVenId}/${_AuthParems.Branch}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        let objBank = res.objBankAcc[0];
        setstrBankName(objBank.strBankName);
        setiBankNameId(objBank.iBankNameId);
        setstrBranch(objBank.strBranch);
        setstrIFSC(objBank.strIFSC);
        setstrMICR(objBank.strMICR);
        setstrAccHolderName(objBank.strAccHolderName);
        setstrAccNo(objBank.strAccNo);
        setiAccType(objBank.iAccType);
        setiCountry(objBank.iCountry.toString());
        setiState(objBank.iState.toString());
        setisPrintdefault(objBank.bPrintDefault);
        setisPaydefault(objBank.bPayDefault);
        setisRecdefault(objBank.bRecDefault);
        setiBankAccId(objBank.iBankAccId);
        setIsInsert(false);
        Pre_Loading(dispatch, false);
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const View_Party = async (objVen) => {
    try {
      setiVenId(objVen.partyId);
      setstrVendoreName(objVen.partyName);
      setstrAccHolderName(objVen.partyName);
      const res = await API_FETCH(
        `BankAccount/View_PartyBank/${iAccHolderType}/${objVen.partyId}/${_AuthParems.Branch}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        setarrEntPartyBank(res.EntBankAcc);
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const ClearPrems = () => {
    setiVenId(0);
    setstrVendoreName("");
    setstrvendoreType("");
    setiBankNameId(0);
    setiBankAccId(0);
    setstrBankName("");
    setstrBranch("");
    setstrIFSC("");
    setstrMICR("");
    setstrAccHolderName("");
    setstrAccNo("");
    setiAccType(2);
    setiCountry("100");
    setiState("32");
    setisPrintdefault(true);
    setisPaydefault(true);
    setisRecdefault(true);
    setisVendore(false);
    setIsInsert(true);
    setarrEntPartyBank([]);
    setarrVendore([]);
  };
  const Clear_BankAccount = () => {
    try {
      ClearPrems();
      setiAccHolderType(1);
      Load_BankAccount(1);
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Delete_BankAccount = async () => {
    try {
      const res = await API_POST(
        `BankAccount/Delete_BankAccount/${iBankAccId}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_BankAccount();
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  //#endregion

  //#region OnChange Event
  const Vendoretype_Onchange = async (iventype) => {
    try {
      Pre_Loading(dispatch, true);
      ClearPrems();
      setiAccHolderType(Number(iventype));
      if (
        Number(iventype) === 2 ||
        Number(iventype) === 3 ||
        Number(iventype) === 4 ||
        Number(iventype) === 5 ||
        Number(iventype) === 6
      ) {
        const res = await API_FETCH(
          `BankAccount/Vendor_Onchange/${Number(iventype)}/${
            _AuthParems.Branch
          }/${_AuthParems.DB}`,
          dispatch
        );
        if (res) {
          setarrVendore(res.EntParty);
          setisVendore(true);
          switch (Number(iventype)) {
            case 2:
              setstrvendoreType("Supplier Name");
              break;
            case 3:
              setstrvendoreType("Customer Name");
              break;
            case 4:
              setstrvendoreType("Service Vendor");
              break;
            case 5:
              setstrvendoreType("Othe service");
              break;
            case 6:
              setstrvendoreType("JobWork Vendor");
              break;
            default:
              break;
          }
        }
      } else Load_BankAccount(Number(iventype));
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const IFSC_Onchange = (IFSCcode) => {
    $.getJSON(`https://ifsc.razorpay.com/${IFSCcode}`)
      .then((res) => {
        setstrBranch(res.BRANCH);
        setstrMICR(res.MICR);
        setstrIFSC(res.IFSC);
      })
      .catch((err) => {
        if (err.status === 404) {
          setstrIFSC(IFSCcode);
          Show_Message(dispatch, "Enter Valid IFSC Code", "error");
        } else if (err.status === 0) setstrIFSC(IFSCcode);
        else Show_Message(dispatch, err, "error");
      });
  };
  const PartySearch = async (strtxt) => {
    try {
      const objSearch = {
        strSearch: strtxt,
        iAccHType: Number(iAccHolderType),
        strDBName: _AuthParems.DB,
        iBranchId: _AuthParems.Branch,
      };
      const res = await API_POST(
        "BankAccount/Search_Vendor/",
        objSearch,
        dispatch
      );
      if (res) {
        setarrVendore(res.EntParty);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  //#endregion

  const KeyDown = (e) => {
    var maxTab = 18;
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex - 1) + "']").select();
      } else {
        $("[tabindex='" + maxTab + "']").focus();
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 18) {
          $(".Ban .ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 18) {
          $(".Ban .ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 18) {
          $(".Ban .ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex - 1) + "']").select();
      } else {
        $(".Ban .ReactTable .rt-tbody .rt-tr:eq(0)").focus();
      }
    }
  };

  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <div className="Master-card">
          <div className="data-list">
            {arrEntPartyBank.map((bank, Index) => (
              <button
                key={Index}
                tabIndex={Index}
                onKeyDown={(e) => {
                  const arrlength = arrEntPartyBank.length - 1;
                  if (e.key === "ArrowDown") {
                    if (Index === arrlength)
                      $(`.data-list [tabindex=${Index + 1}]`).focus();
                    else $(`.data-list [tabindex=${0}]`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    if (Index === 0)
                      $(`.data-list [tabindex=${arrlength}]`).focus();
                    else $(`.data-list [tabindex=${Index - 1}]`).focus();
                  }
                  if (e.key === "Enter") {
                    View_BankAccount(bank.iBankAccId);
                  }
                }}
              >
                <label onClick={(e) => View_BankAccount(bank.iBankAccId)}>
                  {bank.strBankName}
                </label>
              </button>
            ))}
          </div>
        </div>
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-3 col-sm-12 mb-2">
                <label>A/C Holder Type</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_selecrparem.isoptionShow}
                  placeholder="Select A/C Holder Type"
                  select_value={(val) => Vendoretype_Onchange(val)}
                  keydown={(e) => KeyDown(e)}
                  taborder={0}
                  btnname="A/C Holder Type"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iAccHolderType}
                  displayName="accHolderName"
                  disvalue="accHolderId"
                  arrydata={arrAccHoldertype}
                  disabled={false}
                  EmptVal="A/C Holder Type"
                />
              </div>
              {isVendore && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>
                    {strvendoreType}
                    <span className="danger">*</span>
                  </label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow1"
                    showoption={_selecrparem.isoptionShow1}
                    placeholder="Select Vendore"
                    taborder={1}
                    keydown={(e) => KeyDown(e)}
                    onChange={(val) => PartySearch(val)}
                    select_value={(val) => View_Party(val)}
                    btnname="Vendor"
                    btnshow={false}
                    show_popup=""
                    valueonly={false}
                    defaultval={iVenId}
                    displayName="partyName"
                    disvalue="partyId"
                    arrydata={arrVendore}
                    EmptVal="Vendor"
                  />
                </div>
              )}
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  A/C Holder Name<span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  autoFocus
                  placeholder=" Enter Acc Hoder Name"
                  onClick={(e) => e.target.select()}
                  tabIndex="2"
                  onKeyDown={(e) => KeyDown(e)}
                  value={strAccHolderName}
                  onChange={(e) => setstrAccHolderName(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  Bank Name<span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow2"
                  showoption={_selecrparem.isoptionShow2}
                  placeholder="Select Bank"
                  taborder={3}
                  keydown={(e) => KeyDown(e)}
                  select_value={(res) => {
                    setstrBankName(res.bankNames);
                    setiBankNameId(res.bnId);
                  }}
                  btnname="Bank"
                  btnshow={false}
                  show_popup=""
                  valueonly={false}
                  defaultval={iBankNameId}
                  displayName="bankNames"
                  disvalue="bnId"
                  arrydata={arrEntBank}
                  EmptVal="Bank"
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  IFSC Code<span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  placeholder=" Enter IFSC Code"
                  className="form-control"
                  tabIndex="5"
                  onKeyDown={(e) => KeyDown(e)}
                  onClick={(e) => e.target.select()}
                  value={strIFSC}
                  onChange={(e) => setstrIFSC(e.target.value)}
                  onBlur={(e) => IFSC_Onchange(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  Branch Name <span className="danger">*</span>
                </label>
                <input
                  readOnly
                  type="text"
                  placeholder=" Enter Branch Name"
                  className="form-control"
                  value={strBranch}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>MICR CODE</label>
                <input
                  type="text"
                  placeholder=" Enter MICR Code"
                  className="form-control"
                  value={strMICR}
                  readOnly
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  A/C No<span className="danger">*</span>
                </label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  tabIndex="7"
                  placeholder="Account Number"
                  onKeyDown={(e) => KeyDown(e)}
                  value={strAccNo}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrAccNo(e.target.value)}
                />
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-12 mt-2">
                  <label>A/C Type</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow3"
                    showoption={_selecrparem.isoptionShow3}
                    placeholder="Select A/C Type"
                    select_value={(val) => setiAccType(val)}
                    keydown={(e) => KeyDown(e)}
                    taborder={8}
                    btnname="A/C Type"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iAccType}
                    displayName="accTypeName"
                    disvalue="accTypeId"
                    arrydata={arrAcctype}
                    disabled={false}
                    EmptVal="A/C Type "
                  />
                </div>
                <div className="col-md-3 col-sm-6 mt-2">
                  <label>
                    Country <span className="danger">*</span>
                  </label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow4"
                    showoption={_selecrparem.isoptionShow4}
                    placeholder="Select Country"
                    select_value={async (val) => {
                      await setiCountry(val);
                      await setarrState(
                        await Country_OnChange(val, _AuthParems.DB, dispatch)
                      );
                    }}
                    keydown={(e) => KeyDown(e)}
                    taborder={9}
                    btnname="Country"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iCountry}
                    displayName="strCountryName"
                    disvalue="iCountryId"
                    arrydata={arrCountry}
                    disabled={false}
                    EmptVal="Country "
                  />
                </div>
                <div className="col-md-3 col-sm-6 mt-2">
                  <label>
                    State <span className="danger">*</span>
                  </label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow5"
                    showoption={_selecrparem.isoptionShow5}
                    placeholder="Select State"
                    select_value={(val) => setiState(val)}
                    keydown={(e) => KeyDown(e)}
                    taborder={20}
                    btnname="State"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iState}
                    displayName="strSName"
                    disvalue="iSId"
                    arrydata={arrState}
                    disabled={false}
                    EmptVal="State "
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-12 text-nowrap mb-2">
                  <label>Print Default</label>
                  <br />
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      tabIndex="11"
                      onKeyDown={(e) => KeyDown(e)}
                      type="checkbox"
                      checked={isPrintdefault}
                      onChange={(e) => setisPrintdefault(!isPrintdefault)}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 text-nowrap mb-2">
                  <label>Payment Default</label>
                  <br />
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={isPaydefault}
                      tabIndex="12"
                      onKeyDown={(e) => KeyDown(e)}
                      onChange={(e) => setisPaydefault(!isPaydefault)}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 text-nowrap mb-2">
                  <label>Recipte Default</label>
                  <br />
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={isRecdefault}
                      tabIndex="13"
                      onKeyDown={(e) => KeyDown(e)}
                      onChange={(e) => setisRecdefault(!isRecdefault)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Button
            Save={(e) => {
              if (strBankName === "") {
                Show_Message(dispatch, "Please Select Bank", "info");
              } else if (strIFSC === "") {
                Show_Message(dispatch, "Please Enter IFSC Code", "info");
              } else if (strAccHolderName === "") {
                Show_Message(dispatch, "Please Enter Name", "info");
              } else if (strAccNo === "") {
                Show_Message(dispatch, "Please Enter Account Number", "info");
              } else {
                Ask_Question(
                  dispatch,
                  Save_BankAccount,
                  "Do You Want to Save Bank Account?"
                );
              }
            }}
            Clear={Clear_BankAccount}
            Delete={(e) => {
              if (iBankAccId === 0) {
                Show_Message(dispatch, "Please Select Account", "info");
              } else {
                Ask_Question(
                  dispatch,
                  Delete_BankAccount,
                  "Do You Want to Delete Bank Account?"
                );
              }
            }}
            ScrId={iScrId}
            Iid={iBankAccId}
            SaveIndex={14}
            DeleteIndex={15}
            CloseIndex={17}
            ClearIndex={16}
            ExportIndex={""}
            onKeyDown={(e) => KeyDown(e)}
          />
          <Messagedialogbox />
        </div>
      </div>
    </>
  );
}
export default BankAccount;
