import React, { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import Messagedialogbox from "../General/Messagedialogbox";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import ReactTable from "../General/ReactTable";
function PriceMaster() {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [iTaxRelId, setiTaxRelId] = useState(1);
  const [iPrgId, setiPrgId] = useState(1);
  const [strSearch, setstrSearch] = useState("");
  const [colName, setcolName] = useState("Product");

  const [ListTaxRel, setListTaxRel] = useState([]);
  const [ListPrgGrp, setListPrgGrp] = useState([]);
  const [ListEntItem, setListEntItem] = useState([]);
  const [BtnSaveDisable, setBtnSaveDisable] = useState(false);
  const [isProduct, setisProduct] = useState(true);
  useEffect(() => {
    tab_click("Product", 0);
  }, []);
  const Load_PriceMaster = async (TabId) => {
    try {
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);
      const res = await API_FETCH(
        `PriceMaster/Load_PriceMaster/${TabId}/${iTaxRelId}/${Number(
          _LocalParems.Branch
        )}/${_LocalParems.DB}`,
        dispatch
      );
      if (res) {
        setListTaxRel(res.EntTax);
        setListPrgGrp(res.EntPricGrp);
        setListEntItem(res.EntItem);
        ButtonPermission(_LocalParems.MasterPer);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Update_PriceMaster = async () => {
    try {
      const res = await API_POST(
        "PriceMaster/Update_PriceMaster/",
        {
          EntProduct: colName === "Product" ? ListEntItem : [],
          EntJobWork: colName === "JobWork" ? ListEntItem : [],
          iTaxRelId: iTaxRelId,
          iPGId: Number(iPrgId),
          TabItem: colName,
          strDBName: _AuthParems.DB,
        },
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const ClearClick = () => {
    Pre_Loading(dispatch, false);
    setiPrgId(1);
    setiTaxRelId(1);
    setBtnSaveDisable(false);
    Load_PriceMaster(colName);
  };
  const Search_Product = async (SearchText) => {
    setstrSearch(SearchText);
    try {
      SearchText = SearchText === "" ? "null" : SearchText;
      const res = await API_POST(
        "PriceMaster/Search_PriceMaster/",
        {
          strSearch: SearchText,
          Itemtype: colName,
          iPRGId: Number(iPrgId),
          iTaxRelId: iTaxRelId,
          strDBName: _AuthParems.DB,
        },
        dispatch
      );
      if (res) {
        setListEntItem(res.EntItem);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const View_PriceGroupRate = async (iPriceGrpId) => {
    try {
      setiPrgId(iPriceGrpId);
      const res = await API_FETCH(
        `PriceMaster/View_PriceGroupRate/${Number(
          iPriceGrpId
        )}/${iTaxRelId}/${colName}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        setListEntItem(res.EntItem);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const TaxType_OnChange = async (iTaxLvId) => {
    try {
      setiTaxRelId(iTaxLvId);
      const res = await API_FETCH(
        `PriceMaster/View_PriceGroupRate/${iPrgId}/${iTaxLvId}/${colName}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        setListEntItem(res.EntItem);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const ButtonPermission = (Menu) => {
    Menu = Menu.filter((Data) => Data.iScrId === 62);
    if (Menu.length > 0) {
      setBtnSaveDisable(!Menu[0].isModify);
    }
  };
  const tab_click = (Button, tabindex) => {
    setcolName(Button);
    Load_PriceMaster(Button);
    let line = document.getElementById("tab-line");
    let lable = $(`.trans-buttons label`);
    let fromleft = lable[tabindex].offsetLeft;
    let width = lable[tabindex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    setisProduct(tabindex === 0 ? true : false);
    $(`.trans-buttons label:eq(${tabindex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");
  };
  const Rate_OnChange = async (Item, Rate, RateType) => {
    try {
      const objParem = {
        ColName: colName,
        RateType: RateType,
        Rate: Rate,
        iProId: Item.proId ? Item.proId : 0,
        iUnitId: Item.unitId ? Item.unitId : 0,
        iJobId: Item.jobId ? Item.jobId : 0,
        EntProduct: Item.proId ? ListEntItem : [],
        EntJobWork: Item.jobId ? ListEntItem : [],
      };
      const res = await API_POST(
        `PriceMaster/Rate_Calculation`,
        objParem,
        dispatch
      );
      if (res) {
        setListEntItem(res.EntItem);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const Productcolumns = [
    {
      Header: "SNo",
      accessor: "sNo",
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Name",
      accessor: "proName",
      style: { justifyContent: "left" },
    },
    {
      Header: "Attributes",
      accessor: "attValues",
      style: { justifyContent: "left" },
      maxWidth: 150,
    },
    {
      Header: "Unit",
      accessor: "unitName",
      maxWidth: 100,
      style: { justifyContent: "left" },
    },
    {
      Header: "Purchase Rate",
      maxWidth: 150,
      style: { justifyContent: "right" },
      accessor: "purRateWithoutTax",
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.purRateWithoutTax}
          onClick={(e) => e.target.select()}
          onBlur={(e) => {
            Rate_OnChange(rowinfo, e.target.value, "PurWithoutTax");
          }}
        />
      ),
    },
    {
      Header: "Purchase Rate W/Tax",
      accessor: "purRateWithTax",
      maxWidth: 150,
      style: { justifyContent: "right" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.purRateWithTax}
          onClick={(e) => e.target.select()}
          onBlur={(e) => Rate_OnChange(rowinfo, e.target.value, "PurWithTax")}
        />
      ),
    },
    {
      Header: "Sales Rate",
      accessor: "salRateWithoutTax",
      maxWidth: 150,
      style: { justifyContent: "right" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.salRateWithoutTax}
          onClick={(e) => e.target.select()}
          onBlur={(e) =>
            Rate_OnChange(rowinfo, e.target.value, "SalWithoutTax")
          }
        />
      ),
    },
    {
      Header: "Sales Rate W/Tax",
      accessor: "salRateWithTax",
      maxWidth: 150,
      style: { justifyContent: "right" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.salRateWithTax}
          onClick={(e) => e.target.select()}
          onBlur={(e) => Rate_OnChange(rowinfo, e.target.value, "SalWithTax")}
        />
      ),
    },
    {
      Header: "Tax",
      accessor: "taxPer",
      maxWidth: 70,
      style: { justifyContent: "center" },
    },
  ];
  const JobWorkcolumns = [
    {
      Header: "SNo",
      accessor: "sNo",
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Name",
      accessor: "jobName",
      id: "jobId",
      style: { justifyContent: "left" },
    },
    {
      Header: "In Rate",
      accessor: "inRateWithOutTax",
      maxWidth: 150,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.inRateWithOutTax}
          onClick={(e) => e.target.select()}
          onBlur={(e) =>
            Rate_OnChange(rowinfo, e.target.value, "InRateWithOutTax")
          }
        />
      ),
    },
    {
      Header: "In Rate W/Tax",
      accessor: "inRateWithTax",
      maxWidth: 150,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.inRateWithTax}
          onClick={(e) => e.target.select()}
          onBlur={(e) =>
            Rate_OnChange(rowinfo, e.target.value, "InRateWithTax")
          }
        />
      ),
    },
    {
      Header: "Out Rate",
      accessor: "outRateWithOutTax",
      maxWidth: 150,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.outRateWithOutTax}
          onClick={(e) => e.target.select()}
          onBlur={(e) =>
            Rate_OnChange(rowinfo, e.target.value, "OutRateWithOutTax")
          }
        />
      ),
    },
    {
      Header: "OutRate W/Tax",
      accessor: "outRateWithTax",
      maxWidth: 150,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.outRateWithTax}
          onClick={(e) => e.target.select()}
          onBlur={(e) =>
            Rate_OnChange(rowinfo, e.target.value, "OutRateWithTax")
          }
        />
      ),
    },
    {
      Header: "Tax",
      accessor: "taxPer",
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
  ];
  return (
    <>
      <SideMenu ScrId={62} />
      <div className="Master-container">
        <div className="Master-card1">
          <div className="Master-wrapper">
            <div
              className="fg-card shadow-base"
              style={{ position: "sticky", top: "0", zIndex: "99" }}
            >
              <div className="trans-tab">
                <div className="trans-buttons">
                  <label
                    onClick={(e) => tab_click("Product", 0)}
                    className="active"
                  >
                    Product
                  </label>
                  <label onClick={(e) => tab_click("JobWork", 1)}>
                    JobWork
                  </label>
                  <div id="tab-line"></div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-md-3 col-sm-6 mt-1">
                  <label>Tax Type</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow"
                    showoption={_parem.isoptionShow}
                    placeholder="Select Relation"
                    select_value={(val) => TaxType_OnChange(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iTaxRelId}
                    displayName="strTaxType"
                    disvalue="iTaxRelId"
                    arrydata={ListTaxRel}
                    EmptVal="Tax Relation "
                  />
                </div>
                <div className="col-md-3 col-sm-6 mt-1">
                  <label>Price Group</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow1"
                    showoption={_parem.isoptionShow1}
                    placeholder="Select Price Group"
                    select_value={(val) => View_PriceGroupRate(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iPrgId}
                    displayName="strPGrpName"
                    disvalue="iPGrpId"
                    arrydata={ListPrgGrp}
                    EmptVal="Price Group"
                    disabled={isProduct ? false : true}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mt-2">
                  <br />
                  <div className="m-hsearch">
                    <input
                      type="text"
                      autoCorrect="off"
                      autoComplete="off"
                      placeholder="Search Product"
                      value={strSearch}
                      onChange={(e) => Search_Product(e.target.value)}
                      autoFocus
                    />
                    <i
                      className="fas fa-search"
                      onClick={(e) => Search_Product(strSearch)}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <ReactTable
              columns={isProduct ? Productcolumns : JobWorkcolumns}
              data={ListEntItem}
              minRows={10}
              row_click={(ProInfo, Index) => {}}
              row_doubleclick={(ProInfo, Index) => {}}
              background={true}
              className="full-table"
              tabIndex=""
            />
            <Messagedialogbox />
          </div>
          <div className="btn-section">
            <button
              disabled={BtnSaveDisable}
              type="button"
              className="btn-fabgreen"
              onClick={(e) => {
                Ask_Question(
                  dispatch,
                  Update_PriceMaster,
                  "Do You Want to Update Price ?"
                );
              }}
            >
              Update Price&nbsp;<i className="fas fa-save"></i>
            </button>
            <button type="reset" className="btn-fabgreen" onClick={ClearClick}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={() => window.history.back()}
            >
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default PriceMaster;
