import React, { useState } from "react";
import $ from "jquery";
import { API_POST } from "./Utility";
import { useDispatch } from "react-redux";
function NewClientRequest(Props) {
  const dispatch = useDispatch();
  const [strPartyName, setstrPartyName] = useState("");
  const [stroffNo, setstroffNo] = useState("");
  const [strStreet, setstrStreet] = useState("");
  const [strPinCode, setstrPinCode] = useState("");
  const [strArea, setstrArea] = useState("");
  const [strCity, setstrCity] = useState("");
  const [strState, setstrState] = useState("");
  const [strCountry, setstrCountry] = useState("");
  const [strCompanyName, setstrCompanyName] = useState("");
  const [strMobileNumber, setstrMobileNumber] = useState("");
  const [strGST, setstrGST] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [strMessage, setstrMessage] = useState("");

  const SubmitRequest = async () => {
    if (strCompanyName === "") {
      setstrMessage("Company Name Required");
      setTimeout(function () {
        setstrMessage("");
      }, 5000);
    } else if (strMobileNumber === "" && strEmail === "") {
      setstrMessage("MobileNumber or Email Required");
      setTimeout(function () {
        setstrMessage("");
      }, 5000);
    } else {
      const InsUpdRequest = {
        iRequestId: 0,
        strClientName: strPartyName,
        strRequestDate: new Date().toISOString().split("T")[0],
        strMobile: strMobileNumber,
        strEmail: strEmail,
        strGST: strGST,
        strOfficeNo: stroffNo,
        strStreet: strStreet,
        strStatus: "New",
        strPinCode: strPinCode,
        strArea: strArea,
        strCity: strCity,
        strState: strState,
        strCountry: strCountry,
      };
      const res = await API_POST(
        `User/InsUpdClientRequest`,
        InsUpdRequest,
        dispatch
      );
      if (res) {
        clear_click();
        setstrMessage("Request Sent Successfull !");
        Props.Close();
      }
    }
  };
  const RegTabIndex = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 1 && ActiveIndex <= 5)
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
      else $("[tabindex='" + 6 + "']").focus();
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < 5)
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
      else $("[tabindex='" + 1 + "']").focus();
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < 5)
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
      else $("[tabindex='" + 1 + "']").focus();
    }
  };
  const clear_click = () => {
    setstrPartyName("");
    setstroffNo("");
    setstrStreet("");
    setstrArea("");
    setstrCity("");
    setstrPinCode("");
    setstrMobileNumber("");
    setstrEmail("");
    setstrGST("");
    setstrState("");
    setstrCountry("");
  };
  return (
    <div className="Demo-Container">
      <h1 className="Header">
        Get Call Back !
        <i
          className="fa-regular fa-rectangle-xmark"
          onClick={(e) => Props.Close()}
        ></i>
      </h1>
      {strMessage !== "" && <p className="err-msg">{strMessage}</p>}
      <div className="demo-input">
        <div className="demo-col">
          <i className="fa-solid fa-briefcase"></i>
          <input
            type="text"
            placeholder="Enter Company Name"
            tabIndex={1}
            spellCheck={false}
            value={strCompanyName}
            autoFocus
            onKeyDown={(e) => RegTabIndex(e)}
            onClick={(e) => e.target.select()}
            onChange={(e) => setstrCompanyName(e.target.value)}
          />
        </div>
        <div className="demo-col">
          <i className="fa-solid fa-phone"></i>
          <input
            type="number"
            placeholder="Enter Mobile Number"
            tabIndex={2}
            spellCheck={false}
            value={strMobileNumber}
            onKeyDown={(e) => RegTabIndex(e)}
            onClick={(e) => e.target.select()}
            onChange={(e) => setstrMobileNumber(e.target.value)}
          />
        </div>
        <div className="demo-col">
          <i className="fa-solid fa-envelope"></i>
          <input
            type="text"
            placeholder="Enter Email"
            tabIndex={3}
            value={strEmail}
            onKeyDown={(e) => RegTabIndex(e)}
            onClick={(e) => e.target.select()}
            onChange={(e) => setstrEmail(e.target.value)}
          />
        </div>
      </div>
      <div className="demo-btn">
        <button onClick={(e) => SubmitRequest()}>BOOK NOW</button>
      </div>
    </div>
  );
}
export default NewClientRequest;
