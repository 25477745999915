import React, { useEffect, useState } from "react";
import $ from "jquery";
import { Ask_Question, Show_Message } from "../General/Messagedialogbox";
import { useDispatch, useSelector } from "react-redux";
import Restoredatabase from "../Organization/Restoredatabase";
import {
  Backup_Data,
  DecryptData,
  Logout_User,
  View_ScreenName,
} from "./Utility";
import { Update_AdminCntrl } from "../features/CommonStates";

function SideMenu(props) {
  const dispatch = useDispatch();
  const _Common = useSelector((state) => state.Common.Admin);
  const [isShowRestore, setisShowRestore] = useState(false);
  const [ScrName, setScrName] = useState();

  useEffect(() => {
    Load_Company();
  }, []);
  const Load_Company = async () => {
    try {
      await MenuClick(0); // Activate Menu Click Event
      setScrName(await View_ScreenName(props.ScrId));
      await dispatch(
        Update_AdminCntrl(await DecryptData(localStorage.jbctrlparams))
      );
    } catch (err) {
      await Show_Message(dispatch, err.message, "error");
    }
  };
  const MenuClick = (Index) => {
    $(`.nav-item`).removeClass("active rotate show");
    $(`.nav-item:eq(${Index})`).toggleClass("active");
    $(`.nav-item:eq(${Index}) .fa-caret-down`).toggleClass("rotate");
    $(`.nav-item:eq(${Index}) ul`).toggleClass("show");
  };
  const View_Permission = async (permss, ScrId) => {
    if (permss && permss.length > 0)
      return permss.filter((dt) => dt.iScrId === ScrId)[0].isNoAccess;
  };
  const View_CmpanyPermission = (Key) => {
    let CompPer = false;
    if (_Common.CompanyPer && _Common.CompanyPer.length > 0) {
      CompPer = _Common.CompanyPer.filter(
        (per) => per.iCompId === _Common.Company
      )[0][Key];
    }
    return CompPer;
  };
  const Backup_Organization = async () => {
    const BackupData = await Backup_Data(
      dispatch,
      _Common.Client,
      _Common.OrgName
    );
    if (BackupData) {
      BackupData.click();
    }
  };
  return (
    <>
      <section className="header" id="header">
        <div className="ScreenName">
          <h5>{ScrName}</h5>
        </div>
        <div className="Header-Menu">
          {View_Permission(_Common.MasterPer, 1) && (
            <div className="lbltooltip">
              <a href={`/Master/Product`}>
                <span className="material-symbols-outlined">deployed_code</span>
              </a>
              <span className="tooltiptext">Product</span>
            </div>
          )}
          {View_Permission(_Common.PurchasePer, 11) && (
            <div className="lbltooltip">
              <a href={`/Purchase/Invoice`}>
                <span className="material-symbols-outlined">local_mall</span>
              </a>
              <div className="tooltiptext">Purchase</div>
            </div>
          )}
          {View_Permission(_Common.SalesPer, 18) && (
            <div className="lbltooltip">
              <a href={`/Sales/Invoice`}>
                <span className="material-symbols-outlined">shopping_cart</span>
              </a>
              <div className="tooltiptext">Sales</div>
            </div>
          )}
          {View_Permission(_Common.PosPer, 93) && (
            <div className="lbltooltip">
              <a href={`/Pointofsales/Invoice`}>
                <span className="material-symbols-outlined">point_of_sale</span>
              </a>
              <div className="tooltiptext">POS</div>
            </div>
          )}
          <div className="lbltooltip">
            <a
              href="##"
              onClick={(e) => (window.location.href = `/Profile/Settings`)}
            >
              <span className="material-symbols-outlined">manage_accounts</span>
            </a>
            <div className="tooltiptext">Setting</div>
          </div>
          <div className="Profile-Img">
            <img
              src={_Common.Logo}
              alt=""
              onClick={(e) => $(".right_menu").toggleClass("active")}
            ></img>
          </div>
          <ul
            className="right_menu"
            onMouseLeave={(e) => $(".right_menu").toggleClass("active")}
          >
            <li className="profile-desc">
              <div className="img-wrapper">
                <img src={_Common.Logo} alt=""></img>
              </div>
              <div className="org-cont">
                <h5
                  onClick={(e) => {
                    if (!View_CmpanyPermission("isNoAccess")) {
                      window.location.href = "/Profile/CompanyMaster";
                    }
                  }}
                >
                  <span>Organization</span> : {_Common.Branchname}
                </h5>
                <h5
                  className="mt-1"
                  onClick={(e) => {
                    if (!View_CmpanyPermission("isNoAccess"))
                      window.location.href = "/Profile/CompanyMaster";
                  }}
                >
                  <span>Financial Year</span> : {_Common.Fyear}
                </h5>
                <div className="baclog">
                  {View_CmpanyPermission("isBackup") && (
                    <label
                      onClick={(e) => {
                        Ask_Question(
                          dispatch,
                          Backup_Organization,
                          "Do You Want to Backup All Data?"
                        );
                      }}
                    >
                      <i className="fa-solid fa-cloud-arrow-down"></i>Backup
                    </label>
                  )}
                  <label onClick={(e) => Logout_User()}>
                    <i className="fa-solid fa-right-from-bracket"></i> Sign Out
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div
          className="btn-bars"
          onClick={(e) => {
            $(".btn-bars").toggleClass("click");
            $(".sidebar").toggleClass("show");
          }}
        >
          <span className=" fas fa-bars third"></span>
        </div>
      </section>
      <section className="sidebar">
        <a className="logo" href="##">
          <div className="logo-wrapper">
            <img
              src={_Common.Logo}
              alt="Best Billing Software in Coimbatore"
            ></img>
          </div>
          {_Common.Branchname}
        </a>
        <ul>
          <li className="nav-item" onClick={() => MenuClick(0)}>
            <a href={`/JustBill/DashBoard`}>
              <i className="fas fa-home"></i>
              Dashboard
            </a>
          </li>
          <li className="nav-item" onClick={() => MenuClick(1)}>
            <a href="##">
              <i>
                <div className="master-grid">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </i>
              Master
              <i className="fas fa-caret-down"></i>
            </a>
            <ul>
              {_Common.MasterPer.map(
                (Master, Index) =>
                  !Master.isNoAccess && (
                    <li key={Index}>
                      <a href={`/Master${Master.strUrl}`}>{Master.strName}</a>
                    </li>
                  )
              )}
            </ul>
          </li>
          <li className="nav-item" onClick={() => MenuClick(2)}>
            <a href="##">
              <i className="fa-solid fa-cash-register"></i>
              Point of Sales
              <i className="fas fa-caret-down"></i>
            </a>
            <ul>
              {_Common.PosPer.map(
                (pos, Index) =>
                  !pos.isNoAccess && (
                    <li key={Index}>
                      <a href={`/Pointofsales${pos.strUrl}`}>{pos.strName}</a>
                    </li>
                  )
              )}
            </ul>
          </li>
          <li className="nav-item" onClick={() => MenuClick(3)}>
            <a href="##">
              <i className="fa-solid fa-cart-shopping"></i>
              Sales
              <i className="fas fa-caret-down"></i>
            </a>
            <ul>
              {_Common.SalesPer.map(
                (Sales, Index) =>
                  !Sales.isNoAccess && (
                    <li key={Index}>
                      <a href={`/Sales${Sales.strUrl}`}>{Sales.strName}</a>
                    </li>
                  )
              )}
            </ul>
          </li>
          <li className="nav-item" onClick={() => MenuClick(4)}>
            <a href="##">
              <i className="fa-solid fa-bag-shopping"></i>
              Purchase
              <i className="fas fa-caret-down"></i>
            </a>
            <ul>
              {_Common.PurchasePer.map(
                (Purchase, Index) =>
                  !Purchase.isNoAccess && (
                    <li key={Index}>
                      <a href={`/Purchase${Purchase.strUrl}`}>
                        {Purchase.strName}
                      </a>
                    </li>
                  )
              )}
            </ul>
          </li>
          <li className="nav-item" onClick={() => MenuClick(5)}>
            <a href="##">
              <i className="fa-solid fa-boxes-stacked"></i>
              Inventory
              <i className="fas fa-caret-down"></i>
            </a>
            <ul>
              {_Common.InventoryPer.map(
                (Inventory, Index) =>
                  !Inventory.isNoAccess && (
                    <li key={Index}>
                      <a href={`/Inventory${Inventory.strUrl}`}>
                        {Inventory.strName}
                      </a>
                    </li>
                  )
              )}
            </ul>
          </li>
          <li className="nav-item" onClick={() => MenuClick(6)}>
            <a href="##">
              <i className="fa-solid fa-layer-group"></i>
              Groups
              <i className="fas fa-caret-down"></i>
            </a>
            <ul>
              {_Common.GroupsPer.map(
                (Group, Index) =>
                  !Group.isNoAccess && (
                    <li key={Index}>
                      <a href={"" + Group.strUrl}>{Group.strName}</a>
                    </li>
                  )
              )}
            </ul>
          </li>
          <li className="nav-item" onClick={() => MenuClick(7)}>
            <a href="##">
              <i className="fas fa-tools"></i>
              Job work
              <i className="fas fa-caret-down"></i>
            </a>
            <ul>
              {_Common.JobworkPer.map(
                (Jobwork, Index) =>
                  !Jobwork.isNoAccess && (
                    <li key={Index}>
                      <a href={`/Jobwork${Jobwork.strUrl}`}>
                        {Jobwork.strName}
                      </a>
                    </li>
                  )
              )}
            </ul>
          </li>
          <li className="nav-item" onClick={() => MenuClick(8)}>
            <a href="##">
              <i className="fas fa-wallet"></i>
              Accounts
              <i className="fas fa-caret-down"></i>
            </a>
            <ul>
              {_Common.AccountsPer.map(
                (Accounts, Index) =>
                  !Accounts.isNoAccess && (
                    <li key={Index}>
                      <a href={`/Accounts${Accounts.strUrl}`}>
                        {Accounts.strName}
                      </a>
                    </li>
                  )
              )}
            </ul>
          </li>
          <li className="nav-item" onClick={() => MenuClick(9)}>
            <a href="##">
              <i className="fa-solid fa-chart-pie"></i>
              Reports
              <i className="fas fa-caret-down"></i>
            </a>
            <ul>
              {_Common.ReportscrPer.map(
                (Reports, Index) =>
                  !Reports.isNoAccess && (
                    <li key={Index}>
                      <a href={`/Reports${Reports.strUrl}`}>
                        {Reports.strName}
                      </a>
                    </li>
                  )
              )}
            </ul>
          </li>
          <li className="nav-item" onClick={() => MenuClick(10)}>
            <a href="##">
              <i className="fas fa-ellipsis-h"></i>
              Others
              <i className="fas fa-caret-down"></i>
            </a>
            <ul>
              {_Common.OthersPer.map(
                (Others, Index) =>
                  !Others.isNoAccess && (
                    <li key={Index}>
                      <a href={`/Others${Others.strUrl}`}>{Others.strName}</a>
                    </li>
                  )
              )}
            </ul>
          </li>
          <li
            className="nav-item"
            onClick={() => (window.location.href = "/EWayBilling")}
          >
            <a href="##">
              <i className="fa-solid fa-receipt"></i>
              E-invoice
            </a>
          </li>
        </ul>
      </section>
      {isShowRestore && (
        <Restoredatabase Close={(e) => setisShowRestore(!isShowRestore)} />
      )}
    </>
  );
}
export default SideMenu;
