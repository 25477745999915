import React, { useEffect, useState } from "react";
import PrintAccountsDesign from "../General/PrintAccounts";
import { useDispatch, useSelector } from "react-redux";
import { Show_Message } from "../General/Messagedialogbox";
import { API_FETCH, DecryptData } from "../General/Utility";

function AccountsButton(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const _AccParem = useSelector((state) => state.Accounts.default);
  const [isSaveDisable, setisSaveDisable] = useState(false);
  const [isDeleteDisable, setisDeleteDisable] = useState(false);
  const [isSettlementDisable, setisSettlementDisable] = useState(false);
  useEffect(() => {
    ButtonPermission();
  }, [props]);

  const ButtonPermission = async () => {
    const _LocalParems = await DecryptData(localStorage.jbctrlparams);
    let ListMenu = _LocalParems.AccountsPer.filter(
      (Data) => Data.iScrId === props.iScrId
    );
    if (Number(props.iVouchId) > 0) {
      if (_AccParem.isCancelStatus === false) {
        setisSaveDisable(!ListMenu[0].isModify);
        setisDeleteDisable(!ListMenu[0].isDelete);
      } else {
        setisSettlementDisable(true);
        setisSaveDisable(true);
        setisDeleteDisable(true);
      }
    } else if (Number(props.iVouchId) === 0) {
      setisSaveDisable(!ListMenu[0].isCreate);
    }
  };
  const PrintAccounts = async () => {
    if (Number(props.Iid) === 0)
      Show_Message(dispatch, "Please Select Voucher", "info");
    else {
      let VoucherId = Number(props.Iid) === undefined ? 0 : Number(props.Iid);
      let TempId = 0;
      const res = await API_FETCH(
        `PrintDesing/PrintAccounts/${props.ScrId}/${TempId}/${VoucherId}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) PrintAccountsDesign(res, props.ScrId, "Print");
    }
  };

  return (
    <div className="btn-section">
      <button
        type="button"
        className="btn-fabgreen"
        onClick={props.Save}
        disabled={
          isSaveDisable ? true : _AccParem.iVenTypeId === 6 ? true : false
        }
      >
        Save &nbsp; <i className="fas fa-save"></i>
      </button>
      <button
        type="button"
        className="btn-fabgreen"
        onClick={(e) => {
          props.Clear();
          setisSaveDisable(false);
          setisDeleteDisable(false);
          setisSettlementDisable(false);
        }}
      >
        Clear &nbsp; <i className="fas fa-eraser"></i>
      </button>
      {props.iScrId !== 43 && props.iScrId !== 44 && (
        <button
          type="button"
          disabled={
            isSettlementDisable
              ? true
              : _AccParem.iVenTypeId === 6
              ? true
              : false
          }
          className="btn-fabgreen"
          onClick={() => {
            window.location.href =
              props.iScrId === 41
                ? "/Accounts/PaymentSettlement"
                : "/Accounts/ReceiptSettlement";
          }}
        >
          Settlement &nbsp; <i className="fas fa-sync"></i>
        </button>
      )}
      <button
        type="button"
        className="btn-fabgreen"
        onClick={
          props.Cancel ? true : _AccParem.iVenTypeId === 6 ? true : false
        }
        disabled={
          isDeleteDisable ? true : _AccParem.iVenTypeId === 6 ? true : false
        }
      >
        Cancel &nbsp; <i className="far fa-trash-alt"></i>
      </button>
      <button type="button" className="btn-fabgreen" onClick={PrintAccounts}>
        Print &nbsp; <i className="fa-solid fa-print"></i>
      </button>
      <button
        type="button"
        className="btn-fabgreen"
        onClick={() => (window.location.href = `/JustBill/DashBoard`)}
      >
        Close &nbsp; <i className="fas fa-times"></i>
      </button>
    </div>
  );
}
export default AccountsButton;
