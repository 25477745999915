import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Show_Message } from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import {
  AddLess_LostFocus,
  AddLess_Onchange,
  AddUpdate_AddLess,
  Clear_AddLess,
  Modify_AddLess,
  POSAddLess_Onchange,
  Remove_AddLess,
} from "../features/Transactionstates";
import { API_POST } from "../General/Utility";

function OtherCharges(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _Common = useSelector((store) => store.Transstates.Common);
  const _HT = useSelector((store) => store.Transstates.Header);
  const _DT = useSelector((store) => store.Transstates.Data);
  const _Disc = useSelector((store) => store.Transstates.Discount);
  const _Summary = useSelector((state) => state.Transstates.Summary);
  const _AL = useSelector((store) => store.Transstates.AddLess);
  const _Select = useSelector((state) => state.SelectOption.parems);

  const AddServiceClick = async () => {
    try {
      let EntAL = JSON.parse(JSON.stringify(_DT.arrEntAL));
      EntAL = EntAL.filter((dt) => dt.serId === Number(_AL.iSerId));
      if (EntAL.length > 0 && !_AL.UpdateStatus) {
        AlertMessage("Service Already Exists", "info");
      } else {
        const res = await API_POST(
          "Transaction/AddService/",
          {
            iScrId: _Common.iScrId,
            iTaxCalcOnId: _Common.iTaxCalcOnId,
            iPartyId: _HT.iPartyId,
            iTaxRelId: _HT.iTaxRelId,
            iVenId: Number(_AL.iSerVenId),
            iSerId: Number(_AL.iSerId),
            dPriceRate: Number(_AL.dServAmt),
            strVenCode: _AL.VenCode,
            strVenName: _AL.VenName,
            bUpdateStatus: _AL.UpdateStatus,
            iRowIndex: _AL.iRowIndex,

            objEntAddLess: _DT.arrEntAL,
            objBaseTransDT: _DT.arrEntDT,

            iVouchId: 0,
            iProId: 0,
            iUnitId: 0,
            dQty: 0,
            dDiscountPer: 0,
            dDiscountAmt: 0,

            strJobId: "0",
            strProAttDTId: "0",
            strStoAttDTId: "0",
            strTraAttDTId: "0",
            objItemRemark: {},
            objPenCon: [],
            objRootCalc: {},
            objEntConRow: [],
            objEntTax: [],
            strSearch: "",
            strColumn: "",
            strReason: "",
            strSetCurrentStatus: "",
            dTAddLessDiscPer: Number(_Disc.dALBillDisPer),
            dTAddLessDiscAmt: Number(_Disc.dALBillDisAmt),
            dAddLessDiscValue: Number(_Disc.dALBillDisValue),
            iALBillDiscId: _Common.iALBillDiscId,
            iYearId: Number(_AuthParems.Year),
            iBranchId: Number(_AuthParems.Branch),
            strDBName: _AuthParems.DB,
            iUserId: Number(_AuthParems.User),
          },
          dispatch
        );
        if (res) {
          dispatch(AddUpdate_AddLess(res));
        }
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const RemoveAddLess = async (ALIndex) => {
    try {
      const objParem = {
        bUpdateStatus: _AL.UpdateStatus,
        iRowIndex: ALIndex,
        iScrId: _Common.iScrId,
        iTaxCalcOnId: _Common.iTaxCalcOnId,
        iPartyId: _HT.iPartyId,
        iTaxRelId: _HT.iTaxRelId,
        iVenId: Number(_AL.iSerVenId),
        iSerId: Number(_AL.iSerId),
        dPriceRate: Number(_AL.dServAmt),
        strVenCode: "",
        strVenName: "",

        objEntAddLess: _DT.arrEntAL,
        objBaseTransDT: _DT.arrEntDT,

        iVouchId: 0,
        iProId: 0,
        iUnitId: 0,
        dQty: 0,
        dDiscountPer: 0,
        dDiscountAmt: 0,

        strJobId: "0",
        strProAttDTId: "0",
        strStoAttDTId: "0",
        strTraAttDTId: "0",
        objItemRemark: {},
        objPenCon: [],
        objRootCalc: {},
        objEntConRow: [],
        objEntTax: [],
        strSearch: "",
        strColumn: "",
        strReason: "",
        strSetCurrentStatus: "",
        dTAddLessDiscPer: Number(_Disc.dALBillDisPer),
        dTAddLessDiscAmt: Number(_Disc.dALBillDisAmt),
        dAddLessDiscValue: Number(_Disc.dALBillDisValue),
        iALBillDiscId: _Common.iALBillDiscId,

        iYearId: Number(_AuthParems.Year),
        iBranchId: Number(_AuthParems.Branch),
        strDBName: _AuthParems.DB,
        iUserId: Number(_AuthParems.User),
      };
      const res = await API_POST(
        `Transaction/RemoveAddLess`,
        objParem,
        dispatch
      );
      if (res) {
        dispatch(Remove_AddLess(res));
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const AL_Onchange = (e) => {
    dispatch(
      AddLess_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const AlertMessage = (Message, Icon) => {
    Show_Message(dispatch, Message, Icon);
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Other Charges</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-sm-12 col-md-12 mt-1">
              <CustomizedSelectoption
                optionparem="isoptionShow1"
                showoption={_Select.isoptionShow1}
                placeholder="Select Other Charges"
                select_value={(val) => dispatch(POSAddLess_Onchange(val))}
                btnname="Other Charges"
                btnshow={false}
                show_popup={""}
                valueonly={false}
                defaultval={_AL.iSerId}
                displayName="strName"
                disvalue="iId"
                arrydata={_AL.arrService}
                disabled={false}
                EmptVal="Other Charges"
              />
            </div>
            <div className="col-sm-12 col-md-6 mt-1">
              <label>Service Name</label>
              <input
                className="form-control"
                type="text"
                placeholder="Select Party"
                value={_AL.ServName}
                readOnly
              />
            </div>
            <div className="col-sm-6 col-md-3 mt-1">
              <label>Cost Rate</label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                name="dServAmt"
                value={_AL.dServAmt}
                onChange={(e) => AL_Onchange(e)}
                onClick={(e) => e.target.select()}
                onBlur={(e) => {
                  AddLess_LostFocus(e);
                }}
              />
            </div>
            <div className="col-sm-6 col-md-3 mt-1">
              <div className="btn-sub-section mt-3">
                <button className="btn-fabgreen" onClick={AddServiceClick}>
                  Add&nbsp;<i className="fas fa-save"></i>
                </button>
                <button
                  className="btn-fabgreen"
                  onClick={() => dispatch(Clear_AddLess())}
                >
                  Clear&nbsp;<i className="fa-solid fa-broom"></i>
                </button>
              </div>
            </div>
            <div className="tax-table">
              <table>
                <thead>
                  <tr>
                    <th>Service Name</th>
                    <th>Cost Rate</th>
                    <th>Tax</th>
                    <th>Item Amt</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {_DT.arrEntAL.map((AL, Index) => (
                    <tr key={Index}>
                      <td style={{ textAlign: "left" }}>{AL.serName}</td>
                      <td style={{ textAlign: "Right" }}>
                        {Number(AL.rateWithoutTax).toFixed(2)}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {Number(AL.taxPer).toFixed(2)}
                      </td>
                      <td style={{ textAlign: "Right" }}>
                        {Number(AL.itemAmt).toFixed(2)}
                      </td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          height: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          className="fa-solid fa-pen mt-2"
                          onClick={(e) =>
                            dispatch(Modify_AddLess({ Data: AL, Index: Index }))
                          }
                        ></i>
                        <i
                          className="far fa-trash-alt mt-2"
                          onClick={(e) => RemoveAddLess(Index)}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Other Charges</td>
                    <td
                      style={{
                        fontFamily: "arial",
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "17px",
                      }}
                    >
                      {new Intl.NumberFormat("en-GB", {
                        style: "currency",
                        currency: "INR",
                      }).format(Number(_Summary.AddLess).toFixed(2))}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtherCharges;
