import React, { useEffect, useState } from "react";
import SearchLedger from "../Accounts/SearchLedger";
import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { setParty } from "../features/Transactionstates";
import { API_FETCH, API_POST } from "../General/Utility";
import { Country_OnChange } from "../Master/MasterTransaction";
function PartyCreation(props) {
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _HTparem = useSelector((state) => state.Transstates.HT);
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const dispatch = useDispatch();
  const [ScreenName, setScreenName] = useState("Customer Master");
  const [strName, setstrName] = useState("");
  const [strPlaceHolder, setstrPlaceHolder] = useState("");
  const [strCode, setstrCode] = useState("");
  const [strMobile, setstrMobile] = useState("");
  const [iStateId, setiStateId] = useState("32");
  const [iCountryId, setiCountryId] = useState("100");
  const [strLedId, setstrLedId] = useState("");
  const [strLedName, setstrLedName] = useState("");
  const [HideParty, setHideParty] = useState(false);
  const [strSACCode, setstrSACCode] = useState("");
  const [arrSGST, setarrSGST] = useState([]);
  const [arrCGST, setarrCGST] = useState([]);
  const [arrIGST, setarrIGST] = useState([]);
  const [iSGSTTaxId, setiSGSTTaxId] = useState(0);
  const [iCGSTTaxId, setiCGSTTaxId] = useState(0);
  const [iIGSTTaxId, setiIGSTTaxId] = useState(0);
  const [IsManualCode, setIsManualCode] = useState(true);
  const [isLedger, setisLedger] = useState(false);
  const [arrCountry, setarrCountry] = useState([]);
  const [arrState, setarrState] = useState([]);
  const [arrCodeformate, setarrCodeformate] = useState([]);

  useEffect(() => {
    Load_PartyCreation();
  }, []);
  const Load_PartyCreation = async () => {
    try {
      var ScrId = 0;
      var ScreenType = "";
      if (props.strScrType !== undefined) {
        switch (props.strScrType) {
          case "ServiceVendore":
            ScrId = 3;
            setScreenName("Service Vendor");
            setstrPlaceHolder("Enter Vendore Name");
            break;
          case "JobCreation":
            ScrId = 51;
            ScreenType = "JobWork";
            setScreenName("JobWork Master");
            setstrPlaceHolder("Enter Job Name");
            setHideParty(true);
            break;
          case "Service":
            ScrId = 59;
            ScreenType = "Service";
            setScreenName("Service Master");
            setstrPlaceHolder("Enter Service Name");
            setHideParty(true);
            break;
          default:
            break;
        }
      } else {
        switch (_HTparem.strScrType) {
          case "Purchase":
            ScrId = 3;
            setScreenName("Supplier Master");
            setstrPlaceHolder("Enter Supplier Name");
            break;
          case "Sales":
            ScrId = 2;
            setScreenName("Customer Master");
            setstrPlaceHolder("Enter Customer Name");
            break;
          case "Jobwork":
            ScrId = 50;
            setScreenName("Vendore Master");
            setstrPlaceHolder("Enter Vendore Name");
            break;
          default:
            break;
        }
      }
      if (ScrId === 51 || ScrId === 59) {
        const res = await API_FETCH(
          `CommonPartyCreation/Load_JobSerMaster/${ScrId}/${ScreenType}/${Number(
            _AuthParems.Branch
          )}/${_AuthParems.DB}`,
          dispatch
        );
        if (res) {
          setarrSGST(res.objEntSGSTTax);
          setarrCGST(res.objEntCGSTTax);
          setarrIGST(res.objEntIGSTTax);
          setIsManualCode(!res.objCodeFormat[0].isManualNo);
          setstrCode(res.objCodeFormat[0].strCode);
          setarrCodeformate(res.objCodeFormat);
        }
      } else {
        const result = await API_FETCH(
          `CommonPartyCreation/Search_Party/${ScrId}/${Number(
            _AuthParems.Branch
          )}/${_AuthParems.DB}`,
          dispatch
        );
        if (result) {
          setarrCountry(result.objEntCountry);
          setarrState(result.objEntState);
          var EntCode = result.objCodeFormat[0].strPrefix === "" ? false : true;
          setIsManualCode(EntCode);
          setstrCode(result.objCodeFormat[0].strCode);
          setarrCodeformate(result.objCodeFormat);
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  const Select_Party = async (partyId) => {
    props.Close();
    try {
      const res = await API_FETCH(
        `Transaction/View_Party/${partyId}/${Number(_AuthParems.Branch)}/${
          _AuthParems.DB
        }`,
        dispatch
      );
      if (res) {
        var PartAdd =
          res.objPartyDetails[1] +
          res.objPartyDetails[2] +
          res.objPartyDetails[3];
        dispatch(
          setParty({
            iPartyId: Number(partyId),
            iTaxRelId: res.iTaxRelId === undefined ? 0 : Number(res.iTaxRelId),
            strPartyName: res.objPartyDetails[0],
            strPartyAdd: PartAdd,
            strShippingAdd: res.strShippingAddress,
            ListAddres: res.objPartyShipAdd,
          })
        );
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const SaveClick = async () => {
    try {
      var ScrType = "Trans";
      if (props.strScrType === "JobCreation" || props.strScrType === "Service")
        ScrType = "";
      if (strName === "" && ScrType === "Trans")
        Show_Message(dispatch, "Please Enter Party Name", "info");
      else if (strName === "" && props.strScrType === "JobCreation")
        Show_Message(dispatch, "Please Enter Job Name", "info");
      else if (strName === "" && props.strScrType === "Service")
        Show_Message(dispatch, "Please Enter Service Name", "info");
      else {
        Pre_Loading(dispatch, true);
        if (props.strScrType === undefined && _HTparem.strScrType === "Sales") {
          const listEntCusInsUpd = {
            iCustId: 0,
            iBranchId: Number(_AuthParems.Branch),
            strCustCode: strCode,
            strCustName: strName,
            strAliasName: "",
            strAdd1: "",
            strAdd2: "",
            strAdd3: "",
            strAdd4: "",
            strPincode: "",
            iCountry: Number(iCountryId),
            iState: Number(iStateId),
            strPhone1: "",
            strPhone2: "",
            strMobile1: strMobile,
            strMobile2: "",
            strEmail: "",
            strGST: "",
            strRemarks: "",
            iPGId: 1,
            iStatusId: 1,
            dOpBal: 0,
            dCreLimit: 0,
            CreditTerm: "30 Days",
            iBalTypeId: 3,
            iTitleTag: 2,
            iNumSeqNo: arrCodeformate[0].iNumSeqNo,
            strPrefix: arrCodeformate[0].strPrefix,
            strCustCodeTag: "Enter Customer Code",
            strFilterColumn: "All",
            strFilter: "",
            strSearch: "",
            RoyaltyPoint: 0,
            GetLastInsertId: 1,
            strDBName: _AuthParems.DB,
          };
          const res = await API_POST(
            "CustomerMaster/InsUpd_Customer/",
            listEntCusInsUpd,
            dispatch
          );
          if (res) {
            ClearClick();
            Select_Party(res.iPartyId);
          }
        }
        if (
          props.strScrType === undefined &&
          _HTparem.strScrType === "Purchase"
        ) {
          const listEntSupInsUpd = {
            iSuppId: 0,
            iBranchId: Number(_AuthParems.Branch),
            iNumSeqNo: arrCodeformate[0].iNumSeqNo,
            strPrefix: arrCodeformate[0].strPrefix,
            strSuppCode: strCode,
            strSuppName: strName,
            strAliasName: "",
            strAdd1: "",
            strAdd2: "",
            strAdd3: "",
            strAdd4: "",
            strPincode: "",
            iCountry: Number(iCountryId),
            iState: Number(iStateId),
            strPhone1: "",
            strPhone2: "",
            strMobile1: strMobile,
            strMobile2: "",
            strEmail: "",
            strRemarks: "",
            strGST: "",
            strTinNo: "",
            iStatusId: 1,
            dOpBal: 0,
            iBalTypeId: 3,
            iVenTypeId: 1,
            CreditTerm: "30 Days",
            strSearch: "",
            strFilter: "",
            strFilterColumn: "All",
            iTitleTag: 3,
            strSuppCodeTag: "Enter Supplier Code",
            strDBName: _AuthParems.DB,
            GetLastInsertId: 1,
          };
          const res = await API_POST(
            "SupplierMaster/InsUpd_Supplier/",
            listEntSupInsUpd,
            dispatch
          );
          if (res) {
            ClearClick();
            Select_Party(res.iPartyId);
          }
        }
        if (
          props.strScrType === undefined &&
          _HTparem.strScrType === "Jobwork"
        ) {
          const listEntVenInsUpd = {
            iVenId: 0,
            iBranchId: Number(_AuthParems.Branch),
            strVenCode: strCode,
            strVenTag: "",
            strVenName: strName,
            strAliasName: "",
            strAdd1: "",
            strAdd2: "",
            strAdd3: "",
            strAdd4: "",
            strPincode: "",
            iCountry: Number(iCountryId),
            iState: Number(iStateId),
            strPhone1: "",
            strPhone2: "",
            strMobile1: strMobile,
            strMobile2: "",
            strEmail: "",
            strGST: "",
            strRemarks: "",
            TinNo: "",
            iStatusId: 1,
            dOpBal: 0,
            CreditTerm: "30 Days",
            iBalTypeId: 3,
            iVenCastId: 1,
            iTitleTag: 50,
            iNumSeqNo: arrCodeformate[0].iNumSeqNo,
            strPrefix: arrCodeformate[0].strPrefix,
            strVenCodeTag: "",
            strFilterColumn: "All",
            strFilter: "",
            strSearch: "",
            strDBName: _AuthParems.DB,
            GetLastInsertId: 1,
          };
          const res = await API_POST(
            "VendoreMaster/InsUpd_VendoreMaster/",
            listEntVenInsUpd,
            dispatch
          );
          if (res) {
            ClearClick();
            Select_Party(res.iPartyId);
          }
        }
        if (props.strScrType === "ServiceVendore") {
          const listEntSupInsUpd = {
            iSuppId: 0,
            iBranchId: Number(_AuthParems.Branch),
            iNumSeqNo: arrCodeformate[0].iNumSeqNo,
            strPrefix: arrCodeformate[0].strPrefix,
            strSuppCode: strCode,
            strSuppName: strName,
            strAliasName: "",
            strAdd1: "",
            strAdd2: "",
            strAdd3: "",
            strAdd4: "",
            strPincode: "",
            iCountry: Number(iCountryId),
            iState: Number(iStateId),
            strPhone1: "",
            strPhone2: "",
            strMobile1: strMobile,
            strMobile2: "",
            strEmail: "",
            strRemarks: "",
            strGST: "",
            strTinNo: "",
            iStatusId: 1,
            dOpBal: 0,
            iBalTypeId: 3,
            iVenTypeId: 2,
            CreditTerm: "30 Days",
            strSearch: "",
            strFilter: "",
            strFilterColumn: "All",
            iTitleTag: 3,
            strSuppCodeTag: "Enter Supplier Code",
            strDBName: _AuthParems.DB,
            GetLastInsertId: 1,
          };
          const res = await API_POST(
            "SupplierMaster/InsUpd_Supplier/",
            listEntSupInsUpd,
            dispatch
          );
          if (res) {
            ClearClick();
            props.Close();
          }
        }
        if (props.strScrType === "JobCreation") {
          const objJob = {
            strJobCode: strCode,
            strJobName: strName,
            strSACCode: strSACCode,
            strPrintName: "",
            strRemark: "",
            iStatusId: 1,
            strJobCodeTag: "Enter Job Code",
            iTitleTag: 51,
            iJobId: 0,
            iNumSeqNo: arrCodeformate[0].iNumSeqNo,
            strPrefix: arrCodeformate[0].strPrefix,
            strSearch: "",
            strFilter: "",
            strFilterColumn: "All",
            iTaxTypeId: 1,
            iSGSTTaxId: Number(iSGSTTaxId),
            iCGSTTaxId: Number(iCGSTTaxId),
            iIGSTTaxId: Number(iIGSTTaxId),
            strDBName: _AuthParems.DB,
            iBranchId: Number(_AuthParems.Branch),
          };
          const res = await API_POST(
            "JobWorkMaster/InsUpd_JobWork/",
            objJob,
            dispatch
          );
          if (res) {
            ClearClick();
            GetLastJob();
          }
        }
        if (props.strScrType === "Service") {
          const ObjServ = {
            strSerCode: strCode,
            iBranchId: Number(_AuthParems.Branch),
            strSerName: strName,
            strSACCode: strSACCode,
            strPrintName: "",
            strRemarks: "",
            strSerCodeTag: "Enter Service Code",
            iTitleTag: 59,
            iSerId: 0,
            iNumSeqNo: arrCodeformate[0].iNumSeqNo,
            strPrefix: arrCodeformate[0].strPrefix,
            strSearch: "",
            strFilter: "",
            strFilterColumn: "All",
            strLedId: strLedId.toString(),
            strTransTagLedName: "TagLedgerName",
            strLedName: strLedName,
            iTaxTypeId: 1,
            iSGSTTaxId: Number(iSGSTTaxId),
            iCGSTTaxId: Number(iCGSTTaxId),
            iIGSTTaxId: Number(iIGSTTaxId),
            strDBName: _AuthParems.DB,
          };
          const res = await API_FETCH(
            "ServiceMaster/InsUpd_ServiceMaster/",
            ObjServ,
            dispatch
          );
          if (res) {
            ClearClick();
            props.Close();
          }
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  const GetLastJob = async () => {
    try {
      const res = await API_FETCH(
        "OutwardChallan/GetJobList/" +
          Number(_AuthParems.Role) +
          "/" +
          _AuthParems.DB,
        dispatch
      );
      if (res) {
        var JobLenth = res.objJobList.length - 1;
        this.setState({ listJobName: res.objJobList });
        this.setState({ strJobId: res.objJobList[JobLenth].iId });
      }
    } catch (err) {}
  };
  const TaxOnChange = (objtax) => {
    let dTaxPer = Number(objtax.dTaxPer);
    var TaxType = objtax.strTaxDesc.split(" ")[0];
    switch (TaxType) {
      case "SGST":
        setiSGSTTaxId(objtax.iTaxId);
        setiCGSTTaxId(
          arrCGST.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setiIGSTTaxId(
          arrIGST.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      case "CGST":
        setiSGSTTaxId(
          arrSGST.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setiCGSTTaxId(objtax.iTaxId);
        setiIGSTTaxId(
          arrIGST.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      default:
        setiIGSTTaxId(objtax.iTaxId);
        setiSGSTTaxId(
          arrSGST.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        setiCGSTTaxId(
          arrCGST.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        break;
    }
  };
  const ClearClick = () => {
    setstrName("");
    setstrCode("");
    setiStateId("32");
    setiCountryId("100");
    setstrSACCode("");
    setIsManualCode(true);
    setarrCountry([]);
    setarrState([]);
    setarrCodeformate([]);
    setstrLedId(0);
    setstrLedName(0);
    Load_PartyCreation();
  };
  const SelectLedger = (Ledger) => {
    try {
      Pre_Loading(dispatch, true);
      setisLedger(!isLedger);
      setstrLedId(Ledger.ledId);
      setstrLedName(Ledger.ledName);
      Pre_Loading(dispatch, false);
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  return (
    <div className="popup-container">
      <div className="popup-content" style={{ maxWidth: "400px" }}>
        <div className="popup-header">
          <h5>{ScreenName} </h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body p-2">
          <div className="row">
            <div className="col-md-8 col-sm-12">
              <label>
                Name <span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={strName}
                onChange={(e) => setstrName(e.target.value)}
                placeholder={strPlaceHolder}
                autoFocus
                spellCheck={false}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>
                Code <span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={strCode}
                readOnly={IsManualCode}
                onChange={(e) => setstrCode(e.target.value)}
              />
            </div>
            {!HideParty && (
              <>
                <div className="col-md-6 col-sm-12">
                  <label>
                    Country <span className="danger">*</span>
                  </label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow5"
                    showoption={_selecrparem.isoptionShow5}
                    placeholder="Select Country"
                    select_value={async (val) => {
                      await setiCountryId(val);
                      await setarrState(
                        await Country_OnChange(val, _AuthParems.DB, dispatch)
                      );
                    }}
                    btnname="Country"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iCountryId}
                    displayName="strCountryName"
                    disvalue="iCountryId"
                    arrydata={arrCountry}
                    disabled={false}
                    EmptVal="Country"
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <label>
                    State <span className="danger">*</span>
                  </label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow6"
                    showoption={_selecrparem.isoptionShow6}
                    placeholder="Select State"
                    select_value={(val) => setiStateId(val)}
                    btnname="State"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iStateId}
                    displayName="strSName"
                    disvalue="iSId"
                    arrydata={arrState}
                    disabled={false}
                    EmptVal="Sate"
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <label>
                    Mobile Number <span className="danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={strMobile}
                    onChange={(e) => setstrMobile(e.target.value)}
                    placeholder="Enter Mobile Number"
                  />
                </div>
              </>
            )}
            {HideParty && (
              <>
                {props.strScrType === "Service" && (
                  <div className="col-md-6 col-sm-12">
                    <label>Ledger</label>
                    <SearchLedger
                      Close={(e) => setisLedger(!setisLedger)}
                      SelectLedger={SelectLedger}
                      defaultval={strLedName}
                    />
                  </div>
                )}
                <div className="col-md-6 col-sm-12">
                  <label>GST</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow7"
                    showoption={_selecrparem.isoptionShow7}
                    placeholder="Select Tax"
                    select_value={(val) => TaxOnChange(val)}
                    btnname="Tax"
                    btnshow={false}
                    show_popup={""}
                    valueonly={false}
                    defaultval={iIGSTTaxId}
                    displayName="strTaxDesc"
                    disvalue="iTaxId"
                    arrydata={arrIGST}
                    disabled={false}
                    replace={["IGST", "GST"]}
                    EmptVal="Tax"
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <label>
                    SAC Code <span className="danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={strSACCode}
                    onChange={(e) => setstrSACCode(e.target.value)}
                    placeholder="Enter SAC Code"
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="popup-footer mt-2">
          <div className="popup-btn-group">
            <button className="btn  btn-fabgreen" onClick={SaveClick}>
              Save
            </button>
            <button className="btn  btn-fabgreen" onClick={ClearClick}>
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartyCreation;
