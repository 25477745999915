import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import { API_POST } from "../General/Utility";
import ReactTable from "../General/ReactTable";

function MasterCode(props) {
  const dispatch = useDispatch();
  const _Auth = useSelector((state) => state.Common.Admin);
  const _Config = useSelector((state) => state.Config.Config);
  const [strSearch, setstrSearch] = useState("");
  const [MasterCodeList, setMasterCodeList] = useState([]);
  useEffect(() => {
    Load_MasterCodeformate("");
  }, [props]);

  const Load_MasterCodeformate = async (strtxt) => {
    try {
      setstrSearch(strtxt);
      Pre_Loading(dispatch, true);
      const objSearch = {
        strSearch: strtxt,
        iBranchId: Number(_Auth.Branch),
        strDBName: _Auth.DB,
      };
      const res = await API_POST(
        `ConfigurationSetting/Load_MasterCodeSetting/`,
        objSearch,
        dispatch
      );
      if (res) {
        setMasterCodeList(res.EntCodeFormate);
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Save_CodeSetting = async () => {
    try {
      Pre_Loading(dispatch, true);
      let UpdateVoucherNumber = MasterCodeList;
      let CheckUserFormate = UpdateVoucherNumber.filter(
        (Data) => !Data.codeFormat.includes("#N")
      );
      if (CheckUserFormate.length > 0)
        Show_Message(dispatch, "#N is Mandatory", "info");
      else {
        const res = await API_POST(
          "ConfigurationSetting/Update_MasterCodeSetting/",
          {
            _EntMsCodeSett: UpdateVoucherNumber,
            strDBName: _Auth.DB,
            iBranchId: Number(_Auth.Branch),
          },
          dispatch
        );
        if (res) {
          Show_Message(dispatch, res.strMessage, "success");
        }
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Clear_CodeSetting = () => {
    setMasterCodeList([]);
    Load_MasterCodeformate("");
  };
  const Input_OnChange = async (Value, RowInfo, valueType) => {
    let UpdateVoucherList = MasterCodeList;
    switch (valueType) {
      case "MaxDigit":
        UpdateVoucherList = UpdateVoucherList.map((Data) => {
          if (Data.scrId === RowInfo.scrId) {
            Data.numSeqMaxDigit = Number(Value);
          }
          return Data;
        });
        setMasterCodeList(UpdateVoucherList);
        break;
      case "nextNumber":
        Pre_Loading(dispatch, true);
        const res = await API_POST(
          `ConfigurationSetting/View_NextMasterNumber/${RowInfo.scrId}/${Number(
            Value
          )}/${Number(_Auth.Branch)}
            /${_Auth.DB}`,
          dispatch
        );
        if (res) {
          UpdateVoucherList = UpdateVoucherList.map((Data) => {
            if (Data.scrId === RowInfo.scrId) {
              if (RowInfo.numSeqMaxDigit < Value.length) {
                Show_Message(
                  dispatch,
                  "Next Number Lenth More the MaxDigit",
                  "info"
                );
              } else Data.nextNumber = Number(Value);
            }
            return Data;
          });
          setMasterCodeList(UpdateVoucherList);
        }
        break;
      case "isManualNumber":
        UpdateVoucherList = UpdateVoucherList.map((Data) => {
          if (Data.scrId === RowInfo.scrId) {
            Data.isManualNumber = Value;
          }
          return Data;
        });
        setMasterCodeList(UpdateVoucherList);
        break;
      default:
        UpdateVoucherList = UpdateVoucherList.map((Data) => {
          if (Data.scrId === RowInfo.scrId) Data.codeFormat = Value;
          return Data;
        });
        setMasterCodeList(UpdateVoucherList);
        break;
    }
  };
  const MasterCodeColumn = [
    {
      Header: "Screenn Name",
      accessor: "scrName",
      style: { justifyContent: "left" },
    },
    {
      Header: "Prefix",
      accessor: "prefix",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Max Digit",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          defaultValue={rowinfo.numSeqMaxDigit}
          className="form-control"
          onClick={(e) => e.target.select()}
          onChange={(e) => (rowinfo.numSeqMaxDigit = e.target.value)}
        />
      ),
    },
    {
      Header: "Next Number",
      cell: ({ rowinfo }) => (
        <input
          type="number"
          defaultValue={rowinfo.nextNumber}
          className="form-control"
          onClick={(e) => e.target.select()}
          onChange={(e) => (rowinfo.nextNumber = e.target.value)}
        />
      ),
    },
    {
      Header: "Code  Formate",
      cell: ({ rowinfo }) => (
        <input
          type="text"
          defaultValue={rowinfo.codeFormat}
          className="form-control"
          onClick={(e) => e.target.select()}
          onChange={(e) => (rowinfo.codeFormat = e.target.value)}
        />
      ),
    },
    {
      Header: "Code",
      accessor: "code",
    },
    {
      Header: "Manual No",
      maxWidth: 100,
      accessor: "isManualNumber",
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          className="form-check-input"
          type="checkbox"
          defaultChecked={rowinfo.isManualNumber}
          onChange={() => (rowinfo.isManualNumber = !rowinfo.isManualNumber)}
        />
      ),
    },
  ];
  return (
    <div className="Config-card">
      <div className="config-h">
        <h3>{_Config.Header}</h3>
        <div className="m-hsearch">
          <input
            type="text"
            autoCorrect="off"
            autoComplete="off"
            placeholder="Search Voucher Screen"
            value={strSearch}
            onChange={(e) => {
              Load_MasterCodeformate(e.target.value, "Search");
            }}
            autoFocus
          />
          <i
            className="fas fa-search"
            onClick={(e) => Load_MasterCodeformate(strSearch, "Search")}
          ></i>
        </div>
      </div>
      <div className="Config-Wrapper mt-1">
        <ReactTable
          columns={MasterCodeColumn}
          data={MasterCodeList}
          minRows={10}
          row_click={(ProInfo, Index) => {}}
          row_doubleclick={(ProInfo, Index) => {}}
          background={true}
          className="auto-y"
        />
      </div>
      <div className="btn-section">
        <button
          type="button"
          className="btn-fabgreen"
          onClick={Save_CodeSetting}
        >
          Update &nbsp; <i className="fas fa-Save"></i>
        </button>
        <button
          type="button"
          className="btn-fabgreen"
          onClick={Clear_CodeSetting}
        >
          Clear &nbsp; <i className="fas fa-eraser"></i>
        </button>
      </div>
    </div>
  );
}
export default MasterCode;
