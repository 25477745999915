import React from "react";
import $ from "jquery";
import Messagedialogbox from "../General/Messagedialogbox";
import SideMenu from "../General/SideMenu";
import Configuration from "../Setting/Configuration";
import CustomizeScreen from "../Setting/CustomizeScreen";
import UserRole from "../Setting/UserRole";
import WarehouseMaster from "./WarehouseMaster";
import CounterMaster from "./CounterMaster";
import EmployeeMaster from "./EmployeeMaster";
import { useDispatch, useSelector } from "react-redux";
import { Open_Configuration } from "../features/Configuration";
import UserMaster from "./UserMaster";
import MasterCode from "../Setting/MasterCode";
import VoucherNo from "../Setting/VoucherNo";
function ConfigurationSetting() {
  const dispatch = useDispatch();
  const _Config = useSelector((state) => state.Config.Config);

  const settingnameclick = async (Index) => {
    $(`.sett-l:eq(${Index})`)
      .toggleClass("active")
      .siblings()
      .removeClass("active");
  };
  const ViewSetting = async (cartIndex, DT) => {
    $(`.sett-l:eq(${cartIndex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");
    $(`.sett-sub li:eq(${DT.Index - 1})`)
      .addClass("active")
      .siblings()
      .removeClass("active");
    $(".Config-container").addClass("active");
    $(".sett-menu").addClass("active");
    await dispatch(
      Open_Configuration({
        value: DT.HeaderIndex === 1 ? DT.Value : DT.ScrId,
        Header: DT.Name,
        SettIndex: DT.Index,
        IsShowConfig: DT.Config ? true : false,
        IsShowCustom: DT.Custome ? true : false,
      })
    );
  };
  return (
    <>
      <SideMenu ScrId="0" />
      <div className="Config-container">
        <div className="Config-Menu">
          <ul className="sett-menu">
            {_Config.EntConfigType.map((HT, HIndex) => (
              <li key={HIndex} className="sett-l">
                <h1 onClick={(e) => settingnameclick(HIndex)}>
                  <span>{HT}</span>
                  <i className="fas fa-caret-down"></i>
                </h1>
                <ul className="sett-sub">
                  {_Config.EntConfig.map(
                    (DT, tIndex) =>
                      HIndex === DT.HeaderIndex && (
                        <li
                          key={tIndex}
                          onClick={(e) => ViewSetting(HIndex, DT)}
                        >
                          {DT.Name}
                        </li>
                      )
                  )}
                </ul>
              </li>
            ))}
          </ul>
        </div>
        {_Config.SettIndex === 1 && <WarehouseMaster />}
        {_Config.SettIndex === 2 && <CounterMaster />}
        {_Config.SettIndex === 3 && <EmployeeMaster />}
        {_Config.SettIndex === 4 && <UserMaster />}
        {_Config.SettIndex === 5 && <UserRole />}
        {_Config.SettIndex === 47 && <MasterCode />}
        {_Config.SettIndex === 48 && <VoucherNo />}
        {_Config.IsShowCustom && <CustomizeScreen />}
        {_Config.IsShowConfig && <Configuration />}
        <Messagedialogbox />
      </div>
    </>
  );
}

export default ConfigurationSetting;
