import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "../General/SideMenu";
import MasterList from "./MasterListItem";
import {
  API_DELETE,
  API_FETCH,
  API_POST,
  DecryptData,
} from "../General/Utility";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import Messagedialogbox, {
  Ask_Question,
  Show_Message,
  Pre_Loading,
} from "../General/Messagedialogbox";
import Button from "./MasterButton";
import MasterCodeSetting from "./MasterCodeSetting";
import $ from "jquery";
import { Country_OnChange } from "./MasterTransaction";
function DoctorMaster() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _ScrId = 101;
  const [strSearch, setstrSearch] = useState("");

  const [DocId, setDocId] = useState(0);
  const [NumSeqNo, setNumSeqNo] = useState("");
  const [Prefix, setPrefix] = useState("");
  const [Doccode, setDoccode] = useState("");
  const [ManualCode, setManualCode] = useState(true);
  const [DocName, setDocName] = useState("");
  const [RegNo, setRegNo] = useState("");
  const [Specialization, setSpecialization] = useState("");
  const [Qualifications, setQualifications] = useState("");
  const [Experience, setExperience] = useState("");
  const [Add1, setAdd1] = useState("");
  const [Add2, setAdd2] = useState("");
  const [Add3, setAdd3] = useState("");
  const [Add4, setAdd4] = useState("");
  const [Pincode, setPincode] = useState("");
  const [State, setState] = useState("32");
  const [Country, setCountry] = useState("100");
  const [Mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [Status, setStatus] = useState(1);
  const [Remark, setRemark] = useState("");
  const [arrEntDoc, setarrEntDoc] = useState([]);
  const [arrEntState, setarrEntState] = useState([]);
  const [arrEntCountry, setarrEntCountry] = useState([]);
  const [arrEntStatus, setarrEntStatus] = useState([]);
  const [arrEntProperties, setarrEntProperties] = useState([]);

  const [arrEntFields, setarrEntFields] = useState([
    { ColName: "DocName", Name: "Name", isShow: true },
    { ColName: "Doccode", Name: "Code", isShow: true },
    { ColName: "RegNo", Name: "Register No", isShow: true },
    { ColName: "Specialization", Name: "Specialization", isShow: true },
    { ColName: "Qualifications", Name: "Qualifications", isShow: true },
    { ColName: "Experience", Name: "Experience", isShow: true },
    { ColName: "Add1", Name: "Address No", isShow: true },
    { ColName: "Add2", Name: "Street / Area", isShow: true },
    { ColName: "Add3", Name: "Village/Town", isShow: true },
    { ColName: "Add4", Name: "City", isShow: true },
    { ColName: "Pincode", Name: "Pincode", isShow: true },
    { ColName: "State", Name: "State", isShow: true },
    { ColName: "Country", Name: "Country", isShow: true },
    { ColName: "Mobile", Name: "Mobile No", isShow: true },
    { ColName: "Email", Name: "Email", isShow: true },
    { ColName: "Remark", Name: "Remark", isShow: true },
    { ColName: "Status", Name: "Status", isShow: true },
  ]);
  const [arrTabOrder, setarrTabOrder] = useState({
    txtDocName: "",
    txtDocCode: "",
    txtRegNo: "",
    txtSpecialization: "",
    txtQualifications: "",
    txtExperience: "",
    txtAdd1: "",
    txtAdd2: "",
    txtAdd3: "",
    txtAdd4: "",
    txtPincode: "",
    cbCountry: "",
    cbState: "",
    txtMobile: "",
    txtEmail: "",
    txtRemark: "",
    cbStatus: "",
    btnSave: "",
    btnClear: "",
    btnDelete: "",
    btnClose: "",
    btnExpImp: "",
    txtSearch: "",
    listDoc: "",
  });
  const [isExportImport, setisExportImport] = useState(false);
  const [IsMasterCode, setIsMasterCode] = useState(false);
  const [maxTab, setmaxTab] = useState(0);

  useEffect(() => {
    Load_Docter();
  }, []);

  const Load_Docter = async () => {
    try {
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);
      await Pre_Loading(dispatch, true);
      const parems = await `DoctorMaster/Load_DoctorMaster/${_ScrId}/${Number(
        _LocalParems.Branch
      )}/${_LocalParems.DB}`;
      const res = await API_FETCH(parems, dispatch);
      if (res.strStatus === "Success") {
        const Codeformate = res.Codeformate[0];
        setarrEntStatus(res.EntStatus);
        setarrEntCountry(res.EntCountry);
        setarrEntState(res.EntState);
        setarrEntProperties(res.EntProperties);
        setarrEntDoc(res.EntDoctor);
        setNumSeqNo(Codeformate.iNumSeqNo);
        setPrefix(Codeformate.strPrefix);
        setDoccode(Codeformate.strCode);
        setManualCode(!Codeformate.isManualNo);
        await Assign_TabOrder(res.EntProperties);
        await Assign_FieldName(res.EntFields);
      } else {
        Show_Message(dispatch, res.strMessage, "error");
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      Pre_Loading(dispatch, false);
    }
  };
  const Search_Docter = async (strtxt) => {
    try {
      setstrSearch(strtxt);
      await Pre_Loading(dispatch, true);
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      Pre_Loading(dispatch, false);
    }
  };
  const Save_DoctorMaster = async () => {
    try {
      const objDoctor = {
        DocId: DocId,
        NumSeqNo: NumSeqNo.toString(),
        Prefix: Prefix,
        Doccode: Doccode,
        DocName: DocName,
        RegNo: RegNo,
        Specialization: Specialization,
        Qualifications: Qualifications,
        Experience: Experience,
        Add1: Add1,
        Add2: Add2,
        Add3: Add3,
        Add4: Add4,
        Pincode: Pincode,
        State: State,
        Country: Country,
        Mobile: Mobile,
        Email: Email,
        Status: Status,
        Remark: Remark,
        BranchId: Number(_AuthParems.Branch),
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        `DoctorMaster/InsUpd_Doctor`,
        objDoctor,

        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Click();
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const View_Docter = async (doc) => {
    try {
      const res = await API_FETCH(
        `DoctorMaster/View_DoctorMaster/${doc.docId}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        const _Doctor = res.EntDoctor[0];
        setDocId(_Doctor.docId);
        setNumSeqNo(_Doctor.numSeqNo);
        setPrefix(_Doctor.prefix);
        setDoccode(_Doctor.doccode);
        setManualCode(true);
        setDocName(_Doctor.docName);
        setRegNo(_Doctor.regNo);
        setSpecialization(_Doctor.specialization);
        setQualifications(_Doctor.qualifications);
        setExperience(_Doctor.experience);
        setAdd1(_Doctor.add1);
        setAdd2(_Doctor.add2);
        setAdd3(_Doctor.add3);
        setAdd4(_Doctor.add4);
        setPincode(_Doctor.pincode);
        setState(_Doctor.state.toString());
        setCountry(_Doctor.country.toString());
        setMobile(_Doctor.mobile);
        setEmail(_Doctor.email);
        setStatus(_Doctor.status);
        setRemark(_Doctor.remark);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const Delete_Docter = async () => {
    try {
      const res = await API_DELETE(
        `DoctorMaster/Delete_Doctor/${Number(DocId)}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Click();
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const Clear_Click = () => {
    setDocId(0);
    setNumSeqNo("");
    setPrefix("");
    setDoccode("");
    setManualCode("");
    setDocName("");
    setRegNo("");
    setAdd1("");
    setAdd2("");
    setAdd3("");
    setAdd4("");
    setPincode("");
    setState("32");
    setCountry("100");
    setMobile("");
    setEmail("");
    setStatus(1);
    setRemark("");
    setSpecialization("");
    setQualifications("");
    setExperience("");
    Load_Docter();
  };

  const Assign_TabOrder = async (EntProperties) => {
    for (var i = 0; i < EntProperties.length; i++) {
      arrTabOrder[EntProperties[i].strCtrlName] = EntProperties[i].iTabIndex;
    }
    var TotalTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    );
    setarrTabOrder(arrTabOrder);
    setmaxTab(TotalTab.iTabIndex);
  };
  const Assign_FieldName = async (EntFields) => {
    let EntPro = arrEntFields;
    EntFields.forEach((dt) => {
      EntPro.forEach((Data) => {
        if (Data.ColName === dt.strColName) {
          Data.Name = dt.strFieldName;
          Data.isShow = dt.bIsVissible;
        }
      });
    });
    setarrEntFields(EntPro);
  };
  const Key_Down = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === arrTabOrder.listDoc) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === arrTabOrder.listDoc) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === arrTabOrder.listDoc) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === arrTabOrder.listDoc) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === arrTabOrder.listDoc) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === arrTabOrder.listDoc) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === arrTabOrder.listDoc) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  const Properties_Validation = async () => {
    var ErrorMsg = true;
    let ValidateItem = arrEntProperties.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtDocName":
          ErrorMsg = DocName === "" ? false : true;
          break;
        case "txtDocCode":
          ErrorMsg = Doccode === "" ? false : true;
          break;
        case "txtRegNo":
          ErrorMsg = RegNo === "" ? false : true;
          break;
        case "txtAdd1":
          ErrorMsg = Add1 === "" ? false : true;
          break;
        case "txtAdd2":
          ErrorMsg = Add2 === "" ? false : true;
          break;
        case "txtAdd3":
          ErrorMsg = Add3 === "" ? false : true;
          break;
        case "txtAdd4":
          ErrorMsg = Add4 === "" ? false : true;
          break;
        case "txtPincode":
          ErrorMsg = Pincode === "" ? false : true;
          break;
        case "cbState":
          ErrorMsg = Number(State) === 0 ? false : true;
          break;
        case "cbCountry":
          ErrorMsg = Number(Country) === 0 ? false : true;
          break;
        case "txtMobile":
          ErrorMsg = Mobile === "" ? false : true;
          break;
        case "txtEmail":
          ErrorMsg = Email === "" ? false : true;
          break;
        case "txtRemark":
          ErrorMsg = Remark === "" ? false : true;
          break;
        case "cbStatus":
          ErrorMsg = Number(Status) === 0 ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        Show_Message(dispatch, ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };

  return (
    <>
      <SideMenu ScrId={_ScrId} />
      <div className="Master-container">
        <MasterList
          ScrId={_ScrId}
          strSearchtxt={strSearch}
          tabOrder={arrTabOrder.txtSearch}
          Search={(val) => Search_Docter(val)}
          arrMasterData={arrEntDoc}
          isImg={false}
          displayName="docName"
          View_Data={(Doc) => View_Docter(Doc)}
          placeholder="Doctor"
        />
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-sm-12 col-md-3">
                <label>Doctor Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Doctor Name"
                  spellCheck={false}
                  autoComplete="off"
                  value={DocName}
                  onChange={(e) => setDocName(e.target.value)}
                  autoFocus
                  tabIndex={arrTabOrder.txtDocName}
                />
              </div>
              {arrEntFields[1].isShow && (
                <div className="col-sm-12 col-md-3">
                  <label>{arrEntFields[1].Name}</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Doctor Code"
                      spellCheck={false}
                      autoComplete="off"
                      value={Doccode}
                      onChange={(e) => setDoccode(e.target.value)}
                      disabled={ManualCode}
                      tabIndex={arrTabOrder.txtDocCode}
                    />
                    <span
                      className="inv-no"
                      onClick={(e) => setIsMasterCode(!IsMasterCode)}
                    >
                      <i className="bx bx-cog"></i>
                    </span>
                  </div>
                </div>
              )}
              {arrEntFields[2].isShow && (
                <div className="col-sm-12 col-md-3">
                  <label>{arrEntFields[2].Name}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Register No"
                    spellCheck={false}
                    autoComplete="off"
                    value={RegNo}
                    onChange={(e) => setRegNo(e.target.value)}
                    tabIndex={arrTabOrder.txtRegNo}
                  />
                </div>
              )}
              {arrEntFields[3].isShow && (
                <div className="col-sm-12 col-md-3">
                  <label>{arrEntFields[3].Name}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Specialization"
                    spellCheck={false}
                    autoComplete="off"
                    value={Specialization}
                    onChange={(e) => setSpecialization(e.target.value)}
                    tabIndex={arrTabOrder.Specialization}
                  />
                </div>
              )}
              {arrEntFields[4].isShow && (
                <div className="col-sm-12 col-md-3">
                  <label>{arrEntFields[4].Name}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Qualifications"
                    spellCheck={false}
                    autoComplete="off"
                    value={Qualifications}
                    onChange={(e) => setQualifications(e.target.value)}
                    tabIndex={arrTabOrder.Qualifications}
                  />
                </div>
              )}
              {arrEntFields[5].isShow && (
                <div className="col-sm-12 col-md-3">
                  <label>{arrEntFields[5].Name}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Experience"
                    spellCheck={false}
                    autoComplete="off"
                    value={Experience}
                    onChange={(e) => setExperience(e.target.value)}
                    tabIndex={arrTabOrder.Experience}
                  />
                </div>
              )}
              {arrEntFields[6].isShow && (
                <div className="col-sm-12 col-md-3">
                  <label>{arrEntFields[6].Name}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Address No"
                    spellCheck={false}
                    autoComplete="off"
                    value={Add1}
                    onChange={(e) => setAdd1(e.target.value)}
                    tabIndex={arrTabOrder.txtAdd1}
                  />
                </div>
              )}
              {arrEntFields[7].isShow && (
                <div className="col-sm-12 col-md-3">
                  <label>{arrEntFields[7].Name}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Street /Area"
                    spellCheck={false}
                    autoComplete="off"
                    value={Add2}
                    onChange={(e) => setAdd2(e.target.value)}
                    tabIndex={arrTabOrder.txtAdd2}
                  />
                </div>
              )}
              {arrEntFields[8].isShow && (
                <div className="col-sm-12 col-md-3">
                  <label>{arrEntFields[8].Name}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Village / Town"
                    spellCheck={false}
                    autoComplete="off"
                    value={Add3}
                    onChange={(e) => setAdd3(e.target.value)}
                    tabIndex={arrTabOrder.txtAdd3}
                  />
                </div>
              )}
              {arrEntFields[9].isShow && (
                <div className="col-sm-12 col-md-3">
                  <label>{arrEntFields[9].Name}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter City"
                    spellCheck={false}
                    autoComplete="off"
                    value={Add4}
                    onChange={(e) => setAdd4(e.target.value)}
                    tabIndex={arrTabOrder.txtAdd4}
                  />
                </div>
              )}
              {arrEntFields[10].isShow && (
                <div className="col-sm-12 col-md-3">
                  <label>{arrEntFields[10].Name}</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Pincode"
                    spellCheck={false}
                    autoComplete="off"
                    value={Pincode}
                    onChange={(e) => setPincode(e.target.value)}
                    tabIndex={arrTabOrder.txtPincode}
                  />
                </div>
              )}
              {arrEntFields[11].isShow && (
                <div className="col-md-3 col-sm-6 mb-2">
                  <label>
                    {arrEntFields[11].Name} <span className="danger">*</span>
                  </label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow"
                    showoption={_selecrparem.isoptionShow}
                    placeholder="Select Country"
                    tabIndex={arrTabOrder.cbCountry}
                    select_value={async (val) => {
                      await setCountry(val);
                      await setarrEntState(
                        await Country_OnChange(val, _AuthParems.DB, dispatch)
                      );
                    }}
                    btnname="Country"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={Country}
                    displayName="strCountryName"
                    disvalue="iCountryId"
                    arrydata={arrEntCountry}
                    disabled={false}
                    EmptVal="Country "
                  />
                </div>
              )}
              {arrEntFields[12].isShow && (
                <div className="col-md-3 col-sm-6 mb-2">
                  <label>
                    {arrEntFields[12].Name} <span className="danger">*</span>
                  </label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow1"
                    showoption={_selecrparem.isoptionShow1}
                    placeholder="Select State"
                    tabIndex={arrTabOrder.cbState}
                    select_value={(val) => setState(val)}
                    btnname="State"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={State}
                    displayName="strSName"
                    disvalue="iSId"
                    arrydata={arrEntState}
                    disabled={false}
                    EmptVal="State "
                  />
                </div>
              )}
              {arrEntFields[13].isShow && (
                <div className="col-sm-12 col-md-3">
                  <label>{arrEntFields[13].Name}</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Mobile No"
                    spellCheck={false}
                    autoComplete="off"
                    value={Mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    tabIndex={arrTabOrder.txtMobile}
                  />
                </div>
              )}
              {arrEntFields[14].isShow && (
                <div className="col-sm-12 col-md-3">
                  <label>{arrEntFields[14].Name}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Email"
                    spellCheck={false}
                    autoComplete="off"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                    tabIndex={arrTabOrder.txtEmail}
                  />
                </div>
              )}
              {arrEntFields[15].isShow && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>{arrEntFields[15].Name}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Please Enter Remark"
                    value={Remark}
                    onChange={(e) => setRemark(e.target.value)}
                    tabIndex={arrTabOrder.txtRemark}
                  />
                </div>
              )}
              {arrEntFields[16].isShow && (
                <div className="col-md-3 col-sm-6 mb-2">
                  <label>{arrEntFields[16].Name}</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow2"
                    showoption={_selecrparem.isoptionShow2}
                    placeholder="Select Status"
                    tabIndex={arrTabOrder.cbStatus}
                    select_value={(val) => setStatus(val)}
                    btnname="Status"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={Status}
                    displayName="strStatusName"
                    disvalue="iStatusId"
                    arrydata={arrEntStatus}
                    disabled={false}
                    EmptVal="Status "
                  />
                </div>
              )}
            </div>
            {IsMasterCode && (
              <MasterCodeSetting
                Close={(e) => setIsMasterCode(!IsMasterCode)}
                iScrId={_ScrId}
              />
            )}
          </div>
          <Messagedialogbox />
          <Button
            Save={(e) => {
              if (DocName.length === 0)
                Show_Message(dispatch, "Please Enter Doctor Name", "info");
              else if (Doccode.length === 0)
                Show_Message(dispatch, "Please Enter Doctor Code", "info");
              else if (Properties_Validation())
                if (DocId > 0) {
                  Ask_Question(
                    dispatch,
                    Save_DoctorMaster,
                    "Do You Want to Update Doctor?"
                  );
                } else {
                  Ask_Question(
                    dispatch,
                    Save_DoctorMaster,
                    "Do You Want to Save Doctor?"
                  );
                }
            }}
            SaveIndex={arrTabOrder.btnSave}
            DeleteIndex={arrTabOrder.btnDelete}
            CloseIndex={arrTabOrder.btnClose}
            ClearIndex={arrTabOrder.btnClear}
            ExportIndex={arrTabOrder.btnExpImp}
            Export={(e) => setisExportImport(!isExportImport)}
            onKeyDown={(e) => Key_Down(e)}
            Clear={() => Clear_Click()}
            ScrId={_ScrId}
            Iid={DocId}
            Delete={(e) => {
              if (Number(DocId) === 0) {
                Show_Message(dispatch, "Please Select Doctor", "info");
              } else
                Ask_Question(
                  dispatch,
                  Delete_Docter,
                  "Do You Want to  Delete Doctor?"
                );
            }}
          />
        </div>
      </div>
    </>
  );
}

export default DoctorMaster;
