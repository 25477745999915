import React, { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import Messagedialogbox from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
function GSTTaxMaster() {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [strFilterColumn, setstrFilterColumn] = useState("ProName");
  const [strSearch, setstrSearch] = useState("");

  const [iItemTypeId, setiItemTypeId] = useState(27);
  const [iTaxLvlId, setiTaxLvlId] = useState(1);
  const [SGSTTaxId, setSGSTTaxId] = useState(24);
  const [CGSTTaxId, setCGSTTaxId] = useState(27);
  const [IGSTTaxId, setIGSTTaxId] = useState(18);
  const [TcsTaxId, setTcsTaxId] = useState(43);

  const [isTcsShow, setisTcsShow] = useState(false);
  const [isAllItem, setisAllItem] = useState(false);
  const [BtnSaveDisable, setBtnSaveDisable] = useState(false);

  const [ListEntFillter, setListEntFillter] = useState([]);
  const [ListEntItemType, setListEntItemType] = useState([]);
  const [ListEntTaxLevel, setListEntTaxLevel] = useState([]);
  const [ListEntCgst, setListEntCgst] = useState([]);
  const [ListEntsgst, setListEntsgst] = useState([]);
  const [ListEntigst, setListEntigst] = useState([]);
  const [ListEntTCS, setListEntTCS] = useState([]);
  const [ListEntList, setListEntList] = useState([]);

  useEffect(() => {
    Load_TaxMaster();
  }, []);

  const Load_TaxMaster = async () => {
    try {
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);
      const objSearch = {
        ItemtypeId: iItemTypeId,
        iTaxLevel: iTaxLvlId,
        strColName: strFilterColumn,
        strDBName: _LocalParems.DB,
        iBranchId: _LocalParems.Branch,
      };

      const res = await API_POST(
        "TaxMaster/Load_TaxMaster/",
        objSearch,
        dispatch
      );
      if (res) {
        setListEntFillter(res.objEntCmbFilter);
        setListEntItemType(res.objEntItemTyp);
        setListEntTaxLevel(res.objEntTaxType);
        setListEntCgst(res.objEntCGSTTax);
        setListEntsgst(res.objEntSGSTTax);
        setListEntigst(res.objEntIGSTTax);
        setListEntTCS(res.objEntTCSTax);
        res.objEntItem.sort((a, b) => a.sNo - b.sNo);
        setListEntList(res.objEntItem);
        ButtonPermission(_LocalParems.MasterPer);
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const UpdateClick = async () => {
    try {
      Pre_Loading(dispatch, true);
      const objEntTax = {
        strSearchColumn: strFilterColumn,
        strSearch: strSearch,
        iTaxLvl: Number(iTaxLvlId),
        iItemTypeId: Number(iItemTypeId),
        iCGSTTaxId: Number(CGSTTaxId),
        iSGSTTaxId: Number(SGSTTaxId),
        iIGSTTaxId: Number(IGSTTaxId),
        iTCSTaxId: Number(TcsTaxId),
      };
      let Row = ListEntList.filter((e) => e.checkBox === true);
      const objEntTaxUpd = {
        objBulkTaxModel: objEntTax,
        objTaxDT: isAllItem === true ? [] : Row,
        isTaxForAll: isAllItem,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "TaxMaster/Update_Tax/",
        objEntTaxUpd,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        ClearClick();
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const ClearClick = () => {
    try {
      setstrSearch("");
      setSGSTTaxId(24);
      setCGSTTaxId(27);
      setIGSTTaxId(18);
      setTcsTaxId(43);
      setiTaxLvlId(1);
      setisAllItem(false);
      setBtnSaveDisable(false);
      setisTcsShow(false);
      setstrFilterColumn("ProName");
      setListEntList([]);
      Load_TaxMaster();
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Search_Product = async (Search) => {
    try {
      setstrSearch(Search);
      const objsearch = {
        ItemtypeId: iItemTypeId,
        strSearch: Search,
        iTaxLevel: iTaxLvlId,
        strColName: strFilterColumn,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "TaxMaster/Search_Product/",
        objsearch,
        dispatch
      );
      if (res) {
        setListEntList(res.objEntItem);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Filter_Product = async (strCol) => {
    try {
      setstrFilterColumn(strCol);
      const objsearch = {
        ItemtypeId: iItemTypeId,
        strSearch: strSearch,
        iTaxLevel: iTaxLvlId,
        strColName: strCol,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "TaxMaster/Search_Product/",
        objsearch,
        dispatch
      );
      if (res) {
        setListEntList(res.objEntItem);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Screentype_OnChange = async (ItemTypeId) => {
    try {
      setiItemTypeId(ItemTypeId);
      const res = await API_FETCH(
        "TaxMaster/Load_TaxType/" +
          ItemTypeId +
          "/" +
          iTaxLvlId +
          "/" +
          strFilterColumn +
          "/" +
          _AuthParems.DB,
        dispatch
      );
      if (res) {
        setListEntList(res.objEntItem);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const TaxTypeChange = async (iTaxLvl) => {
    try {
      Pre_Loading(dispatch, true);
      setiTaxLvlId(Number(iTaxLvl));
      const res = await API_FETCH(
        "TaxMaster/Load_TaxType/" +
          iItemTypeId +
          "/" +
          Number(iTaxLvl) +
          "/" +
          strFilterColumn +
          "/" +
          _AuthParems.DB,
        dispatch
      );
      if (res) {
        setisTcsShow(Number(iTaxLvl) === 1 ? false : true);
        setListEntList(res.objEntItem);
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const TaxOnChange = (objtax) => {
    let dTaxPer = Number(objtax.dTaxPer);
    var TaxType = objtax.strTaxDesc.split(" ")[0];
    switch (TaxType) {
      case "SGST":
        setSGSTTaxId(objtax.iTaxId);
        setCGSTTaxId(
          ListEntCgst.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setIGSTTaxId(
          ListEntigst.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      case "CGST":
        setSGSTTaxId(
          ListEntsgst.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setCGSTTaxId(objtax.iTaxId);
        setIGSTTaxId(
          ListEntigst.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      default:
        setIGSTTaxId(objtax.iTaxId);
        setSGSTTaxId(
          ListEntsgst.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        setCGSTTaxId(
          ListEntCgst.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        break;
    }
  };
  const CheckBoxChange = (checked, rowInfo) => {
    try {
      let EntList = ListEntList;
      EntList = EntList.map((e) => {
        if (e.itemId === rowInfo.itemId) {
          e.checkBox = checked;
        }
        return e;
      });
      setListEntList(EntList);
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const AllBoxOnChange = (isallSelect) => {
    try {
      setisAllItem(isallSelect);
      let listEntItem = ListEntList;
      listEntItem = listEntItem.map((Data) => {
        Data.checkBox = isallSelect;
        return Data;
      });
      setListEntList(listEntItem);
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const ButtonPermission = (MasterPer) => {
    MasterPer = MasterPer.filter((Data) => Data.iScrId === 62);
    setBtnSaveDisable(!MasterPer[0].isModify);
  };
  return (
    <>
      <SideMenu ScrId={6} />
      <div className="Master-container">
        <div className="Master-card1">
          <div className="row p-2">
            <div className="col-md-2 col-sm-6 mt-2">
              <label>Screen Type</label>
              <CustomizedSelectoption
                optionparem="isoptionShow"
                showoption={_parem.isoptionShow}
                placeholder="Select Item Type"
                select_value={(val) => Screentype_OnChange(val)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iItemTypeId}
                displayName="strItemType"
                disvalue="iItemTypeId"
                arrydata={ListEntItemType}
                EmptVal="Item Type "
              />
            </div>
            <div className="col-md-2 col-sm-6 mt-2">
              <label>Tax Type</label>
              <CustomizedSelectoption
                optionparem="isoptionShow1"
                showoption={_parem.isoptionShow1}
                placeholder="Select Tax Type"
                select_value={(val) => TaxTypeChange(val)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iTaxLvlId}
                displayName="strTaxLvlType"
                disvalue="iTaxLvlId"
                arrydata={ListEntTaxLevel}
                EmptVal="Tax Type "
              />
            </div>
            {!isTcsShow && (
              <div className="col-md-2 col-sm-6 mt-2">
                <label>GST Tax</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow4"
                  showoption={_parem.isoptionShow4}
                  placeholder="Select IGST Tax"
                  select_value={(val) => TaxOnChange(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={false}
                  defaultval={IGSTTaxId}
                  displayName="strTaxDesc"
                  disvalue="iTaxId"
                  arrydata={ListEntigst}
                  EmptVal="GST Tax "
                  replace={["IGST", "GST"]}
                />
              </div>
            )}
            {isTcsShow && (
              <div className="col-md-2 col-sm-6 mt-2">
                <label>TCS Tax</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow5"
                  showoption={_parem.isoptionShow5}
                  placeholder="Select TCS Tax"
                  select_value={(val) => setTcsTaxId(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={TcsTaxId}
                  displayName="strTaxDesc"
                  disvalue="iTaxId"
                  arrydata={ListEntTCS}
                  EmptVal="TCS Tax "
                />
              </div>
            )}
            <div className="col-md-4 col-sm-12 mt-2 ">
              <br />
              <div className="m-hsearch mt-1">
                <input
                  type="text"
                  autoCorrect="off"
                  autoComplete="off"
                  placeholder="Search"
                  value={strSearch}
                  onChange={(e) => Search_Product(e.target.value)}
                  autoFocus
                />
                <i
                  className="fas fa-search"
                  onClick={(e) => Search_Product(strSearch)}
                ></i>
              </div>
            </div>
            {iItemTypeId === 27 && (
              <div className="col-md-2 col-sm-12 mt-2">
                <label>Search By</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow6"
                  showoption={_parem.isoptionShow6}
                  placeholder="Select Filter"
                  select_value={(val) => Filter_Product(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={strFilterColumn}
                  displayName="strDisplayName"
                  disvalue="strValueName"
                  arrydata={ListEntFillter}
                  EmptVal="Filter Column "
                />
              </div>
            )}
          </div>
          <div className="Master-wrapper">
            <div className="taxtable">
              <table>
                <thead>
                  <tr>
                    <th>SNo</th>
                    <th>
                      <input
                        type="checkbox"
                        className="form-check"
                        checked={isAllItem}
                        onChange={(e) => AllBoxOnChange(!isAllItem)}
                      />
                    </th>
                    <th>Product</th>
                    {iTaxLvlId === 1 && <th>SGST</th>}
                    {iTaxLvlId === 1 && <th>CGST</th>}
                    {iTaxLvlId === 1 && <th>IGST</th>}
                    {iTaxLvlId !== 1 && <th>TCS</th>}
                  </tr>
                </thead>
                <tbody>
                  {ListEntList.map((product, Index) => (
                    <tr key={Index}>
                      <td>{product.sNo}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={product.checkBox}
                          className="form-check"
                          onChange={(e) =>
                            CheckBoxChange(!product.checkBox, product)
                          }
                        />
                      </td>
                      <td>{product.itemName}</td>
                      {iTaxLvlId === 1 && <td>{product.sgstTaxDesc}</td>}
                      {iTaxLvlId === 1 && <td>{product.cgstTaxDesc}</td>}
                      {iTaxLvlId === 1 && <td>{product.igstTaxDesc}</td>}
                      {iTaxLvlId !== 1 && <td>{product.tcsTaxDesc}</td>}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <Messagedialogbox />
          <div className="btn-section">
            <button
              disabled={BtnSaveDisable}
              type="button"
              className="btn-fabgreen"
              onClick={(e) => {
                Ask_Question(
                  dispatch,
                  UpdateClick,
                  "Do You Want to  Update Tax?"
                );
              }}
            >
              Update &nbsp; <i className="fas fa-Save"></i>
            </button>
            <button type="reset" className="btn-fabgreen" onClick={ClearClick}>
              Clear &nbsp; <i className="fas fa-eraser"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={() => (window.location.href = `/JustBill/DashBoard`)}
            >
              Close &nbsp; <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default GSTTaxMaster;
