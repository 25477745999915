import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Transaction, { Load_Transaction } from "../Transaction/Transaction";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import { API_POST } from "../General/Utility";
import { Clear_Transaction } from "../features/Transactionstates";
function SalesEnquiry() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _Common = useSelector((store) => store.Transstates.Common);
  const _HT = useSelector((store) => store.Transstates.Header);
  const _DT = useSelector((store) => store.Transstates.Data);
  const _Sum = useSelector((store) => store.Transstates.Summary);
  const _Disc = useSelector((store) => store.Transstates.Discount);

  const _iScrId = 13;

  useEffect(() => {
    Load_Transaction(dispatch, _Common, _iScrId);
  }, []);

  const SaveClick = async () => {
    if (_DT.arrInvListDT.length === 0) {
      Show_Message(dispatch, "Atleast One Product Required", "error");
    } else {
      Pre_Loading(dispatch, true);
      const SalEnqListHT = {
        iBranchId: Number(_AuthParems.Branch),
        iSalEnqId: _HT.iVouchId,
        iTitleTag: _HT.iScrId,
        iPartyId: _HT.iPartyId,
        strShippingAdd:
          _HT.strShippingAdd === "" ? _HT.strPartyAdd : _HT.strShippingAdd,
        iNumSeqNo: _Common.arrNoFormat[0].iNumSeqNo,
        strPrefix: _Common.arrNoFormat[0].strPrefix,
        strCompCode: _Common.arrNoFormat[0].strCompCode,
        strFinanFrom: _Common.arrNoFormat[0].strFinanFrom,
        strTransNo: _Common.arrNoFormat[0].strTransNo,
        strVouchNo: _HT.strVouchNo,
        strVouchDate: _HT.strVouchDate,
        iAddLessBillDiscId: _HT.iALBillDiscId,
        iBillTypeId: _HT.iBillTypeId,
        iTaxCalcOnId: _HT.iTaxCalcOnId,
        iTaxTypeId: _HT.iTaxRelId,
        iDMId: _HT.iDMId,
        strConvertDCVouch: _HT.strConvertDCVouch,
        strPartyDCVouch: _HT.strPartyDCVouch,
        strRefNum1: _HT.strRefNum1,
        dpRefNum1Date: _HT.dpRefNum1Date,
        strRefNum2: _HT.strRefNum2,
        dpRefNum2Date: _HT.dpRefNum2Date,
        strRefNum3: _HT.strRefNum3,
        dpRefNum3Date: _HT.dpRefNum3Date,
        strRefNum4: _HT.strRefNum4,
        dpRefNum4Date: _HT.dpRefNum4Date,
        strRemark1: _HT.strRemark1,
        strRemark2: _HT.strRemark2,
        strRemark3: _HT.strRemark3,
        strRemark4: _HT.strRemark4,
        strValidity: _HT.strValidity,
        dpValDateUpto: _HT.dpValDateUpto,
        strPayTerm: _HT.strPayTerm,
        dpPayTermDateUpto: _HT.dpPayTermDateUpto,
        strDeliTerm: _HT.strDeliTerm,
        dpDelTermDateUpto: _HT.dpDelTermDateUpto,
        strEComGstNo: _HT.strEComGstNo,
        strTransport: _HT.strTransport,
        strVehicle: _HT.strVehicle,
        strDespatch: _HT.strDespatch,
        dpDespatchDate: _HT.dpDespatchDate,
        strLRNNumber: _HT.strLRNNumber,
        dpLRNoDate: _HT.dpLRNoDate,
        strAgent: _HT.strAgent,
        strReference: _HT.strReference,
        strDestination: _HT.strDestination,

        dTBillDiscPer: Number(_Disc.dBillDisPer),
        dTBillDiscAmt: Number(_Disc.dBillDisAmt),
        dTBillDiscValue: Number(_Disc.dBillDisValue),
        dTAddLessDiscPer: Number(_Disc.dALBillDisPer),
        dTAddLessDiscAmt: Number(_Disc.dALBillDisAmt),
        dTAddLessDiscValue: Number(_Disc.dALBillDisValue),

        dTotQty: Number(_Sum.Qty),
        dTotGoodval: Number(_Sum.GoodsValue),
        dTotDiscount: Number(_Sum.TotalDiscount),
        dTotTax: Number(_Sum.TotalTax),
        dAddLess: Number(_Sum.TotalAddLess),
        dTotTCSTax: Number(_Sum.TotalTCSTax),
        dRoundOff: Number(_Sum.RoundOff),
        dNetAmt: Number(_Sum.NetAmt),
      };
      const res = await API_POST(
        "SalesEnquiry/InsUpd_SalesEnquiry/",
        {
          objEntSI: SalEnqListHT,
          objBaseTransDT: _DT.arrInvListDT,
          objEntALDT: _DT.arrInvListAL,
          objEntTax: _DT.arrInvListTax,
          iUserId: Number(_AuthParems.User),
          FYid: Number(_AuthParems.Year),
          strDBName: _AuthParems.DB,
          objDTHelp: _DT.arrInvListDTHelp,
        },
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Click();
      }
    }
  };
  const Clear_Click = async () => {
    try {
      await Pre_Loading(dispatch, true);
      await dispatch(Clear_Transaction());
      await Load_Transaction(dispatch, _Common, _Common.iScrId);
    } catch (e) {
      await Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };

  return <Transaction Save={SaveClick} ScrId={_iScrId} />;
}
export default SalesEnquiry;
