import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Show_Message } from "../General/Messagedialogbox";
import { API_FETCH, API_POST } from "../General/Utility";
import {
  Select_ReturnProduct,
  ShowHide_Popup,
} from "../features/Transactionstates";
import ReactTable from "../General/ReactTable";
function SearchReturn() {
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.Transstates.Common);
  const _HT = useSelector((store) => store.Transstates.Header);
  const _DT = useSelector((store) => store.Transstates.Data);
  const [arrEntReturn, setarrEntReturn] = useState([]);

  useEffect(() => {
    Load_ReturnProducts();
  }, []);
  const Load_ReturnProducts = async () => {
    const res = await API_FETCH(
      `Transaction/Load_ReturnProducts/${_HT.iScrId}/${_HT.iPartyId}/${
        _HT.iTaxRelId
      }/${Number(_AuthParems.Branch)}/${_AuthParems.DB}`,
      dispatch
    );
    if (res) {
      let EntRetPro = res.EntReturnPro;
      if (_DT.arrEntDT.length > 0) {
        let NotExistsItem = [];
        for (var i = 0; i < EntRetPro.length; i++) {
          for (let j = 0; j < _DT.arrEntDT.length; j++) {
            if (
              _DT.arrEntDT[j].proId !== EntRetPro[i].proId &&
              _DT.arrEntDT[j].vouchId !== EntRetPro[i].vouchId &&
              _DT.arrEntDT[j].qty !== EntRetPro[i].qty
            ) {
              NotExistsItem.push(EntRetPro[i]);
            }
          }
        }
        NotExistsItem.map((Data) => {
          Data.vouchDate = Data.vouchDate.split(" ")[0];
          return Data;
        });
        setarrEntReturn(NotExistsItem);
      } else {
        EntRetPro.map((Data) => {
          Data.vouchDate = Data.vouchDate.split(" ")[0];
          return Data;
        });
        setarrEntReturn(EntRetPro);
      }
    }
  };
  const ConvertClick = async () => {
    try {
      let EntRetPro = arrEntReturn.filter((DT) => DT.isChecked === true);
      const res = await API_POST(
        `Transaction/View_ReturnProducts`,
        {
          bUpdateStatus: false,
          iRowIndex: 0,
          iScrId: _Common.iScrId,
          iTaxRelId: _HT.iTaxRelId,
          iPartyId: _HT.iPartyId,
          iTaxCalcOnId: _Common.iTaxCalcOnId,
          objBaseTransDT: _DT.arrEntDT,
          objReturnModel: EntRetPro,
          strDBName: _AuthParems.DB,
          iBranchId: Number(_AuthParems.Branch),
        },
        dispatch
      );
      if (res) {
        await dispatch(Select_ReturnProduct(res));
        await dispatch(ShowHide_Popup("IsReturnPro"));
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const columns = [
    {
      Header: "Select",
      accessor: "IsChecked",
      minWidth: 50,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          className="form-check"
          type="checkbox"
          defaultChecked={false}
          id="IsChecked"
          onClick={() =>
            (rowinfo.isChecked = rowinfo.isChecked === true ? false : true)
          }
        />
      ),
    },
    {
      Header: "Vouch No",
      accessor: "vouchNo",
      style: { justifyContent: "left" },
      minWidth: 150,
    },
    {
      Header: "Vouch Date",
      accessor: "vouchDate",
      minWidth: 100,
      style: { justifyContent: "center" },
    },

    {
      Header: "Product Code",
      accessor: "proCode",
      minWidth: 100,
      style: { justifyContent: "left" },
    },
    {
      Header: "Product Name",
      accessor: "proName",
      minWidth: 250,
      style: { justifyContent: "left" },
    },
    {
      Header: "Qty",
      accessor: "qty",
      DataType: "Decimal",
      minWidth: 100,
      style: { justifyContent: "right" },
    },

    {
      Header: "Unit",
      accessor: "unit",
      minWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Rate W/O Tax",
      accessor: "rateWithoutTax",
      DataType: "Decimal",
      maxWidth: 150,
      minWidth: 150,
      style: { justifyContent: "right" },
    },
    {
      Header: "Rate With Tax",
      accessor: "rateWithTax",
      DataType: "Decimal",
      maxWidth: 150,
      minWidth: 150,
      style: { justifyContent: "right" },
    },
    {
      Header: "Item Discount",
      accessor: "itemDiscValue",
      DataType: "Decimal",
      maxWidth: 150,
      minWidth: 150,
      style: { justifyContent: "right" },
    },
    {
      Header: "Item Amount",
      accessor: "itemAmt",
      DataType: "Decimal",
      maxWidth: 150,
      minWidth: 150,
      style: { justifyContent: "right" },
    },
  ];

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>{_Common.strScrName}</h5>
          <div className="icon-popup-btn-grp">
            <span
              className="icon-btn"
              onClick={() => dispatch(ShowHide_Popup("IsReturnPro"))}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body pt-2 ">
          <div className="row p-2">
            <ReactTable
              columns={columns}
              data={arrEntReturn}
              minRows={10}
              row_click={(rowInfo, Index) => {}}
              row_doubleclick={(rowInfo, Index) => {}}
              background={true}
              className="full-table"
            />
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button className="btn-fabgreen" onClick={ConvertClick}>
              Convert&nbsp;<i className="bx bx-transfer"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SearchReturn;
