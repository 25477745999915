import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Transaction, { Load_Transaction } from "../Transaction/Transaction";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import { API_POST } from "../General/Utility";
import { Clear_Transaction } from "../features/Transactionstates";

function OutwardChallan() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _Common = useSelector((store) => store.Transstates.Common);
  const _HT = useSelector((store) => store.Transstates.Header);
  const _DT = useSelector((store) => store.Transstates.Data);
  const _Sum = useSelector((store) => store.Transstates.Summary);

  const _iScrId = 72;

  useEffect(() => {
    Load_Transaction(dispatch, _Common, _iScrId);
  }, []);
  const SaveClick = async () => {
    let SaveItem = _DT.arrInvListDT.filter(
      (Data) => Number(Data.jobId) === 0 && Number(Data.isSerProduct) === 0
    );
    if (_DT.arrInvListDT.length === 0) {
      Show_Message(dispatch, "Atleast One Item is Required per Bill", "error");
    } else if (SaveItem.length > 0) {
      Show_Message(dispatch, "Converted Product Job Name Rquired", "error");
    } else {
      Pre_Loading(dispatch, true);
      const OutWDCListHT = {
        iOutWDCId: _HT.iVouchId,
        iBranchId: Number(_AuthParems.Branch),
        iTitleTag: _HT.iScrId,
        iPartyId: _HT.iPartyId,
        strShippingAdd:
          _HT.strShippingAdd === ""
            ? _HT.strPartyAdd
            : _HT.strShippingAdd,
        iNumSeqNo: _Common.arrNoFormat[0].iNumSeqNo,
        strPrefix: _Common.arrNoFormat[0].strPrefix,
        strCompCode: _Common.arrNoFormat[0].strCompCode,
        strFinanFrom: _Common.arrNoFormat[0].strFinanFrom,
        strTransNo: _Common.arrNoFormat[0].strTransNo,
        strVouchNo: _HT.strVouchNo,
        strVouchDate: _HT.strVouchDate,
        iAddLessBillDiscId: _HT.iALBillDiscId,
        iBillTypeId: _HT.iBillTypeId,
        iTaxCalcOnId: _HT.iTaxCalcOnId,
        iTaxTypeId: _HT.iTaxRelId,
        iDMId: _HT.iDMId,
        strConvertDCVouch: _HT.strConvertDCVouch,
        strPartyDCVouch: _HT.strPartyDCVouch,
        strRefNum1: _HT.strRefNum1,
        dpRefNum1Date: _HT.dpRefNum1Date,
        strRefNum2: _HT.strRefNum2,
        dpRefNum2Date: _HT.dpRefNum2Date,
        strRefNum3: _HT.strRefNum3,
        dpRefNum3Date: _HT.dpRefNum3Date,
        strRefNum4: _HT.strRefNum4,
        dpRefNum4Date: _HT.dpRefNum4Date,
        strRemark1: _HT.strRemark1,
        strRemark2: _HT.strRemark2,
        strRemark3: _HT.strRemark3,
        strRemark4: _HT.strRemark4,
        strEComGstNo: _HT.strEComGstNo,
        strTransport: _HT.strTransport,
        strVehicle: _HT.strVehicle,
        strDespatch: _HT.strDespatch,
        dpDespatchDate: _HT.dpDespatchDate,
        strLRNNumber: _HT.strLRNNumber,
        dpLRNoDate: _HT.dpLRNoDate,
        strAgent: _HT.strAgent,
        strReference: _HT.strReference,
        strDestination: _HT.strDestination,

        dTotQty: Number(_Sum.Qty),
        dTotGoodval: Number(_Sum.GoodsValue),
        dRoundOff: Number(_Sum.RoundOff),
        dNetAmt: Number(_Sum.NetAmt),
      };
      const res = await API_POST(
        "OutwardChallan/InsUpd_OutwardChallan/",
        {
          objEntSI: OutWDCListHT,
          objBaseTransDT: _DT.arrInvListDT,
          objEntPC: _DT.arrInvPenConListDT,
          iUserId: Number(_AuthParems.User),
          objDTHelp: _DT.arrInvListDTHelp,
          FYid: Number(_AuthParems.Year),
          strDBName: _AuthParems.DB,
        },
        dispatch
      );
      if (res) {
        Clear_Click();
        Show_Message(dispatch, res.strMessage, "success");
      }
    }
  };
  const Clear_Click = async () => {
    try {
      await Pre_Loading(dispatch, true);
      await dispatch(Clear_Transaction());
      await Load_Transaction(dispatch, _Common, _Common.iScrId);
    } catch (e) {
      await Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  return <Transaction Save={SaveClick} ScrId={_iScrId} />;
}
export default OutwardChallan;
